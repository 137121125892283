import styled from 'styled-components';

import { device } from 'components/device';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ onlyOne }) => (onlyOne ? '100%' : '17.188rem')};
  min-height: 17.125rem;
  padding: 1.875rem;
  border: 1px solid #c6c8cc;
  border-radius: 0.5rem;

  @media ${device.laptop} {
    width: 100%;
    min-height: 14.25rem;
    padding: 1.25rem;
  }
`;

export const WrapTitle = styled.div`
  max-width: 20rem;

  @media ${device.laptop} {
    max-width: unset;
  }
`;

export const Title = styled.h4`
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: #000000;

  @media ${device.laptop} {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;

export const WrapFooter = styled.div`
  display: flex;
`;

export const DownloadLink = styled.a`
  display: inline-block;
  font-size: 0.875rem;
  color: #ffffff;
  padding: 0.875rem 1.25rem;
  background: #45a7df;
  border-radius: 6.25rem;

  @media ${device.laptop} {
    font-size: 0.875rem;
  }
`;

export const WrapDownloadInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;

  @media ${device.laptop} {
    margin-left: 1.5rem;
  }
`;

export const Image = styled.img`
  margin-right: 0.5rem;
  width: 100%;
  max-width: 1.25rem;
  height: 100%;
  max-height: 1.25rem;
`;

export const FileSize = styled.p`
  font-size: 1rem;
  line-height: 1.625rem;
  color: #8f9194;

  @media ${device.laptop} {
    font-size: 0.875rem;
  }
`;
