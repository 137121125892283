// Libs
import React from 'react';

//content

import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';

// Components
import { device } from '../device';

const Content = styled.div`
  padding: ${props => props.paddingContent || '0.8125rem 1.25rem'};
  margin-top: ${props => props.marginTopContent};
  margin-bottom: ${props => props.marginBottomContent};
  order: ${props =>
    props.isAdjustOrderAlertDesktop && props.isAdjustOrderAlertDesktop};
  z-index: ${props => props.isZindex && props.isZindex};
  width: 100%;
  height: auto;
  display: flex;
  justify-content: ${props => props.justifyContentCenter && 'center'};
  align-items: ${props => props.alignItemsContentCenter && 'center'};
  background-color: ${props => props.backgroundColorContent || '#ecf6fc'};
  border-radius: ${props => !props.noBorderRadiusContent && '12px'};

  ${props =>
    props.alertMarkdown &&
    `
      a {
      color: rgb(69, 167, 223);
      width: auto;
      font-size: 0.875rem;
      font-weight: 600;
      z-index: 2;

      :hover {
        cursor: pointer;
      }
    }

    p {
      font-size: 0.875rem;
    }
  `}
  @media ${device.laptop} {
    border-radius: ${props => !props.noBorderRadiusContent && '8px'};
    margin: ${props => props.marginContentLap || '0.875rem 0 1.875rem 0'};

    ${props =>
      props.isFixed &&
      `
      position: fixed;
      top: 0;
    `}
  }
  @media ${device.mobile} {
    order: ${props =>
      props.isAdjustOrderAlertMobile && props.isAdjustOrderAlertMobile};
  }
`;

const Text = styled.p`
  font-size: 0.875rem;
  line-height: ${props => !props.textBorderBottom && '1.5rem'};
  display: flex;
  color: ${props => props.textColor || '#231f20'};
  border-bottom: ${props => props.textBorderBottom && '1px solid #fff'};

  @media ${device.laptop} {
    display: block;
  }
`;

const TextBold = styled.p`
  margin-right: 0.3rem;
  font-size: 0.875rem;
  font-weight: 600;
`;

const LinkBlog = styled(Link)`
  color: ${props => props.linkcolor || '#45a7df'};
  width: auto;
  font-size: 0.875rem;
  font-weight: 600;
  z-index: 2;

  :hover {
    cursor: pointer;
  }
`;

function Alert({
  isFixed,
  isOncologia,
  paddingContent,
  marginTopContent,
  marginBottomContent,
  justifyContentCenter,
  alignItemsContentCenter,
  backgroundColorContent,
  noBorderRadiusContent,
  marginContentLap,
  alertMarkdown,
  textColor,
  textBorderBottom,
  haveBoldText,
  linkcolor,
  alertText,
  linkAlert,
  externalLink,
  isAdjustOrderAlertDesktop,
  isAdjustOrderAlertMobile,
  isZindex,
}) {
  return (
    <>
      <Content
        isFixed={isFixed}
        isOncologia={isOncologia}
        paddingContent={paddingContent}
        marginTopContent={marginTopContent}
        marginBottomContent={marginBottomContent}
        justifyContentCenter={justifyContentCenter}
        alignItemsContentCenter={alignItemsContentCenter}
        backgroundColorContent={backgroundColorContent}
        noBorderRadiusContent={noBorderRadiusContent}
        marginContentLap={marginContentLap}
        alertMarkdown={alertMarkdown}
        isAdjustOrderAlertDesktop={isAdjustOrderAlertDesktop}
        isZindex={isZindex}
        isAdjustOrderAlertMobile={isAdjustOrderAlertMobile}
      >
        {alertMarkdown ? (
          <ReactMarkdown
            children={alertMarkdown}
            linkTarget={externalLink ? '_blank' : '_self'}
          />
        ) : (
          <Text textColor={textColor} textBorderBottom={textBorderBottom}>
            {haveBoldText && (
              <TextBold>Quando a saúde não pode esperar: </TextBold>
            )}
            {alertText}

            {linkAlert && (
              <LinkBlog to={linkAlert} linkcolor={linkcolor}>
                &nbsp;Saiba mais.
              </LinkBlog>
            )}
          </Text>
        )}
      </Content>
    </>
  );
}
export default Alert;
