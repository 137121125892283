// Libs
import React, { useState, useContext, useEffect } from 'react';

import { Link, StaticQuery, graphql } from 'gatsby';
import flatten from 'lodash.flatten';

// Context
import { HeaderContext } from 'context/header';
import { InternacionalizationContext } from 'context/internacionalization';

// Components
import AlertPostFeriado from 'components/alerts/alertPostFeriado';
import CustomerSelect from 'components/CustomerSelect';

//ComponentsDS
import {
  TmpButton,
  TmpLink,
  TmpTypography,
  TmpButtonIcon,
} from '@tempo/tempo-design-system-core/dist';
import { busca as Search } from '@tempo/tempo-assets/dist';

// Images
import Logo from 'images/logos/logo_hsl.svg';
import arrowDown from 'images/icons/arrow-down.svg';
import arrowUp from 'images/icons/ds-arrow-up.svg';
import dropIcon from 'images/icons/drop-mobile.svg';
import closeIcon from 'images/icons/close-menu.svg';
import BackIcon from 'images/icons/arrow-left.svg';

import BrazilIcon from 'images/icons/flags/brazil.svg';
import SpainIcon from 'images/icons/flags/spain.svg';

// Styled
import {
  ContainerInternationalPatient,
  HeaderAlertContainer,
  WrapHeaderAlert,
  HeaderAlertLeftContent,
  HeaderAlertContent,
  HeaderAlertRightContent,
  TopBarLinks,
  TopBarLink,
  HeaderGridArea,
  Container,
  ContainerMarketingLogo,
  LogoHsl,
  ButtonDropMobile,
  DropMobile,
  Overlay,
  ContainerMenuResponsive,
  MenuList,
  ItemsList,
  MobileHighlightLinkExternal,
  MobileHighlightLinkInternal,
  ContainerNav,
  WrapperNav,
  NavItem,
  Img,
  ButtonContainer,
  ContainerMenuDropdown,
  WrapperMenuDropdown,
  WrapTextDropdown,
  TextDropdown,
  ContentMenuDropdown,
  WrapperMenuResponsiveDropdown,
  ItemMenuDropdown,
  DropdownContent,
  ContainerCallCenterMob,
  DonationHeaderMobileLink,
  SearchIconContainer,
} from './style';

export default function Header({ HideHeader, modalBackLink, ...data }) {
  const isWindow = typeof window !== 'undefined';
  const pathname = isWindow && window.location.pathname;

  const {
    hasDefaultHeader,
    headerText,
    callCenterBackLink,
    setIsOverflow,
    hideHeader,
    showAlertPostFeriado,
    linkPostFeriado,
    setIsVisibleNovoSite,
  } = useContext(HeaderContext);

  const { language } = useContext(InternacionalizationContext);

  const [selectedItem, setSelectedItem] = useState(undefined);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpenMob, setIsMenuOpenMob] = useState(false);
  const [openClientY, setOpenClientY] = useState(0);
  const [animation, setAnimation] = useState(false);
  const [showAlertNovoSite, setShowAlertNovoSite] = useState(false);
  const [bgColor, setBgColor] = useState(null);
  const isNewSiteMessage = !showAlertPostFeriado && showAlertNovoSite;

  const translatedData =
    data?.localizations?.find(item => {
      const foundItemLocale = item.locale
        .toLowerCase()
        .includes(language.toLowerCase());

      if (!foundItemLocale) return false;

      if (item?.menuLinks?.length < 1) return false;

      return true;
    }) ??
    data?.localizations?.find(item => item.locale.toLowerCase().includes('pt'));

  const navMenuLink = translatedData?.menuLinks.map(menuLink => ({
    ...menuLink,
    hasSubmenu: menuLink.submenu ? true : false,
  }));
  const topBarLinks = translatedData?.topBarLinks ?? [];
  const navMobileLinks = [
    ...navMenuLink,
    ...topBarLinks,
    ...navMenuLink
      .filter(menuLink => menuLink.submenu)
      .reduce((acc, menuLink) => {
        acc.push(...menuLink.submenu);

        return acc;
      }, []),
  ]
    .filter(link => link.showOnMobileInPosition)
    .sort((a, b) => a.showOnMobileInPosition - b.showOnMobileInPosition);

  const countriesOption = [
    {
      value: 'PT',
      label: <img src={BrazilIcon} height="16px" width="23px" alt="" />,
    },
    {
      value: 'ES',
      label: <img src={SpainIcon} height="16px" width="23px" alt="" />,
    },
  ];

  const isInternalLink = link => {
    if (String(link)?.startsWith('/') || String(link)?.startsWith('./')) {
      return true;
    }

    return false;
  };

  const listenScrollEvent = event => {
    if (window.scrollY < 3) {
      return setBgColor('#ecf6fc');
    } else {
      return setBgColor(null);
    }
  };

  const handleMouseMoveHeader = ev => {
    if (
      selectedItem &&
      window.innerWidth >= '1024' &&
      ev.clientY <= openClientY - 5
    ) {
      handleSelectedItem(undefined, true);
    }
  };

  const handleMenuItem = () => {
    const menuItens = translatedData?.menuLinks?.find(
      item => item.name === selectedItem
    );

    return menuItens?.submenu ?? '';
  };

  const handleSelectedItem = (item, desk, e) => {
    if (desk && item && !selectedItem && e) {
      setOpenClientY(e.clientY);
    }

    if (desk) {
      if (selectedItem !== item) {
        setSelectedItem(item);
        !item && setIsMenuOpen(false);
        item && !isMenuOpen && setIsMenuOpen(true);
        setAnimation(true);
      }
    } else {
      if (selectedItem === item) {
        setSelectedItem(undefined);
        setAnimation(false);
      } else {
        setSelectedItem(item);
        setAnimation(true);
      }
    }
  };

  const renderDropdown = items => {
    const data = flatten(items);
    return (
      <ContentMenuDropdown data={data.length}>
        <WrapperMenuResponsiveDropdown data={data.length}>
          {data?.length > 1 &&
            data.map(
              item =>
                item.name &&
                item.link && (
                  <ItemMenuDropdown key={item.link}>
                    {isInternalLink(item.link) ? (
                      <Link to={item.link}>
                        <TextDropdown>
                          {isWindow && { TmpLink } && (
                            <TmpLink
                            // theme={item.theme ?? ''} // descomentar se for usar o theme
                            >
                              {item.name}
                            </TmpLink>
                          )}
                        </TextDropdown>
                      </Link>
                    ) : (
                      isWindow && { TmpLink } && (
                        <TmpLink
                          href={item.link}
                          target={item.target ?? '_blank'}
                          rel="noopener noreferrer"
                          // theme={item.theme ?? ''} // descomentar se for usar o theme
                        >
                          {item.name}
                        </TmpLink>
                      )
                    )}
                  </ItemMenuDropdown>
                )
            )}
        </WrapperMenuResponsiveDropdown>
      </ContentMenuDropdown>
    );
  };

  const renderMenuMobile = () => {
    const items = handleMenuItem();

    return (
      <Overlay isOpen={isMenuOpenMob}>
        <CustomerSelect items={countriesOption} isMobile></CustomerSelect>
        <ContainerMenuResponsive>
          <MenuList>
            {isWindow && { TmpButtonIcon } && (
              <SearchIconContainer>
                <TmpButtonIcon
                  size="lg"
                  icon={<Search />}
                  handleClick={() => window.open('/busca', '_self')}
                />
              </SearchIconContainer>
            )}
            {navMobileLinks.map(item =>
              isInternalLink(item.link) ? (
                <MobileHighlightLinkInternal
                  key={item.link}
                  to={item.link}
                  target={item.target ?? '_self'}
                >
                  {isWindow && { TmpLink } && <TmpLink>{item.name}</TmpLink>}
                </MobileHighlightLinkInternal>
              ) : (
                <MobileHighlightLinkExternal
                  key={item.link}
                  href={item.link}
                  target={item.target}
                  rel="noopener noreferrer"
                >
                  {isWindow && { TmpLink } && <TmpLink>{item.name}</TmpLink>}
                </MobileHighlightLinkExternal>
              )
            )}
            {navMenuLink.map(
              nav =>
                !nav.showOnMobileInPosition &&
                (nav.hasSubmenu ? (
                  <ItemsList
                    key={nav.name}
                    className="dropItems"
                    selectedItem={nav.name === selectedItem && isMenuOpen}
                    dropSelectedItem={nav.name === selectedItem}
                    onClick={() => handleSelectedItem(nav.name)}
                  >
                    <p>
                      {nav.name}
                      {nav.name === selectedItem && isMenuOpenMob ? (
                        <Img src={arrowUp} alt="Aberto" />
                      ) : (
                        <Img src={arrowDown} alt="Fechado" />
                      )}
                    </p>
                    {nav.name === selectedItem ? (
                      <DropdownContent onClick={ev => ev.stopPropagation()}>
                        {renderDropdown(items)}
                      </DropdownContent>
                    ) : (
                      <DropdownContent
                        onClick={ev => ev.stopPropagation()}
                        animation={animation}
                        menu={selectedItem}
                      />
                    )}
                  </ItemsList>
                ) : (
                  <ItemsList
                    key={nav.name}
                    onClick={() => setSelectedItem(undefined)}
                    className="dropItems"
                  >
                    <p>
                      {isInternalLink(nav.link) ? (
                        <Link
                          to={nav.link}
                          target={nav.target ?? '_self'}
                          style={{ color: 'inherit' }}
                        >
                          {nav.name}
                        </Link>
                      ) : (
                        <a
                          href={nav.link}
                          style={{ color: 'inherit' }}
                          target={nav.target ?? '_blank'}
                          rel="noopener noreferrer"
                        >
                          {nav.name}
                        </a>
                      )}
                    </p>
                  </ItemsList>
                ))
            )}
          </MenuList>
        </ContainerMenuResponsive>
      </Overlay>
    );
  };

  const renderOpenMenu = () => {
    const items = handleMenuItem();
    return (
      <ContainerMenuDropdown
        dropdown={selectedItem}
        animation={animation}
        menu={isMenuOpen}
        onMouseLeave={() => handleSelectedItem(undefined, true)}
        bgColor={bgColor}
      >
        <WrapperMenuDropdown menu={isMenuOpen}>
          <WrapTextDropdown>
            <TextDropdown isTitle>
              {isWindow && { TmpTypography } && (
                <TmpTypography component="subtitle">
                  {selectedItem}
                </TmpTypography>
              )}
            </TextDropdown>
          </WrapTextDropdown>
          {renderDropdown(items)}
        </WrapperMenuDropdown>
      </ContainerMenuDropdown>
    );
  };

  useEffect(() => {
    if (pathname === '/' || pathname === '/home' || pathname === '/home/') {
      setBgColor('#ecf6fc');
      window.addEventListener('scroll', listenScrollEvent);
      return () => window.removeEventListener('scroll', listenScrollEvent);
    }
  }, []);

  // useEffect(() => {
  //   let increaseAccess = 0;

  //   if (typeof window !== 'undefined') {
  //     increaseAccess = parseInt(localStorage.getItem('blogNovoSite'));

  //     if (localStorage.getItem('blogNovoSite')) {
  //       if (increaseAccess < 6) {
  //         increaseAccess = parseInt(localStorage.getItem('blogNovoSite')) + 1;

  //         localStorage.setItem('blogNovoSite', increaseAccess);
  //       }
  //     } else {
  //       localStorage.setItem('blogNovoSite', 1);
  //     }

  //     increaseAccess >= 6
  //       ? setShowAlertNovoSite(false)
  //       : setShowAlertNovoSite(true);
  //   }
  // }, []);

  useEffect(() => {
    setIsVisibleNovoSite(showAlertNovoSite);
  }, [showAlertNovoSite]);

  useEffect(() => {
    setIsOverflow(true);
  }, []);

  return (
    <HeaderGridArea
      HideHeader={HideHeader || hideHeader}
      id="header"
      bgColor={bgColor}
    >
      <AlertPostFeriado backgroundColor="#45A7DF" textColor="#fff" />
      <ContainerInternationalPatient
        isVisible={
          (showAlertPostFeriado && linkPostFeriado) || isNewSiteMessage
        }
      >
        <HeaderAlertContainer>
          <WrapHeaderAlert>
            <HeaderAlertLeftContent>
              <HeaderAlertContent>
                {isNewSiteMessage && (
                  <>
                    Nosso site mudou.{' '}
                    <Link to="/blog/acontecenosiriolibanes/nosso-site-mudou-confira-as-novidades">
                      Saiba mais
                    </Link>
                  </>
                )}
                {showAlertPostFeriado && linkPostFeriado && (
                  <>
                    Confira os horários no feriado.{' '}
                    <Link to={linkPostFeriado}>Saiba mais</Link>
                  </>
                )}
              </HeaderAlertContent>
            </HeaderAlertLeftContent>
            <HeaderAlertRightContent>
              <TopBarLinks>
                {topBarLinks.map(
                  topBarLink =>
                    topBarLink.name &&
                    topBarLink.link &&
                    (isInternalLink(topBarLink.link) ? (
                      topBarLink.icon_url ? (
                        <TopBarLink key={topBarLink.link} to={topBarLink.link}>
                          {isWindow && { TmpLink } && (
                            <TmpLink>{topBarLink.name}</TmpLink>
                          )}
                          <img
                            src={topBarLink.icon_url}
                            alt={topBarLink.name}
                          />
                        </TopBarLink>
                      ) : (
                        <TopBarLink key={topBarLink.link} to={topBarLink.link}>
                          {isWindow && { TmpLink } && (
                            <TmpLink>{topBarLink.name}</TmpLink>
                          )}
                        </TopBarLink>
                      )
                    ) : (
                      isWindow && { TmpLink } && (
                        <TmpLink
                          key={topBarLink.link}
                          href={topBarLink.link}
                          target={topBarLink.target ?? '_blank'}
                          rel="noopener noreferrer"
                        >
                          {topBarLink.name}
                        </TmpLink>
                      )
                    ))
                )}
              </TopBarLinks>
              <CustomerSelect items={countriesOption}></CustomerSelect>
            </HeaderAlertRightContent>
          </WrapHeaderAlert>
        </HeaderAlertContainer>
      </ContainerInternationalPatient>
      <Container
        onMouseMove={ev => handleMouseMoveHeader(ev)}
        isMobOpen={isMenuOpenMob}
        bgColor={bgColor}
      >
        <ButtonDropMobile
          hasDefaultHeader={hasDefaultHeader}
          isOpen={isMenuOpenMob}
          onClick={() => {
            setIsMenuOpenMob(!isMenuOpenMob);
            setSelectedItem(undefined);
            setAnimation(false);
          }}
        >
          <DropMobile src={isMenuOpenMob ? closeIcon : dropIcon} alt="Menu" />
        </ButtonDropMobile>
        <ContainerMarketingLogo hasDefaultHeader={hasDefaultHeader}>
          <Link to="/">
            <LogoHsl isOpen={isMenuOpenMob} src={Logo} alt="Sírio-Libanês" />
          </Link>
          {[
            [...navMenuLink, ...topBarLinks].find(
              linkData => linkData.showOnMobileHeader
            ),
          ].map(linkData => (
            <DonationHeaderMobileLink
              key={linkData.link}
              to={linkData.link}
              isOpen={isMenuOpenMob}
            >
              {isWindow && { TmpButton } && (
                <TmpButton
                  type="secondary"
                  size="sm"
                  startIcon={
                    linkData.icon_url && (
                      <img src={linkData.icon_url} alt={linkData.name} />
                    )
                  }
                >
                  {linkData.name}
                </TmpButton>
              )}
            </DonationHeaderMobileLink>
          ))}
        </ContainerMarketingLogo>

        <ContainerNav hasDefaultHeader={!hasDefaultHeader}>
          <WrapperNav width="100%">
            {navMenuLink
              ?.filter(menuLink => !menuLink.showOnlyMobile)
              .map(item =>
                item.hasSubmenu ? (
                  <NavItem
                    key={item.name}
                    selectedItem={item.name === selectedItem && isMenuOpen}
                    onMouseMove={ev =>
                      handleSelectedItem(item.name, 'desk', ev)
                    }
                  >
                    {item.name}

                    {item.name === selectedItem && isMenuOpen ? (
                      <Img src={arrowUp} alt="Aberto" />
                    ) : (
                      <Img src={arrowDown} alt="Fechado" />
                    )}
                  </NavItem>
                ) : (
                  item.theme !== 'button' && (
                    <NavItem key={item.name}>
                      {isInternalLink(item.link) ? (
                        <Link
                          to={item.link}
                          target={item.target ?? '_self'}
                          style={{ color: 'inherit' }}
                        >
                          {item.name}
                        </Link>
                      ) : (
                        <a
                          href={item.link}
                          target={item.target ?? '_blank'}
                          rel="noopener noreferrer"
                          style={{ color: 'inherit' }}
                        >
                          {item.name}
                        </a>
                      )}
                    </NavItem>
                  )
                )
              )}
          </WrapperNav>
          <WrapperNav>
            {isWindow && { TmpButtonIcon } && (
              <SearchIconContainer>
                <TmpButtonIcon
                  size="lg"
                  icon={<Search />}
                  handleClick={() => window.open('/busca', '_self')}
                />
              </SearchIconContainer>
            )}
          </WrapperNav>
          <WrapperNav>
            {navMenuLink
              ?.filter(menuLink => !menuLink.showOnlyMobile)
              .map(
                item =>
                  item.theme === 'button' && (
                    <ButtonContainer key={item.name}>
                      {isWindow && { TmpButton } && (
                        <TmpButton
                          type="primary"
                          size="sm"
                          handleClick={() => {
                            isWindow && isInternalLink(item.link)
                              ? window.open(item?.link, item?.target ?? '_self')
                              : window.open(
                                  item?.link,
                                  item?.target ?? '_blank'
                                );
                          }}
                        >
                          {item.name}
                        </TmpButton>
                      )}
                    </ButtonContainer>
                  )
              )}
          </WrapperNav>
          {!hasDefaultHeader && (
            <ContainerCallCenterMob>
              <Link to={modalBackLink ? '' : callCenterBackLink}>
                <Img src={BackIcon} alt="Voltar" callCenterMob />
              </Link>
              <div>
                <p>{headerText}</p>
              </div>
            </ContainerCallCenterMob>
          )}
        </ContainerNav>
        {isMenuOpen && renderOpenMenu()}
      </Container>
      {isMenuOpenMob && renderMenuMobile()}
    </HeaderGridArea>
  );
}
