import React from 'react';

import { formatClassName } from 'utils/formatClassName';
import ListPosts from 'components/blog/listPosts';

import { Container, Content } from './style';

function ListPostContent({
  type,
  title,
  headerLinkTitle,
  headerLink,
  posts,
  sendTo,
  isDesignSystem,
}) {
  const hasPosts = posts.length >= 1;

  return hasPosts ? (
    <Container className={formatClassName(type)}>
      <Content className={formatClassName(type)}>
        <ListPosts
          title={title ?? ''}
          headerLink={headerLink ?? ''}
          headerLinkTitle={headerLinkTitle ?? ''}
          postsToShow={3}
          posts={posts}
          sendTo={sendTo}
          isDesignSystem={isDesignSystem}
        />
      </Content>
    </Container>
  ) : (
    <></>
  );
}

export { ListPostContent };
