import React from 'react';

import { CustomFragment } from 'components/CmsComponents/CustomFragment';
import { formatClassName } from 'utils/formatClassName';

import { Container, Content, Box, Information } from './style';

function DSLandingSchedulingCards(content) {
  return (
    <Container
      bgColor={content?.backgroundColor?.css ?? 'transparent'}
      data-testid="container"
    >
      <Content hasBorderBottom={!content?.backgroundColor?.css}>
        <Box>
          <Information>
            {content?.fragments?.map(fragment => {
              return (
                <CustomFragment
                  key={fragment.id}
                  className={formatClassName(fragment.type)}
                  children={fragment?.markdown?.replaceAll('[break]', '\n')}
                  debugName={fragment.name}
                  singletexts={fragment.singletexts}
                />
              );
            })}
          </Information>
        </Box>
      </Content>
    </Container>
  );
}

export { DSLandingSchedulingCards };
