import React from 'react';
import ReactMarkdown from 'react-markdown';

import {
  Container,
  ContainerImportanceText,
  Text,
  ContainerMessageText,
  MessageLink,
} from './style.js';

const BreakingNews = ({ markdownContent, className = '' }) => (
  <Container className={className}>
    {markdownContent?.[0]?.singletexts?.[0] ? (
      <ContainerImportanceText importance className={className}>
        <Text importance className={className}>
          {markdownContent?.[0]?.singletexts?.[0]}
        </Text>
      </ContainerImportanceText>
    ) : null}
    <ContainerMessageText className={className}>
      <Text className={className}>
        <ReactMarkdown
          className={className}
          children={markdownContent?.[0]?.markdown || '-'}
        />
        {markdownContent?.[0]?.singletexts?.[1] && (
          <MessageLink
            className={className}
            to={markdownContent?.[0]?.singletexts?.[1] || '/'}
          >
            Saiba mais
          </MessageLink>
        )}
      </Text>
    </ContainerMessageText>
  </Container>
);

export default BreakingNews;
