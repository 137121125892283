// Libs
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
// Components
import { device } from 'components/device';

export const Container = styled.div``;

export const DSWrapper = styled.div`
  > h1 {
    margin-bottom: 1rem;
    text-align: center;
  }
  > span {
    display: block;
    text-align: center;
    margin-bottom: 1.75rem;
  }
  div {
    background: transparent !important;
  }
  button {
    background: transparent !important;
  }
  .tmp-accordion__content--opened {
    max-height: none;
  }
`;

export const Title = styled(ReactMarkdown)`
  p,
  h2 {
    font-size: 2.25rem;
    font-weight: 600;
    text-align: center;
    margin: 4.375rem 0 0;

    @media ${device.laptop} {
      font-size: 1.625rem;
      margin: 1.875rem 0 0;
    }
  }
`;

export const ContainerAuthor = styled.div`
  margin: 0 0 4.375rem;

  @media ${device.laptop} {
    margin: 0 0 1.875rem;
  }

  p,
  a,
  h2 {
    font-weight: normal;
    font-size: 1rem;
    color: #231f20;
    text-align: center;

    @media ${device.laptop} {
      font-size: 0.875rem;
    }
  }

  a {
    margin-left: 0.5rem;
    color: rgb(69, 167, 223);
  }
  .hiper-link:hover {
    text-decoration: underline;
  }
`;

export const FaqLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;

  a {
    display: flex;
    text-decoration: none;
  }

  button {
    border: none;
    color: #45a7df;
    text-align: center;
    text-decoration: none;
    background-color: #fff;
    font-size: 16px;
  }
`;
export const ContentText = styled(ReactMarkdown)`
  font-size: 1rem;
  line-height: 1.625rem;
  color: ${props =>
    props.colorText
      ? ''
      : props.theme.textColor
      ? props.theme.textColor
      : props.isFormatted
      ? '#231F20'
      : '#8f9194'};
  padding: ${props =>
    props.isPaddingAccordion ? '1.25rem 0 1.875rem' : '1.25rem 0 3.125rem'};
  margin-bottom: ${props =>
    props.contentTextMarginBottom
      ? props.contentTextMarginBottom
      : props.DEFAULT};

  @media ${device.mobile} {
    margin-bottom: 0;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: normal;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  span:first-of-type {
    display: ${props => props.isEnsinoEPesquisa && 'flex'};
    justify-content: ${props => props.isEnsinoEPesquisa && 'center'};
    align-items: ${props => props.isEnsinoEPesquisa && 'center'};
    margin-top: ${props => props.isEnsinoEPesquisa && '1.875rem'};
  }
  p:nth-child(6) {
    margin-top: ${props => props.isEnsinoEPesquisaAndNossaEquipe && '1.875rem'};
  }

  p + p {
    margin-top: 1rem;

    :nth-child(7) {
      display: ${props =>
        props.isDisplay
          ? props.isDisplay
          : props.isEnsinoEPesquisaAndNossaEquipe && 'flex'};
      margin-top: ${props =>
        props.isEnsinoEPesquisaAndNossaEquipe && '1.875rem'};

      @media ${device.mobile} {
        margin-top: ${props =>
          props.isEnsinoEPesquisaAndNossaEquipe && '1.25rem'};
      }

      img {
        margin-right: ${props =>
          props.isEnsinoEPesquisaAndNossaEquipe && '0.625rem'};
        transform: ${props =>
          props.isEnsinoEPesquisaAndNossaEquipe && 'translate(0,0.625rem)'};
        transform: ${props => props.infoNumbers && 'translate(0,1.3rem)'};
      }

      a {
        transform: ${props =>
          props.isEnsinoEPesquisaAndNossaEquipe &&
          'translate(-6.125rem, 1.25rem)'};

        margin-left: ${props => props.infoNumbers && '35px'};

        @media ${device.mobile} {
          transform: ${props =>
            props.isEnsinoEPesquisaAndNossaEquipe &&
            'translate(-5.3125rem, 1.25rem)'};
        }
      }
      a + a {
        margin-left: 0;
      }
    }

    :nth-child(8) {
      padding-top: ${props =>
        props.isEnsinoEPesquisaAndNossaEquipe && '1.25rem'};
    }

    :nth-child(14) {
      display: ${props => props.isEnsinoEPesquisa && 'flex'};

      img {
        margin-right: ${props => props.isEnsinoEPesquisa && '0.625rem'};
      }
    }

    :nth-child(15) {
      display: ${props => props.isEnsinoEPesquisa && 'flex'};

      img {
        margin-right: ${props => props.isEnsinoEPesquisa && '0.625rem'};
      }

      a {
        position: ${props => props.isEnsinoEPesquisa && 'relative'};
        top: ${props => props.isEnsinoEPesquisa && '1.25rem'};
        right: ${props => props.isEnsinoEPesquisa && '6.25rem'};
        margin-bottom: ${props => props.isEnsinoEPesquisa && '1.25rem'};

        @media ${device.mobile} {
          right: ${props => props.isEnsinoEPesquisa && '5.3125rem'};
        }
        @media ${device.tablet} {
          right: ${props => props.isEnsinoEPesquisa && '5.25rem'};
        }
      }
    }

    :nth-child(16) {
      width: ${props => props.isEnsinoEPesquisa && '100%'};
      max-width: ${props => props.isEnsinoEPesquisa && '20.9375rem'};
    }
  }

  ul {
    margin-top: ${({ marginTopUl }) => marginTopUl};
    li {
      margin-bottom: 1rem;

      &::marker {
        color: ${props => props.bulletColor && props.bulletColor};
      }
    }
  }

  a {
    color: #45a7df;
  }

  span {
    display: ${({ spanDisplay }) => (spanDisplay ? spanDisplay : 'flex')};
    justify-content: center;

    :nth-of-type(1) {
      margin-top: ${props => props.isAdjustAccordionMargin && '1.875rem'};

      @media ${device.mobile} {
        margin-top: ${props => props.isAdjustAccordionMargin && '1.25rem'};
      }
    }
    :nth-of-type(2) {
      margin-top: ${props => props.isAdjustAccordionMargin && '1.875rem'};

      @media ${device.mobile} {
        margin-top: ${props => props.isAdjustAccordionMargin && '1.25rem'};
      }
    }
  }

  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: ${props => (props.isEnsinoEPesquisa ? '1.625rem' : '1.5rem')};
    padding: 0.625rem 0 1.875rem;

    h3 {
      font-size: 1.125rem;
      margin-top: 1.375rem;
      margin-bottom: 1.375rem;
    }
  }

  iframe {
    width: 35.625rem;
    margin-right: 1.875rem;
    margin-bottom: 4.125rem;

    @media ${device.mobile} {
      width: 20.938rem;
      height: 11.563rem;
      margin-bottom: 1.875rem;
    }
  }
`;
