export const getCookie = () => {
  let s = document.createElement('script');
  s.src = 'https://cdn-prod.securiti.ai/consent/cookie-consent-sdk.js';
  s.setAttribute('data-tenant-uuid', '8026c384-3078-4423-9fc8-ffd7b8cabd9e');
  s.setAttribute('data-domain-uuid', '7a42222d-df6b-448f-96a5-0ed827de3737');
  s.setAttribute('data-backend-url', 'https://app.securiti.ai');
  s.defer = false;
  let parent_node = document.head || document.body;
  parent_node.appendChild(s);
  s.addEventListener('load', function () {
    window.initCmp();
  });
};
