import React from 'react';
import { Link } from 'gatsby';

import { TmpTypography } from '@tempo/tempo-design-system-core/dist';

import * as S from './style';

const SimpleTextDS = ({ universalLink, ...rest }) => {
  const isWindow = typeof window !== 'undefined';

  const LinkRender = ({ children, link }) => {
    const isInternalLink = link?.startsWith('/');

    return isInternalLink ? (
      <Link to={link}>{children}</Link>
    ) : (
      <a href={link} target="_blank" rel="noreferrer noopener">
        {children}
      </a>
    );
  };
  return (
    <S.Container>
      {isWindow && { TmpTypography } && (
        <LinkRender link={universalLink}>
          <TmpTypography component="paragraph">
            <S.Text children={rest.children} />
          </TmpTypography>
        </LinkRender>
      )}
    </S.Container>
  );
};

export default SimpleTextDS;
