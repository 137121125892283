import styled from 'styled-components';
import { device } from 'components/device';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100vw;
  padding: 65px 0 44px;
  background: ${({ backgroundColor }) => backgroundColor?.hex};

  @media ${device.mobile} {
    padding: 65px 1.25rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 73.125rem;

  @media ${device.mobile} {
    max-height: initial;
  }
`;

export const Title = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #231f20;
  margin-bottom: 40px;

  @media ${device.mobile} {
    font-size: 1.125rem;
  }
`;

export const ContainerCards = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const ContainerCard = styled.div`
  width: 16.875rem;
  height: 17.125rem;
  border: 1px solid #c6c8cc;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;

  :nth-child(2n) {
    margin-right: 0;
  }

  @media ${device.laptop} {
    width: 100%;
    height: 186px;
    margin-right: 0;
    height: 227px;
  }
  @media ${device.mobile} {
    width: 100%;
    max-width: 100%;
  }
`;

export const TitleCard = styled.h2`
  width: 100%;
  max-width: 13.125rem;
  height: 5.4375rem;
  margin-top: 1.875rem;
  margin-right: 1.875rem;
  margin-left: 1.875rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  color: #000000;

  @media ${device.laptop} {
    width: 100%;
    max-width: 90%;
    margin-top: 1.25rem;
    margin-right: 1.25rem;
    margin-left: 1.25rem;
    font-size: 18px;
    line-height: 22px;
  }

  @media ${device.mobile} {
    font-size: 18px;
    line-height: 22px;
  }

  @media ${device.mobile320} {
    max-width: 80%;
  }
`;

export const ContainerItemCard = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonCard = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 5.0625rem;
  height: 2.8125rem;
  background: #45a7df;
  border-radius: 100px;
  margin-left: 1.875rem;
  margin-right: 2.0625rem;
  margin-bottom: 1.875rem;
  color: #ffffff;
  border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-align: center;
  transition: 0.6s background;

  :hover {
    background: #1d52a3;
  }

  @media ${device.mobile} {
    margin-left: 20px;
    margin-right: 23px;
    margin-bottom: 20px;
  }
`;

export const ImageCard = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 1.875rem;

  @media ${device.mobile} {
    margin-bottom: 20px;
  }
`;

export const DescriptionCard = styled.span`
  width: 100%;
  margin-bottom: 1.875rem;
  margin-left: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #8f9194;

  @media ${device.mobile} {
    margin-bottom: 20px;
    font-size: 14px;
  }
`;
