import { Link } from 'gatsby';
import styled from 'styled-components';

// Components
import { device } from '../../../device';

export const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: #1d52a3;
  border-radius: 0.5rem;
  margin-top: 2rem;
  margin-bottom: -2rem;

  @media ${device.laptop} {
    padding: 1.25rem;
    margin-top: -1.875rem;
    margin-bottom: 1.875rem;
    flex-direction: column;
  }

  &.FeaturedMarketingModelo3 {
    margin-top: 2.5rem;

    @media ${device.laptop} {
      margin-top: 3.7rem;
      margin-bottom: -2rem;
      z-index: 1;
    }
  }

  &.FeaturedMarketingModelo6 {
    margin-top: 6.375rem;
    margin-bottom: -6.25rem;
    @media ${device.laptop} {
      margin-top: 2rem;
      margin-bottom: 0;
    }
  }
`;

export const ContainerImportanceText = styled.span`
  padding: 1.75rem 1.875rem;
  width: 9.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ importance }) => importance && '#203861'};
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;

  @media ${device.laptop} {
    padding: 0;
    margin-bottom: 0.2rem;
    background-color: transparent;
  }
`;

export const ContainerMessageText = styled.span`
  padding: 1.75rem 1.875rem;
  width: 100%;
  display: flex;
  align-items: center;

  > p {
    margin-right: 0.5rem;
  }

  @media ${device.laptop} {
    padding: 0;
  }

  @media ${device.tablet} {
    display: contents;
  }
`;

export const Text = styled.div`
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.5125rem;
  font-weight: ${({ importance }) => importance && '800'};
  text-transform: ${({ importance }) => importance && 'uppercase'};

  @media ${device.tablet} {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  @media ${device.mobile} {
    font-size: 0.875rem;
  }
`;

export const MessageLink = styled(Link)`
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.5125rem;
  border-bottom: 1px solid #fff;
  cursor: pointer;

  @media ${device.tablet} {
    width: fit-content;
    font-size: 1rem;
    line-height: 1.375rem;
  }

  @media ${device.mobile} {
    font-size: 0.875rem;
  }
`;
