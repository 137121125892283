import React from 'react';

import { CustomFragment } from 'components/CmsComponents/CustomFragment';
import { formatClassName } from 'utils/formatClassName';
import { DownloadCard } from 'components/CmsComponents/SimpleContent/DownloadCard';
import VirtualAssistant from 'components/VirtualAssistant/index.js';

import {
  Container,
  Content,
  Box,
  WrapTitle,
  Title,
  WrapContainer,
} from './style';

function SimpleContent({ title, type, fragments, backgroundColor }) {
  const isDownloadCards = formatClassName(type).includes(
    'SimpleContentDownloadCards'
  );

  switch (formatClassName(type)) {
    case 'VirtualAssistant':
      return fragments?.map((fragment, _, array) => {
        return (
          <VirtualAssistant
            key={fragment.id}
            title={title}
            children={fragment.markdown}
            number={fragment.singletexts}
          />
        );
      });
    default:
      return (
        <Container
          className={formatClassName(type)}
          backgroundColor={backgroundColor}
        >
          <Content className={formatClassName(type)}>
            <Box className={formatClassName(type)}>
              <WrapTitle className={formatClassName(type)}>
                <Title className={formatClassName(type)}>
                  {title?.replaceAll('[break]', '\n')}
                </Title>
              </WrapTitle>
              <WrapContainer className={formatClassName(type)}>
                {fragments?.map((fragment, _, array) => {
                  return isDownloadCards ? (
                    <DownloadCard
                      key={fragment.id}
                      dataFragment={fragment.datafragment}
                      onlyOne={array.length === 1}
                    />
                  ) : (
                    <CustomFragment
                      key={fragment.id}
                      className={formatClassName(fragment.type)}
                      children={fragment.markdown}
                      debugName={fragment.name}
                      singletexts={fragment.singletexts}
                      rawFragment={fragment}
                      assetpickerMultiple={fragment.assetpickerMultiple}
                      type={formatClassName(type)}
                    />
                  );
                })}
              </WrapContainer>
            </Box>
          </Content>
        </Container>
      );
  }
}

export { SimpleContent };
