import React from 'react';

import * as S from './style';

export const ProfessioanlSpeech = ({
  title,
  professionalName,
  professional,
  text,
  image,
}) => {
  return (
    <S.Container>
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.LeftColmunContent>
          <S.Image src={image?.url} alt="Foto" />
          <S.ProfessionalName>{professionalName}</S.ProfessionalName>
          <S.Professional>{professional}</S.Professional>
        </S.LeftColmunContent>
        <S.Text children={text} />
      </S.Content>
    </S.Container>
  );
};
