import React, { memo } from 'react';

import Loader from 'components/loader';

import { Container, Placeholder } from './style';

const DropdownComponent = ({
  theme,
  data,
  handleSelectOption,
  inputSearch = '',
  showPlaceholder = false,
  placeholder = '',
  emptyResultFeedback = 'Nenhum resultado encontrado para:',
}) => {
  return (
    <Container customTheme={theme}>
      <div>
        {data?.length <= 0 && inputSearch?.length <= 0 ? (
          <Loader margin="0" heightMobile="unset" marginMobile="0" />
        ) : data?.length <= 0 ? (
          <p className="empty-result">
            {emptyResultFeedback} <strong>{inputSearch}</strong>
          </p>
        ) : (
          <>
            {showPlaceholder && data.length > 0 && (
              <Placeholder
                key={'emptyValue'}
                onClick={() => handleSelectOption({ id: '', value: '' })}
              >
                {placeholder}
              </Placeholder>
            )}

            {data.map((optionValue, index) => (
              <p
                key={`${index}-${Object.values(optionValue)[0]}`}
                onClick={() => handleSelectOption(optionValue)}
              >
                {Object.values(optionValue)[1]}
              </p>
            ))}
          </>
        )}
      </div>
    </Container>
  );
};

export const Dropdown = memo(DropdownComponent);
