import React, { useEffect, useState, useRef } from 'react';

import { formatClassName } from 'utils/formatClassName';

import {
  Container,
  Content,
  Box,
  WrapTitle,
  Title,
  WrapContainer,
  WrapRecipeTypesContent,
  RecipeTypesContent,
  WrapRecipeTypesImage,
  RecipeTypesImage,
  WrapRecipeTypesTitle,
  RecipeTypesTitle,
  WrapRecipesList,
  WrapRecipesListItem,
  WrapRecipesListItemLink,
} from './style';

function RecipesContent({ type, title, receitasTemas }) {
  const [activeSlider, setActiveSlider] = useState(false);
  const contentRef = useRef(null);
  const recipeTypesSet = new Set();

  const contentSlider = contentRef;
  let isMouseDown = false;
  let listenerPositionX;
  let elementScrollLeft;
  
  let typesAndRecipes = receitasTemas?.receitas?.reduce(
    (acc, currentRecipe) => {
      const types = currentRecipe.receitasTipos.filter(rTypes => {
        const duplicate = recipeTypesSet.has(rTypes.id);
        recipeTypesSet.add(rTypes.id);
        return !duplicate;
      });

      if (types.length) {
        return [...acc, ...types];
      }

      return acc;
    },
    []
  );


  typesAndRecipes.forEach((item) => {
    item.receitas.forEach((receita, index) => {
      if (!(receita.receitasTemas.some(e => e.title === receitasTemas.title))) {
        item.receitas.splice(index, 1); 
      }
    })
  });


  useEffect(() => {
    contentSlider.current.addEventListener('mousedown', listener => {
      isMouseDown = true;
      listenerPositionX = listener.pageX - contentSlider.current.offsetLeft;
      elementScrollLeft = contentSlider.current.scrollLeft;
    });
    contentSlider.current.addEventListener('mouseleave', () => {
      isMouseDown = false;
      setActiveSlider(false);
    });
    contentSlider.current.addEventListener('mouseup', () => {
      isMouseDown = false;
      setActiveSlider(false);
    });
    contentSlider.current.addEventListener('mousemove', listener => {
      if (!isMouseDown) return;
      listener.preventDefault();
      setActiveSlider(true);
      const positionX = listener.pageX - contentSlider.current.offsetLeft;
      const distance = (positionX - listenerPositionX) * 2; //change multiply value to change scroll-speed
      contentSlider.current.scrollLeft = elementScrollLeft - distance;
    });
  }, []);

  return (
    <Container className={formatClassName(type)}>
      <Content className={formatClassName(type)}>
        <Box className={formatClassName(type)}>
          <WrapTitle>
            <Title>{title}</Title>
          </WrapTitle>
          <WrapContainer className={formatClassName(type)}>
            <WrapRecipeTypesContent
              ref={contentRef}
              activeSlider={activeSlider}
            >
              {typesAndRecipes?.length > 0 &&
                typesAndRecipes?.map(typeAndRecipe => (
                  <RecipeTypesContent key={typeAndRecipe.id}>
                    <WrapRecipeTypesImage>
                      <RecipeTypesImage
                        src={typeAndRecipe.image?.url ?? ''}
                        alt={typeAndRecipe.title ?? 'Destaque da Receita'}
                      />
                    </WrapRecipeTypesImage>
                    <WrapRecipeTypesTitle>
                      <RecipeTypesTitle>
                        {typeAndRecipe.title ?? ''}
                      </RecipeTypesTitle>
                    </WrapRecipeTypesTitle>
                    <WrapRecipesList>
                      {typeAndRecipe.receitas?.map(recipe => (
                        <WrapRecipesListItem key={recipe.id}>
                          <WrapRecipesListItemLink
                            to={`/${receitasTemas.slug}/${typeAndRecipe.slug}/${recipe.slug}`}
                          >
                            {recipe.title}
                          </WrapRecipesListItemLink>
                        </WrapRecipesListItem>
                      ))}
                    </WrapRecipesList>
                  </RecipeTypesContent>
                ))}
            </WrapRecipeTypesContent>
          </WrapContainer>
        </Box>
      </Content>
    </Container>
  );
}

export { RecipesContent };
