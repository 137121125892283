import React, { useEffect, useState } from 'react';

import { Container, Content, Text } from './style';

function InformativeBanner({
  markdown,
  backgroundColorInitialInfo,
  backgroundColorFinalInfo,
}) {
  const [isMobile, setIsMobile] = useState(false);

  const updateWindowSize = () => {
    setIsMobile(window.innerWidth < 641);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowSize);
      return () => window.removeEventListener('resize', updateWindowSize);
    }
  }, []);

  return (
    <Container
      backgroundInitialColor={backgroundColorInitialInfo?.hex}
      backgroundFinalColor={backgroundColorFinalInfo?.hex}
    >
      <Content>
        <Text>{markdown}</Text>
      </Content>
    </Container>
  );
}

export { InformativeBanner };
