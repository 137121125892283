import React from 'react';

import { formatClassName } from 'utils/formatClassName';
import Qa from 'components/Qa';

import { Container, Content } from './style';

function ListQAContent({
  type,
  backgroundColor,
  title,
  subTitle = 'Principais dúvidas dos clientes - perguntas e respostas',
  qaData,
  faqLink,
  anchorTo,
  isDesignSystem,
}) {
  const isQaCustomFragment =
    qaData?.[0]?.__typename === 'GraphCMS_CustomFragment';

  const formattedQaPergunta =
    isQaCustomFragment &&
    qaData?.[0]?.markdown
      ?.replace(/(<div id=.*[\n|\r]\n)/i, '')
      ?.split(/(##.*\n)/gim)
      ?.filter(el => el)
      ?.map((item, index) => {
        return index % 2 === 0 ? `${item.replace(/\n/gim, '')}` : item;
      })
      ?.slice(0, 500)
      ?.join('\n');

  const qaContent = isQaCustomFragment
    ? {
        autor: [],
        doctors: [],
        title: null,
        pergunta: formattedQaPergunta || '',
      }
    : qaData?.[0] ?? [];

  const formattedFaqLink =
    faqLink?.charAt(faqLink.length - 1) === '/'
      ? faqLink.replace(/.$/, '')
      : faqLink;
  const link = anchorTo
    ? `${formattedFaqLink}/?s=${anchorTo}`
    : formattedFaqLink;

  return (
    <Container
      className={formatClassName(type)}
      bgColor={backgroundColor?.hex ?? 'transparent'}
    >
      <Content className={formatClassName(type)}>
        <Qa
          title={title ?? 'Perguntas Frequentes'}
          subTitle={subTitle}
          data={qaContent}
          showFaqLink={link}
          showFaqLinkOnClick={() => localStorage.setItem('page', anchorTo)}
          isDesignSystem={isDesignSystem}
        />
      </Content>
    </Container>
  );
}

export { ListQAContent };
