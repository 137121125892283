import styled, { css } from 'styled-components';
import { device } from 'components/device';

export const ButtonCopy = styled.button`
  display: none;

  @media ${device.laptop} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 54px;
    margin-bottom: 10px;
    padding: 1rem;
    color: #45a7df;
    font-size: 0.875rem;
    border: 0.063rem solid #c6c8cc;
    border-radius: 6.25rem;
    outline: none;
    background: transparent;

    p {
      ${props =>
        props.animation &&
        css`
          animation: 'ShadeIn' 2s;
        `}

      @keyframes ShadeIn {
        0% {
          color: #45a7df;
          transform: rotateX(0deg);
        }
        10% {
          color: #8f9194;
          transform: rotateX(90deg);
        }
        50% {
          color: #8f9194;
          transform: rotateX(0deg);
        }
        85% {
          color: #8f9194;
          transform: rotateX(90deg);
        }
        100% {
          color: #45a7df;
          transform: rotateX(0deg);
        }
      }
    }
  }
`;
