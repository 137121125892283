import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { device } from 'components/device';

export const Container = styled.section`
  background-color: #f4f5f7;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 4.375rem 0;

  @media ${device.laptop} {
    padding: 1.875rem 0;
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 73.125rem;
  gap: 1.25rem;
  justify-content: space-between;

  @media ${device.laptop} {
    display: flex;
    justify-content: center;
    padding: 0 1.25rem;
  }
`;

export const LeftColmunContent = styled.div`
  width: 15.75rem;

  @media ${device.laptop} {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  width: 100%;
  margin-bottom: 2rem;

  @media ${device.laptop} {
    text-align: center;
  }
`;

export const ProfessionalName = styled.p``;

export const Professional = styled.p`
  color: #8f9194;
  margin-top: 0.5rem;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

export const Text = styled(ReactMarkdown)`
  width: 856px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;

  p {
    margin-bottom: 16px;
  }
`;

export const Image = styled.img`
  border-radius: 100%;
  margin-bottom: 1rem;
  width: 10.625rem;
  height: 10.625rem;
`;
