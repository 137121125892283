import React, { useCallback, useState } from 'react';

// Components
import { Certificates } from 'components/certificates/index';

// Style
import * as S from './style.js';

// Images
import ArrowUp from 'images/icons/minus.svg';
import ArrowDown from 'images/icons/plus.svg';

const Accordion = ({ ...rest }) => {
  const [collapseOpen, setCollapseOpen] = useState([]);
  const title = rest.singletexts[0];

  const splittedMarkdown = rest.children.split('(end)\n\n').filter(e => e);
  const markdownContent = splittedMarkdown.map(text => {
    return text.split('\n\n');
  });

  const renderDropdown = useCallback(
    (drop, indexList) => {
      let statusCollapse = collapseOpen.includes(indexList);

      return (
        <S.DropdownContainer>
          <S.DropdownBoxTitle
            isOpen={statusCollapse}
            isCertificate={rest.className.includes('Certificates')}
          >
            <S.DropdownTitle>{drop.name}</S.DropdownTitle>
            {statusCollapse ? (
              <img src={ArrowUp} alt={`Fechar ${drop.name}`} />
            ) : (
              <img src={ArrowDown} alt={`Visualizar ${drop.name}`} />
            )}{' '}
          </S.DropdownBoxTitle>
          {statusCollapse &&
          rest.className.includes('Certificates') &&
          rest.assetpickerMultiple.length > 0 ? (
            <S.CertificatesContent>
              {drop.text.split('[end]').map((item, i) => (
                <Certificates
                  key={i}
                  markdown={item}
                  asset={rest.assetpickerMultiple[i]}
                />
              ))}
            </S.CertificatesContent>
          ) : (
            <S.Content
              statusCollapse={statusCollapse}
              className={rest.className}
            >
              <div></div>
              <S.ContentText escapeHtml={false} children={drop.text} />
            </S.Content>
          )}{' '}
        </S.DropdownContainer>
      );
    },
    [collapseOpen]
  );

  const handleListInformationPeoples = idCollapse => {
    let auxCollapse = collapseOpen.slice();

    const item = auxCollapse.includes(idCollapse);

    if (!item) {
      auxCollapse.push(idCollapse);
    } else {
      const removeCollapse = auxCollapse.indexOf(idCollapse);
      auxCollapse.splice(removeCollapse, 1);
    }

    setCollapseOpen(auxCollapse);
  };

  let drop = {
    name: '',
    text: '',
  };

  return (
    <div>
      <S.Title hasTitle={title}>{title}</S.Title>
      {markdownContent.map((item, indexList) => {
        drop.name = item[0].replace('###', '');
        drop.text = item.filter(item => !item.includes('###')).join(' ');

        return (
          <S.DropdownContent
            key={indexList}
            onClick={() => handleListInformationPeoples(indexList)}
          >
            {renderDropdown(drop, indexList)}{' '}
          </S.DropdownContent>
        );
      })}{' '}
    </div>
  );
};

export default Accordion;