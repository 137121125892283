import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

export const Container = styled.div`
  width: 16.875rem;
  height: 17.125rem;
  border: 1px solid #c6c8cc;
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 1.875rem;
`;

export const Title = styled(ReactMarkdown)`
  h2 {
    margin-bottom: 0;

    a {
      color: #000;
    }

    a:hover {
      color: #000;
    }

    a:visited {
      color: #000;
    }
  }
`;

export const Content = styled(ReactMarkdown)`
  align-self: flex-start;

  p {
    em {
      font-style: normal;
      color: #8f9194;
      justify-self: start;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      height: 8rem;
    }

    a {
      color: #8F9194;
    }

    a:hover {
      color: #8F9194;
    }

    a:visited {
      color: #8F9194;
    }
  }
`;

export const BoxContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 14rem;
`;

export const Img = styled(ReactMarkdown)`
  align-self: flex-end;

  img {
    width: 1.125rem !important;
    height: 1.125rem !important;
  }
`;
