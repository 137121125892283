import React, { useEffect, useState } from 'react';
import * as S from './style';

function ImageCircle({ backgroundColor, title, description, imagesData }) {
  const [isMobile, setIsMobile] = useState(false);
  const isWindow = typeof window !== 'undefined';

  const updateWindowSize = () => {
    setIsMobile(window.innerWidth < 641);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowSize);
      return () => window.removeEventListener('resize', updateWindowSize);
    }
  }, []);

  return (
    <S.Wrapper backgroundColor={backgroundColor}>
      <S.Container>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>

        {imagesData.map((image, index) => {
          return (
            <S.ImageContainer>
              <S.ImageContent id="image-content" key={index}>
                <a href={image.linkCircle} target="_blank">
                  <S.Image src={image.image.url} alt="" />
                </a>
                <S.TitleImage>{image.titleImage}</S.TitleImage>
                <S.DescriptionImage>
                  {image.descriptionImage}
                </S.DescriptionImage>
              </S.ImageContent>
            </S.ImageContainer>
          );
        })}
      </S.Container>
    </S.Wrapper>
  );
}

export { ImageCircle };
