module.exports.formatPhraseToSlug = (phrase, separator = '-') => {
    return phrase
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove accents
        .replace(/([^\w]+|\s+)/g, separator) // Replace space and other characters by hyphen
        .toLowerCase();
};

module.exports.createCustomSlug = (name, uniqueInfo, flag) => {
    const formattedUniqueInfo = uniqueInfo
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove accents
        .replace(/([^\w]+|\s+)/g, '') // Replace space and other characters by hyphen
        .toLowerCase();

    const formattedName = name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove accents
        .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
        .toLowerCase();

    return `${formattedName}${flag && '-' + flag}${formattedUniqueInfo && '-' + formattedUniqueInfo
        }`;
};
