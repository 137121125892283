import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-top: ${props =>
    props.customTheme?.marginTop && props.customTheme?.marginTop};
  padding: ${props =>
    props.customTheme?.containerPadding && props.customTheme?.containerPadding};
  border: 1px solid rgba(198, 200, 204, 0.8);
  border-top: ${props =>
    props.customTheme?.borderTop && props.customTheme?.borderTop};
  border-radius: ${props =>
    props.customTheme?.borderRadius && props.customTheme?.borderRadius};
  box-shadow: 0 2px 5px #231f200d;
  background: #ffffff;
  height: auto;
  max-height: 20vh;
  overflow-y: scroll;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 0.25rem;
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    margin-right: 0.625rem;
    width: 0.25rem;
    border-radius: 6.25rem;
    background: #c6c8cc;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #ffffff;

    p {
      cursor: pointer;
      font-size: 0.875rem;
      line-height: 1.5rem;
      padding: ${props =>
        props.customTheme?.itemPadding && props.customTheme?.itemPadding};

      &:hover {
        background: #f4f5f7;
      }

      &.empty-result {
        cursor: default;

        &:hover {
          background: unset;
        }
      }
    }

    span {
      font-size: 0.875rem;
      line-height: 1.5rem;
      padding: ${props =>
        props.customTheme?.padding && props.customTheme?.padding};
    }
  }
`;

export const Placeholder = styled.p`
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #8f9194;
  padding: ${props => props.customTheme?.padding && props.customTheme?.padding};

  cursor: pointer;

  &:hover {
    background: unset;
  }
`;
