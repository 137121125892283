import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
  background: ${({ bgColor, backgroundInitialColor, backgroundFinalColor }) =>
    bgColor !== undefined && !backgroundInitialColor && !backgroundFinalColor
      ? bgColor
      : backgroundInitialColor &&
        backgroundFinalColor &&
        `linear-gradient(135deg, ${backgroundInitialColor} 0%, ${backgroundFinalColor} 100%)`};

  @media ${device.laptop} {
    padding: 0 1.25rem;
  }

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.mb70 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 3.125rem;
    }
  }

  &.mb70x20 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.LinksLandingStyle {
    a {
      color: white;
      text-decoration: none;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 3.125rem 0;

  &.borderBottom {
    border-bottom: 1px solid #c6c8cc;
  }

  @media ${device.laptop} {
    margin-top: 1.875rem;
    padding: 1.25rem 0;
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1.875rem;

  @media ${device.laptop} {
    align-items: center;
  }

  @media ${device.mobile} {
    flex-direction: column-reverse;
    gap: unset;
  }
`;

export const WrapTitle = styled.div`
  width: 100%;
  margin-bottom: 1.875rem;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }
`;

export const Title = styled.h4`
  width: 100%;
  font-size: ${props => (props.titleLanding ? '2rem' : '1.5rem')};
  line-height: 1.875rem;
  font-weight: ${props => (props.titleLanding ? '700' : 'normal')};
  margin: 0;
  color: ${props => (props.titleLanding ? 'white !important' : '#231f20')};

  @media ${device.laptop} {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;

export const WrapDescription = styled.div`
  width: 100%;
  margin-bottom: 1.875rem;
`;

export const Description = styled(ReactMarkdown)`
  width: 100%;
  font-size: 1rem;
  line-height: 1.625rem;
  color: ${props => (props.descriptionLanding ? 'white' : '#231f20')};
  font-weight: normal;
`;

export const WrapImage = styled.div`
  width: 100%;
  max-width: 29.375rem;
  height: auto;
  min-height: 100%;

  @media ${device.laptop} {
    max-width: 25rem;
  }

  @media ${device.mobile} {
    margin-top: -7.25rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const WrapContainer = styled.div`
  width: 100%;
  max-width: 35.625rem;

  @media ${device.laptop} {
    max-width: 40%;
  }

  @media ${device.mobile} {
    max-width: unset;
  }
`;
