import React from 'react';
import styled, { css } from 'styled-components';

import ServicesDefault from 'components/units/unitPages/unit/servicesDefault';
import { device } from 'components/device';

const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
    display: ${props => props.isDesktop && 'flex'};
    padding: 0 20px;
  }

  .cardsCdi {
    section {
      width: 100%;
      div {
        width: 100%;
        margin-left: 0;
        @media ${device.laptop} {
          display: flex;
        }
        div {
          > ul {
            width: 100%;
            @media ${device.laptop} {
              display: flex;
              flex-direction: column;
              margin: 0;
            }
            > li {
              width: 100%;
              margin-right: 0;
              > h2 {
                font-weight: 400;
                width: 65%;
                padding-bottom: 1.875rem;

                @media ${device.laptop} {
                  width: 100%;
                  margin: 0;
                  padding: 0;
                  padding-bottom: 1.875rem;
                }
              }
              :hover {
                box-shadow: unset;
              }
              :first-child {
                margin-right: 0;
                padding: 0 15px 0 0 !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                  border-bottom: 1px solid #c6c8cc;
                  border-radius: unset;
                }
              }
              :last-child {
                margin-right: 0;
                padding: 0 0 0 15px !important;
                @media ${device.laptop} {
                  height: unset;
                  padding: 0 !important;
                }
              }
              @media ${device.laptop} {
                height: unset;
              }
              ul {
                grid-template-columns: 1fr;
                width: 100%;
                li {
                  margin-right: 0;
                  @media ${device.laptop} {
                    width: 100%;
                    height: unset;
                  }
                  p a {
                    display: contents;
                  }
                  h3 {
                    width: 100%;
                    > a {
                      :first-child {
                        @media ${device.laptop} {
                          display: flex;
                          width: 100%;
                        }
                        @media ${device.tablet} {
                          width: 100%;
                        }
                      }
                      :last-child {
                        @media ${device.laptop} {
                          top: 0;
                          right: 0;
                        }
                      }
                    }
                    @media ${device.laptop} {
                      width: 100%;
                      position: relative;
                    }
                  }
                }
                @media ${device.laptop} {
                  display: flex;
                  flex-direction: column;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  ${props =>
    props.paddingDesktop &&
    css`
      padding: ${props.paddingDesktop};
    `}
  ${props =>
    props.bgColor &&
    css`
      background: ${props.bgColor};
    `}

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;

    ${props =>
      props.fullWidthMobile &&
      css`
        width: 100vw;
        margin: 0 -1.25rem;
      `}

    ${props =>
      props.paddingMobile &&
      css`
        padding: ${props.paddingMobile};
      `}

    ${props =>
      props.marginTopMobile &&
      css`
        margin-top: ${props.marginTopMobile};
      `}
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
`;

function OurSpecialties({ customFragment, title }) {
  const [isDesktop, setDesktop] = React.useState(true);
  const [, setRedirect] = React.useState(false);
  const { markdown } = customFragment;

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
    setRedirect(true);
  });

  return (
    <GridContainer isDesktop={isDesktop}>
      <Part
        gridColumn={'2 / -2'}
        Bottom={'2.5rem'}
        isNotBorder
        className={'cardsServices'}
        paddingMobile={'0 0 30px 0px'}
      >
        <ServicesDefault
          title={title}
          markdown={markdown}
          fragments={customFragment}
          isCDIUnits
          isOncologia
          isCentroOncologico
          isAdjustmentInCssLayout
          isAdjustmentInCssLayoutMobile
          isCarousel
        />
      </Part>
    </GridContainer>
  );
}

export { OurSpecialties };
