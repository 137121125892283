import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { device } from 'components/device';

export const Container = styled.div`
  background: #f4f5f7;
  padding: 1.875rem 1.875rem 0;
  border-radius: 0.5rem;
  min-width: 572px;
  margin-top: -4rem;
  height: 100%;

  @media ${device.laptop} {
    min-width: 100%;
    margin-top: 0rem;
  }
`;

export const Content = styled(ReactMarkdown)`
  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.875rem;
    margin-bottom: 2.125rem;
  }

  a {
    color: #45a7df;
  }

  p {
    margin-bottom: 1.875rem;
    font-size: 1rem;
    line-height: 1.625rem;

    @media ${device.laptop} {
      font-size: 0.875rem;
    }
  }

  em {
    font-style: normal;

    img {
      width: 1.5rem;
      margin-right: 1rem;
      transform: translateY(6px);
    }
  }

  blockquote {
    margin-left: 0;
    margin-top: -1.875rem;

    p {
      img {
        transform: translateY(155%);
      }

      em {
        color: #231f20;
        width: calc(100% - 2.25rem);
        display: inline-block;
        margin-left: 2.25rem;
        font-style: normal;
        font-size: 1rem;
        line-height: 1.625rem;
        margin-left: 2.8rem;
      }
    }
  }
`;
