// Libs
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';

// Components
import { Dropdown } from 'components/SearchDoctor/SelectGroup/Dropdown';

// Styles
import { Container, Label, Select, SpanValue, InputSearch } from './style';

const SelectGroup = React.forwardRef(
  (
    {
      label = '',
      placeholder = '',
      showDropdownPlaceholder = false,
      data = [],
      inputValue = value => {
        return;
      },
      onSelectOption = option => {
        return;
      },
      handleFilters = () => {
        return;
      },
      defaultValue = '',
      disableAutoSelectOption = false,
      isError = false,
      enableInputSearch = false,
      enableSearchWithEnter = false,
      preventOpenDropdownEmptySearch = false,
      inputType = 'text',
      emptyDropdownResultFeedback = 'Nenhum resultado encontrado para:',
      theme = {
        select: {
          disabledArrow: false,
          cursor: 'text',
        },
        label: {
          color: '#231F20',
          fontWeight: '600',
        },
        input: {
          type: 'text',
          color: '#231F20',
          placeholderColor: '#8F9194',
        },
        dropdown: {
          marginTop: '0.625rem',
          borderTop: '1px solid rgba(198, 200, 204, 0.8)',
          borderRadius: '0.5rem',
          containerPadding: '1.25rem 0',
          itemPadding: '0.625rem 1.875rem',
        },
      },
    },
    ref
  ) => {
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);
    const [filteredData, setFilteredData] = useState(null);
    const [displayValue, setDisplayValue] = useState('');
    const [inputSearch, setInputSearch] = useState('');
    const [search, setSearch] = useState('');
    const containerRef = useRef(null);
    const inputSearchRef = useRef(null);
    const defaultTheme = {
      select: {
        disabledArrow: false,
        cursor: 'text',
      },
      label: {
        color: '#231F20',
        fontWeight: '600',
      },
      input: {
        type: 'text',
        color: '#231F20',
        placeholderColor: '#8F9194',
      },
      dropdown: {
        marginTop: '0.625rem',
        borderTop: '1px solid rgba(198, 200, 204, 0.8)',
        borderRadius: '0.5rem',
        containerPadding: '1.25rem 0',
        itemPadding: '0.625rem 1.875rem',
      },
    };

    const customTheme = useMemo(() => {
      return {
        ...defaultTheme,
        select: {
          ...defaultTheme.select,
          ...theme.select,
        },
        label: {
          ...defaultTheme.label,
          ...theme.label,
        },
        input: {
          ...defaultTheme.input,
          ...theme.input,
        },
        dropdown: {
          ...defaultTheme.dropdown,
          ...theme.dropdown,
        },
      };
    }, [theme]);

    const handleEnterKey = value => {
      if (enableSearchWithEnter) {
        setDisplayValue(inputSearch);
        setInputSearch(inputSearch);
        inputValue(inputSearch);
        onSelectOption({ value, sendByEnter: true });
        setIsOpenDropdown(false);
      }
    };

    const handleClickOutsideComponent = e => {
      if (containerRef.current.contains(e.target)) {
        return;
      }
      setIsOpenDropdown(false);

      if (enableSearchWithEnter) {
        return;
      }

      setInputSearch(displayValue);
      setSearch(displayValue);
    };

    const openDropdown = event => {
      event.stopPropagation();
      setIsOpenDropdown(!isOpenDropdown);
    };

    const handleSelectOnClick = event => {
      enableSearchWithEnter ? dataSearch() : setFilteredData(data);
      openDropdown(event);
    };

    const handleSelectOption = useCallback(optionValue => {
      if (Array.isArray(optionValue)) {
        onSelectOption(optionValue);

        return;
      }

      const formattedValue =
        inputType === 'number'
          ? Object.values(optionValue)[1].replace(/[^0-9]/g, '')
          : Object.values(optionValue)[1];

      setDisplayValue(formattedValue);
      setInputSearch(formattedValue);
      handleFilters();
      onSelectOption(Object.values(optionValue)[0]);
      setIsOpenDropdown(false);
    }, []);

    const clearSelectOption = () => {
      setDisplayValue('');
      setInputSearch('');
      setSearch('');
    };

    const removeSpecialCharacters = phrase => {
      return phrase
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '')
        ?.replace(/([^\w]+|\s+)/g, '')
        ?.toLowerCase();
    };

    const dataSearch = () => {
      const filteredSearchData = data.reduce(
        (acc, current) => {
          let accKey = '';
          if (
            removeSpecialCharacters(Object.values(current)[1]).startsWith(
              removeSpecialCharacters(inputSearch)
            )
          ) {
            accKey = 'startsWith';
          } else if (
            removeSpecialCharacters(Object.values(current)[1]).includes(
              removeSpecialCharacters(inputSearch)
            )
          ) {
            accKey = 'others';
          }

          accKey && acc[accKey].push(current);

          return acc;
        },
        { startsWith: [], others: [] }
      );

      const sortedFilteredSearchData = [
        ...filteredSearchData.startsWith.sort(
          (a, b) => (Object.values(a)[1] > Object.values(b)[1] && 1) || -1
        ),
        ...filteredSearchData.others.sort(
          (a, b) => (Object.values(a)[1] > Object.values(b)[1] && 1) || -1
        ),
      ];

      setFilteredData(sortedFilteredSearchData);
    };

    useEffect(() => {
      setFilteredData(data);
    }, [data]);

    useEffect(() => {
      if (data?.length >= 0 && defaultValue === '') {
        clearSelectOption();
      }

      if (Array.isArray(defaultValue)) {
        const formattedDefaultValue = defaultValue
          ?.map(value => {
            const valueName = data?.find(
              item => Object.values(item)[0] === value
            );

            return Object.values(valueName)[1];
          })
          .join(', ');

        setInputSearch(formattedDefaultValue);
        setDisplayValue(formattedDefaultValue);
        handleSelectOption(defaultValue);

        return;
      }

      const foundValue = data?.find(
        item => Object.values(item)[0] === defaultValue
      );

      if (!foundValue) {
        setInputSearch(defaultValue);
        return;
      }

      !disableAutoSelectOption && handleSelectOption(foundValue);
    }, [defaultValue]);

    useEffect(() => {
      const timer = setTimeout(() => {
        dataSearch();

        inputValue(inputSearch);
        setSearch(inputSearch);
      }, 300);

      return () => clearTimeout(timer);
    }, [inputSearch]);

    useEffect(() => {
      if (isOpenDropdown) {
        enableInputSearch && inputSearchRef.current.focus();
        !enableSearchWithEnter && enableInputSearch && setInputSearch('');
        return;
      }

      enableInputSearch && inputSearchRef.current.blur();
    }, [isOpenDropdown, inputSearchRef]);

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutsideComponent);
      return () => {
        document.removeEventListener('mousedown', handleClickOutsideComponent);
      };
    }, [displayValue, inputSearch]);

    return (
      <Container ref={containerRef}>
        {label && <Label customTheme={customTheme.label}>{label}</Label>}
        <Select
          ref={ref}
          onClick={ev => handleSelectOnClick(ev)}
          isError={isError}
          rotateImg={isOpenDropdown}
          customTheme={customTheme.select}
        >
          {enableInputSearch ? (
            <InputSearch
              ref={inputSearchRef}
              type={inputType}
              placeholder={placeholder}
              value={inputSearch}
              onChange={e => setInputSearch(e.target.value)}
              customTheme={customTheme.input}
              defaultCursor={customTheme.select.disabledArrow}
              onKeyDown={e =>
                (e.key === 'Enter' || e.key === 13) &&
                handleEnterKey(e.target.value)
              }
            />
          ) : (
            <SpanValue hasValue={displayValue} customTheme={customTheme.input}>
              {displayValue ? <p>{displayValue}</p> : <p>{placeholder}</p>}
            </SpanValue>
          )}
        </Select>
        {!(preventOpenDropdownEmptySearch && !search) && isOpenDropdown && (
          <Dropdown
            theme={customTheme.dropdown}
            data={filteredData ?? data}
            handleSelectOption={handleSelectOption}
            inputSearch={search}
            emptyValuePlaceholder={placeholder}
            showPlaceholder={showDropdownPlaceholder && !!placeholder}
            placeholder={placeholder}
            emptyResultFeedback={emptyDropdownResultFeedback}
          />
        )}
      </Container>
    );
  }
);

export default SelectGroup;
