import styled, { css } from 'styled-components';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};

  @media ${device.laptop} {
    padding: 0 1.25rem;
    &.noPaddingFeatured {
      padding: 0;
    }
  }

  &.mb20 {
    padding-bottom: 1.25rem;
  }

  &.mb30 {
    padding-bottom: 1.875rem;

    @media ${device.laptop} {
      padding-bottom: 1.25rem;
    }
  }

  &.mb50 {
    padding-bottom: 3.125rem;

    @media ${device.laptop} {
      padding-bottom: 1.875rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  min-height: ${({ bgHeight }) => (bgHeight ? bgHeight + 'px' : 'auto')};
  margin: 0 auto;

  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'unset')};
  background-repeat: no-repeat;
  background-position: right top;

  ${({ hasBorderBottom }) =>
    hasBorderBottom &&
    css`
      border-bottom: 1px solid #c6c8cc;
    `}

  @media ${device.laptop} {
    max-width: unset;
  }

  &.maxHeight480 {
    max-height: 30rem;

    @media ${device.laptop} {
      max-height: unset;
    }
  }
`;

export const WrapBreadcrumb = styled.div`
  width: 100%;
  position: absolute;
  top: 1.875rem;
  z-index: 2;

  @media ${device.laptop} {
    display: none;
  }
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4rem;

  @media ${device.laptop} {
    align-items: center;
  }

  @media ${device.mobile} {
    align-items: unset;
  }

  &.customizationOfEmergencyService {
    min-height: auto;
  }

  &.mt48 {
    margin-top: 5.69rem;
  }

  &.MinHeightUnset {
    min-height: unset !important;
  }

  &.TitleMobileUnits {
    @media ${device.mobile} {
      flex-direction: column-reverse;
    }
  }
`;

export const Information = styled.div`
  width: 100%;
  margin: 0rem 0 1.875rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.625rem;

  @media ${device.laptop} {
    grid-template-columns: 1fr;
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth / 16 + 'rem' : 'unset')};
  height: auto;

  object-fit: cover;
  object-position: center;

  &.FeaturedMarketingModelo11 {
    width: 600px;
    height: 443px;
    object-position: 0% -2%;

    @media ${device.mobile} {
      width: 150px;
      height: 160px !important;
      object-fit: cover;
      object-position: -170% 0%;
    }
  }

  &.TitleMobileUnits {
    @media ${device.mobile} {
      position: unset !important;
      max-width: unset !important;
      width: 100%;
      height: auto !important;
    }
  }
`;
