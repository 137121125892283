import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { device } from '../device';
import GraphImg from 'graphcms-image';

export const Container = styled.div`
  display: flex;
`;
export const ContainerContent = styled.div`
  display: flex;
  font-family: Inter;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #231f20;
  border-radius: 0.5rem;
  padding: 1.875rem;

  @media ${device.mobile} {
    flex-direction: column;
    padding: 1.25rem;
    font-family: Inter;
  }
`;

export const Content = styled(ReactMarkdown)`
  width: 100%;

  p {
    margin-bottom: 1.625rem;

    :last-child {
      margin-bottom: 0;
    }
  }

  p > a {
    color: #45a7df;
  }
`;

export const ContainerImg = styled.div`
  display: flex;
`;
export const ImgCertificates = styled(GraphImg)`
  width: 150px;
  margin-right: 1.875rem;

  @media ${device.mobile} {
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
`;
