//libs
import React from 'react';
import { Helmet } from 'react-helmet';

//utils
import { faqFormatter } from 'utils/faqFormatter';

function DynamicFaq({ fullMarkdown }) {
  const faqSchema = faqFormatter(fullMarkdown) ?? '';

  const data = JSON.stringify(faqSchema);
  return (
    <Helmet>
      {data && <script type="application/ld+json">{data}</script>}
    </Helmet>
  );
}

export default DynamicFaq;
