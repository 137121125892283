import styled from 'styled-components';
import { device } from 'components/device';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100vw;
  padding: 65px 0 50px;

  @media ${device.mobile} {
    padding: 65px 1.25rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 73.125rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @media ${device.mobile} {
    max-height: initial;
  }
`;

export const Title = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #231f20;
  width: 100%;
  text-align: center;

  @media ${device.mobile} {
    font-size: 1.125rem;
  }
`;

export const Description = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #231f20;
  margin: 17px 0 40px;
  width: 100%;
  text-align: center;

  @media ${device.mobile} {
    font-size: 0.875rem;
  }
`;

export const CardContainer = styled.div`
  position: relative;
  width: 370px;
  height: 450px;
  border-radius: 20px;
  margin-bottom: 10px;

  :hover #card {
    transform: rotateY(180deg);
  }

  @media ${device.mobile} {
    :hover #card {
      transform: rotateY(180deg);
    }
  }
`;

export const Card = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 1.5s ease;
`;

export const FirstImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`;

export const SecondImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
`;
