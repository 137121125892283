import styled, { css } from 'styled-components';
import { device } from 'components/device';

export const Row = styled.div`
  display: flex;
  position: relative;

  @media ${device.mobile} {
    max-width: 23.438rem;
    width: 100%;
  }
`;

const transition = css`
  transition: transform 0.45s;
`;

export const Underline = styled.div`
  position: absolute;
  left: 0;
  bottom: 0%;
  width: 50%;
  height: 0.125rem;
  background: #2c6ef2;
  transform: translateX(
    ${props => (props.active === 0 ? 0 : props.active * 100)}%
  );
  ${transition}
`;

export const Button = styled.button`
  flex: 1 1 33.33%;
  cursor: pointer;
  font-family: 'Inter';
  display: flex;
  justify-content: center;
  border: unset;
  background: #fff;
  margin-bottom: 11px;
  color: ${props => (props.active ? '#2C6EF2' : '#292929')};
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
`;
