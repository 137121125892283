import styled, { css } from 'styled-components';
import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
  white-space: pre-line;

  @media ${device.laptop} {
    padding: 0 1.25rem;
    margin-top: 3.375rem;
  }

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.mb70 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 3.125rem;
    }
  }

  &.mb70x20 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 7rem 0 8.43rem 0;

  @media ${device.mobile} {
    padding: 3rem 0 5.125rem 0;
  }

  &.borderBottom {
    border-bottom: 1px solid #c6c8cc;
  }
`;
export const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
  max-height: 33rem;

  @media ${device.laptop} {
    height: auto;
    flex-direction: column;
    max-height: none;
  }
`;
export const Information = styled.div`
  width: 100%;
  white-space: pre-line;

  @media ${device.mobile} {
    white-space: normal;
  }

  p {
    padding-top: 20px;
  }
`;
export const CardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }

  > div {
    max-width: 281px;
    height: 180px;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media ${device.laptop} {
      max-width: none;
    }
  }
`;
export const WrapImage = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const Modal = styled.div`
  z-index: 9999999;

  p {
    padding-top: 32px;
    padding-bottom: 54px;
  }

  @media ${device.mobile} {
    .tmp-typography__subtitle {
      width: 90%;
      margin-top: 0.6rem;
    }

    > div {
      &:first-child {
        > div {
          overflow-y: scroll;
        }
      }
    }

    div:nth-child(1) {
      display: grid;
    }
  }

  @media (max-width: 376px) {
    div:nth-child(1) {
      display: grid;
    }
  }
`;
