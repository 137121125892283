import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import { TmpTypography } from '@tempo/tempo-design-system-core/dist';

import { CustomFragment } from 'components/CmsComponents/CustomFragment';
import Components from 'components/CmsComponents/';
import { formatClassName } from 'utils/formatClassName';

import {
  Container,
  Content,
  Box,
  Information,
  WrapImage,
  Image,
  ButtonsContent,
} from './style';

function DSLandingScheduling(content) {
  const [isMobile, setIsMobile] = useState(false);
  const isWindow = typeof window !== 'undefined';
  const hasMultipleImages = content?.images?.length > 1;

  const image = hasMultipleImages
    ? isMobile
      ? content?.images?.[1]
      : content?.images?.[0]
    : content?.images?.[0];

  const updateWindowSize = () => {
    setIsMobile(window.innerWidth < 1025);
  };

  const LinkRender = ({ children, link }) => {
    const isInternalLink = link?.startsWith('/');

    return isInternalLink ? (
      <Link to={link}>{children}</Link>
    ) : (
      <a href={link} target="_blank" rel="noreferrer noopener">
        {children}
      </a>
    );
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowSize);
      return () => window.removeEventListener('resize', updateWindowSize);
    }
  }, []);
  const components = content.components ?? [];

  const fragmentsWithButtons = content?.fragments?.filter(fragment =>
    fragment.type.includes('CTAButtonWithIconDS')
  );
  const containsButtonWithIcon = !!fragmentsWithButtons;

  return (
    <Container
      bgColor={content?.backgroundColor?.css ?? 'transparent'}
      data-testid="container"
    >
      <Content hasBorderBottom={!content?.backgroundColor?.css}>
        <Box>
          <WrapImage>
            {image?.url && (
              <LinkRender link={content?.universalLink}>
                <Image
                  maxWidth={image?.width}
                  src={image?.url}
                  alt="Destaque Marketing"
                  loading="lazy"
                />
              </LinkRender>
            )}
          </WrapImage>
          <Information>
            {isWindow && { TmpTypography } && (
              <LinkRender link={content?.universalLink}>
                <TmpTypography component="heading">
                  {content?.title?.replaceAll('[break]', '\n') ?? ''}
                </TmpTypography>
              </LinkRender>
            )}
            {content?.fragments
              ?.filter(
                fragment => !fragment.type.includes('CTAButtonWithIconDS')
              )
              .map(fragment => {
                return (
                  <CustomFragment
                    key={fragment.id}
                    className={formatClassName(fragment.type)}
                    children={fragment?.markdown?.replaceAll('[break]', '\n')}
                    debugName={fragment.name}
                    singletexts={fragment.singletexts}
                    universalLink={content?.universalLink}
                  />
                );
              })}
            {containsButtonWithIcon && (
              <ButtonsContent>
                {fragmentsWithButtons.map(fragment => {
                  return (
                    <CustomFragment
                      key={fragment.id}
                      className={formatClassName(fragment.type)}
                      children={fragment?.markdown?.replaceAll('[break]', '\n')}
                      debugName={fragment.name}
                      singletexts={fragment.singletexts}
                      universalLink={content?.universalLink}
                    />
                  );
                })}
              </ButtonsContent>
            )}
          </Information>
          {components.length >= 1 &&
            components?.map(component => {
              return Components(component);
            })}
        </Box>
      </Content>
    </Container>
  );
}

export { DSLandingScheduling };
