import React from 'react';

import { SimpleContent } from 'components/CmsComponents/SimpleContent';
import { HighlightContent } from 'components/CmsComponents/HighlightContent';
import { HighlightFormLeadContent } from 'components/CmsComponents/HighlightFormLeadContent';
import { FormPremioVioleta } from 'components/CmsComponents/FormPremioVioleta';
import { VideoGalleryContent } from 'components/CmsComponents/VideoGalleryContent';
import { BannerSpecialtyContent } from 'components/CmsComponents/BannerSpecialtyContent';
import { ListPostContent } from 'components/CmsComponents/ListPostContent';
import { DoctorAndSpecialtyContent } from 'components/CmsComponents/DoctorAndSpecialtyContent';
import { CarouselGalleryContent } from 'components/CmsComponents/CarouselGalleryContent';
import { RecipesContent } from 'components/CmsComponents/RecipesContent';
import { ProfessioanlSpeech } from 'components/CmsComponents/ProfessioanlSpeech';
import { ListQAContent } from 'components/CmsComponents/ListQAContent';
import { DSCardsContent } from 'components/CmsComponents/DSCardsContent';
import { DSLandingScheduling } from 'components/CmsComponents/DSLandingScheduling';
import { DSLandingSchedulingCards } from 'components/CmsComponents/DSLandingSchedulingCards';
import { DoctorsSearch } from 'components/CmsComponents/DoctorsSearch';
import { OurUnits } from 'components/CmsComponents/OurUnits';
import { OurSpecialties } from 'components/CmsComponents/OurSpecialties';
import { InformativeBanner } from 'components/CmsComponents/InformativeBanner';
import { AppDownloadBanner } from 'components/CmsComponents/AppDownloadBanner';
import { DSButtonContent } from 'components/CmsComponents/DSButtonContent';
import { CarouselBanner } from 'components/CmsComponents/CarouselBanner';
import { ImageCircle } from 'components/CmsComponents/ImageCircle';
import { CardEffect } from 'components/CmsComponents/CardEffect';
import { ContentTab } from 'components/CmsComponents/ContentTab';
import { CardDownload } from 'components/CmsComponents/CardDownload';
import { BannerFullImage } from 'components/CmsComponents/BannerFullImage';
import { FormLeadPersonalizado } from 'components/CmsComponents/FormLeadPersonalizado';
import { Logo } from 'components/CmsComponents/Logo';
import Header from 'components/CmsComponents/Header';
import Footer from 'components/CmsComponents/Footer';

const Components = {
  SimpleContent: SimpleContent,
  HighlightContent: HighlightContent,
  VideoGalleryContent: VideoGalleryContent,
  BannerSpecialtyContent: BannerSpecialtyContent,
  ListPostContent: ListPostContent,
  DoctorAndSpecialtyContent: DoctorAndSpecialtyContent,
  CarouselGalleryContent: CarouselGalleryContent,
  HighlightFormLeadContent: HighlightFormLeadContent,
  RecipesContent: RecipesContent,
  ComponentFormPremioVioleta: FormPremioVioleta,
  ProfessioanlSpeech: ProfessioanlSpeech,
  ListQAContent: ListQAContent,
  DsCardsContent: DSCardsContent,
  DSLandingScheduling: DSLandingScheduling,
  DSLandingSchedulingCards: DSLandingSchedulingCards,
  ComponentDsHeader: Header,
  ComponentDsFooter: Footer,
  DoctorsSearch: DoctorsSearch,
  OurUnits: OurUnits,
  OurSpecialties: OurSpecialties,
  InformativeBanner: InformativeBanner,
  AppDownloadBanner: AppDownloadBanner,
  DSButtonContent: DSButtonContent,
  CarouselBanner: CarouselBanner,
  ImageCircle: ImageCircle,
  CardEffect: CardEffect,
  ContentTab: ContentTab,
  CardDownload: CardDownload,
  BannerFullImage: BannerFullImage,
  Logo: Logo,
  FormLeadPersonalizado: FormLeadPersonalizado,
};

export default function Component(props) {
  const componentName = props.__typename.replace('GraphCMS_', '');
  const componentKey =
    props.id ?? `${Math.ceil(new Date().getTime() * Math.random())}`;

  if (typeof Components[componentName] !== 'undefined') {
    return React.createElement(Components[componentName], {
      key: componentKey,
      ...props,
    });
  }
  return React.createElement(() => <div />, { key: componentKey });
}
