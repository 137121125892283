// Libs
import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../device';

// Components
import InputMask from 'react-input-mask';
import AlertIcon from 'images/icons/alert.svg';

export const BoxInput = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;

  @media ${device.laptop} {
    width: 100%;
  }

  :first-child {
    @media ${device.laptop} {
      padding-bottom: 1.25rem;
    }
  }
`;

export const TitleInput = styled.span`
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.0625rem;
  padding-bottom: 0.625rem;
  margin-bottom: 0;
  ${props =>
    props.error &&
    css`
      color: #eb5757;
    `}
`;

const Input = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 1px solid #c6c8cc;
  padding-bottom: 0.625rem;
  line-height: 1.05875rem;
  outline: none;
  color: #8f9194;

  ${props =>
    props.error &&
    css`
      border-bottom: 1px solid #eb5757;
    `}
`;

const ErrorMessage = styled.small`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1rem;
  color: #eb5757;
  margin-top: 0.5rem;

  img {
    margin-right: 0.625rem;
  }
`;

const InputComponent = ({
  label = 'Nome',
  placeholder = 'Digite o seu nome',
  mask = false,
  error = false,
  ...rest
}) => {
  return (
    <BoxInput>
      <TitleInput error={!!error}>{label}</TitleInput>
      {!mask ? (
        <Input type="" placeholder={placeholder} error={!!error} {...rest} />
      ) : (
        <InputMask
          mask={mask}
          maxLength={rest.maxLength}
          onPaste={rest.onPaste}
          onCopy={rest.onCopy}
          placeholder={placeholder}
          formatChars={rest.formatChars}
          error={!!error}
          {...rest}
        >
          {inputProps => {
            return <Input {...inputProps} {...rest} />;
          }}
        </InputMask>
      )}
      {!!error && (
        <ErrorMessage>
          <img src={AlertIcon} alt="Alerta" />
          {error}
        </ErrorMessage>
      )}
    </BoxInput>
  );
};

export default InputComponent;
