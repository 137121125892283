import styled from 'styled-components';
import { device } from 'components/device';
import ReactMarkdown from 'react-markdown';

export const Container = styled.div`
  > div {
    min-height: inherit;
    height: 237px;
  }
`;
export const Title = styled.div`
  margin-bottom: 1rem;
  width: calc(100% - 2.25rem);
  margin-left: 2.25rem;
`;
export const Content = styled(ReactMarkdown)`
  > p {
    position: relative;
    margin-bottom: 1rem;

    img {
      position: absolute;
      top: 50%;
      transform: ${props =>
        props.isTransform ? props.isTransform : 'translateY(-50%)'};
    }
    > p {
      width: calc(100% - 2.25rem);
      display: inline-block;
      margin-left: 2.25rem;

      strong {
        color: #231f20;
      }
    }
  }
`;
