import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
  background: ${({ bgColor, backgroundInitialColor, backgroundFinalColor }) =>
    bgColor !== undefined && !backgroundInitialColor && !backgroundFinalColor
      ? bgColor
      : backgroundInitialColor &&
        backgroundFinalColor &&
        `linear-gradient(135deg, ${backgroundInitialColor} 0%, ${backgroundFinalColor} 100%)`};

  @media ${device.laptop} {
    padding: 0 1.25rem;
  }

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.mb70 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 3.125rem;
    }
  }

  &.mb70x20 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.LinksLandingStyle {
    a {
      color: white;
      text-decoration: none;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 3.125rem 0;
  display: flex;
  justify-content: center;

  &.borderBottom {
    border-bottom: 1px solid #c6c8cc;
  }

  @media ${device.laptop} {
    margin-top: 1.875rem;
    padding: 1.25rem 0;
  }
`;

export const Text = styled(ReactMarkdown)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
  width: 674px;

  @media ${device.laptop} {
    font-size: 20px;
  }
`;
