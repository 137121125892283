// Libs
import React, { useState, useRef } from 'react';

// Images
import plusIcon from 'images/icons/plus.svg';
import minusIcon from 'images/icons/minus.svg';

// Styles
import {
  Container,
  Header,
  HeaderTitle,
  Content,
  ContentText,
  WrapCustomContainer,
  ContainerChildren,
} from './style';

function Accordion({
  children,
  markdownContent,
  noBorderTop,
  isDisplay,
  noBorderBottomLastElement,
  isFormatted = false,
  theme = {
    title: {
      fontSize: '',
      fontSize320: '',
    },
    content: {
      textColor: '',
    },
  },
  isEnsinoEPesquisa,
  marginBottomNossaEquipe,
  isEnsinoEPesquisaAndNossaEquipe,
  bulletColor,
  isQualityAndHealth,
  markDownTitle,
  isAdjustAccordionMargin,
  isPaddingAccordion,
  contentTextMarginBottom,
  className = '',
  spanDisplay,
  colorText,
  infoNumbers,
  marginTopUl,
}) {
  const [isActive, setIsActive] = useState(false);
  const [contentHeight, setContentHeight] = useState('0px');

  const contentRef = useRef(null);

  let title = '';
  let content = '';

  if (markDownTitle !== undefined) {
    title = markDownTitle[0]?.singletexts[0];
  } else if (markdownContent) {
    const markdownSplitted = markdownContent.split('(end)');
    title = markdownSplitted[0];
    content = markdownSplitted[1];
  }

  function toggleAccordion() {
    setIsActive(!isActive);
    setContentHeight(isActive ? '0px' : `${contentRef.current.scrollHeight}px`);
  }

  const blockquoteRender = ({ node, children }) => {
    const regex = /({.*})/gim;
    const rawClasList = node?.children?.[0]?.children?.[0]?.value;

    if (!regex.test(rawClasList)) return <blockquote>{children}</blockquote>;

    const formattedClasses = rawClasList
      .replace(/\{/gim, '')
      .replace(/\}/gim, '')
      .split(',')
      .join(' ');

    children[0].props.children.shift();

    return (
      <WrapCustomContainer isinfoNumbers={infoNumbers}>
        <div className={formattedClasses}>{children}</div>
      </WrapCustomContainer>
    );
  };

  return (
    <Container
      className={className}
      noBorderTop={noBorderTop}
      noBorderBottomLastElement={noBorderBottomLastElement}
    >
      <Header aria-expanded="true" onClick={toggleAccordion}>
        <HeaderTitle theme={theme.title} children={title} />
        {isActive ? (
          <img src={minusIcon} alt="close" />
        ) : (
          <img src={plusIcon} alt="open" />
        )}
      </Header>
      <Content
        ref={contentRef}
        isEnsinoEPesquisaAndNossaEquipe={isEnsinoEPesquisaAndNossaEquipe}
        maxHeight={contentHeight}
      >
        {children ? (
          <ContainerChildren isQualityAndHealth={isQualityAndHealth}>
            {children}
          </ContainerChildren>
        ) : (
          <ContentText
            theme={theme.content}
            isEnsinoEPesquisaAndNossaEquipe={isEnsinoEPesquisaAndNossaEquipe}
            isEnsinoEPesquisa={isEnsinoEPesquisa}
            escapeHtml={false}
            children={content}
            marginBottomNossaEquipe={marginBottomNossaEquipe}
            linkTarget="_blank"
            isFormatted={isFormatted}
            isDisplay={isDisplay}
            renderers={{ blockquote: blockquoteRender }}
            isAdjustAccordionMargin={isAdjustAccordionMargin}
            isPaddingAccordion={isPaddingAccordion}
            bulletColor={bulletColor}
            contentTextMarginBottom={contentTextMarginBottom}
            spanDisplay={spanDisplay}
            colorText={colorText}
            infoNumbers={infoNumbers}
            marginTopUl={marginTopUl}
          />
        )}
      </Content>
    </Container>
  );
}

export default Accordion;
