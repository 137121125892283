// Libs
import React, { useContext, useEffect, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { navigate } from 'gatsby';

// Components
import { device } from './device';
import { PaginationContext } from '../context/pagination';

// Images
import prevIconBlue from 'images/icons/arrowLeft.svg';
import nextIcon from 'images/icons/arrow-right.svg';

//Styles

const Container = styled.div`
  width: 100%;
  margin: ${props => (props.isBlog ? '2.875rem 0' : '2.875rem 0 2rem 0')};
  display: ${props => props.numOfPages <= 1 && 'none'};
  grid-area: inherit;

  @media ${device.laptop} {
    margin: ${props => (props.isBlog ? '2.875rem 0' : 0)};
    padding-bottom: ${props =>
      props.paddingBottom ? `${props.paddingBottom}px` : 0};
  }

  .disabled {
    display: none;
    cursor: unset !important;
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;

    @media ${device.laptop} {
      background: ${props => (props.team ? 'transparent' : '#fff')};
      margin-top: ${props => props.team && '30px'};
    }

    @media ${device.mobile} {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .pagination li {
    margin: 0;
    list-style: none;
    cursor: pointer;

    :not(:first-child) {
      margin-left: ${props => (props.team ? 0 : '0.625rem')};
    }
  }

  .pagination li a {
    padding: 0 0.5rem;
    width: 2.75rem;
    height: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #45a7df;
    border-radius: 50%;
    outline: none;

    @media ${device.mobile320} {
      width: 2rem;
      height: 2rem;
    }
  }

  .pagination .active a {
    background: #45a7df;
    color: #fff;
  }

  .pagination > .next,
  .pagination > .previous {
    padding: 0;

    a {
      padding: 0;
      width: auto;
    }
  }

  @media ${device.laptop} {
    ${props =>
      props.isBlog
        ? `
      margin: 0.875rem 0;
    `
        : `
      margin-top: 0;

    `}

    .pagination li {
      font-size: 0.75rem;
    }

    .pagination > .next {
      padding: 0 0 0 0.5rem;
    }
    .pagination > .previous {
      padding: 0 0.5rem 0 0;
    }
  }
`;

const Icon = styled.img`
  margin-right: ${props => props.back && '0.625rem'};
  margin-left: ${props => !props.back && '0.625rem'};
  width: 1.5rem;
  cursor: pointer;

  @media ${device.laptop} {
    margin-left: ${props => !props.back && '0'};
  }
`;

const PaginationGeneral = ({
  dataPagination,
  isBlog,
  blogLink,
  isDesktop,
  team,
  paddingBottom,
  itensPerPage = 10,
}) => {
  const { setPagination } = useContext(PaginationContext);
  const pageRef = useRef(null);

  const pageCount = isBlog
    ? dataPagination.numPages
    : isDesktop
    ? Math.ceil(dataPagination && dataPagination.length / 15)
    : Math.ceil(dataPagination && dataPagination.length / itensPerPage);

  useEffect(() => {
    if (!isBlog) {
      if (dataPagination !== undefined) {
        handlePage();
      }
    }
  }, [dataPagination]);

  const handlePage = data => {
    let pageActual = data ? data.selected + 1 : 1;
    let count = isDesktop
      ? !isBlog && pageActual * 15 - 15
      : !isBlog && pageActual * itensPerPage - itensPerPage;

    let limit = isDesktop
      ? !isBlog && count + 15
      : !isBlog && count + itensPerPage;

    let result = [];
    if (isBlog) {
      if (pageActual <= pageCount) {
        navigate(`/blog/${blogLink}/${pageActual === 1 ? '' : pageActual}`);
      }
    } else {
      if (pageActual <= pageCount) {
        for (let i = count; i < limit; i++) {
          if (dataPagination && dataPagination[i] != null) {
            result.push(dataPagination[i]);
            count += 1;
          }
        }
      }
    }
    setPagination(result);
  };

  return (
    <Container
      numOfPages={pageCount}
      isBlog={isBlog}
      team={team}
      paddingBottom={paddingBottom}
    >
      <ReactPaginate
        previousLabel={<Icon back src={prevIconBlue} alt="Voltar" />}
        nextLabel={<Icon src={nextIcon} alt="Próxima" />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePage}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        disableInitialCallback={!isBlog ? true : false}
        forcePage={isBlog ? dataPagination.currentPage - 1 : 0}
        ref={pageRef}
      />
    </Container>
  );
};

export default PaginationGeneral;
