// Libs
import React from 'react';
import styled from 'styled-components';

// Images
import Logo from 'images/logos/Logo-Certification.png';

// Components
import { device } from './device';

// Styles
const Container = styled.section`
  grid-area: OUT;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media ${device.laptop} {
    margin-top: ${props => props.isOncologia && '0'};
    margin-bottom: ${props => props.isOncologia && '1.875rem'};
  }
`;

const AllServices = styled.div`
  padding: 1.875rem;
  margin-bottom: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 0.5rem;
  background: #f4f5f7;

  @media ${device.laptop} {
    padding: 1.25rem;
    margin-bottom: 1.875rem;
    margin-top: 1.875rem;
    align-items: flex-start;
    flex-direction: column;
  }

  @media ${device.tablet} {
    margin-bottom: 0.625rem;
  }
`;

const ContainerText = styled.div`
  justify-content: center;

  > p {
    width: 79%;
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    margin-left: 1.875rem;
    line-height: 1.375rem;

    @media ${device.laptop} {
      width: fit-content;
      font-size: ${props => (props.isOncologia ? '0.875rem' : '1rem')};
      margin-left: 0;
    }

    > b {
      margin-left: 0;
    }
  }
`;

const BoxLogo = styled.figure`
  width: 32%;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }

  @media ${device.mobile} {
    width: 59%;
  }

  @media ${device.mobile375} {
    width: 62%;
  }

  @media ${device.mobile320} {
    width: 78%;
  }
`;

const LogoCert = styled.img`
  width: 10.5625rem;
`;

const Certificate = ({ marginTop, isOncologia }) => {
  return (
    <Container isOncologia={isOncologia}>
      <AllServices marginTop={marginTop}>
        <BoxLogo>
          <LogoCert src={Logo} alt="logo" />
        </BoxLogo>
        <ContainerText isOncologia={isOncologia}>
          <p>
            <b>Certificação ASCO QOPI</b>
            Nossos Centros de Oncologia possuem a certificação QOPI, concedida
            pela Sociedade Americana de Oncologia Clínica(ASCO), que atesta os
            mais elevados padrões de qualidade e segurança em todas as áreas de
            tratamento, levando a melhores cuidados e resultados para nossos
            pacientes.
          </p>
        </ContainerText>
      </AllServices>
    </Container>
  );
};

export default Certificate;
