import React from 'react';

import { TmpButton } from '@tempo/tempo-design-system-core/dist';

import * as S from './style';
import { Link } from 'gatsby';

const CTAButtonDS = ({ ...rest }) => {
  let content = rest.children.match('\\[(.*?)\\]')[1];
  let url = rest.children.match('\\((.*?)\\)')[1];
  const isWindow = typeof window !== 'undefined';

  const LinkRender = ({ href, content }) => {
    const isInternalLink = href.startsWith('/');
    const isInternalAnchor = href.includes('/#');
    const isMedicalSpecialty = href.includes('especialidades-medicas');
    const isUniversalLink = rest?.universalLink ?? false;

    if (
      isInternalLink &&
      isInternalAnchor &&
      !isMedicalSpecialty &&
      !isUniversalLink
    ) {
    }
    if (isInternalLink && !isUniversalLink) {
      return (
        <Link to={href}>
          {isWindow && { TmpButton } && (
            <TmpButton type="primary" size="sm">
              {content}
            </TmpButton>
          )}
        </Link>
      );
    }

    if (isUniversalLink) {
      return (
        <a href={isUniversalLink}>
          {isWindow && { TmpButton } && (
            <TmpButton type="primary" size="sm">
              {content}
            </TmpButton>
          )}
        </a>
      );
    }

    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {isWindow && { TmpButton } && (
          <TmpButton type="primary" size="sm">
            {content}
          </TmpButton>
        )}
      </a>
    );
  };

  return (
    <S.Container>
      <LinkRender href={url} content={content} />
    </S.Container>
  );
};

export default CTAButtonDS;
