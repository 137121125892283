module.exports.createDoctorData = ({ doctorCMS, doctorsAPI }) => {
  // Precisa ser exportado com "module exports" por conta do gatsby-node
  const createCustomSlug = (name, uniqueInfo, flag) => {
    const formattedUniqueInfo = uniqueInfo
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove accents
      .replace(/([^\w]+|\s+)/g, '') // Replace space and other characters by hyphen
      .toLowerCase();

    const formattedName = name
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Remove accents
      .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
      .toLowerCase();

    return `${formattedName}${flag && '-' + flag}${
      formattedUniqueInfo && '-' + formattedUniqueInfo
    }`;
  };

  const foundDoctorApi = doctorsAPI.find(
    doctorApi => doctorApi.id === doctorCMS.id_api
  );

  if (foundDoctorApi) {
    const slug = createCustomSlug(
      foundDoctorApi.name ?? doctorCMS.name,
      foundDoctorApi.crm ?? doctorCMS.id,
      foundDoctorApi.crm ? 'crm' : ''
    );

    return {
      ...doctorCMS,
      ...foundDoctorApi,
      id_cms: doctorCMS.id,
      id_api: foundDoctorApi.id,
      crm: foundDoctorApi.crm ?? '',
      nameWithAssignment: `${doctorCMS.assignment || ''} ${
        foundDoctorApi.name ?? doctorCMS.name
      }`.trim(),
      slug,
    };
  }

  if (doctorCMS?.id_api) return null;

  const slug = createCustomSlug(doctorCMS.name, doctorCMS.id, '');

  return {
    ...doctorCMS,
    id_cms: doctorCMS.id,
    id_api: '',
    nameWithAssignment: `${doctorCMS.assignment || ''} ${
      doctorCMS.name
    }`.trim(),
    slug,
    specialty:
      doctorCMS.specialties?.map(specialty => specialty.name).join(',') ?? '',
  };
};
