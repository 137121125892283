import React, { useEffect, useState } from 'react';
import * as S from './style';

function Logo({ content }) {
  const [isMobile, setIsMobile] = useState(false);
  const isWindow = typeof window !== 'undefined';

  const updateWindowSize = () => {
    setIsMobile(window.innerWidth < 641);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowSize);
      return () => window.removeEventListener('resize', updateWindowSize);
    }
  }, []);

  return (
    <S.Wrapper>
      <S.Container>
        {content?.logoData.map((image, index) => {
          return (
            <S.ImageContainer>
              <S.ImageContent id="image-content" key={index}>
                  <S.Image src={image.image.url} alt="" />
              </S.ImageContent>
            </S.ImageContainer>
          );
        })}
      </S.Container>
    </S.Wrapper>
  );
}

export { Logo };
