import React, { useEffect, useState } from 'react';

import { CustomFragment } from 'components/CmsComponents/CustomFragment';
import { formatClassName } from 'utils/formatClassName';

import {
  Container,
  Content,
  Box,
  WrapTitle,
  Title,
  WrapDescription,
  Description,
  WrapImage,
  Image,
  WrapContainer,
} from './style';

function BannerSpecialtyContent({
  title,
  description,
  images,
  backgroundColor,
  backgroundColorInitialAlias,
  backgroundColorFinalAlias,
  type,
  fragments,
}) {
  const [isMobile, setIsMobile] = useState(false);
  const hasMultipleImages = images?.length > 1 ?? false;

  const updateWindowSize = () => {
    setIsMobile(window.innerWidth < 641);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowSize);
      return () => window.removeEventListener('resize', updateWindowSize);
    }
  }, []);

  return (
    <Container
      bgColor={backgroundColor?.hex ?? 'transparent'}
      backgroundInitialColor={backgroundColorInitialAlias?.hex}
      backgroundFinalColor={backgroundColorFinalAlias?.hex}
      className={formatClassName(type)}
    >
      <Content className={formatClassName(type)}>
        <Box>
          <WrapContainer>
            {!!title && (
              <WrapTitle>
                <Title titleLanding={!!backgroundColorInitialAlias?.hex}>{title}</Title>
              </WrapTitle>
            )}
            {!!description && (
              <WrapDescription>
                <Description descriptionLanding={!!backgroundColorInitialAlias?.hex}>{description}</Description>
              </WrapDescription>
            )}
            {fragments?.map(fragment => (
              <CustomFragment
                key={fragment.id}
                className={formatClassName(fragment.type)}
                children={fragment.markdown}
                debugName={fragment.name}
              />
            ))}
          </WrapContainer>
          <WrapImage>
            {hasMultipleImages ? (
              <Image
                src={isMobile ? images?.[1]?.url : images?.[0]?.url}
                alt="Destaque Marketing"
              />
            ) : (
              <Image src={images?.[0]?.url} alt="Destaque Marketing" />
            )}
          </WrapImage>
        </Box>
      </Content>
    </Container>
  );
}

export { BannerSpecialtyContent };
