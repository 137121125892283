import React, { useEffect, useState } from 'react';

import { formatClassName } from 'utils/formatClassName';
import FormRecipeGuide from 'components/CmsComponents/HighlightFormLeadContent/FormRecipeGuide';

import {
  Container,
  Content,
  Box,
  WrapImage,
  Image,
  ImageBg,
  WrapContainer,
  WrapTitle,
  Title,
  CustomTitle,
  WrapDescription,
  Description,
  WrapForm,
  WrapFootnote,
  Footnote,
} from './style';

function HighlightFormLeadContent({
  title,
  customTitle,
  description,
  images,
  type,
  backgroundColorInitial = { hex: '#1D52A3' },
  backgroundColorFinal = { hex: '#45A7DF' },
  backgroundColorText,
  documentsForDownload,
  referenceToSalesforce,
  footnote,
}) {
  const [isMobile, setIsMobile] = useState(false);
  const hasMultipleImages = images?.length > 1 ?? false;

  const filesUrlToDownload = documentsForDownload.map(document => document.url);

  const hasBgImage = formatClassName(type).includes('bgImage');
  const hasTextDarkColor = formatClassName(type).includes('textDarkColor');

  const updateWindowSize = () => {
    setIsMobile(window.innerWidth < 641);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowSize);
      return () => window.removeEventListener('resize', updateWindowSize);
    }
  }, []);

  return (
    <Container
      bgColorInitial={backgroundColorInitial?.hex ?? '#fff'}
      bgColorFinal={backgroundColorFinal?.hex ?? '#fff'}
      className={formatClassName(type)}
    >
      <Content className={formatClassName(type)}>
        <Box>
          <WrapImage className={formatClassName(type)}>
            {hasMultipleImages ? (
              hasBgImage ? (
                <ImageBg
                  bgUrl={isMobile ? images?.[1]?.url : images?.[0]?.url}
                />
              ) : (
                <Image
                  src={isMobile ? images?.[1]?.url : images?.[0]?.url}
                  alt="Destaque Marketing"
                />
              )
            ) : hasBgImage ? (
              <ImageBg bgUrl={images?.[0]?.url ?? ''} />
            ) : (
              <Image src={images?.[0]?.url} alt="Destaque Marketing" />
            )}
          </WrapImage>
          <WrapContainer>
            {(title || customTitle) && (
              <WrapTitle>
                {title ? (
                  <Title color={hasTextDarkColor ? '#000' : '#fff'}>
                    {title}
                  </Title>
                ) : customTitle ? (
                  <CustomTitle backgroundColorText={backgroundColorText}>
                    {customTitle}
                  </CustomTitle>
                ) : (
                  <></>
                )}
              </WrapTitle>
            )}
            {description && (
              <WrapDescription>
                <Description backgroundColorText={backgroundColorText}>
                  {description}
                </Description>
              </WrapDescription>
            )}
            <WrapForm>
              <FormRecipeGuide
                filesUrlToDownload={filesUrlToDownload}
                serviceType={referenceToSalesforce}
                customClass={formatClassName(type)}
              />
            </WrapForm>
            <WrapFootnote>
              <Footnote backgroundColorText={backgroundColorText}>
                {footnote}
              </Footnote>
            </WrapFootnote>
          </WrapContainer>
        </Box>
      </Content>
    </Container>
  );
}

export { HighlightFormLeadContent };
