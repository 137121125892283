// Libs
import React, { useContext, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import scrollTo from 'gatsby-plugin-smoothscroll';

// Components
import Components from 'components/CmsComponents/';
import { device } from 'components/device';

import { HeaderContext } from 'context/header';

// Assets
import ArrowAnchor from 'images/icons/arrow-anchor.svg';

// Styles
import './layout.css';

// HD - HEADER
// FT - FOOTER
// CTT - CONTENT

const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  justify-content: center;

  grid-template-areas:
    'HD HD HD HD HD HD HD HD HD HD HD HD HD HD'
    'CTT CTT CTT CTT CTT CTT CTT CTT CTT CTT CTT CTT CTT CTT'
    '. FT FT FT FT FT FT FT FT FT FT FT FT .';
  overflow: ${props =>
    props.overflow || props.isOverflow ? 'initial' : 'hidden'};

  @media ${device.laptop} {
    display: block;
    overflow-x: hidden;
  }
`;

const Main = styled.main`
  grid-area: CTT;

  @media ${device.laptop} {
    display: block;
  }
`;

const AnimationIn = keyframes`
  from { 
    bottom: -5rem;
  } to {
    bottom: 10px;
  }
`;

const AnimationOut = keyframes`
  from {
    bottom: 0;
  } to {
    bottom: -5rem;
    transform: rotate(180deg);
  }
`;

const ButtonAnchor = styled.button`
  position: fixed;
  right: 0;
  bottom: ${props => (props.scroll !== true ? '-5rem' : '0px')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 0 20px 20px 0;
  background: #fff;
  border: 1px solid #c6c8cc;
  outline: 0;
  border-radius: 50%;
  animation-name: ${props => (props.scroll ? AnimationIn : AnimationOut)};
  animation-duration: 0.8s;
  cursor: pointer;
  z-index: 99;
`;

const Layout = ({
  children,
  overflow,
  className = '',
  page,
  pageContext,
  hideHeader = false,
  headerReplace,
}) => {
  const [scroll, setScroll] = useState(false);
  const { isOverflow } = useContext(HeaderContext);

  const isWindow = typeof window !== 'undefined';

  const checkScrollTop = () => {
    if (!scroll && window.pageYOffset > 400) {
      setScroll(true);
    } else if (scroll && window.pageYOffset <= 400) {
      setScroll(false);
    }
  };

  const scrollTop = () => {
    if (isWindow) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      scrollTo('#topPage');
    }
  };

  if (isWindow) {
    window.addEventListener('scroll', checkScrollTop);
  }
  const footer = page?.footerDS ?? [];
  const header = page?.headerDS ?? [];
  return (
    <ContainerGrid
      className={className}
      overflow={overflow}
      isOverflow={isOverflow}
      id="topPage"
    >
      {!hideHeader ? header && Components(header) : headerReplace}
      <Main>{children}</Main>
      {footer && Components(footer)}
      <ButtonAnchor onClick={scrollTop} scroll={scroll}>
        <img src={ArrowAnchor} alt="Âncora" />
      </ButtonAnchor>
    </ContainerGrid>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default rest => {
  return (
    <StaticQuery
      query={graphql`
        {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              headerDS {
                ... on GraphCMS_ComponentDsHeader {
                  id
                  localizations(includeCurrent: true) {
                    locale
                    topBarLinks
                    menuLinks
                    imageLogo {
                      width
                      height
                      handle
                      url
                    }
                  }
                }
              }
              footerDS {
                ... on GraphCMS_ComponentDsFooter {
                  id
                  badgeText
                  contactLinks
                  contactPhones
                  copyrightText
                  policyText
                  infoLinks
                  footerLinks
                  imageLogo {
                    handle
                    fileName
                    height
                    width
                    url
                  }
                  badges {
                    id
                    height
                    width
                    fileName
                    url
                  }
                  socialMedia
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <Layout page={response.gcms.site} {...rest} />;
      }}
    />
  );
};
