import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
  background: ${({ bgColorInitial, bgColorFinal }) =>
    `linear-gradient(135deg, ${bgColorInitial} 0%, ${bgColorFinal} 100%)`};

  @media ${device.laptop} {
    margin-top: 1.25rem;
  }

  &.mt0 {
    margin-top: 0;

    @media ${device.laptop} {
      margin-top: 0;
    }
  }

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.mb70 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 3.125rem;
    }
  }

  &.mb70x20 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  .textWhiteLink a {
    color: white;
    text-decoration: underline;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 4.375rem 0;

  @media ${device.laptop} {
    padding: 1.25rem 0;
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1.875rem;

  @media ${device.laptop} {
    align-items: center;
    flex-direction: column;
    gap: 1.25rem;
    padding: 1rem 1.25rem;
  }
`;

export const WrapVideo = styled.div`
  width: 100%;
  max-width: 29.375rem;
  height: 100%;

  @media ${device.laptop} {
    margin-top: -2.5rem;
  }

  @media ${device.mobile} {
    height: auto;
  }

  &.VideoSize570px {
    iframe {
      width: 35.625rem;
      height: 19.75rem;

      @media ${device.tablet} {
        width: 100%;
      }

      @media ${device.mobile} {
        height: 11.625rem;
      }
    }
  }
`;

export const VideoEmbed = styled(ReactMarkdown)`
  width: 100%;

  iframe {
    width: 100%;
    height: 16.5rem;
    margin: 0;
  }

  @media ${device.mobile} {
    iframe {
      height: 11.625rem;
    }
  }
`;

export const WrapImage = styled.div`
  width: 100%;
  max-width: 29.375rem;
  height: auto;
  min-height: 100%;

  @media ${device.laptop} {
    margin-top: -2.5rem;
  }
  &.normalizedContainerImg {
    @media ${device.laptop} {
      margin-top: 0;
    }
  }
  &.WrapImageWidthFull {
    max-width: 100%;
  }

  &.imgMobileWidth100vw {
    @media ${device.laptop} {
      min-width: 100vw;
    }
  }

  &.imgWidth230 {
    @media ${device.laptop} {
      width: 14.375rem;
    }

    @media ${device.mobile} {
      width: 11.375rem;
    }
  }
`;

export const ImageBg = styled.div`
  width: 100%;
  min-height: 100%;
  background-image: url(${({ bgUrl }) => bgUrl});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  @media ${device.laptop} {
    height: 18rem;
  }

  @media ${device.mobile} {
    height: 12.5rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const WrapContainer = styled.div`
  width: 100%;
  max-width: 35.625rem;

  @media ${device.laptop} {
    max-width: unset;
  }
`;

export const WrapTitle = styled.div`
  width: 100%;

  & + div {
    margin-top: 3.125rem;
  }

  @media ${device.laptop} {
    & + div {
      margin-top: 1.25rem;
    }
  }
`;

export const CustomTitle = styled(ReactMarkdown)`
  width: 100%;
  color: ${({ colorTxt }) => `${colorTxt}`};
  margin: 0;

  h1,
  h2,
  h3,
  h4 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: normal;
    margin: 0;
  }

  &.standardWhite {
    a {
      color: #ffffff;
    }
  }

  &.standardBlack {
    color: #231f20;

    a {
      color: #231f20;
    }
  }

  p {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: normal;
  }

  strong {
    font-weight: 600;
  }

  @media ${device.laptop} {
    h1,
    h2,
    h3,
    h4 {
      font-size: 1.125rem;
    }

    p {
      font-size: 1.125rem;
    }

    &.lh20 {
      p {
        line-height: 1.25rem;
      }
    }
  }
`;

export const Title = styled.h4`
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: #ffffff;
  margin: 0;

  &.standardWhite {
    a {
      color: #ffffff;
    }
  }

  &.standardBlack {
    color: #231f20;

    a {
      color: #231f20;
    }
  }

  @media ${device.laptop} {
    font-size: 1.125rem;
  }
`;

export const WrapDescription = styled.div`
  width: 100%;

  & + div {
    margin-top: 3.125rem;
  }

  @media ${device.laptop} {
    & + div {
      margin-top: 1.25rem;
    }
  }
`;

export const Description = styled(ReactMarkdown)`
  width: 100%;
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: normal;
  margin: 0;
  color: #ffffff;

  &.standardWhite {
    p {
      color: #ffffff;
    }

    a {
      color: #ffffff;
    }
  }

  &.standardBlack {
    p {
      color: #231f20;
    }

    a {
      color: #231f20;
    }
  }

  p + p {
    margin-top: 1.875rem;
  }

  @media ${device.laptop} {
    font-size: 0.875rem;

    &.lh20 {
      p + p {
        margin-top: 1.25rem;
      }
    }
  }
`;

export const WrapFragments = styled.div`
  width: 100%;

  &.twoCTAButtons {
    display: flex;
    flex-wrap: wrap;
    gap: 3.125rem;

    @media ${device.laptop} {
      gap: 1.25rem;
    }
  }
`;

export const WrapSliderFragments = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;

  @media ${device.laptop} {
    width: 100vw;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin: 0 -20px;
    padding: 0 20px;
    gap: 1.25rem;

    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    ${props =>
      props.activeSlider &&
      css`
        cursor: grabbing !important;
        user-select: none;

        a {
          pointer-events: none;
        }
      `}
  }
`;
