import styled from 'styled-components';
import { device } from 'components/device';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 425px;

  @media ${device.mobile} {
    height: 716px;
  }
`;

export const StepsContainer = styled.div`
  justify-content: center;
  position: absolute;
  bottom: 32px;
  z-index: 1;
  width: 100%;
  max-width: 1005px;
  display: ${props => (props.displayBanner ? 'none' : 'flex')};
  @media (max-width: 648px) {
    bottom: 32px;
  }
`;

export const Container = styled.div`
  display: flex;
  touch-action: none;
  transform: ${({ currentBanner, bannerWidth }) =>
    `translateX(-${currentBanner * bannerWidth}px)`};
  width: 100%;
  max-width: calc(100vw * ${props => props.bannersLength});
  transition: transform 600ms cubic-bezier(0.25, 1, 0.35, 1);

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 648px) {
    touch-action: auto;
  }
`;

export const BannerItem = styled.div`
  flex-shrink: 0;
  width: 100vw;
  height: 425px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};

  @media ${device.mobile} {
    height: 716px;
  }
`;

export const BannerContent = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;

  @media ${device.mobile} {
    height: 96%;
    flex-direction: column;
    justify-content: center;
  }
`;

export const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media ${device.mobile} {
    order: -1;
  }
`;

export const ArrowsContainer = styled.div`
  justify-content: space-between;
  position: absolute;
  width: 100%;
  max-width: 1368px;
  z-index: 1;
  top: 50%;
  display: ${props => (props.displayBreakingNews ? 'none' : 'flex')};

  @media ${device.mobile} {
    display: none;
  }
`;

export const Arrow = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: #f4f5f7;
  border-radius: 1000px;
  border: none;
`;
