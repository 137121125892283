// Libs
import React, { useEffect, useState } from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import {
  TmpAccordionGroup,
  TmpAccordion,
  TmpTypography,
  TmpLink,
} from '@tempo/tempo-design-system-core/dist';

// Components
import DynamicFaq from 'components/faq/dynamicFaq';
import Accordion from 'components/Accordion';
import { formatPhraseToSlug } from 'utils/phraseToSlug';

// Images
import arrowRightIcon from 'images/icons/arrow-right.svg';

// Styles
import {
  Container,
  DSWrapper,
  Title,
  ContainerAuthor,
  FaqLink,
  ContentText,
} from './style';

function Qa({
  doctorsApi,
  title,
  subTitle,
  data,
  showFaqLink = '',
  showFaqLinkOnClick = () => {},
  noBorderBottomLastElement,
  isFormatted = false,
  className = '',
  isDesignSystem,
}) {
  const [doctorLink, setDoctorLink] = useState('');

  const isWindow = typeof window !== 'undefined';

  const questions = data?.pergunta
    .replace(/(<div id=.*[\n|\r]\n)/i, '')
    .split(/(##.*\n)/gim)
    .filter(el => el)
    .map((item, index) =>
      index % 2 === 0
        ? `${item.replace(/\n/gim, '').replace(/\(end\)/gim, '')} (end)`
        : item
    )
    .reduce((acc, item, index, array) => {
      const isPair = index % 2 === 0;

      if (!isPair) return acc;

      let q = [];

      q.push(item);
      q.push(array[index + 1]);

      return [...acc, q.join('\n\n')];
    }, []);

  const questionsDynamicFaq = questions.join('').replace(/\(end\)/gim, '');
  const assignmentAndName = `${data?.doctors[0]?.assignment || ''} ${
    data?.doctors[0]?.name || ''
  }`.trim();
  const doctorIsActive = data?.doctors[0]?.active;

  useEffect(() => {
    async function loadDoctor() {
      try {
        const doctor = doctorsApi.find(
          doctor => doctor.name === data?.doctors[0]?.name
        );

        if (!doctor) {
          setDoctorLink('');
        }

        const formattedCRM = doctor.crm.replace(/[^A-Z0-9]/gi, '');
        const slug = `${formatPhraseToSlug(doctor.name)}-crm-${formattedCRM}`;

        setDoctorLink(slug);
      } catch (err) {
        setDoctorLink('');
      }
    }
    loadDoctor();
  }, []);

  return (
    <Container className={className}>
      {isDesignSystem ? (
        <DSWrapper>
          {isWindow && { TmpTypography } && (
            <>
              <TmpTypography component="heading" size="large">
                {title}
              </TmpTypography>
              <TmpTypography component="caption">{subTitle}</TmpTypography>
            </>
          )}
          {isWindow && { TmpAccordionGroup } && (
            <TmpAccordionGroup>
              {isWindow && { TmpAccordion } &&
                questions.map((question, index) => {
                  let questionTitle = question.split('(end)')[0];

                  let questionResponse = question.split('(end)')[1];
                  return (
                    isWindow && { TmpAccordion } && (
                      <TmpAccordion
                        key={index}
                        label={questionTitle.replaceAll('## ', '')}
                      >
                        <ContentText
                          escapeHtml={false}
                          children={questionResponse}
                          linkTarget="_blank"
                        />
                      </TmpAccordion>
                    )
                  );
                })}
            </TmpAccordionGroup>
          )}
          {showFaqLink && (
            <FaqLink>
              <Link to={showFaqLink || '#'} onClick={showFaqLinkOnClick}>
                {isWindow && { TmpLink } && (
                  <TmpLink icon>Veja mais perguntas e respostas</TmpLink>
                )}
              </Link>
            </FaqLink>
          )}
        </DSWrapper>
      ) : (
        <>
          <Title children={title} />
          <ContainerAuthor>
            {doctorLink && doctorIsActive ? (
              <>
                {assignmentAndName && (
                  <p>
                    Por
                    <Link
                      className="hiper-link"
                      to={`/encontre-seu-medico/${doctorLink}`}
                    >
                      {assignmentAndName}
                    </Link>
                  </p>
                )}
              </>
            ) : (
              <>{assignmentAndName && <p>Por {assignmentAndName}</p>}</>
            )}
          </ContainerAuthor>
          <DynamicFaq fullMarkdown={questionsDynamicFaq} />
          {questions.map((question, index) => (
            <Accordion
              key={index}
              isFormatted={isFormatted}
              markdownContent={question}
              noBorderBottomLastElement={
                index === questions.length - 1
                  ? noBorderBottomLastElement
                  : false
              }
            />
          ))}

          {showFaqLink && (
            <FaqLink>
              <Link to={showFaqLink || '#'} onClick={showFaqLinkOnClick}>
                <button>Veja mais perguntas e respostas</button>
                <img src={arrowRightIcon} alt="Access link" />
              </Link>
            </FaqLink>
          )}
        </>
      )}
    </Container>
  );
}

const PageComponent = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          doctorsApi {
            doctors {
              id
              name
              gender
              crm
              email
              phone
              phonecommercial
              specialty
              employee
              estab
              state
            }
          }
        }
      `}
      render={response => {
        return <Qa doctorsApi={response?.doctorsApi?.doctors} {...props} />;
      }}
    />
  );
};
export default PageComponent;
