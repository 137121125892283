import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;

  @media ${device.laptop} {
    padding: 0 1.25rem;
  }

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.mb70 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 3.125rem;
    }
  }

  &.mb70x20 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 4.375rem 0;

  &.borderBottom {
    border-bottom: 1px solid #c6c8cc;
  }

  @media ${device.laptop} {
    padding: 1.25rem 0;
  }
`;

export const Box = styled.div`
  width: 100%;
`;

export const WrapTitle = styled.div`
  width: 100%;
  margin-bottom: 3.75rem;

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
  }
`;

export const Title = styled.h4`
  width: 100%;
  font-size: 2.25rem;
  line-height: 2.875rem;
  font-weight: 600;
  color: #231f20;
  text-align: center;
  margin: 0;

  @media ${device.laptop} {
    font-size: 1.625rem;
    line-height: 2.25rem;
  }
`;

export const WrapContainer = styled.div`
  width: 100%;
`;

export const WrapRecipeTypesContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 3.75rem 1.875rem;

  @media ${device.laptop} {
    width: 100vw;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin: 0 -20px;
    padding: 0 20px;
    gap: 1.875rem;

    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    ${props =>
      props.activeSlider &&
      css`
        cursor: grabbing !important;
        user-select: none;

        a {
          pointer-events: none;
        }
      `}
  }
`;

export const RecipeTypesContent = styled.div`
  width: 100%;
  max-width: 35.625rem;

  @media ${device.laptop} {
    min-width: 16.875rem;
    max-width: 16.875rem;
  }
`;

export const WrapRecipeTypesImage = styled.div`
  width: 100%;
  height: 100%;
  max-height: 20rem;

  margin-bottom: 1.875rem;

  @media ${device.laptop} {
    max-height: 9.375rem;
    margin-bottom: 1.25rem;
  }
`;

export const RecipeTypesImage = styled.img`
  width: 100%;
  height: auto;

  object-fit: cover;
  object-position: center;
`;

export const WrapRecipeTypesTitle = styled.div`
  width: 100%;
  margin-bottom: 1.875rem;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }
`;

export const RecipeTypesTitle = styled.h6`
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: #231f20;
  font-weight: normal;
  margin: 0;

  @media ${device.laptop} {
    font-size: 1rem;
    line-height: 1.625rem;
  }
`;

export const WrapRecipesList = styled.ul`
  margin-bottom: 0;
`;

export const WrapRecipesListItem = styled.li`
  font-size: 1rem;
  line-height: 1.625rem;
  color: #231f20;
  font-weight: normal;
  margin: 0;

  &::marker {
    color: #45a7df;
  }

  & + li {
    margin-top: 1.25rem;
  }

  @media ${device.laptop} {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;

export const WrapRecipesListItemLink = styled(Link)`
  color: #45a7df;
`;
