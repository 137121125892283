import React, { useEffect, useState } from 'react';
import * as S from './style';

function CardEffect({ title, description, cardsData }) {
  const [isMobile, setIsMobile] = useState(false);

  const updateWindowSize = () => {
    setIsMobile(window.innerWidth < 641);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowSize);
      return () => window.removeEventListener('resize', updateWindowSize);
    }
  }, []);

  return (
    <S.Wrapper>
      <S.Container>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>

        {cardsData.map(card => {
          return (
            <S.CardContainer>
              <S.Card id="card">
                <S.FirstImage src={card.firstImage.url} alt="" />
                <S.SecondImage>
                  {card.linkSecondImage ? (
                    <a href={card.linkSecondImage} target="_blank">
                      <img src={card.secondImage.url} alt="" />
                    </a>
                  ) : (
                    <img src={card.secondImage.url} alt="" />
                  )}
                </S.SecondImage>
              </S.Card>
            </S.CardContainer>
          );
        })}
      </S.Container>
    </S.Wrapper>
  );
}

export { CardEffect };
