import styled, { css } from 'styled-components';
import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
  background: ${({ bgColor }) => bgColor};

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.mb70 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 3.125rem;
    }
  }

  &.mb70x20 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 4.375rem 0;

  @media ${device.laptop} {
    padding: 1.25rem;
  }
`;

export const Box = styled.div`
  width: 100%;
`;

export const WrapTitle = styled.div`
  width: 100%;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
  }
`;

export const Title = styled.h4`
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: normal;
  color: #231f20;

  @media ${device.laptop} {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
`;

export const WrapCarouselMobile = styled.div`
  display: none;
  width: 100vw;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin: 0 -20px;
  padding: 0 20px;
  gap: 1.25rem;

  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  ${props =>
    props.activeSlider &&
    css`
      cursor: grabbing !important;
      user-select: none;

      a {
        pointer-events: none;
      }
    `}
  @media ${device.laptop} {
    display: flex;
  }
`;

export const CarouselMobileItem = styled.div`
  width: 100%;
  min-width: 40%;
  height: auto;

  img {
    object-fit: cover;
    object-position: center;
  }

  @media ${device.laptop} {
    min-width: 40%;
  }

  @media ${device.mobile} {
    min-width: 85%;
  }
`;

export const WrapCarousel = styled.div`
  .rec.rec-slider-container {
    margin-right: 0;
    margin-left: 0;
  }

  .rec.rec-arrow[disabled] {
    opacity: 0;
    cursor: default;
  }

  .rec.rec-arrow-left {
    position: absolute;
    top: 4rem;
    right: 2.75rem;
    background: #ffffff;
    color: #45a7df;
    box-shadow: none;
    transition: opacity 0.3s;
    line-height: 0;
    font-size: 16px;
    width: 34px;
    min-width: 34px;
    height: 34px;

    @media ${device.laptop} {
      bottom: 3rem;
      right: 17.75rem;
    }
  }

  .rec.rec-arrow-right {
    position: absolute;
    top: 4rem;
    right: 0;
    background: #ffffff;
    box-shadow: none;
    color: #45a7df;
    transition: opacity 0.3s;
    line-height: 0;
    font-size: 16px;
    width: 34px;
    min-width: 34px;
    height: 34px;

    @media ${device.laptop} {
      bottom: 3rem;
      right: 13.75rem;
    }
  }

  .rec.rec-arrow-left:hover {
    filter: brightness(0.9);
    background: #c4c4c4;
  }

  .rec.rec-arrow-right:hover {
    filter: brightness(0.9);
    background: #c4c4c4;
  }

  @media ${device.laptop} {
    display: none;
  }

  ${({ fragments }) =>
    fragments &&
    css`
      .rec.rec-arrow[disabled] {
        opacity: 0.5;
        background: #f4f5f7;
      }

      .rec.rec-arrow-left {
        background: #f4f5f7;
      }

      .rec.rec-arrow-left:hover {
        filter: none;
        background: #f4f5f7;
      }

      .rec.rec-arrow-right {
        background: #f4f5f7;
      }

      .rec.rec-arrow-right:hover {
        filter: none;
        background: #f4f5f7;
      }
    `}
`;

export const CarouselItem = styled.div`
  img {
    width: 35.625rem;
    height: 23.5rem;

    object-fit: cover;
    object-position: center;
  }
`;

export const BallSlider = styled.div`
  display: flex;
  transform: ${props =>
    props.whoWeAre ? 'translate(0, 0)' : 'translate(-560px, 433px)'};
  position: ${props => (props.whoWeAre ? 'relative' : 'absolute')};
  transform: ${props =>
    props.whoWeAre ? 'translate(0,0)' : 'translate(-560px, 433px)'};
  width: ${props => (props.whoWeAre ? '100%' : 'auto')};
  position: ${props => (props.whoWeAre ? 'relative' : 'absolute')};
  margin-top: 0;
  margin-left: 0;

  @media ${device.laptop} {
    display: ${props => (props.whoWeAre ? 'none' : 'block')};
    transform: ${props =>
      props.whoWeAre ? 'translate(0,0)' : 'translate(-560px, 433px)'};
    width: ${props => (props.whoWeAre ? '100%' : 'auto')};
    position: ${props => (props.whoWeAre ? 'relative' : 'absolute')};
    margin-top: 34px;
    margin-bottom: 30px;
  }
`;

export const Balls = styled.button`
  margin-right: 8px;
  width: 8px;
  height: 8px;
  border-radius: 1.875rem;
  background-color: ${({ active }) => (active ? '#C6C8CC' : '#FFF')};
  border: 1px solid #c6c8cc;
  cursor: pointer;
  outline: none;
  :active {
    background: #c6c8cc;
    border: unset;
  }
`;
