import React from 'react';
import { ButtonCopy } from 'components/CmsComponents/ButtonCopy';

const OtherCustomFragments = ({ ...rest }) => {
  switch (true) {
    case rest.className.includes('CopyButton'):
      return <ButtonCopy {...rest} />;
    default:
      return <></>;
  }
};

export { OtherCustomFragments };
