import React, { useState, useEffect } from 'react';
import reactStringReplace from 'react-string-replace';
import DSArrow from 'images/icons/arrow-right-white.svg';
import {
  TmpTypography,
  TmpShape,
  TmpModal,
  TmpLink,
  TmpButton,
} from '@tempo/tempo-design-system-core/dist';
import * as S from './style';

function DSButtonContent({ title, description, buttons }) {
  const [isOpened, setIsOpened] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [descriptionModal, setDescriptionModal] = useState('');
  const [linkModal, setLinkModal] = useState('');
  const [termoLink, setTermoLink] = useState('');
  const [containerDescriptionModal, setContainerDescriptionModal] = useState(
    ''
  );
  const isWindow = typeof window !== 'undefined';

  const openModal = (titleModal, descriptionModal, linkModal, termoLink) => {
    setTitleModal(titleModal);
    setDescriptionModal(descriptionModal);
    setLinkModal(linkModal);
    setTermoLink(termoLink);
    setIsOpened(true);
  };

  useEffect(() => {
    const numbers = new RegExp('^[0-9]+$');
    if (!descriptionModal) return;
    if (numbers.test(linkModal)) {
      setContainerDescriptionModal(
        reactStringReplace(
          descriptionModal?.replaceAll('[break]', '\n'),
          '[espacoTermoLink]',
          () => (
            <TmpLink href={'tel:+' + linkModal} target="_blank">
              {termoLink}
            </TmpLink>
          )
        )
      );
    } else {
      setContainerDescriptionModal(
        reactStringReplace(
          descriptionModal?.replaceAll('[break]', '\n'),
          '[espacoTermoLink]',
          () => (
            <TmpLink href={linkModal} target="_blank">
              {termoLink}
            </TmpLink>
          )
        )
      );
    }
  }, [descriptionModal]);

  return (
    <>
      <S.Container>
        <S.Content>
          <S.Box>
            <S.Information>
              <S.Title>{title?.replaceAll('[break]', '\n')}</S.Title>
              <S.Description>
                {description?.replaceAll('[break]', '\n')}
              </S.Description>
            </S.Information>
            <S.ButtonContainer>
              {buttons.map((button, index) =>
                button.url
                  ? isWindow && { TmpButton } && (
                      <TmpButton
                        handleClick={() =>
                          window.open(button.url, button.target)
                        }
                      >
                        {button.title?.replaceAll('[break]', '\n')}

                        <S.WrapImage>
                          <img
                            src={DSArrow}
                            alt={button.title?.replaceAll('[break]', ' ')}
                          />
                        </S.WrapImage>
                      </TmpButton>
                    )
                  : button.titleModal && button.descriptionModal
                  ? isWindow && { TmpButton } && (
                      <TmpButton
                        key={index}
                        handleClick={() =>
                          openModal(
                            button.titleModal,
                            button.descriptionModal,
                            button.linkModal,
                            button.termoLink
                          )
                        }
                      >
                        {button.title?.replaceAll('[break]', '\n')}

                        <S.WrapImage>
                          <img
                            src={DSArrow}
                            alt={button.title?.replaceAll('[break]', ' ')}
                          />
                        </S.WrapImage>
                      </TmpButton>
                    )
                  : isWindow && { TmpShape } && (
                      <TmpShape key={index}>
                        {button.title?.replaceAll('[break]', '\n')}
                      </TmpShape>
                    )
              )}
            </S.ButtonContainer>
            {isWindow && { TmpModal } && (
              <S.Modal>
                <TmpModal
                  isOpened={isOpened}
                  isDoubleAction={false}
                  labelPrimary="Entendi"
                  handleConfirm={e => setIsOpened(false)}
                  handleCancel={e => setIsOpened(false)}
                  handleClose={e => setIsOpened(false)}
                >
                  <TmpTypography component="subtitle">
                    {titleModal?.replaceAll('[break]', '\n')}
                  </TmpTypography>
                  <TmpTypography component="paragraph">
                    {containerDescriptionModal}
                  </TmpTypography>
                </TmpModal>
              </S.Modal>
            )}
          </S.Box>
        </S.Content>
      </S.Container>
    </>
  );
}

export { DSButtonContent };
