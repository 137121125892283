import styled from 'styled-components';
import { device } from 'components/device';

export const Container = styled.div`
  margin-top: 2rem;

  @media ${device.mobile} {
    display: flex;
    justify-content: center;
  }
`;
