// Libs
import React, { useState } from 'react';
import styled from 'styled-components';
import { device } from 'components/device';
import * as Yup from 'yup';

// Components
import InputComponent from 'components/Input';
import ButtonComponent from 'components/SimpleButton';
import { Grid } from '@material-ui/core';
import { formatClassName } from 'utils/formatClassName';

// Services
import { userSend } from 'components/services/salesForceAPI.js';

const Content = styled.div`
  width: 100%;
`;

const ContentLogin = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  width: 100%;
  background: #ffffff;
  padding: 2.5rem 1.875rem;
  margin-bottom: 3.125rem;
  border: ${props => (props.hasBorder ? '1px solid #c6c8cc' : '0px')};
  border-radius: ${props => (props.hasBorder ? '8px' : '0.5rem')};

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }
`;

const BoxBottom = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
`;
const BoxDownloadButtons = styled.div`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  gap: 1.875rem;

  @media ${device.tablet} {
    flex-direction: column;
    gap: 1.25rem;
  }
`;

const FormRecipeGuide = ({
  filesUrlToDownload = [],
  serviceType = '',
  customClass,
}) => {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isVisibleDownloadLinks, setIsVisibleDownloadLinks] = useState(false);
  const hasBorder = customClass.includes('borderFormCartilha');
  const hasButtonFormColorPink = customClass.includes('buttonFormColorPink');
  const hasButtonFormColorWhite = customClass.includes('buttonFormColorWhite');
  const hasButtonFormBackgroundPink = customClass.includes(
    'buttonFormBackgroundPink'
  );
  const hasButtonFormBackgroundBlue = customClass.includes(
    'buttonFormBackgroundBlue'
  );

  const phoneRegExp = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;
  let schema = Yup.object().shape({
    name: Yup.string().required('Nome obrigatório').min(2, 'Nome obrigatório'),
    lastname: Yup.string()
      .required('Sobrenome obrigatório')
      .min(2, 'Sobrenome obrigatório'),
    email: Yup.string()
      .email('Digite um e-mail valido')
      .required('E-mail obrigatório'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Digite um telefone valido')
      .min(12, 'Digite um telefone valido'),
  });

  const handleSubmit = async () => {
    setLoading(true);
    setErrors({});

    try {
      await schema.validate(
        {
          name,
          lastname,
          email,
          phone,
        },
        {
          abortEarly: false,
        }
      );

      if (filesUrlToDownload?.length === 1) {
        window.open(filesUrlToDownload[0], '_blank');
      }

      await userSend({ name, lastname, email, phone, serviceType });

      if (filesUrlToDownload?.length > 1) {
        setIsVisibleDownloadLinks(true);
      }

      setLoading(false);

      setName('');
      setLastname('');
      setEmail('');
      setPhone('');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        setErrors(errorMessages);
      }

      setLoading(false);
      return;
    }
  };

  return (
    <Content>
      <ContentLogin isVisible={!isVisibleDownloadLinks} hasBorder={hasBorder}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <InputComponent
              value={name}
              onChange={e => setName(e.currentTarget.value)}
              error={errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputComponent
              value={lastname}
              onChange={e => setLastname(e.currentTarget.value)}
              label="Sobrenome"
              placeholder="Digite o seu sobrenome"
              error={errors.lastname}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputComponent
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
              label="E-mail"
              placeholder="Digite o seu e-mail"
              error={errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputComponent
              value={phone}
              onChange={e => setPhone(e.currentTarget.value)}
              label="Celular"
              placeholder="Digite o seu número de celular"
              mask={
                phone.length <= 10
                  ? '+99 (99) 9999-9999?'
                  : '+99 (99) 99999-9999'
              }
              error={errors.phone}
            />
          </Grid>
        </Grid>
      </ContentLogin>
      <BoxBottom isVisible={!isVisibleDownloadLinks}>
        <ButtonComponent
          width="unset"
          heightDesktop="unset"
          heightMobile="unset"
          color={
            hasButtonFormColorPink
              ? '#FD528F'
              : hasButtonFormColorWhite
              ? '#FFF'
              : '#45A7DF'
          }
          background={
            hasButtonFormBackgroundPink
              ? '#FD528F'
              : hasButtonFormBackgroundBlue
              ? '#2c6ef2'
              : '#FFF'
          }
          marginTop="0"
          paddingDesktop="0.875rem 1.25rem"
          paddingMobile="1.25rem 1.75rem"
          label={
            filesUrlToDownload.length > 1 ? 'Enviar' : 'Fazer download gratuito'
          }
          onClick={handleSubmit}
          loading={loading}
          isButtonActive={true}
          hasHover={false}
          loaderSize={14}
        />
      </BoxBottom>
      <BoxDownloadButtons isVisible={isVisibleDownloadLinks}>
        {filesUrlToDownload.map((url, index, array) => (
          <ButtonComponent
            key={index}
            width="100%"
            heightDesktop="unset"
            heightMobile="unset"
            color="#45A7DF"
            background="#FFFFFF"
            marginTop="0"
            paddingDesktop="0.875rem 1.25rem"
            paddingMobile="1.25rem 1.75rem"
            label={`Baixar o ${array.length - index}º volume`}
            onClick={() => window.open(url, '_blank')}
            isButtonActive={true}
            hasHover={false}
          />
        ))}
      </BoxDownloadButtons>
    </Content>
  );
};

export default FormRecipeGuide;
