import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

export const Button = styled.button`
  width: 3.125rem;
  height: 3.125rem;
  border: 1px solid #c6c8cc;
  background: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 1.25rem 5.625rem 0;
`;

export const Img = styled.img``;

export const BoxAssistant = styled.div`
  display: ${props => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  position: fixed;
  width: 305px;
  height: 201px;
  background: #fff;
  border: 1px solid #c6c8cc;
  border-radius: 8px;
  z-index: 900;
  right: 1rem;
  bottom: 5.625rem;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h2 {
    font-size: 14px;
    margin: 0 0.5rem;
  }
`;

export const Text = styled(ReactMarkdown)`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #8f9194;
  margin-top: 1rem;
`;

export const DarkScreen = styled.div`
  display: ${props => (props.active ? 'flex' : 'none')};
  position: fixed;
  z-index: 800;
  width: 100vw;
  height: 100vh;
  background: #231f20;
  opacity: 0.5;
  left: 0px;
  top: 0px;
`;

export const WhatsappButton = styled.a`
  width: 273px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  background: #45a7df;
  border-radius: 100px;
  color: #fff;
  margin: 16px 0;

  em {
    font-style: normal;
    margin-left: 0.5rem;
  }
`;
