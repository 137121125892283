import React from 'react';
import SearchDoctor from 'components/SearchDoctor';

function DoctorsSearch({ location, noSearch }) {
  return (
    <SearchDoctor location={location} noSearch={noSearch} displayTags="none" />
  );
}

export { DoctorsSearch };
