import React from 'react';

import * as S from './style';

const Cards = ({ ...rest }) => {
  const markdown = rest.markdown.split('(end)');

  const title = markdown[0];
  const content = markdown[1].split('\n\n');

  const text = content[0];
  const img = content[1];

  return (
    <S.Container>
      <S.BoxContent>
      <S.Title escapeHtml={false} children={title} />
        <S.Content escapeHtml={false} children={text} />
        <S.Img escapeHtml={false} children={img} />
      </S.BoxContent>
    </S.Container>
  );
};

export default Cards;
