import React, { Fragment, useContext, useEffect } from 'react';
import { TmpFooterNavigation } from '@tempo/tempo-design-system-core';
import { FooterContext } from 'context/footer';
import { getCookie } from 'utils/getCookie';
import { Helmet } from 'react-helmet';

import { ContainerFooter, Content, LogoHSL } from './style';

export default function Footer(footer) {
  const { hasFooter } = useContext(FooterContext);
  const isWindow = typeof window !== 'undefined';

  const getDate = new Date();
  const getYear = getDate.getFullYear();

  useEffect(() => {
    if (isWindow) {
      //Faz a chamada do metodo que executa o modal do cookie
      getCookie();

      const allElementsLink = document.querySelectorAll('a');
      allElementsLink.forEach((item) => {
        if(item.innerHTML === 'Cookies'){
          item.classList.add('cmp-revoke-consent');
        } 
      })
    }
  }, []);

  return (
    <>
      <Helmet>
        {/* Faz a chamada ao CDN para aplicar o CSS ao modal do cookie */}
        {
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdn-prod.securiti.ai/consent/cookie-consent.css"
          />
        }
      </Helmet>
      <ContainerFooter show={hasFooter}>
        <Content>
          {typeof window !== 'undefined' && { TmpFooterNavigation } && (
            <TmpFooterNavigation
              logo={
                <LogoHSL
                  src={footer.imageLogo.url}
                  alt={footer.imageLogo.fileName}
                />
              }
              rewardsLabel={footer.badgeText}
              copyText={footer.copyrightText.replace('{ano}', `${getYear}`)}
              privacyLabel={footer.policyText.text}
              privacyLink={footer.policyText.link}
              areaContactPhones={footer.contactPhones}
              areaInfoLinks={footer.infoLinks}
              areaContactLinks={footer.contactLinks}
              footerLinks={footer.footerLinks}
              socialLinks={footer?.socialMedia?.map(item => {
                return {
                  handleClick: () => {
                    isWindow && window.open(item?.link, item?.target);
                  },
                  icon: <img src={item?.imageLink} alt={item?.imageLink} />,
                };
              })}
              rewardsBadges={footer.badges}
            />
          )}
        </Content>
      </ContainerFooter>
    </>
  );
}
