import styled from 'styled-components';
import { device } from 'components/device';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100vw;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 73.125rem;
  max-height: 46.875rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 2.5rem 0;

  @media ${device.mobile} {
    padding: 7.5rem 0 0 0 ;
  }
`;

export const ImageContainer = styled.div`
  text-align: center;
  padding: 10px;
`;

export const ImageContent = styled.div`
  width: 320px;

  @media ${device.mobile} {
    width: 250px;
  }
`;

export const Image = styled.img``;
