import React, { useEffect, useContext, useState } from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import GraphImg from 'graphcms-image';

import { TmpTypography, TmpLink } from '@tempo/tempo-design-system-core/dist';

import ArrowRight from 'images/icons/arrow-right.svg';
import { formatPhraseToSlug } from 'utils/phraseToSlug.js';
import { createDoctorData } from 'utils/createDoctorData.js';

import { TabsContext } from 'context/tabs';

import {
  Container,
  Header,
  HeaderTitle,
  HeaderLinkContainer,
  HeaderLink,
  Content,
  PostContainer,
  PostContainerLink,
  PostImageContainer,
  PostWithoutImageContainer,
  PostTitle,
  PostAuthor,
} from './listPostsStyle.js';

function ListPosts({
  title,
  headerLinkTitle,
  headerLink,
  headerLinkZIndex,
  postsToShow,
  postsOrderBy,
  posts,
  sendTo,
  doctorsCms,
  doctorsApi,
  pageContext,
  isDesignSystem,
}) {
  const { setTabs } = useContext(TabsContext);
  const doctorsData =
    doctorsCms
      ?.map(doctor => {
        return createDoctorData({
          doctorCMS: doctor,
          doctorsAPI: doctorsApi,
        });
      })
      ?.filter(e => e) ?? [];

  const [temporaryPosts, setTemporaryPosts] = useState([]);
  const isWindow = typeof window !== 'undefined';

  useEffect(() => {
    const orderedPostsNullDate = [...posts]
      .filter(post => post.date === null)
      .sort(
        (postA, postB) =>
          (new Date(postA['id']) < new Date(postB['id']) && 1) || -1
      );

    const orderedPosts = [...posts]
      .filter(post => post.date !== null)
      .sort(
        (postA, postB) =>
          (new Date(postA[postsOrderBy]) < new Date(postB[postsOrderBy]) &&
            1) ||
          -1
      );

    setTemporaryPosts(orderedPostsNullDate.concat(orderedPosts));

    if (postsToShow && typeof postsToShow === 'number') {
      setTemporaryPosts(
        orderedPostsNullDate.concat(orderedPosts).slice(0, postsToShow)
      );
    } else {
      setTemporaryPosts(
        orderedPostsNullDate
          .concat(orderedPosts)
          .slice(pageContext.skip, pageContext.skip + pageContext.limit)
      );
    }
  }, []);

  const formattedPosts = temporaryPosts.map(post => {
    const formattedAuthor =
      post.doctors?.length > 0
        ? post.doctors?.map(doctor => {
            const foundDoctor = doctorsData.find(
              doctorCMS => doctorCMS.id_cms === doctor.id
            );

            if (!foundDoctor)
              return {
                fullName: doctor.name,
                profileLink: '',
              };

            return {
              fullName: foundDoctor.nameWithAssignment,
              profileLink: foundDoctor.active
                ? `/encontre-seu-medico/${foundDoctor.slug}`
                : '',
            };
          })
        : post?.author?.length > 0
        ? post?.author?.map(authorName => ({
            fullName: authorName,
            profileLink: '',
          }))
        : [
            {
              fullName: 'Hospital Sírio-Libanês',
              profileLink: '',
            },
          ];

    const formattedCategorySlug = formatPhraseToSlug(post.topic, '');

    return { ...post, formattedCategorySlug, formattedAuthor };
  });

  const dynamicHeight = formattedPosts.reduce((accumulator, currentPost) => {
    const authorsName = currentPost.formattedAuthor
      .map(author => author.fullName)
      .join(' · ');
    const calcHeight = Math.ceil(authorsName.length / 2.38);
    if (calcHeight >= accumulator) {
      accumulator = calcHeight;
    }
    return accumulator;
  }, 0);
  const dynamicHeightInRem = `${dynamicHeight / 16}rem`;

  useEffect(() => {
    if (sendTo.category) {
      setTabs(sendTo.category);
    }
  }, [sendTo.category]);

  return (
    <Container>
      <Header isVisible={!!title}>
        {isDesignSystem ? (
          isWindow && { TmpTypography } && (
            <TmpTypography component="subtitle">{title}</TmpTypography>
          )
        ) : (
          <HeaderTitle>{title}</HeaderTitle>
        )}
        {headerLinkTitle && headerLink && (
          <HeaderLinkContainer headerLinkZIndex={headerLinkZIndex}>
            {isDesignSystem ? (
              isWindow && { TmpLink } && (
                <TmpLink href={headerLink} icon>
                  {headerLinkTitle}
                </TmpLink>
              )
            ) : (
              <HeaderLink to={headerLink}>
                {headerLinkTitle}{' '}
                <img src={ArrowRight} alt={headerLinkTitle} loading="lazy" />
              </HeaderLink>
            )}
          </HeaderLinkContainer>
        )}
      </Header>
      <Content>
        {formattedPosts.map(post => {
          const formattedLink =
            sendTo.url === 'blog'
              ? `/blog/${post.formattedCategorySlug}/${post.slugPost}`
              : `/${sendTo.url}/${post.slugPost}`;

          return (
            <PostContainer key={post.id}>
              <PostContainerLink to={formattedLink}>
                {post.assetpicker.length > 0 ? (
                  <PostImageContainer>
                    {post.assetpicker[0].handle ? (
                      <img
                        src={`${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/resize=w:370,h:246,fit:crop/quality=value:100/output=format:webp/compress/${post.assetpicker[0].handle}`}
                        alt={post.title}
                        loading="lazy"
                      />
                    ) : (
                      <GraphImg
                        image={post.assetpicker[0]}
                        maxWidth={370}
                        alt={post.title}
                        fit="crop"
                        withWebp={true}
                        loading="lazy"
                      />
                    )}
                  </PostImageContainer>
                ) : (
                  <PostWithoutImageContainer>
                    {isDesignSystem ? (
                      isWindow && { TmpTypography } && (
                        <TmpTypography component="subtitle">
                          {post.title}
                        </TmpTypography>
                      )
                    ) : (
                      <p>{post.title}</p>
                    )}
                  </PostWithoutImageContainer>
                )}
                {isDesignSystem ? (
                  isWindow && { TmpTypography } && (
                    <TmpTypography component="subtitle">
                      {post.title}
                    </TmpTypography>
                  )
                ) : (
                  <PostTitle>{post.title}</PostTitle>
                )}
              </PostContainerLink>
              {isDesignSystem ? (
                <PostAuthor
                  minHeight={dynamicHeightInRem}
                  isDesignSystem={isDesignSystem}
                >
                  <span>
                    {isWindow && { TmpTypography } && (
                      <TmpTypography component="caption">por</TmpTypography>
                    )}
                  </span>
                  <p>
                    {isWindow && { TmpTypography } && (
                      <TmpTypography component="caption">
                        {post.formattedAuthor.map((author, index) =>
                          author.profileLink ? (
                            <span key={index}>
                              <Link to={author.profileLink}>
                                <TmpLink>{author.fullName}</TmpLink>
                              </Link>
                            </span>
                          ) : (
                            <span key={index}>{author.fullName}</span>
                          )
                        )}
                      </TmpTypography>
                    )}
                  </p>
                </PostAuthor>
              ) : (
                <PostAuthor minHeight={dynamicHeightInRem}>
                  <span>por</span>
                  <p>
                    {post.formattedAuthor.map((author, index) =>
                      author.profileLink ? (
                        <span key={index}>
                          <Link to={author.profileLink}>{author.fullName}</Link>
                        </span>
                      ) : (
                        <span key={index}>{author.fullName}</span>
                      )
                    )}
                  </p>
                </PostAuthor>
              )}
            </PostContainer>
          );
        })}
      </Content>
    </Container>
  );
}

ListPosts.defaultProps = {
  title: 'Blog do Sírio-Libanês',
  headerLinkTitle: 'Ver blog',
  headerLink: '/blog/',
  headerLinkZIndex: 'unset',
  postsOrderBy: 'date',
  doctorsData: [],
  sendTo: { url: 'blog', category: '' },
};

ListPosts.propTypes = {
  title: PropTypes.string,
  headerLinkTitle: PropTypes.string,
  headerLink: PropTypes.string,
  headerLinkZIndex: PropTypes.string,
  postsToShow: PropTypes.number,
  postsOrderBy: PropTypes.string,
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
  doctorsData: PropTypes.arrayOf(PropTypes.object),
  sendTo: PropTypes.object,
};

const PageComponent = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          doctorsApi {
            doctors {
              id
              name
              gender
              crm
              email
              phone
              phonecommercial
              specialty
              employee
              estab
              state
            }
          }
          gcms {
            doctors(first: 1000, locales: [pt_BR, en]) {
              id
              id_api
              active
              assignment
              name
            }
          }
        }
      `}
      render={response => {
        return (
          <ListPosts
            doctorsApi={response?.doctorsApi?.doctors}
            doctorsCms={response?.gcms?.doctors}
            {...props}
          />
        );
      }}
    />
  );
};
export default PageComponent;
