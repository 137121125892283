import React, { useEffect, useState } from 'react';
import {
  Container,
  Content,
  Box,
  ContainerText,
  Title,
  Description,
  ImgContainer,
  ImgLeft,
  ImgRight,
  ImgCenter,
  Button,
} from './style';
import { TmpButton } from '@tempo/tempo-design-system-core/dist';

function AppDownloadBanner({
  title,
  description,
  imgLeft,
  linkImgLeft,
  imgRight,
  linkImgRight,
  imgCenter,
  textButton,
  linkTextButton,
}) {
  const [isMobile, setIsMobile] = useState(false);
  const isWindow = typeof window !== 'undefined';

  const updateWindowSize = () => {
    setIsMobile(window.innerWidth < 641);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowSize);
      return () => window.removeEventListener('resize', updateWindowSize);
    }
  }, []);

  return (
    <Container>
      <Content>
        <Box>
          <ContainerText>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <ImgContainer>
              <a href={linkImgLeft}>
                <ImgLeft src={imgLeft.url} alt=""></ImgLeft>
              </a>
              <a href={linkImgRight}>
                <ImgRight src={imgRight.url} alt=""></ImgRight>
              </a>
            </ImgContainer>
          </ContainerText>
          <ImgCenter src={imgCenter.url} alt="" />
          <Button>
            {isWindow && { TmpButton } && (
              <a href={linkTextButton}>
                <TmpButton type="primary" size="sm">
                  {textButton}
                </TmpButton>
              </a>
            )}
          </Button>
        </Box>
      </Content>
    </Container>
  );
}

export { AppDownloadBanner };
