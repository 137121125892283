import React, { useEffect, useState, useRef } from 'react';
import Carousel from 'react-elastic-carousel';

import { formatClassName } from 'utils/formatClassName';

import CustomFragments from 'components/CmsComponents/CarouselGalleryContent/CustomFragments/Cards';

import {
  Container,
  Content,
  Box,
  WrapTitle,
  Title,
  WrapCarouselMobile,
  CarouselMobileItem,
  WrapCarousel,
  BallSlider,
  Balls,
  CarouselItem,
} from './style';

function CarouselGalleryContent({
  title,
  type,
  backgroundColor,
  images,
  fragments = [],
}) {
  const [activeSlider, setActiveSlider] = useState(false);
  const contentRef = useRef(null);
  const [numberOfItemsShow, setNumberOfItemsShow] = useState(2);

  const contentSlider = contentRef;
  let isMouseDown = false;
  let listenerPositionX;
  let elementScrollLeft;

  useEffect(() => {
    contentSlider.current.addEventListener('mousedown', listener => {
      isMouseDown = true;
      listenerPositionX = listener.pageX - contentSlider.current.offsetLeft;
      elementScrollLeft = contentSlider.current.scrollLeft;
    });
    contentSlider.current.addEventListener('mouseleave', () => {
      isMouseDown = false;
      setActiveSlider(false);
    });
    contentSlider.current.addEventListener('mouseup', () => {
      isMouseDown = false;
      setActiveSlider(false);
    });
    contentSlider.current.addEventListener('mousemove', listener => {
      if (!isMouseDown) return;
      listener.preventDefault();
      setActiveSlider(true);
      const positionX = listener.pageX - contentSlider.current.offsetLeft;
      const distance = (positionX - listenerPositionX) * 2; //change multiply value to change scroll-speed
      contentSlider.current.scrollLeft = elementScrollLeft - distance;
    });

    if (fragments?.length > 0) {
      setNumberOfItemsShow(4);
    }
  }, []);

  const carouselBreakPoints = [
    {
      width: 1,
      itemsToShow: 1,
      itemsToScroll: 1,
      showArrows: true,
      pagination: false,
    },
    {
      width: 1024,
      itemsToShow: numberOfItemsShow,
      itemsToScroll: 1,
      showArrows: true,
      pagination: false,
    },
  ];

  return (
    <Container
      bgColor={backgroundColor?.hex ?? 'transparent'}
      className={formatClassName(type)}
    >
      <Content className={formatClassName(type)}>
        <Box>
          <WrapTitle>
            <Title>{title}</Title>
          </WrapTitle>
          <WrapCarouselMobile ref={contentRef} activeSlider={activeSlider}>
            {fragments.length > 0
              ? fragments.map(item => (
                  <CarouselItem key={item.id}>
                    <CustomFragments
                      {...item}
                      className={formatClassName(type)}
                    />
                  </CarouselItem>
                ))
              : images?.map(image => (
                  <CarouselMobileItem key={image.handle}>
                    <img src={image.url} alt="carousel" />
                  </CarouselMobileItem>
                ))}
          </WrapCarouselMobile>
          <WrapCarousel fragments={fragments.length > 0}>
            <Carousel
              breakPoints={carouselBreakPoints}
              renderPagination={({pages, activePage, onClick }) => {
                return (
                  <BallSlider whoWeAre={true}>
                    {pages.map((page, index) => {
                      const isActivePage = activePage === page;
                      return (
                        <Balls
                          key={index}
                          onClick={() => onClick(page)}
                          active={isActivePage}
                        />
                      );
                    })}
                  </BallSlider>
                );
              }}
            >
              {fragments.length > 0
                ? fragments.map(item => (
                    <CarouselItem key={item.id}>
                      <CustomFragments
                        {...item}
                        className={formatClassName(type)}
                      />
                    </CarouselItem>
                  ))
                : images.map(image => (
                    <CarouselItem key={image.handle}>
                      <img src={image.url} alt="carousel" />
                    </CarouselItem>
                  ))}
            </Carousel>
          </WrapCarousel>
        </Box>
      </Content>
    </Container>
  );
}

export { CarouselGalleryContent };
