// Libs
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { device } from 'components/device';
import * as Yup from 'yup';

// Components
import InputComponent, { TitleInput, BoxInput } from 'components/Input';
import ButtonComponent from 'components/SimpleButton';
import { Grid, MenuItem, Select } from '@material-ui/core';
import Loader from 'components/loader';

// Services
import { userSendVioleta } from 'components/services/salesForceAPI.js';

const ContentLogin = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  width: 100%;
  border-radius: 0.5rem;
  background: #ffffff;
  margin-bottom: 3.125rem;

  .MuiInput-underline:after {
    border-bottom: 2px solid #45a7df !important;
  }

  .MuiSelect-select:focus {
    background: white !important;
  }

  .MuiSelect-root {
    color: #8f9194;
    font-size: 11px;
  }

  .MuiSelect-select.MuiSelect-select {
    padding-bottom: 3px;
  }

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }
`;

const BoxBottom = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  padding-left: 80%;
  @media ${device.laptop} {
    padding-left: 0%;
  }
`;

export const Container = styled.section`
  width: 100%;

  @media ${device.laptop} {
    padding: 0 1.25rem;
  }

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.mb70 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 3.125rem;
    }
  }

  &.mb70x20 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 4.375rem 0;

  &.borderBottom {
    border-bottom: 1px solid #c6c8cc;
  }

  @media ${device.laptop} {
    padding: 1.25rem 0;
  }
`;

const FormPremioVioleta = () => {
  const [name, setName] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [pacientName, setPacientName] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [pacientArea, setPacientArea] = useState('');
  const [pacientStory, setPacientStory] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formState, setFormState] = useState('');
  const [formSend, setFormSend] = useState(false);
  const [loadComponent, setLoadComponent] = useState(true);

  const phoneRegExp = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;
  let schema = Yup.object().shape({
    formState: Yup.string(),
    name: Yup.string()
      .required('Nome obrigatório')
      .min(2, 'Nome obrigatório')
      .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, 'Nome Completo obrigatório'),
    appointmentDate: Yup.string()
      .required('Data do Atendimento obrigatória')
      .min(8, 'Data do Atendimento obrigatória'),
    email: Yup.string()
      .email('Digite um e-mail valido')
      .required('E-mail obrigatório'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Digite um telefone valido')
      .min(12, 'Digite um telefone valido'),
    pacientName: Yup.string().when('formState', {
      is: formState => formState === 'Paciente' || formState === 'Familiar',
      then: Yup.string()
        .required('Nome obrigatório')
        .min(2, 'Nome obrigatório'),
    }),
    pacientStory: Yup.string().required(
      'Experiência vivenciada com este profissional obrigatória'
    ),
    doctorName: Yup.string().required(
      'Nome completo do profissional de excelência no cuidado obrigatória'
    ),
  });

  const handleSubmit = async () => {
    setLoading(true);
    setErrors({});
    try {
      await schema.validate(
        {
          name,
          email,
          phone,
          appointmentDate,
          pacientName,
          haveLastName: !!name.split(' ')?.[1],
          formState: formState,
          pacientStory,
          doctorName,
        },
        {
          abortEarly: false,
        }
      );
      await userSendVioleta({
        name,
        appointmentDate,
        phone,
        pacientName,
        pacientArea,
        pacientStory,
        formState,
        email,
        doctorName,
      });

      setLoading(false);

      setName('');
      setEmail('');
      setPhone('');
      setPacientName('');
      setPacientArea('');
      setDoctorName('');
      setAppointmentDate('');
      setFormSend(true);
      setPacientStory('');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });
        setErrors(errorMessages);
      }

      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    setLoadComponent(false);
  }, []);

  return (
    <>
      {loadComponent ? (
        <Loader />
      ) : (
        <Container>
          <Content>
            {formSend ? (
              <p
                style={{ textAlign: 'center', marginTop: 30, marginBottom: 30 }}
              >
                Obrigada pela sua participação!
              </p>
            ) : (
              <>
                <ContentLogin isVisible={true}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <BoxInput>
                        <TitleInput id="demo-simple-select-label">
                          Nos conte a sua história abaixo, selecionando quem
                          você é:
                        </TitleInput>
                        <Select
                          displayEmpty
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formState}
                          onChange={event => setFormState(event.target.value)}
                        >
                          <MenuItem disabled value="">
                            Selecione
                          </MenuItem>
                          <MenuItem value={'Paciente'}>Paciente</MenuItem>
                          <MenuItem value={'Familiar'}>Familiar</MenuItem>
                          <MenuItem value={'Corpo Clínico'}>
                            Corpo Clínico
                          </MenuItem>
                          <MenuItem value={'Colaboradores'}>
                            Colaboradores
                          </MenuItem>
                        </Select>
                      </BoxInput>
                    </Grid>
                    {formState !== '' && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <InputComponent
                            value={name}
                            onChange={e => setName(e.currentTarget.value)}
                            error={errors.name}
                            label="Nome Completo"
                            maxLength={50}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputComponent
                            value={email}
                            onChange={e => setEmail(e.currentTarget.value)}
                            label="E-mail"
                            placeholder="Digite o seu e-mail"
                            error={errors.email}
                            maxLength={50}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <InputComponent
                            value={phone}
                            onChange={e => setPhone(e.currentTarget.value)}
                            label="Celular"
                            placeholder="Digite o seu número de celular"
                            mask={
                              phone.length <= 10
                                ? '+99 (99) 9999-9999?'
                                : '+99 (99) 99999-9999'
                            }
                            error={errors.phone}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <InputComponent
                            value={appointmentDate}
                            onChange={e =>
                              setAppointmentDate(e.currentTarget.value)
                            }
                            label="Data do Atendimento"
                            placeholder="Data do Atendimento"
                            error={errors.appointmentDate}
                            mask={'99/99/9999'}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <InputComponent
                            value={pacientName}
                            onChange={e =>
                              setPacientName(e.currentTarget.value)
                            }
                            label="Por gentileza, informe o nome completo do(a) paciente"
                            placeholder="Por gentileza, informe o nome completo do(a) paciente"
                            error={errors.pacientName}
                            maxLength={50}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <InputComponent
                            value={doctorName}
                            onChange={e => setDoctorName(e.currentTarget.value)}
                            label="Por favor, indique o nome completo do profissional de excelência no cuidado"
                            placeholder="Por favor, indique o nome completo do profissional de excelência no cuidado"
                            error={errors.doctorName}
                            maxLength={50}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <InputComponent
                            value={pacientArea}
                            onChange={e =>
                              setPacientArea(e.currentTarget.value)
                            }
                            label="Por favor, indique a área a qual o profissional pertence"
                            placeholder="Por favor, indique a área a qual o profissional pertence"
                            error={errors.pacientArea}
                            maxLength={50}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <InputComponent
                            value={pacientStory}
                            onChange={e =>
                              setPacientStory(e.currentTarget.value)
                            }
                            label="Relate a história ou experiência vivenciada com este profissional"
                            placeholder="Relate a história ou experiência vivenciada com este profissional"
                            error={errors.pacientStory}
                            maxLength={5000}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </ContentLogin>
                {formState !== '' && (
                  <BoxBottom isVisible={true}>
                    <ButtonComponent
                      width="unset"
                      heightDesktop="unset"
                      heightMobile="unset"
                      // color="#45A7DF"
                      // background="#FFFFFF"
                      marginTop="0"
                      paddingDesktop="0.875rem 1.25rem"
                      paddingMobile="1.25rem 1.75rem"
                      label={'Enviar'}
                      onClick={handleSubmit}
                      loading={loading}
                      isButtonActive={true}
                      hasHover={false}
                      loaderSize={14}
                    />
                  </BoxBottom>
                )}
              </>
            )}
          </Content>
        </Container>
      )}
    </>
  );
};

export { FormPremioVioleta };
