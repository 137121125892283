export const normalizeValue = value =>
  typeof value === 'string' ? value.toLocaleLowerCase() : value;

export const filterDoctors = (array, filters) => {
  const filterKeys = Object.keys(filters);
  return array?.filter(item => {
    return filterKeys?.every(key => {
      if (!filters[key]?.length) {
        return true;
      }
      if (Array.isArray(item.infos[key])) {
        return item.infos[key].some(item => {
          return filters[key].includes(item);
        });
      }
      if (Array.isArray(filters[key])) {
        return filters[key].find(
          filter => normalizeValue(filter) === normalizeValue(item.infos[key])
        );
      }
      return normalizeValue(item.infos[key]) === normalizeValue(filters[key]);
    });
  });
};

export const unique = array => {
  return array.filter((element, index) => {
    return array.indexOf(element) === index;
  });
};

export const normalizeSelects = array => ({
  specialtiesId: unique(
    array
      .map(item => item.infos.specialtiesId)
      .filter(item => item !== null && item !== undefined)
      .sort((a, b) => {
        return a - b;
      })
      .flat(Infinity)
  ),
  practicesId: unique(array.map(item => item.infos.practicesId))
    .filter(item => item !== null && item !== undefined)
    .sort((a, b) => {
      return a - b;
    })
    .flat(Infinity),
  id: unique(array.map(item => item.infos.id))
    .filter(item => item !== null && item !== undefined)
    .sort((a, b) => {
      return a - b;
    }),
  crm: unique(array.map(item => item.infos.crm))
    .filter(item => item !== null && item !== undefined)
    .sort((a, b) => {
      return a - b;
    }),
});
