import React from 'react';

import {
  TmpTypography,
  TmpShape,
  TmpLink,
} from '@tempo/tempo-design-system-core/dist';

import * as S from './style';
import { Link } from 'gatsby';

const ContactCardDS = ({ ...rest }) => {
  const isWindow = typeof window !== 'undefined';
  const LinkRender = ({ href, children }) => {
    const isInternalLink = href.startsWith('/');

    if (isInternalLink) {
      return (
        <Link to={href}>
          {isWindow && { TmpLink } && <TmpLink>{children}</TmpLink>}
        </Link>
      );
    }

    return (
      isWindow && { TmpLink } && (
        <TmpLink href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </TmpLink>
      )
    );
  };

  const EmphasisDS = ({ children }) => {
    return (
      isWindow && { TmpTypography } && (
        <TmpTypography component="paragraph">{children}</TmpTypography>
      )
    );
  };

  const HeadingDS = ({ children }) => {
    return (
      <S.Title>
        {isWindow && { TmpTypography } && (
          <TmpTypography component="heading" variant="h4" size="small">
            {children}
          </TmpTypography>
        )}
      </S.Title>
    );
  };
  return (
    <S.Container>
      {isWindow && { TmpShape } && (
        <TmpShape>
          <S.Content
            children={rest.children}
            renderers={{
              link: LinkRender,
              emphasis: EmphasisDS,
              heading: HeadingDS,
            }}
          />
        </TmpShape>
      )}
    </S.Container>
  );
};

export default ContactCardDS;
