import React from 'react';
import styled from 'styled-components';

import loaderImage from 'images/icons/loading.png';
import { device } from 'components/device';

const BoxLoading = styled.div`
  width: 100%;
  display: ${props => (props.isOncologia ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  margin: ${props => props.margin || '2rem 0 4rem 0'};

  @media ${device.laptop} {
    margin: ${props => props.marginMobile || '0 1.25rem'};
    height: ${props =>
      props.heightMobile || (props.list !== undefined ? '100vh' : '30vh')};
    z-index: 99;
    top: ${props => (props.list !== undefined ? 0 : '50vh')};
    left: 0;
    position: ${props => props.list !== undefined && 'fixed'};
    background-color: #fff;
    opacity: 0.7;
  }

  @media ${device.mobile} {
    margin: 0;
  }
`;

const Loading = styled.img`
  width: ${props => props.imgSize || '1.875rem'};
  height: ${props => props.imgSize || '1.875rem'};
  animation: is-rotating 1s infinite;

  @keyframes is-rotating {
    to {
      transform: rotate(1turn);
    }
  }
`;

const Loader = props => {
  return (
    <BoxLoading
      isSpecialty={props.isSpecialty}
      margin={props.margin}
      marginMobile={props.marginMobile}
      heightMobile={props.heightMobile}
    >
      <Loading src={loaderImage} alt="loader" isSpecialty={props.isSpecialty} imgSize={props.imgSize} />
    </BoxLoading>
  );
};

export default Loader;
