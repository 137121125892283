import styled, { css } from 'styled-components';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};

  @media ${device.laptop} {
    padding: 0 1.25rem;

    &.noPaddingFeatured {
      padding: 0;
    }
  }

  &.mb20 {
    padding-bottom: 1.25rem;
  }

  &.mb30 {
    padding-bottom: 1.875rem;

    @media ${device.laptop} {
      padding-bottom: 1.25rem;
    }
  }

  &.mb50 {
    padding-bottom: 3.125rem;

    @media ${device.laptop} {
      padding-bottom: 1.875rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  min-height: ${({ bgHeight }) => (bgHeight ? bgHeight + 'px' : 'auto')};
  margin: 0 auto;

  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'unset')};
  background-repeat: no-repeat;
  background-position: right top;

  ${({ hasBorderBottom }) =>
    hasBorderBottom &&
    css`
      border-bottom: 1px solid #c6c8cc;
    `}

  @media ${device.laptop} {
    max-width: unset;
  }

  &.maxHeight480 {
    max-height: 30rem;

    @media ${device.laptop} {
      max-height: unset;
    }
  }
`;

export const WrapBreadcrumb = styled.div`
  width: 100%;
  position: absolute;
  top: 1.875rem;
  z-index: 2;

  @media ${device.laptop} {
    display: none;
  }
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4rem;

  @media ${device.laptop} {
    align-items: center;
    gap: 0rem;
    height: auto;
    min-height: unset;
    flex-direction: column;
    align-items: center;
  }

  @media ${device.mobile} {
    align-items: unset;
  }

  &.customizationOfEmergencyService {
    min-height: auto;
  }

  &.mt48 {
    margin-top: 5.69rem;
  }

  &.MinHeightUnset {
    min-height: unset !important;
  }

  &.TitleMobileUnits {
    @media ${device.mobile} {
      flex-direction: column-reverse;
    }
  }
`;

export const Information = styled.div`
  width: 100%;
  margin: 6rem 0 1.875rem;
  max-width: 25rem;
  @media ${device.laptop} {
    max-width: unset;
    margin: 0 0 1.25rem;
    align-items: center;
  }

  &.defaultLayoutMedicalSpecialties {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.defaultLayoutMedicalSpecialtiesType2 {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  &.customizationOfEmergencyService {
    margin-top: 5.0625rem;

    @media ${device.laptop} {
      margin-top: unset;
    }

    @media ${device.mobile} {
      margin-top: 1.875rem;
    }
  }

  &.leftSideWidth476px {
    width: 100%;
    max-width: 29.75rem;

    @media ${device.laptop} {
      max-width: unset;
    }
  }

  &.maxWidthInfoUnset {
    max-width: unset;
  }
`;

export const WrapImage = styled.div`
  position: relative;
  width: 100%;
  max-width: 35.625rem;
  height: auto;
  min-height: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &.defaultLayoutMedicalSpecialties {
    margin-top: 8rem;
    margin-bottom: 8rem;

    @media ${device.laptop} {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &.defaultLayoutMedicalSpecialtiesType2 {
    margin-top: 6rem;
    margin-bottom: 6rem;

    @media ${device.laptop} {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &.top20 {
    @media ${device.mobile} {
      top: 1.25rem;
    }
    @media ${device.mobile320} {
      top: 2.5rem;
    }
  }

  &.top20 {
    @media ${device.mobile} {
      top: 1.25rem;
    }
    @media ${device.mobile320} {
      top: 2.5rem;
    }
  }

  &.WrapImgTop {
    @media ${device.mobile} {
      top: -5rem;
    }

    @media ${device.mobile320} {
      top: -3rem;
    }
  }

  &.imgMobileMaxWidh160 {
    @media ${device.mobile} {
      max-width: 10rem;
    }

    @media ${device.mobile320} {
      max-width: 8rem;
    }
  }

  &.TitleMobileUnits {
    @media ${device.mobile} {
      position: unset;
      top: 0;
      right: 0;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth / 16 + 'rem' : 'unset')};
  height: auto;

  object-fit: cover;
  object-position: center;

  &.FeaturedMarketingModelo11 {
    width: 600px;
    height: 443px;
    object-position: 0% -2%;

    @media ${device.mobile} {
      width: 150px;
      height: 160px !important;
      object-fit: cover;
      object-position: -170% 0%;
    }
  }

  &.TitleMobileUnits {
    @media ${device.mobile} {
      position: unset !important;
      max-width: unset !important;
      width: 100%;
      height: auto !important;
    }
  }
`;

export const ButtonsContent = styled.div`
  display: flex;
  flex-direction: 'row';
  gap: 16px;
  width: 100%;

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-bottom: 32px;
    align-items: center;

    div:nth-child(1) {
      margin-top: 0;
    }

    div:nth-child(2) {
      margin-top: 24px;
    }

    div {
      width: 100%;
    }

    a {
      width: 100%;
    }
    button {
      width: 100%;
    }
  }

  @media ${device.laptop} {
    justify-content: center;
  }
`;
