// Libs
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';

import Carousel, { consts } from 'react-elastic-carousel';

// Components
import { device } from '../../../device';

// Images
import arrowRight from 'images/icons/arrow-right.svg';
import arrowLeft from 'images/icons/arrowLeft.svg';

// Styles
const Container = styled.section`
  grid-area: SVC;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${props => props.isOncologia && '4.375rem 0'};

  @media ${device.desktop4K} {
    width: ${props => (props.isCDIUnits ? '100%' : '93%')};
  }

  @media ${device.laptop} {
    padding: ${props => props.isOncologia && '0'};
  }

  @media ${device.tablet} {
    width: 100%;
    align-items: flex-start;
    margin-left: unset;
  }

  a {
    width: 100%;
  }
`;

const ContainerTitle = styled.div`
  padding-left: ${props => props.isCDIUnits && '1rem'};
  width: ${props => (props.isHome ? '97%' : props.isCDIUnits ? '100%' : '95%')};
  margin-bottom: 3.125rem;
  text-align: left;
  font-size: 1.5rem;

  ${props =>
    props.isCDIUnits &&
    `
      display: flex;
      justify-content: space-between;
    `}

  @media ${device.desktop4K} {
    width: ${props => (props.isHome ? '97%' : !props.isCDIUnits && '93%')};
  }

  @media ${device.tablet} {
    margin: 1.875rem 0;
    padding-left: ${props => props.isCDIUnits && 0};
    font-size: 1.125rem;
  }
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 0;
  margin-left: -1rem;
  color: #231f20;

  @media ${device.laptop} {
    margin-top: 2rem;
  }

  @media ${device.tablet} {
    margin-left: 0;
    margin-top: 0;
    font-size: 1.125rem;
  }
`;

const ServicesContainer = styled.div`
  width: ${props =>
    props.isHome
      ? '97%'
      : props.isProntoAtendimento
      ? '100%'
      : props.isCDIUnits
      ? '-webkit-fill-available'
      : '95%'};
  display: ${props => (props.isHome || props.isCDIUnits ? 'flex' : 'grid')};
  grid-template-columns: ${props =>
    !props.isHome || (!props.isCDIUnits && 'repeat(2, 1fr)')};
  margin-left: ${props =>
    props.isCDIUnits ? '-1rem' : props.isProntoAtendimento ? '0' : '-2rem'};

  @media ${device.laptop} {
    width: ${props => (props.scrolled ? '100vw' : 'calc(100% + 3rem)')};
    grid-auto-flow: column;
    grid-template-columns: ${props =>
      !props.isProntoAtendimento && 'repeat(2, 1fr)'};
    align-items: center;
    overflow-x: scroll;
    margin-left: 0;

    ::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }

  @media ${device.tablet} {
    margin-left: -20px;
  }

  @media ${device.mobile375} {
    width: ${props => props.isProntoAtendimento && '100%'};
    margin-left: ${props => props.isProntoAtendimento && 'initial'};
  }
`;

const ServicesContent = styled.div`
  width: ${props => props.isCDIUnits && '100%'};

  @media ${device.laptop} {
    display: ${props => props.isCDIUnits && 'flex'};
    margin-left: ${props => props.isCDIUnits && '1.25rem'};
    width: ${props => props.isCDIUnits && 'auto'};
  }

  ul {
    display: ${props =>
      props.isHome ? 'flex' : props.isCDIUnits ? 'flex' : 'grid'};
    grid-template-columns: ${props => !props.isHome && 'repeat(2, 1fr)'};
    margin: 0;

    @media ${device.laptop} {
      margin-left: 1.4rem;
      margin-right: 0.6rem;
      width: ${props => props.isProntoAtendimento && 'auto'};
      display: ${props => props.isProntoAtendimento && 'block'};
      grid-auto-flow: column;
      grid-template-columns: ${props =>
        !props.isProntoAtendimento && 'repeat(2, 1fr)'};
    }

    @media ${device.mobile375} {
      margin: ${props => props.isProntoAtendimento && '0'};
    }
  }

  li,
  .rec-item-wrapper {
    padding: ${props =>
      props.isProntoAtendimento
        ? '1.25rem !important'
        : props.paddingCardDesk
        ? `${props.paddingCardDesk} !important`
        : '1.4rem !important'};
    margin-right: ${props => props.marginRightCard || '1.875rem'};
    margin-bottom: ${props =>
      props.isProntoAtendimento ? '12rem' : '1.875rem'};
    width: ${props =>
      props.isProntoAtendimento
        ? '35.625rem'
        : props.isDiagnostico
        ? 'auto'
        : '16.875rem !important'};
    height: ${props =>
      props.isProntoAtendimento
        ? '12.125rem'
        : props.isDiagnostico || props.cardHeightAuto
        ? 'auto'
        : props.isCentroOncologico
        ? '18.9375rem !important'
        : '17.125rem'};
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    border: ${props =>
      props.isProntoAtendimento
        ? '0.063rem solid #c6c8cc'
        : props.isDiagnostico || props.hasNoBorder
        ? 'none'
        : '0.063rem solid #c6c8cc'};
    border-radius: 0.5rem;
    cursor: initial;
    border-top: ${props => props.borderTopCard && '1px solid #231F20'};
    border-radius: ${props => !props.hasNoBorderRadius && '0.5rem'};
    justify-content: ${props => props.isProntoAtendimento && 'space-between'};

    > h2 {
      margin-bottom: 0;
      height: ${props => props.isAdjustmentInCssLayout && '5.4375rem'};
    }

    p:nth-child(2) {
      margin-top: 20px;
      margin-bottom: 27px;
    }

    p:nth-child(3) {
      margin-bottom: ${props => props.isAdjustmentInCssLayout && '-1.875rem'};
    }

    ${props =>
      !props.hasNoHover &&
      `
      :hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
      }
    `}

    li {
      border: ${props =>
        props.isDiagnostico ? '0.063rem solid #c6c8cc' : 'none'};
    }

    :nth-child(2) {
      @media ${device.laptop} {
        padding: ${props =>
          props.pageBelaVista
            ? '1.4rem 1.188rem 1.4rem 1.4rem'
            : props.pageItaim
            ? '1.4rem 1.188rem 1.4rem 1.4rem'
            : props.pageBrasilia && '1.4rem 1.063rem 1.4rem 1.4rem'};
      }

      @media ${device.tablet} {
        padding: ${props =>
          props.pageBelaVista
            ? '1.4rem'
            : props.pageItaim
            ? '1.4rem'
            : props.pageBrasilia && '1.4rem'};
      }
    }

    :nth-child(3) {
      @media ${device.laptop} {
        padding: ${props =>
          props.pageBrasilia && '1.4rem 1.188rem 1.4rem 1.4rem'};
      }

      @media ${device.tablet} {
        padding: ${props => props.pageBrasilia && '1.4rem'};
      }
    }

    @media ${device.laptop} {
      position: ${props => props.isProntoAtendimento && 'relative'};
      width: ${props => (props.isProntoAtendimento ? 'auto' : '16.875rem')};
      height: ${props =>
        props.isProntoAtendimento
          ? '10.25rem'
          : props.cardHeightLap
          ? props.cardHeightLap
          : '16.25rem'};
      margin-right: 0.625rem;
      margin-bottom: 1.875rem;
    }

    @media ${device.mobile375} {
      margin-right: ${props => props.isProntoAtendimento && '0'};
      height: ${props =>
        props.isProntoAtendimento
          ? 'auto'
          : props.cardHeightMob
          ? props.cardHeightMob
          : '16.25rem'};
    }

    :nth-child(5n + 0) {
      h2 {
        width: 85%;

        @media ${device.laptop} {
          width: ${props => (props.titleDeskWith ? '100%' : '85%')};
        }
        @media ${device.tablet} {
          width: ${props => (props.pageBelaVista ? '45%' : '90%')};
        }
      }
    }

    ${props =>
      props.pageBrasilia &&
      `
      :nth-child(2n+0) {
        h2 {
          width: 85%;

          @media ${device.laptop} {
            width: 55%;
          }
        }
      }
    `}
  }

  h2,
  h3 {
    max-width: ${props => (props.titleDeskWith ? '100%' : '93%')};
    width: ${props =>
      props.short ? '83%' : props.titleDeskWith ? props.titleDeskWith : '87%'};
    margin-bottom: ${props => (props.isProntoAtendimento ? '0' : '1.25rem;')};
    font-size: 1.5rem;
    font-weight: 600;
    color: #231f20;
    padding-right: ${props => (props.isDiagnostico ? '7rem' : '0')};

    > a {
      color: #231f20;

      :last-child {
        display: ${props => (props.isDiagnostico ? 'none' : 'flex')};
      }
    }

    @media ${device.laptop} {
      width: 92%;
      max-width: 100%;

      > a {
        :last-child {
          position: ${props => props.isDiagnostico && 'absolute'};
          top: ${props => props.isDiagnostico && '1.25rem'};
          right: ${props => props.isDiagnostico && '1.25rem'};
          display: ${props => props.isDiagnostico && 'flex'};
          width: ${props => props.isDiagnostico && 'auto'};
          padding: ${props => props.isDiagnostico && '0.625rem'};
          border: ${props => props.isDiagnostico && '1px solid #C6C8CC'};
          border-radius: ${props => props.isDiagnostico && '50%'};
        }
      }
    }

    @media ${device.tablet} {
      width: ${props => (props.short ? '60%' : '66%')};
      font-size: 1.125rem;
      line-height: 1.375rem;
      height: ${props => props.isAdjustmentInCssLayoutMobile && '3.4375rem'};
    }

    @media ${device.mobile} {
      width: ${props =>
        props.short ? '62%' : props.isOncologia ? '65%' : '75%'};
    }
  }

  ${'' /* Card */}
  p {
    width: 99%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: end;

    margin: ${props => props.isProntoAtendimento && '0'};

    font-size: 0.875rem;
    line-height: ${props => (props.isProntoAtendimento ? '0.8rem' : '1.5rem')};
    color: #8f9194;
    height: ${props => (props.heightP ? props.heightP : null)};

    @media ${device.laptop} {
      width: 100%;
    }

    > a {
      color: #8f9194;
    }

    > img {
      margin-top: 1.25rem;
      margin-bottom: 0.5rem;
      border: none;
      background: transparent;
    }
  }
  @media ${device.laptop} {
    p:nth-child(3) {
      margin-bottom: ${props =>
        props.isAdjustmentInCssLayoutMobile && '-1.4375rem'};
    }
  }

  .rec-swipable {
    margin-left: 1rem;
  }

  .rec-slider-container {
    width: 100vw;
    margin: ${props => (props.isCarousel ? '0 -3rem 0 -37px' : '0 -1rem 0 0')};
  }

  .rec-pagination {
    display: none;
  }

  .rec-item-wrapper {
    margin-bottom: 0;
  }

  .rec-carousel {
    ${props =>
      props.isOncologia &&
      `
      position: relative;
    `}
  }
`;

const ButtonServicesUnity = styled.div`
  margin-top: 0;
  margin-bottom: 3.313rem;
  padding: 0;
  width: 100%;
  display: ${props => (props.isDiagnostico ? 'none' : 'flex')};
  justify-content: left;
  align-items: center;
  font-size: 1rem;
  color: #45a7df;

  p:hover {
    text-decoration: underline;
  }

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
    padding: 1.35rem;
    justify-content: center;
    font-size: 0.875rem;
    border-radius: 6.25rem;
    color: #fff;
    background: #45a7df;
    transition: 0.6s background;

    > img {
      display: none;
    }
    :hover {
      background: #1d52a3;
    }
    p:hover {
      text-decoration: none;
    }
  }
`;

const CarouselButton = styled.button`
  position: ${props => (props.isCarousel ? 'relative' : 'absolute')};
  top: ${props => (props.isCarousel ? '0rem' : '48.313rem')};
  width: ${props => (props.isCarousel ? '2.600rem' : '2.125rem')};
  height: 2.125rem;
  display: ${props => (props.haveNoButtons ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  transform: translate(1148px, 0);
  background: #f4f5f7;
  border-radius: 50%;
  outline: none;
  border: none;

  :first-child {
    transform: translate(1106px, 0);
    left: ${props => props.isCarousel && '-0.5rem'};
    top: ${props => props.isCarousel && '-5rem'};
  }

  :last-child {
    left: ${props => props.isCarousel && '-72rem'};
    top: ${props => props.isCarousel && '-5rem'};
  }

  :disabled {
    cursor: default;
    opacity: 0.5;
  }

  @media ${device.laptop} {
    display: flex;
  }
`;

function ServicesDefault({
  markdown,
  pageBrasilia,
  isHome,
  isCDIUnits,
  pageBelaVista,
  pageItaim,
  titleDeskWith,
  haveNotTitle,
  isDiagnostico,
  isProntoAtendimento,
  isOncologia,
  borderTopCard,
  hasNoBorder,
  hasNoBorderRadius,
  cardHeightAuto,
  paddingCardDesk,
  haveNoButtons,
  hasNoHover,
  cardHeightLap,
  cardHeightMob,
  marginRightCard,
  isCentroOncologico,
  isAdjustmentInCssLayout,
  isAdjustmentInCssLayoutMobile,
  isCarousel,
  heightP,
  title,
}) {
  const [scroll, setScroll] = useState(false);

  const data = [markdown];

  const cutData = data[0].split('(end)');

  const [isDesktop, setDesktop] = useState(undefined);

  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <img src={arrowLeft} alt="voltar" />
      ) : (
        <img src={arrowRight} alt="próximo" />
      );
    return (
      <CarouselButton
        onClick={onClick}
        disabled={isEdge}
        isCarousel={isCarousel}
        haveNoButtons={haveNoButtons}
      >
        {pointer}
      </CarouselButton>
    );
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  return (
    <Container isCDIUnits={isCDIUnits} isOncologia={isOncologia}>
      {!haveNotTitle && (
        <ContainerTitle
          isHome={isHome}
          isCDIUnits={isCDIUnits}
          isOncologia={isOncologia}
        >
          <Title>{title}</Title>
        </ContainerTitle>
      )}
      <ServicesContainer
        isHome={isHome}
        isCDIUnits={isCDIUnits}
        onScroll={() => !scroll && setScroll(true)}
        scrolled={scroll}
        isProntoAtendimento={isProntoAtendimento}
        isOncologia={isOncologia}
      >
        {isCDIUnits ? (
          isDesktop ? (
            <ServicesContent //Card's Centro de Oncologia
              isAdjustmentInCssLayout={isAdjustmentInCssLayout}
              isCentroOncologico={isCentroOncologico}
              isCDIUnits={isCDIUnits}
              borderTopCard={borderTopCard}
              hasNoBorder={hasNoBorder}
              hasNoBorderRadius={hasNoBorderRadius}
              cardHeightAuto={cardHeightAuto}
              paddingCardDesk={paddingCardDesk}
              hasNoHover={hasNoHover}
              cardHeightLap={cardHeightLap}
              cardHeightMob={cardHeightMob}
              marginRightCard={
                marginRightCard && cutData.length > 4 ? '1.4rem' : '1.875rem'
              }
              isCarousel={isCarousel}
              heightP={heightP}
            >
              <Carousel itemsToShow={4} renderArrow={myArrow}>
                {cutData.map(
                  item =>
                    item !== '' && <ReactMarkdown key={item} children={item} />
                )}
              </Carousel>
            </ServicesContent>
          ) : (
            <ServicesContent
              isAdjustmentInCssLayoutMobile={isAdjustmentInCssLayoutMobile}
              isCDIUnits={isCDIUnits}
              titleDeskWith={titleDeskWith}
              borderTopCard={borderTopCard}
              hasNoBorder={hasNoBorder}
              hasNoBorderRadius={hasNoBorderRadius}
              cardHeightAuto={cardHeightAuto}
              paddingCardDesk={paddingCardDesk}
              hasNoHover={hasNoHover}
              cardHeightLap={cardHeightLap}
              cardHeightMob={cardHeightMob}
              marginRightCard={
                marginRightCard && cutData.length > 4 ? '1.7rem' : '1.5rem'
              }
              heightP={heightP}
            >
              {cutData.map(
                item =>
                  item !== '' && (
                    <li>
                      <ReactMarkdown key={item} children={item} />
                    </li>
                  )
              )}
            </ServicesContent>
          )
        ) : (
          <ServicesContent
            pageBrasilia={pageBrasilia}
            isHome={isHome}
            pageBelaVista={pageBelaVista}
            pageItaim={pageItaim}
            isCDIUnits={isCDIUnits}
            isDiagnostico={isDiagnostico}
            isProntoAtendimento={isProntoAtendimento}
            borderTopCard={borderTopCard}
            hasNoBorder={hasNoBorder}
            hasNoBorderRadius={hasNoBorderRadius}
            cardHeightAuto={cardHeightAuto}
            hasNoHover={hasNoHover}
            cardHeightLap={cardHeightLap}
            marginRightCard={
              marginRightCard && cutData.length > 4 ? '1.4rem' : '1.5rem'
            }
            heightP={heightP}
          >
            <ReactMarkdown children={markdown || '-'} />
          </ServicesContent>
        )}
      </ServicesContainer>
      <Link to={'detalhes'}>
        {!isHome && !isCDIUnits && !haveNotTitle && (
          <ButtonServicesUnity>
            <p>Veja todos os serviços dessa unidade</p>
            <img src={arrowRight} alt="Veja todos os serviços dessa unidade" />
          </ButtonServicesUnity>
        )}
      </Link>
    </Container>
  );
}

export default ServicesDefault;
