import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { device } from 'components/device';

export const ContainerGrid = styled.div`
  margin: ${props =>
    props.containerGridMargin
      ? props.containerGridMargin
      : props.render === 'search'
      ? '4.375rem 0'
      : '0 0 4.375rem'};
  display: ${props => (props.render === 'noRender' ? 'none' : 'grid')};
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 1.875rem;
  align-items: center;
  flex-direction: column;
  background: #f4f5f7;

  @media ${device.laptop} {
    display: ${props => (props.showModal === false ? 'none' : 'flex')};
    width: 100vw;
    padding: 2rem 2rem 0 2rem;
    margin: ${props => (props.render === 'search' ? '0' : '0 -1.25rem')};
    ${({ render, backgroundMobile }) =>
      render === 'search' &&
      css`
        z-index: 5;
        position: fixed;
        top: 7.125rem;
        bottom: 0;
        left: 0;
        padding: 0 1.25rem;
        margin-top: 0;
        width: 100%;
        background: ${backgroundMobile ? backgroundMobile : '#f4f5f7'};
        overflow-y: auto;
        overflow-x: hidden;
      `}
  }

  @media ${device.tablet} {
    padding: ${props => (props.render === 'search' ? '0 1.25rem' : '0 2rem')};
    padding: 0 1rem;
  }
  @media ${device.mobile} {
    top: ${props => props.render === 'search' && '5.7rem'};
    height: ${props =>
      props.pathname === '/encontre-seu-medico/' && 'calc(100vh - 5rem)'};
  }
`;

export const GridContent = styled.div`
  justify-items: center;
  grid-column: 2 / -2;
  padding: ${props =>
    props.list > 14 ? '4.375rem 0 0 0' : '4.375rem 0 4.375rem 0'};

  @media ${device.laptop} {
    padding: 0;
    display: flex;
    flex-direction: column;
    padding: ${props => props.modal === 'search' && '1.875rem 0'};
    width: 100%;
  }
`;

export const ContainerCards = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column;
    gap: 0;
    ${props =>
      props.marginTopLaptop &&
      css`
        margin-top: ${props => props.marginTopLaptop};
      `}
  }
`;

export const Cards = styled.div`
  padding: 1.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 23.125rem;
  height: 8.9375rem;
  border: 0.063rem solid #c6c8cc;
  background: #fff;
  border-radius: 0.5rem;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
    width: 100%;
    :last-child {
      margin-bottom: 8rem;
    }
  }

  @media ${device.mobile} {
    padding: 1.25rem;
    :last-child {
      margin-bottom: 6rem;
    }
  }
`;

export const CardsContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Avatar = styled.figure`
  position: relative;
  width: 3.75rem;
  height: 3.75rem;
  background: #f4f5f7;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  margin-right: 20px;
`;

export const Photo = styled.img`
  position: relative;
  min-width: 3.75rem;
  display: block;
  max-height: 3.75rem;
  border-radius: 100%;
  object-fit: cover;
`;

export const MandalaIcon = styled.img`
  width: 1.125rem;
  display: flex;
  transform: translate(40px, -0.125rem);
  bottom: 0;
  position: absolute;
  right: 38px;
`;

export const LinkProfile = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardsDescription = styled.div`
  width: 100%;
  margin-right: 0.5rem;
  p {
    margin-bottom: 0.3125rem;
    font-size: 0.875rem;
    line-height: 1.05875rem;
    color: #8f9194;

    :first-child {
      color: #231f20;
      font-weight: 600;

      @media ${device.laptop} {
        max-width: 100%;
      }
    }
    :last-child {
      margin-bottom: 0;
    }
  }
  a {
    font-size: 0.875rem;
    line-height: 1.05875rem;
    color: #45a7df;
    :visited {
      color: #45a7df;
    }
  }
`;

export const BoxTags = styled.div`
  display: ${props => (props.displayTags ? props.displayTags : 'flex')};
  flex-wrap: wrap;
  margin-bottom: 2.25rem;
  margin-left: -0.0625rem;

  @media ${device.laptop} {
    width: ${props =>
      props.isSearch === 'search' ? 'auto' : props.scroll && '100%'};
    flex-wrap: nowrap;
    margin-bottom: ${props => props.isSearch === 'search' && '1.875rem'};
    margin-right: ${props => props.isSearch === 'search' && '-1rem'};
    margin-left: ${props =>
      props.isSearch === 'search' && !props.scroll
        ? '-0.1rem'
        : props.scroll
        ? '-0.9375rem'
        : '-0.0625rem'};
    padding-left: ${props => !props.cdo && '0.55rem'};

    overflow-x: auto;
    ::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
  }

  @media ${device.tablet} {
    margin-top: ${props =>
      props.isSearch === 'search' ? '1.25rem' : '1.875rem'};
  }

  @media ${device.mobile375} {
    width: 100vw;
    margin-right: -0.9375rem;
  }
`;

export const HeaderModal = styled.header`
  display: none;
  @media ${device.laptop} {
    top: 0 !important;
    margin: 0 auto;
    padding: 0 1.25rem;
    width: 100%;
    height: 7.125rem;
    display: ${props => (props.headerResults === false ? 'none' : 'flex')};
    justify-content: space-between;
    align-items: center;
    z-index: 4;
    background: #fff;
    button {
      background: transparent;
      border: none;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @media ${device.mobile} {
    padding: 1rem;
    height: 6rem;
  }
`;

export const Title = styled.h2`
  width: 100%;
  display: flex;
  align-self: flex-start;
  margin-bottom: 3.5rem;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.875rem;

  b {
    display: contents;
    margin-left: 0.5rem;
  }

  @media ${device.laptop} {
    margin-bottom: ${props => props.isSearch && '1.25rem'};
    font-size: ${props => props.isSearch && '1.5rem'};
  }

  @media ${device.tablet} {
    margin-bottom: ${props => (props.noSearch ? '1.875rem' : '0')};
    margin-top: ${props => (props.isSearch ? '0' : '1.875rem')};
    font-size: 1.3rem;
  }
`;

export const Tags = styled.div`
  margin: 0 0.625rem 0.625rem 0;
  width: auto;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 1.8125rem;
  border-radius: 6.25rem;
  border: 1px solid #c6c8cc;
  background-color: ${props => (props.background ? '#45a7df' : '#FFF')};
  color: ${props => (props.background || props.item ? '#FFF' : '#8f9194')};
  white-space: nowrap;
  padding: 0 0.625rem;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  filter: ${props => (props.disabled ? 'opacity(0.3)' : 'opacity(1)')};

  ${props =>
    props.disabled &&
    css`
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    `}
`;

export const BoxError = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 4.375rem;

  > p {
    line-height: 1.5rem;
  }

  @media ${device.laptop} {
    padding-bottom: 1.875rem;
  }
`;

export const Subtitle = styled.p`
  color: #231f20;
  margin-bottom: 1.8rem;
  margin-top: 1.8rem;
`;

export const BoxErrorFilter = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3rem;
`;

export const TextErrorFilter = styled.p`
  display: flex;
  width: 100%;
`;

export const ButtonErrorFilter = styled.p`
  color: #45a7df;
  cursor: pointer;
  margin-left: 0.5rem;
`;

export const BackButton = styled.div`
  position: fixed;
  display: flex;
  align-self: center;
  bottom: 20px;
`;
