import React, { useEffect, useState } from 'react';

import * as S from './style';

import iconWaze from 'images/icons/waze.svg';
import arrowRight from 'images/icons/arrow.svg';
import { CustomFragment } from 'components/CmsComponents/CustomFragment';

const WazeCard = ({ fullMobileDesk = false, ...rest }) => {
  const [isMobile, setIsMobile] = useState(false);

  rest.className = rest.className?.replace(' WazeCardMob', '') ?? '';
  rest.className = rest.className?.replace(' MobAndDeskWazeCard', '') ?? '';
  const url = rest?.singletexts[0] ?? '';
  const secondUrl = rest?.singletexts[1] ??  url;
  const content = rest.children.split('\n');
  const title = content[1];

  function getText() {
    if (content.length > 2) {
      return content
        .slice(2, 10)
        .map(item => item)
        .join('\n');
    } else {
      return content[2];
    }
  }

  const updateSize = () => {
    setIsMobile(window.innerWidth < 648);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateSize);
      setIsMobile(window.innerWidth < 648);
      return () => window.removeEventListener('resize', updateSize);
    }
  }, []);

  const breakTitle = title => {
    if (!title) {
      return title;
    }

    const titleSplit = title.split(' ');
    const title1 = titleSplit.filter((item, i) => i < titleSplit.length / 2);
    const title2 = titleSplit.filter(
      (item, i) => i > titleSplit.length / 2 - 1
    );
    const titleMerge = `${title1.join(' ')}*** \n\n ***${title2.join(' ')}`;
    const titleFinal = titleMerge.replace('***Sírio-Libanês ', '***');
    return titleFinal;
  };

  if (isMobile || fullMobileDesk) {
    return (
      <S.Container fullMobileDesk={fullMobileDesk}>
        <S.Header>
          <S.Title>{breakTitle(title)}</S.Title>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <S.WazeIcon src={iconWaze} alt="Waze" />
          </a>
        </S.Header>
        <S.Text fullMobileDesk={fullMobileDesk}>{getText()}</S.Text>
        <div>
          <a href={secondUrl} target="_self" rel="noopener noreferrer">
            <S.ArrowRight src={arrowRight} alt="Waze" />
          </a>
        </div>
      </S.Container>
    );
  } else {
    return <CustomFragment {...rest} />;
  }
};

export default WazeCard;
