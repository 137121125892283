import React, { useEffect, useState } from 'react';
import Tabs from 'components/CmsComponents/ContentTab/Tabs';
import * as S from './style';

function ContentTab({ title, tabsData }) {
  const [isMobile, setIsMobile] = useState(false);
  const [active, setActive] = React.useState(0);
  const isWindow = typeof window !== 'undefined';
  const mobile = isWindow && window.innerWidth <= 414;

  const tabs = [
    {
      title: tabsData[0].tabTitle,
    },
    {
      title: tabsData[1].tabTitle,
    },
  ];

  const updateWindowSize = () => {
    setIsMobile(window.innerWidth < 641);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowSize);
      return () => window.removeEventListener('resize', updateWindowSize);
    }
  }, []);

  return (
    <S.Wrapper>
      <S.Container>
        <S.Title>{title}</S.Title>
        <S.TabContainer mobile>
          <Tabs active={active} setActive={setActive} tabs={tabs} />
          <S.ImageContainer active={active}>
            {mobile ? (
              <>
                <S.ImageContent
                  src={tabsData[0].imageMobile.url}
                  alt=""
                  mobile
                />
                <S.ImageContent
                  src={tabsData[1].imageMobile.url}
                  alt=""
                  mobile
                />
              </>
            ) : (
              <>
                <S.ImageContent src={tabsData[0].image.url} alt="" />
                <S.ImageContent src={tabsData[1].image.url} alt="" />
              </>
            )}
          </S.ImageContainer>
        </S.TabContainer>
      </S.Container>
    </S.Wrapper>
  );
}

export { ContentTab };
