import React from 'react';

import { TmpButton } from '@tempo/tempo-design-system-core/dist';
import * as Icons from '@tempo/tempo-assets/dist';

import * as S from './style';
import { Link } from 'gatsby';

const CTAButtonWithIconDS = ({ ...rest }) => {
  let contentButton = rest.children.match('\\[(.*?)\\]')[1];
  let icon = contentButton.split('|')[0];
  let type = contentButton.split('|')[1];
  let content = contentButton.split('|')[2];
  let url = rest.children.match('\\((.*?)\\)')[1];
  let MyIcon = Icons[icon];
  const isWindow = typeof window !== 'undefined';

  const LinkRender = ({ href, content }) => {
    const isInternalLink = href.startsWith('/');
    const isInternalAnchor = href.includes('/#');
    const isMedicalSpecialty = href.includes('especialidades-medicas');
    const isUniversalLink = rest?.universalLink ?? false;

    if (
      isInternalLink &&
      isInternalAnchor &&
      !isMedicalSpecialty &&
      !isUniversalLink
    ) {
    }
    if (isInternalLink && !isUniversalLink) {
      return (
        <Link to={href}>
          {isWindow && { TmpButton } && (
            <TmpButton type={type} size="sm" startIcon={<MyIcon />}>
              {content}
            </TmpButton>
          )}
        </Link>
      );
    }

    if (isUniversalLink) {
      return (
        <a href={isUniversalLink}>
          {isWindow && { TmpButton } && (
            <TmpButton type={type} size="sm" startIcon={<MyIcon />}>
              {content}
            </TmpButton>
          )}
        </a>
      );
    }

    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {isWindow && { TmpButton } && (
          <TmpButton type={type} size="sm" startIcon={<MyIcon />}>
            {content}
          </TmpButton>
        )}
      </a>
    );
  };

  return (
    <S.Container>
      <LinkRender href={url} content={content} />
    </S.Container>
  );
};

export default CTAButtonWithIconDS;
