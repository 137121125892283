import React, { useEffect, useState } from 'react';

// Components
import StepsNav from '../CarouselBanner/StepsNav';

// Styles
import * as S from './style';

// Assets
import arrowRight from 'images/icons/directionalArrowRight.svg';
import arrowLeft from 'images/icons/directionalArrowLeft.svg';

function BannerFullImage({ bannersData }) {
  const [currentBanner, setCurrentBanner] = useState(0);
  const [bannerWidth, setBannerWidth] = useState(0);
  const isWindow = typeof window !== 'undefined';
  const mobile = isWindow && window.innerWidth <= 430;

  useEffect(() => {
    if (isWindow) {
      setBannerWidth(window.innerWidth);
      const handleResize = () => {
        setBannerWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isWindow]);

  const bannersLength = bannersData?.length;

  const goToNextBanner = () => {
    const nextBanner = (currentBanner + 1) % bannersLength;
    setCurrentBanner(nextBanner);
  };

  const goToPreviousBanner = () => {
    const previousBanner = (currentBanner - 1 + bannersLength) % bannersLength;
    setCurrentBanner(previousBanner);
  };

  const handleDotClick = idx => {
    setCurrentBanner(idx);
  };

  return (
    <S.Wrapper>
      <S.Container
        id="banner-container"
        currentBanner={currentBanner}
        bannerWidth={bannerWidth}
        bannersLength={bannersLength}
      >
        {bannersData?.map((banner, index) => {
          return (
            <S.BannerItem
              id="banner-item"
              key={index}
              backgroundColor={
                banner.backgroundColor && banner.backgroundColor.hex
              }
            >
              <S.BannerContent>
                {mobile ? (
                  banner.imageLink ? (
                    <a href={banner.imageLink} target="_blank">
                      <S.BannerImage
                        src={banner.bannerImageMobile.url}
                        alt=""
                      />
                    </a>
                  ) : (
                    <S.BannerImage src={banner.bannerImageMobile.url} alt="" />
                  )
                ) : banner.imageLink ? (
                  <a href={banner.imageLink} target="_blank">
                    <S.BannerImage src={banner.bannerImage.url} alt="" />
                  </a>
                ) : (
                  <S.BannerImage src={banner.bannerImage.url} alt="" />
                )}
              </S.BannerContent>
            </S.BannerItem>
          );
        })}
      </S.Container>
      {bannersData?.length <= 1 ? (
        <>
          <S.ArrowsContainer displayBanner />
          <S.StepsContainer displayBanner />
        </>
      ) : (
        <>
          <S.ArrowsContainer>
            <S.Arrow onClick={goToPreviousBanner}>
              <img src={arrowLeft} alt="Ir para o anterior" />
            </S.Arrow>
            <S.Arrow onClick={goToNextBanner}>
              <img src={arrowRight} alt="Ir para o próximo" />
            </S.Arrow>
          </S.ArrowsContainer>
          <S.StepsContainer>
            <StepsNav
              current={currentBanner}
              steps={bannersLength}
              handleChange={handleDotClick}
            />
          </S.StepsContainer>
        </>
      )}
    </S.Wrapper>
  );
}

export { BannerFullImage };
