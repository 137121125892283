import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';

//Components
import { formatClassName } from 'utils/formatClassName';
import { OtherCustomFragments } from './OtherCustomFragments';
import GrayAccordion from './OthersCustomFragments/GrayAccordion';
import Accordion from './OthersCustomFragments/Accordion';
import GrayRectangle from './OthersCustomFragments/GrayRectangle';
import WazeCard from './OthersCustomFragments/WazeCard';
import CTAButtonDS from './OthersCustomFragments/CTAButtonDS';
import CTAButtonWithIconDS from './OthersCustomFragments/CTAButtonWithIconDS';
import SimpleTextDS from './OthersCustomFragments/SimpleTextDS';
import ContactCardDS from './OthersCustomFragments/ContactCardDS';

import {
  Container,
  CustomFragmentContent,
  ImageContainer,
  Image,
  ButtonAnchor,
  ButtonUniversal,
} from './style';

function CustomFragment({ debugName, ...rest }) {
  const [isMobile, setIsMobile] = useState(false);
  const classesNeedEscapeHtml = ['VideoWithDescription', 'EscapeHtml'];
  const hasImageAsset = formatClassName(rest.className).includes('ImageAsset');

  const isEscapeHtml =
    !!rest.className
      .split(' ')
      .find(classItem => classesNeedEscapeHtml.join(',').includes(classItem)) ??
    false;
  const isUniversalLink = rest?.universalLink ?? false;
  const LinkRender = ({ href, children }) => {
    const isInternalLink = href.startsWith('/');
    const isInternalAnchor = href.includes('/#');
    const isMedicalSpecialty = href.includes('especialidades-medicas');
    const isUniversalLink = rest?.universalLink ?? false;

    if (
      isInternalLink &&
      isInternalAnchor &&
      !isMedicalSpecialty &&
      !isUniversalLink
    ) {
      const anchor = href.split('#')[1];

      return (
        <ButtonAnchor onClick={() => scrollTo(`#${anchor}`)}>
          {children}
        </ButtonAnchor>
      );
    }
    if (rest.videoAction) {
      return (
        <ButtonUniversal onClick={rest.videoAction}>{children}</ButtonUniversal>
      );
    }
    if (isInternalLink && !isUniversalLink) {
      return <Link to={href}>{children}</Link>;
    }

    if (isUniversalLink) {
      return (
        <ButtonUniversal href={isUniversalLink}>{children}</ButtonUniversal>
      );
    }

    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  const updateSize = () => {
    setIsMobile(window.innerWidth < 648);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateSize);
      setIsMobile(window.innerWidth < 648);
      return () => window.removeEventListener('resize', updateSize);
    }
  }, []);

  function GetTypeFragmentLink() {
    if (rest.videoAction) {
      return (
        <ButtonUniversal onClick={rest.videoAction}>
          <CustomFragmentContent
            escapeHtml={!isEscapeHtml}
            renderers={{
              link: LinkRender,
            }}
            {...rest}
          />
        </ButtonUniversal>
      );
    }
    if (isUniversalLink) {
      return (
        <a href={isUniversalLink} className={rest.className}>
          <CustomFragmentContent
            escapeHtml={!isEscapeHtml}
            renderers={{
              link: LinkRender,
            }}
            {...rest}
          />
        </a>
      );
    }

    return (
      <CustomFragmentContent
        isTransform={'translateY(0)'}
        escapeHtml={!isEscapeHtml}
        renderers={{
          link: LinkRender,
        }}
        {...rest}
      />
    );
  }

  switch (true) {
    case rest.className.includes('CTAButtonDS'):
      return <CTAButtonDS {...rest} />;
    case rest.className.includes('CTAButtonWithIconDS'):
      return <CTAButtonWithIconDS {...rest} />;
    case rest.className.includes('ContactCardDS'):
      return <ContactCardDS {...rest} />;
    case rest.className.includes('SimpleTextDS'):
      return <SimpleTextDS {...rest} />;
    case rest.className.includes('GrayAccordion'):
      return <GrayAccordion {...rest} />;
    case rest.className.includes('Accordion'):
      return <Accordion {...rest} />;
    case rest.className.includes('Certificates'):
      return <Accordion {...rest} />;
    case rest.className.includes('GrayRectangle'):
      return <GrayRectangle {...rest} />;
    case rest.className.includes('WazeCardMob'):
      return <WazeCard {...rest} />;
    case rest.type?.includes('WazeCardsMob') && isMobile:
      return <></>;
    case rest.className.includes('MobAndDeskWazeCard'):
      return <WazeCard fullMobileDesk={true} {...rest} />;
    case rest.type?.includes('MobAndDeskWazeCard') && isMobile:
      return <></>;
    default:
      return (
        <Container className={rest.className}>
          {hasImageAsset && !!rest?.rawFragment?.assetpicker?.url && (
            <ImageContainer>
              <Image
                src={rest?.rawFragment?.assetpicker?.url}
                alt="Marketing Image"
              />
            </ImageContainer>
          )}
          <GetTypeFragmentLink />
          <OtherCustomFragments {...rest} />
        </Container>
      );
  }
}

export { CustomFragment };
