import React from 'react';

import {
  Container,
  WrapTitle,
  Title,
  WrapFooter,
  DownloadLink,
  WrapDownloadInfo,
  Image,
  FileSize,
} from './style';

function DownloadCard({ debugName, dataFragment, onlyOne, ...rest }) {
  return (
    <Container className={rest.className} onlyOne={onlyOne}>
      <WrapTitle>
        <Title>{dataFragment?.[0]?.title ?? ''}</Title>
      </WrapTitle>
      <WrapFooter>
        <DownloadLink
          href={dataFragment?.[0]?.download_link ?? ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          Baixar
        </DownloadLink>

        <WrapDownloadInfo>
          <Image
            src={dataFragment?.[0]?.desc_img?.img_link ?? ''}
            alt="download file"
          />
          <FileSize>{dataFragment?.[0]?.desc_img?.description ?? ''}</FileSize>
        </WrapDownloadInfo>
      </WrapFooter>
    </Container>
  );
}

export { DownloadCard };
