import React, { useEffect, useState } from 'react';
import * as S from './style';
// import React, { Fragment } from 'react';

function CardDownload({ backgroundColor, title, fragments }) {
  const [isMobile, setIsMobile] = useState(false);

  const result = fragments[0].datafragment;

  const updateWindowSize = () => {
    setIsMobile(window.innerWidth < 641);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowSize);
      return () => window.removeEventListener('resize', updateWindowSize);
    }
  }, []);

  return (
    <S.Wrapper backgroundColor={backgroundColor}>
      <S.Container>
        <S.Title>{title}</S.Title>
        <S.ContainerCards>
          {result.map(resultCard => (
            <S.ContainerCard>
              <S.TitleCard>{resultCard.title}</S.TitleCard>
              <S.ContainerItemCard>
                <S.ButtonCard target="_blank" href={resultCard.download_link}>
                  Baixar
                </S.ButtonCard>
                <S.ImageCard src={resultCard.desc_img.img_link} alt="" />
                <S.DescriptionCard>
                  {resultCard.desc_img.description}
                </S.DescriptionCard>
              </S.ContainerItemCard>
            </S.ContainerCard>
          ))}
        </S.ContainerCards>
      </S.Container>
    </S.Wrapper>
  );
}

export { CardDownload };
