import styled from 'styled-components';
import { device } from 'components/device';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100vw;
  background: ${({ backgroundColor }) => backgroundColor?.hex};
  padding: 4.375rem 0;

  @media ${device.mobile} {
    padding: 65px 1.25rem;
  }
`;

export const Container = styled.div`
  width: 54rem;
  max-width: 73.125rem;
  height: 100%;
  border-radius: 10px;
  background: #fff;
  padding: 75px 101px;

  @media ${device.mobile} {
    padding: 50px 20px;
    width: 100%;
  }
`;

export const ContainerTexts = styled.div`
  text-align: center;
`;

export const Title = styled.h1`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
`;

export const Description = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin: 24px 0;

  @media ${device.mobile} {
    font-size: 16px;
    margin: 12px 0;
  }
`;

export const Subtitle = styled.h1`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 16px;

  @media ${device.mobile} {
    font-size: 14px;
  }
`;

export const Form = styled.div``;

export const InputDiv = styled.div`
  font-family: 'Inter', sans-serif;
  margin: 30px 10px 0;

  @media ${device.mobile} {
    margin: 20px 0;
  }
`;

export const ContainerTwoInputs = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const ContainerSelect = styled.div`
  button {
    width: 100%;
  }

  @media ${device.mobile} {
    margin-top: 20px;
  }
`;

export const ContainerCheckbox = styled.div`
  margin: 30px 10px 0;

  @media ${device.mobile} {
    margin: 20px 0;
  }
`;

export const ContainerButton = styled.div`
  margin: 24px 0 0 0;
  text-align: center;

  @media ${device.mobile} {
    margin-top: 20px;
  }
`;

export const ContainerSuccess = styled.div`
  text-align: center;
`;

export const ImageSuccess = styled.img``;

export const TitleSuccess = styled.h1`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 22px;
  margin: 10px 0;
`;

export const DescriptionSuccess = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
`;
export const TextCheckbox = styled.div`
  width: 100%;
`;
