// Libs
import React, { useState } from 'react';
import axios from 'axios';

// Styles
import * as S from './style';

// API
// import { apiLead } from '../../services/salesForceAPI';

// DS
import {
  TmpInput,
  TmpSelect,
  TmpTextarea,
  TmpCheckbox,
  TmpLink,
  TmpButton,
  TmpLoading,
} from '@tempo/tempo-design-system-core/dist';

// Assets
import Success from 'images/icons/success.svg';

function FormLeadPersonalizado({
  backgroundColor,
  title,
  description,
  subtitle,
  textNome,
  placeholderNome,
  textSobrenome,
  placeholderSobrenome,
  textEmail,
  placeholderEmail,
  textCelular,
  placeholderCelular,
  paisBoolean,
  textPais,
  placeholderPais,
  localizacaoBoolean,
  textEstado,
  placeholderEstado,
  textCidade,
  placeholderCidade,
  areaAtuacaoBoolean,
  textAreaDeAtuacao,
  placeholderAreaDeAtuacao,
  razaoContatoBoolean,
  textRazaoDoContato,
  placeholderRazaoDoContato,
  descrevaSolicitacaoBoolean,
  textDescrevaSolicitacao,
  placeholderDescrevaSolicitacao,
  pdfBoolean,
  pdfDownload,
  textBotao,
  textLoadingButton,
  titleSuccess,
  descriptionSuccess,
}) {
  const isWindow = typeof window !== 'undefined';
  const [formFields, setFormFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
    cellPhone: '',
    country: '',
    state: '',
    city: '',
    activityArea: '',
    reason: '',
    requestDescription: '',
    acceptedTerm: false,
  });
  const formData = {
    activityArea: '',
    reason: '',
  };
  const ref = React.useRef(null);
  const [isChecked, setIsChecked] = useState(false);
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (fieldName, value) => {
    setFormFields(prevFields => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  const isFormValid = () => {
    const requiredFields = [
      { fieldName: 'firstName', isRequired: true },
      { fieldName: 'lastName', isRequired: true },
      { fieldName: 'email', isRequired: true },
      { fieldName: 'cellPhone', isRequired: true },
      { fieldName: 'country', isRequired: paisBoolean },
      { fieldName: 'state', isRequired: localizacaoBoolean },
      { fieldName: 'city', isRequired: localizacaoBoolean },
      { fieldName: 'activityArea', isRequired: areaAtuacaoBoolean },
      { fieldName: 'reason', isRequired: razaoContatoBoolean },
      {
        fieldName: 'requestDescription',
        isRequired: descrevaSolicitacaoBoolean,
      },
      { fieldName: 'acceptedTerm', isRequired: true },
    ];

    const allFieldsValid = requiredFields.every(field => {
      const fieldValue = formFields[field.fieldName];
      const isFieldValid =
        typeof fieldValue === 'boolean' ? fieldValue : fieldValue.trim() !== '';
      return !field.isRequired || isFieldValid;
    });

    return allFieldsValid && isChecked;
  };

  const handleNewCase = async () => {
    try {
      const originForm = window.location.pathname;

      const formDataWithOrigin = {
        ...formFields,
        originForm: originForm,
      };

      // Envio da requisição para o backend
      const response = await axios.post(
        'https://0odh92yll4.execute-api.us-east-1.amazonaws.com/prod/leads',
        formDataWithOrigin,
        {
          headers: { 'x-api-key': 'd4sTwxMWK32pN2QzhsT7k7azUfpdAR5sjtwgT8ti' },
        }
      );

      console.log('Resposta do Backend:', response.data);
      setSuccess(true);
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } catch (e) {
      if (e.response) {
        console.error('Erro no Backend:', e.response.data);
      } else if (e.request) {
        console.error('Sem resposta do Backend:', e.request);
      } else {
        console.error('Erro durante a solicitação:', e.message);
      }
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(prevChecked => !prevChecked);
    setFormFields(prevFields => ({
      ...prevFields,
      acceptedTerm: !isChecked,
    }));
  };

  const handleDownload = () => {
    const pdfUrl = pdfDownload?.[0].url;
    window.open(pdfUrl, '_blank');
  };

  const handleButtonClick = async () => {
    setLoading(true);
    if (pdfBoolean) {
      await handleNewCase(formFields);

      handleDownload();
    } else {
      await handleNewCase(formFields);
    }
    setLoading(false);
  };

  return (
    <S.Wrapper ref={ref} backgroundColor={backgroundColor}>
      {!success ? (
        <S.Container>
          <S.ContainerTexts>
            <S.Title>{title}</S.Title>
            <S.Description>{description}</S.Description>
            <S.Subtitle>{subtitle}</S.Subtitle>
          </S.ContainerTexts>
          <S.Form>
            <S.ContainerTwoInputs>
              <S.InputDiv>
                {isWindow && TmpInput && (
                  <TmpInput
                    id="firstName"
                    label={textNome}
                    type="text"
                    placeholder={placeholderNome}
                    value={formFields.firstName}
                    maxLength={50}
                    handleChange={e =>
                      handleFieldChange('firstName', e.target.value)
                    }
                  />
                )}
              </S.InputDiv>
              <S.InputDiv>
                {isWindow && TmpInput && (
                  <TmpInput
                    id="lastName"
                    label={textSobrenome}
                    type="text"
                    placeholder={placeholderSobrenome}
                    value={formFields.lastName}
                    maxLength={50}
                    handleChange={e =>
                      handleFieldChange('lastName', e.target.value)
                    }
                  />
                )}
              </S.InputDiv>
            </S.ContainerTwoInputs>

            <S.InputDiv>
              {isWindow && TmpInput && (
                <TmpInput
                  id="email"
                  label={textEmail}
                  type="text"
                  placeholder={placeholderEmail}
                  value={formFields.email}
                  maxLength={50}
                  handleChange={e => handleFieldChange('email', e.target.value)}
                />
              )}
            </S.InputDiv>

            <S.InputDiv>
              {isWindow && TmpInput && (
                <TmpInput
                  id="cellPhone"
                  label={textCelular}
                  placeholder={placeholderCelular}
                  value={formFields.cellPhone}
                  maxLength={15}
                  onInput={e => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, '');
                    handleFieldChange('cellPhone', numericValue);
                  }}
                  inputMode="numeric"
                />
              )}
            </S.InputDiv>

            {paisBoolean && (
              <S.InputDiv>
                {isWindow && TmpInput && (
                  <TmpInput
                    id="country"
                    label={textPais}
                    type="text"
                    placeholder={placeholderPais}
                    value={formFields.country}
                    maxLength={50}
                    handleChange={e =>
                      handleFieldChange('country', e.target.value)
                    }
                  />
                )}
              </S.InputDiv>
            )}

            <S.ContainerTwoInputs>
              {localizacaoBoolean && (
                <S.InputDiv>
                  {isWindow && TmpInput && (
                    <TmpInput
                      id="state"
                      label={textEstado}
                      type="text"
                      placeholder={placeholderEstado}
                      value={formFields.state}
                      maxLength={50}
                      handleChange={e =>
                        handleFieldChange('state', e.target.value)
                      }
                    />
                  )}
                </S.InputDiv>
              )}

              {localizacaoBoolean && (
                <S.InputDiv>
                  {isWindow && TmpInput && (
                    <TmpInput
                      id="city"
                      label={textCidade}
                      type="text"
                      placeholder={placeholderCidade}
                      value={formFields.city}
                      maxLength={50}
                      handleChange={e =>
                        handleFieldChange('city', e.target.value)
                      }
                    />
                  )}
                </S.InputDiv>
              )}
            </S.ContainerTwoInputs>

            <S.ContainerSelect>
              {areaAtuacaoBoolean && (
                <S.InputDiv>
                  {isWindow && TmpSelect && (
                    <TmpSelect
                      id="activityArea"
                      label={textAreaDeAtuacao}
                      placeholder={placeholderAreaDeAtuacao}
                      value={formData.activityArea}
                      listItems={[
                        { label: 'Recursos Humanos' },
                        { label: 'Marketing' },
                        { label: 'Comercial' },
                        { label: 'Finanças' },
                        { label: 'C-Level' },
                        { label: 'Outros' },
                      ]}
                      handleChange={value =>
                        handleFieldChange('activityArea', value.label)
                      }
                    />
                  )}
                </S.InputDiv>
              )}
            </S.ContainerSelect>

            <S.ContainerSelect>
              {razaoContatoBoolean && (
                <S.InputDiv>
                  {isWindow && TmpSelect && (
                    <TmpSelect
                      id="reason"
                      label={textRazaoDoContato}
                      placeholder={placeholderRazaoDoContato}
                      value={formData.reason}
                      listItems={[
                        { label: 'Programación de citas' },
                        { label: 'Programación de exámenes' },
                        { label: 'Presupuestos para procedimientos' },
                        {
                          label: 'Transferencia de pacientes al Sírio-Libanês',
                        },
                        { label: 'Seguros y Coberturas Internacionales' },
                        { label: 'Equipos Médicos - Encuentra tu Doctor' },
                        { label: 'Pagos' },
                        { label: 'Outro' },
                      ]}
                      handleChange={value =>
                        handleFieldChange('reason', value.label)
                      }
                    />
                  )}
                </S.InputDiv>
              )}
            </S.ContainerSelect>

            {descrevaSolicitacaoBoolean && (
              <S.InputDiv>
                {isWindow && TmpTextarea && (
                  <TmpTextarea
                    id="requestDescription"
                    label={textDescrevaSolicitacao}
                    placeholder={placeholderDescrevaSolicitacao}
                    value={formFields.requestDescription}
                    maxLength={600}
                    handleChange={e =>
                      handleFieldChange('requestDescription', e.target.value)
                    }
                  />
                )}
              </S.InputDiv>
            )}

            <S.ContainerCheckbox>
              {isWindow && TmpCheckbox && TmpLink && (
                <>
                  <TmpCheckbox
                    label=""
                    id="acceptedTerm"
                    name="acceptedTerm"
                    value={formFields.acceptedTerm}
                    checked={isChecked}
                    handleChange={handleCheckboxChange}
                  />
                  <S.TextCheckbox>
                    Eu concordo com a{' '}
                    <TmpLink target="_blank" href="politica-de-privacidade">
                      política do site
                    </TmpLink>{' '}
                    para receber informações e contato do Sírio-Libanês.
                  </S.TextCheckbox>
                </>
              )}
            </S.ContainerCheckbox>

            <S.ContainerButton>
              {loading ? (
                <>
                  {isWindow && TmpLoading && (
                    <TmpLoading label={textLoadingButton} />
                  )}
                </>
              ) : (
                <>
                  {isWindow && TmpButton && (
                    <TmpButton
                      success={success}
                      disabled={!isFormValid()}
                      handleClick={handleButtonClick}
                    >
                      {textBotao}
                    </TmpButton>
                  )}
                </>
              )}
            </S.ContainerButton>
          </S.Form>
        </S.Container>
      ) : (
        <>
          <S.ContainerSuccess>
            <S.ImageSuccess src={Success} alt="" />
            <S.TitleSuccess>{titleSuccess}</S.TitleSuccess>
            <S.DescriptionSuccess>{descriptionSuccess}</S.DescriptionSuccess>
          </S.ContainerSuccess>
        </>
      )}
    </S.Wrapper>
  );
}

export { FormLeadPersonalizado };
