import React, { useContext, useEffect, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';

import { ContainerAlert, MessageAlert } from './alertPostFeriadoStyle';
import { HeaderContext } from '../../context/header';

function AlertPostFeriado({ posts, backgroundColor, textColor }) {
  const { setShowAlertPostFeriado, setLinkPostFeriado } = useContext(
    HeaderContext
  );
  const [slugPostRecente, setSlugPostRecente] = useState('');
  /** estados  */

  const linkRedirect = `/blog/acontecenosiriolibanes/${slugPostRecente}`;

  /* apenas trazer os post que são feriados de acordo com a categoria */
  let r1 = posts?.filter(
    resultpost =>
      resultpost?.postFeriados.length > 0 &&
      resultpost?.date !== null &&
      resultpost?.categories.find(
        elCategories => elCategories.tag === 'acontecenosiriolibanes'
      )
  );

  /* apenas trazer os post que são feriados de acordo com a categoria */

  /*criar um Campo do tipo data e converter a data para milisegundos*/
  let r2 = r1?.map(resultMapDate => {
    let newDate = new Date(resultMapDate?.date);
    return { ...resultMapDate, dataForm: newDate };
  });

  let r3 = r2?.filter(ResultFilterTime => ResultFilterTime?.dataForm.getTime());
  /*criar um Campo do tipo data e converter a data para milisegundos*/

  /* apos a conversão do  tempo para identificar qual o post com a maior data e captura do link  */
  let r5 = r3?.sort((a, b) => (a?.dataForm < b?.dataForm && 1) || -1);

  const r6 = r5 && r5[0]?.slugPost;

  useEffect(() => {
    if (r6) {
      setSlugPostRecente(r6);
      setLinkPostFeriado(`/blog/acontecenosiriolibanes/${r6}`);
    }
  }, [r6]);
  /* apos a conversão do  tempo para identificar qual o post com a maior data e captura do link  */

  /* Logica para pegar os dias de feriado dentro de um post mais recente*/
  let arrayDataResult = r5[0]?.postFeriados[0]?.units?.map(ResultUnits =>
    ResultUnits?.unidadeFeriadoFuncionamento[
      ResultUnits?.unidadeFeriadoFuncionamento.length - 1
    ]?.datainfo?.map(resultUniFuncionamento =>
      resultUniFuncionamento.date?.map(resultDate => resultDate?.targetDate)
    )
  );

  /* Logica para pegar os dias de feriado dentro de um post mais recente*/

  let result;
  let followingDay;
  if (r1.length > 0) {
    result = arrayDataResult
      .flat(Infinity)
      .map(date => {
        let formattedDate = new Date(date?.split('/').reverse().join('-'));
        return (
          formattedDate.getTime() +
          formattedDate.getTimezoneOffset() * 60 * 1000
        );
      })
      .sort((a, b) => (a < b && 1) || -1);
    const oneDay = 86400000;
    followingDay = result[0] + oneDay;
  }

  useEffect(() => {
    if (followingDay > new Date().getTime()) {
      setShowAlertPostFeriado(true);
    } else {
      setShowAlertPostFeriado(false);
    }
  }, []);
  return (
    <ContainerAlert backgroundColor={backgroundColor} isVisible={false}>
      <MessageAlert to={linkRedirect}>
        Confira os horários no feriado.
      </MessageAlert>
    </ContainerAlert>
  );
}
export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cklmo803chhjx0b688o3wdzxm" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
              posts(orderBy: date_DESC, first: 2000) {
                postFeriados {
                  id
                  units(locales: pt_BR) {
                    link
                    name
                    addressAndInformationJSON
                    unidadeFeriadoFuncionamento(locales: pt_BR) {
                      datainfo
                    }
                  }
                }
                id
                title
                topic
                slugPost
                markdown
                author
                date
                description
                assetpicker {
                  handle
                  height
                  width
                }
                localizations {
                  markdown
                  description
                  author
                  date
                  assetpicker {
                    handle
                    height
                    width
                  }
                }
                categories {
                  tag
                  highlight
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <AlertPostFeriado
            posts={response.gcms.site?.posts}
            page={response.gcms.site?.pages}
            {...props}
          />
        );
      }}
    />
  );
};
