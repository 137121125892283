import React, { useState, useEffect } from 'react';
import reactStringReplace from 'react-string-replace';
import DSArrow from 'images/icons/DS_arrow_cards.svg';
import {
  TmpTypography,
  TmpCard,
  TmpShape,
  TmpModal,
  TmpLink,
} from '@tempo/tempo-design-system-core/dist';
import * as S from './style';

function DSCardsContent({ title, description, cards, backgroundColor }) {
  const [isOpened, setIsOpened] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [descriptionModal, setDescriptionModal] = useState('');
  const [linkModal, setLinkModal] = useState('');
  const [termoLink, setTermoLink] = useState('');
  const [containerDescriptionModal, setContainerDescriptionModal] = useState(
    ''
  );
  const isWindow = typeof window !== 'undefined';

  const openModal = (titleModal, descriptionModal, linkModal, termoLink) => {
    setTitleModal(titleModal);
    setDescriptionModal(descriptionModal);
    setLinkModal(linkModal);
    setTermoLink(termoLink);
    setIsOpened(true);
  };

  useEffect(() => {
    const numbers = new RegExp('^[0-9]+$');
    if (!descriptionModal) return;
    if (numbers.test(linkModal)) {
      setContainerDescriptionModal(
        reactStringReplace(
          descriptionModal?.replaceAll('[break]', '\n'),
          '[espacoTermoLink]',
          () => (
            <TmpLink href={'tel:+' + linkModal} target="_blank">
              {termoLink}
            </TmpLink>
          )
        )
      );
    } else {
      setContainerDescriptionModal(
        reactStringReplace(
          descriptionModal?.replaceAll('[break]', '\n'),
          '[espacoTermoLink]',
          () => (
            <TmpLink href={linkModal} target="_blank">
              {termoLink}
            </TmpLink>
          )
        )
      );
    }
  }, [descriptionModal]);

  return (
    <>
      <S.Container bgColor={backgroundColor?.hex ?? 'transparent'}>
        <S.Content>
          <S.Box>
            <S.Information>
              {isWindow && { TmpTypography } && (
                <>
                  <TmpTypography component="heading" size="medium">
                    {title?.replaceAll('[break]', '\n')}
                  </TmpTypography>
                  <TmpTypography component="paragraph">
                    {description?.replaceAll('[break]', '\n')}
                  </TmpTypography>
                </>
              )}
            </S.Information>
            <S.CardContainer>
              {cards.map((card, index) =>
                card.url
                  ? isWindow && { TmpCard } && (
                      <TmpCard
                        key={index}
                        handleClick={() => window.open(card.url, card.target)}
                      >
                        {isWindow && { TmpTypography } && (
                          <TmpTypography
                            component="heading"
                            size="small"
                            variant="h2"
                          >
                            {card.title?.replaceAll('[break]', '\n')}
                          </TmpTypography>
                        )}
                        <S.WrapImage>
                          <img
                            src={DSArrow}
                            alt={card.title?.replaceAll('[break]', ' ')}
                          />
                        </S.WrapImage>
                      </TmpCard>
                    )
                  : card.titleModal && card.descriptionModal
                  ? isWindow && { TmpCard } && (
                      <TmpCard
                        key={index}
                        handleClick={() =>
                          openModal(
                            card.titleModal,
                            card.descriptionModal,
                            card.linkModal,
                            card.termoLink
                          )
                        }
                      >
                        {isWindow && { TmpTypography } && (
                          <TmpTypography
                            component="heading"
                            size="small"
                            variant="h2"
                          >
                            {card.title?.replaceAll('[break]', '\n')}
                          </TmpTypography>
                        )}
                        <S.WrapImage>
                          <img
                            src={DSArrow}
                            alt={card.title?.replaceAll('[break]', ' ')}
                          />
                        </S.WrapImage>
                      </TmpCard>
                    )
                  : isWindow && { TmpShape } && (
                      <TmpShape key={index}>
                        {isWindow && { TmpTypography } && (
                          <TmpTypography
                            component="heading"
                            size="small"
                            variant="h2"
                          >
                            {card.title?.replaceAll('[break]', '\n')}
                          </TmpTypography>
                        )}
                      </TmpShape>
                    )
              )}
            </S.CardContainer>
            {isWindow && { TmpModal } && (
              <S.Modal>
                <TmpModal
                  isOpened={isOpened}
                  isDoubleAction={false}
                  labelPrimary="Entendi"
                  handleConfirm={e => setIsOpened(false)}
                  handleCancel={e => setIsOpened(false)}
                  handleClose={e => setIsOpened(false)}
                >
                  <TmpTypography component="subtitle">
                    {titleModal?.replaceAll('[break]', '\n')}
                  </TmpTypography>
                  <TmpTypography component="paragraph">
                    {containerDescriptionModal}
                  </TmpTypography>
                </TmpModal>
              </S.Modal>
            )}
          </S.Box>
        </S.Content>
      </S.Container>
    </>
  );
}

export { DSCardsContent };
