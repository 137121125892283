import React, { useState } from 'react';

import * as S from './style';

function ButtonCopy({ debugName, ...rest }) {
  const [buttonAnimation, setButtonAnimation] = useState(false);
  const [buttonText, setButtonText] = useState(
    rest.singletexts[1] ?? 'Copiar endereço'
  );

  const addressCopy =
    (rest && rest.singletexts?.filter(item => item.includes('Endereço:'))) ||
    undefined;

  const addressRemoveString =
    addressCopy && addressCopy[0] && addressCopy[0].replace('Endereço:', ' ');

  function copyToClipboard(e) {
    if (typeof document !== 'undefined') {
      navigator.clipboard.writeText(addressRemoveString);
      handleAnimation();
      e.target.focus();
    }
  }

  const handleAnimation = () => {
    setButtonAnimation(true);

    setTimeout(() => {
      setButtonText(rest.singletexts[1] ?? 'Copiar endereço');
    }, 1500);

    setTimeout(() => {
      setButtonText(rest.singletexts[2] ?? 'Endereço copiado');
    }, 200);

    setTimeout(() => {
      setButtonAnimation(false);
    }, 2000);
  };

  return (
    <S.ButtonCopy
      onClick={(!buttonAnimation && copyToClipboard) || undefined}
      animation={buttonAnimation}
    >
      <p>{buttonText}</p>
    </S.ButtonCopy>
  );
}

export { ButtonCopy };
