import React from 'react';
import { TmpDropdown } from '@tempo/tempo-design-system-site/dist';

export function ComboGroup({
  label,
  listItems,
  selectedChecked,
  onSelectOption,
  handleFilters,
}) {
  const checkboxes =
    listItems?.map(item => {
      return {
        label: item?.name,
        value: item?.id,
        checked: selectedChecked.includes(item?.id),
        handleChange: () => onSelectOption(item?.id),
      };
    }) ?? [];

  for (let i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].checked) {
      return <p>{checkboxes[i].label}</p>;
    }
  }

  return (
    typeof window !== 'undefined' && { TmpDropdown } && (
      <TmpDropdown
        title={label}
        label={label}
        handleChange={() => {
          handleFilters();
        }}
        checkbox={checkboxes}
      />
    )
  );
}
