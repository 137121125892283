import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import { device } from 'components/device';

export const Container = styled.section`
  & + section.CTAButton {
    margin-top: 3.125rem;
  }

  &.CTAButtonWithIcon {
    @media ${device.laptop} {
      width: 100%;
    }
  }

  & + section.CTAButtonWithIcon {
    margin-top: 0rem;
  }

  & + section.CTAButtonType2 {
    margin-top: 3.125rem;

    @media ${device.laptop} {
      margin-top: 1.25rem;
    }
  }

  & + section.CTAButtonType3 {
    margin-top: 3.125rem;

    @media ${device.laptop} {
      margin-top: 1.25rem;
    }
  }

  & + section.Title {
    margin-top: 3.125rem;
  }

  &.LiveScheduleCard {
    width: 100%;
  }

  &.ImageAsset {
    display: flex;
    justify-content: space-between;
    gap: 1.875rem;
    margin-top: 1.25rem;

    @media ${device.laptop} {
      flex-direction: column;
      justify-content: unset;
      margin-top: 0;
    }
  }

  & + section.mt30 {
    margin-top: 1.875rem;
  }

  & + section.mt50 {
    margin-top: 3.125rem;

    @media ${device.mobile} {
      margin-top: 1.875rem;
    }
  }

  &.mt30x50 {
    margin-top: 1.875rem;

    @media ${device.laptop} {
      margin-top: 3.125rem;
    }
  }

  &.mt30x80 {
    margin-top: 1.875rem;

    @media ${device.laptop} {
      margin-top: 5rem;
    }
  }

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.Card {
    border: 1px solid #c6c8cc;
    border-radius: 0.5rem;
    padding: 1.875rem;
    max-width: 23.125rem;
    width: 100%;

    h2 {
      font-size: 1.5rem;
      line-height: 1.875rem;
      color: #000;
      font-weight: 600;
      margin: 0;

      @media ${device.laptop} {
        font-size: 1rem;
        line-height: 1.375rem;
      }
    }

    p {
      color: #8f9194;
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }

  &.VideoWithDescription {
    @media ${device.mobile} {
      width: 100%;
    }
  }
`;

export const CustomFragmentContent = styled(ReactMarkdown)`
  p {
    font-size: 1rem;
    line-height: 1.625rem;
    white-space: pre-line;

    & + p {
      margin-top: 1.875rem;
    }

    @media ${device.laptop} {
      font-size: 0.875rem;
      line-height: 1.625rem;
    }
  }

  ul {
    font-size: 1rem;
    line-height: 1.625rem;
    @media ${device.laptop} {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }

  &.blueBullets {
    ul {
      margin-left: 1.12rem;

      @media ${device.laptop} {
        font-size: 0.875rem;
      }
    }
    ul li::marker {
      color: #45a7df;
      font-size: 16px;
    }
  }

  strong {
    font-weight: 600;
  }

  a {
    color: #45a7df;
  }

  &.borderBottom30 {
    width: 31rem;
    border-bottom: 1px solid #c6c8cc;
    margin: 0 0 1.875rem;
    @media ${device.laptop} {
      width: 100%;
    }
  }

  &.contactCardType2 {
    display: flex;
    gap: 0.5rem;
  }

  &.CTAButtonType2 {
    width: fit-content;
    min-width: 15rem;
    background: #45a7df;
    border-radius: 6.25rem;
    padding: 1rem 1.25rem;

    text-align: center;

    a {
      color: #ffffff;
      display: flex;
      align-items: center;
    }

    @media ${device.laptop} {
      width: 100%;
      min-width: unset;
      line-height: 1.625rem;
    }
  }

  &.CTAButtonType3 {
    width: fit-content;
    min-width: 15rem;
    background: #45a7df;
    border-radius: 6.25rem;
    padding: 1rem 1.25rem;

    text-align: center;

    a {
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media ${device.laptop} {
      width: 100%;
      min-width: unset;
      line-height: 1.625rem;
    }
  }

  &.CTAButton {
    width: fit-content;
    min-width: 15rem;
    background: #45a7df;
    border-radius: 6.25rem;

    text-align: center;

    a {
      padding: 1rem 1.25rem;
      color: #ffffff;
      display: block;
    }

    @media ${device.laptop} {
      width: 100%;
      min-width: unset;
      line-height: 1.625rem;
    }
  }

  &.CTAButtonWithIcon {
    width: fit-content;
    min-width: 100%;
    background: #45a7df;
    border-radius: 6.25rem;
    padding: 0.938rem 1.25rem;

    text-align: center;

    a {
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.75rem;

      img {
        width: 24px;
        height: 24px;
      }

      em {
        font-style: normal;
      }
    }
  }

  &.CTAButtonLanding {
    width: 100%;
    min-width: 15rem;
    background: #ffffff;
    border-radius: 6.25rem;
    padding: 0.938rem 1.25rem;
    margin-top: 2.75rem;

    text-align: center;

    a {
      color: #fd528f !important;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.75rem;
      text-decoration-color: white !important;
      font-weight: 500;

      img {
        width: 24px;
        height: 24px;
      }

      em {
        font-style: normal;
      }
    }
  }

  &.mt30x0 {
    @media ${device.laptop} {
      margin-top: 1.875rem;
    }
  }

  &.WazeButton {
    display: none;

    @media ${device.tablet} {
      display: flex;
    }

    p {
      display: flex;
      margin-bottom: 10px;
      margin-top: 30px;
      width: 100%;
      color: rgb(69, 167, 223);
      font-weight: 400;
    }

    a {
      display: flex;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 100%;
      height: 54px;
      padding: 1rem;
      font-size: 0.875rem;
      border: 0.063rem solid rgb(198, 200, 204);
      border-radius: 6.25rem;
      outline: none;
      background: transparent;
      gap: 0.625rem;
    }
  }

  &.EmergecyButton {
    display: none;

    @media ${device.tablet} {
      display: flex;
    }

    p {
      display: flex;
      margin-bottom: 10px;
      width: 100%;
      color: rgb(69, 167, 223);
      font-weight: 400;
    }

    a {
      display: flex;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 100%;
      height: 54px;
      padding: 1rem;
      font-size: 0.875rem;
      border: 0.063rem solid rgb(198, 200, 204);
      border-radius: 6.25rem;
      outline: none;
      background: transparent;
    }
  }

  &.CentralizationText {
    justify-content: center;
  }

  &.LeftPositionText {
    display: flex;
    justify-content: flex-end;

    &.mt50 {
      margin-top: 3.125rem;
    }

    @media ${device.laptop} {
      justify-content: flex-start;
    }

    p {
      display: flex;
      align-items: center;
      gap: 0.4rem;

      @media ${device.laptop} {
        display: block;
      }
    }

    a {
      display: inline-flex;
      align-items: center;
    }
  }

  &.CTALink {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      font-size: 1rem;
      line-height: 1.625rem;
      text-decoration: underline;

      > em {
        font-style: normal;
      }
    }

    @media ${device.laptop} {
      a {
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
    }
  }

  &.WhatsAppIcon {
    img {
      margin-right: 1rem;
    }
  }

  &.textColorWhite {
    color: #ffffff;
  }

  &.Fs075Lh1 {
    p {
      font-size: 0.75rem;
      line-height: 1rem;
      font-style: normal;
    }
  }

  &.Title p {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: 600;
  }

  &.LiveScheduleCard {
    position: relative;
    background: #ffffff;
    border: 1px solid #c6c8cc;
    border-radius: 0.5rem;
    padding: 1.875rem;

    h4 {
      width: 100%;
      max-width: 24.25rem;
      font-size: 1.5rem;
      line-height: 1.875rem;
      font-weight: 600;
      color: #000000;
      margin-bottom: 0.75rem;
    }

    p {
      width: 100%;
      max-width: 24.25rem;
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: #8f9194;

      & + p {
        margin: 0;
      }

      strong {
        font-weight: 600;
      }
    }

    p:last-child {
      a {
        display: inline-block;
        width: unset;
        max-width: unset;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1.875rem;
        background: #45a7df;
        padding: 0.875rem 1.25rem;
        border-radius: 6.25rem;
        color: #ffffff;
      }
    }

    @media ${device.laptop} {
      width: 100%;
      height: 100%;
      padding: 1.25rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h4 {
        max-width: unset;
        font-size: 1.125rem;
        line-height: 1.375rem;
        margin-bottom: 0.5rem;
      }

      p:last-child {
        a {
          margin-top: 1.25rem;
          position: unset;
          top: unset;
          transform: unset;
          right: unset;
        }
      }
    }

    @media ${device.mobile} {
      width: 16.875rem;
    }
  }

  &.VideoWithDescription {
    width: 16.875rem;

    &.VideoSize570px {
      width: 35.625rem;

      iframe {
        width: 35.625rem;
        height: 23.5rem;
      }
    }

    iframe {
      width: 16.875rem;
      height: 9.375rem;
      margin: 0;

      &.VideoSize570px {
        width: 35.625rem;
        height: 23.5rem;
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.625rem;
      color: #231f20;
      margin-top: 1.875rem;
    }

    @media ${device.laptop} {
      font-size: 0.875rem;
      line-height: 1.625rem;

      &.VideoSize570px {
        width: 16.875rem;

        iframe {
          width: 16.875rem;
          height: 9.375rem;
        }
      }
    }

    @media ${device.mobile} {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      flex-direction: column ;
    }
  }

  &.ContactCard {
    position: relative;
    img {
      position: absolute;
      top: 50%;
      transform: ${props =>
        props.isTransform ? props.isTransform : 'translateY(-50%)'};
    }
    em {
      color: #8f9194;
      width: calc(100% - 2.25rem);
      display: inline-block;
      margin-left: 2.25rem;
      font-style: normal;

      strong {
        color: #231f20;
      }
    }
  }

  &.ContactCardLanding {
    position: relative;
    img {
      position: absolute;
      top: 50%;
      transform: ${props =>
        props.isTransform ? props.isTransform : 'translateY(-50%)'};
    }
    em {
      color: white;
      width: calc(100% - 2.25rem);
      display: inline-block;
      margin-left: 2.25rem;
      font-style: normal;

      strong {
        color: white;
      }
    }
  }

  &.bgWhite {
    background: #ffffff;
  }

  &.textColorLink {
    a {
      color: #45a7df;
    }

    p {
      color: #45a7df;
    }
  }

  &.textColorGray {
    color: #8f9194;
  }

  &.textColorDark {
    color: #231f20;

    em {
      color: #231f20;
    }
  }

  &.fitContent {
    width: fit-content;
    min-width: unset;

    @media ${device.laptop} {
      width: 100%;
    }
  }

  &.TwoColumnText {
    column-count: 2;
    column-gap: 1.875rem;
    column-fill: auto;

    @media ${device.laptop} {
      column-count: unset;
      column-gap: unset;
      column-fill: unset;
    }
  }

  &.ThreeColumnText {
    column-count: 3;
    column-gap: 1.875rem;
    column-fill: balance;

    @media ${device.laptop} {
      column-count: unset;
      column-gap: unset;
      column-fill: unset;
    }
  }

  &.CustomFragmentHeight800 {
    height: 50rem;

    @media ${device.laptop} {
      height: unset;
    }
  }

  &.CustomFragmentHeight480 {
    height: 30rem;

    @media ${device.laptop} {
      height: unset;
    }
  }

  &.LastContainerFs075Lh1 {
    p:last-child {
      font-size: 0.75rem;
      line-height: 1rem;
      font-style: normal;
    }
  }

  &.Card {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  min-width: 35.625rem;
  max-width: 35.625rem;

  @media ${device.laptop} {
    min-width: unset;
    max-width: unset;
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const ButtonAnchor = styled.button`
  font-size: 1rem;
  line-height: 1.625rem;
  border: none;

  @media ${device.laptop} {
    font-size: 0.875rem;
    line-height: 1.625rem;
  }

  width: fit-content;
  min-width: 15rem;
  background: #45a7df;
  border-radius: 6.25rem;

  text-align: center;

  padding: 1rem 1.25rem;
  color: #ffffff;
  display: block;

  @media ${device.laptop} {
    width: 100%;
    min-width: unset;
    line-height: 1.625rem;
  }
`;
export const ButtonUniversal = styled.a`
  cursor: pointer;
`;
