import styled from 'styled-components';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
`;
export const WrapHeader = styled.div`
  display: ${props => (props.externalPage ? 'flex' : 'none')};
  align-items: center;
  position: relative;
  margin-bottom: 2.5rem;

  @media ${device.laptop} {
    margin-bottom: 1.5rem;
  }
`;

export const HeaderTitle = styled.h2`
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: normal;
  margin-bottom: 0;

  @media ${device.laptop} {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;

export const HeaderImage = styled.img`
  width: 100%;
  max-width: 13.125rem;
  height: auto;

  object-fit: contain;
  object-position: center;

  position: absolute;
  top: -4.375rem;
  left: 16rem;
  z-index: -1;

  @media ${device.laptop} {
    max-width: 9.5rem;
    top: -2.375rem;
    left: 13rem;
  }

  @media ${device.mobile} {
    left: unset;
    right: 0.25rem;
  }
`;

export const WrapContent = styled.div`
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
`;

export const Content = styled.div`
  display: flex;
  gap: 1.875rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.875rem;
  border: 1px solid #c6c8cc;
  border-radius: 0.75rem;
  background: #ffffff;

  @media ${device.laptop} {
    padding: 1.25rem;
    flex-direction: column;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  max-width: 18rem;
  position: relative;

  @media ${device.laptop} {
    max-width: unset;
  }

  @media ${device.laptop} {
    ul {
      height: 50%;
      top: 50%;
    }

    li:first-child {
      position: fixed;
      background-color: white;
      z-index: 999;
      width: 100%;
    }

    li:second-child {
      margin-top: 50px;
    }
  }
`;

export const SearchButton = styled.button`
  width: 100%;
  max-width: 9.125rem;
  height: fit-content;
  background: #45a7df;
  font-size: 0.875rem;
  color: #fff;
  border: 0px;
  border-radius: 6.25rem;
  margin: 0px;
  padding: 1rem 1.25rem;
  outline: none;
  cursor: pointer;

  transition: background 0.6s ease 0s;

  &:hover {
    background: #1d52a3;
  }

  @media ${device.laptop} {
    max-width: unset;
  }
`;
