import styled from 'styled-components';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
  background: ${({ backgroundColor }) => backgroundColor?.hex};

  @media ${device.laptop} {
    padding: 0 1.25rem;
  }

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.mb70 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 3.125rem;
    }
  }

  &.mb70x20 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 4.375rem 0;
  &.MobAndDeskWazeCard {
    padding: 0;
  }
  &.SimpleContentAccordion {
    padding: 4.375rem 0 0 0;

    @media ${device.laptop} {
      padding: 1.5rem 0 0 0;
    }
  }

  &.borderTop {
    border-top: 1px solid #c6c8cc;
  }

  &.borderBottom {
    border-bottom: 1px solid #c6c8cc;
  }

  @media ${device.laptop} {
    padding: 1.25rem 0;
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1.875rem;

  &.SimpleContentAccordion {
    gap: 4.875rem;

    @media ${device.laptop} {
      gap: 0.25rem;
    }
  }

  &.SimpleContentWithGrayRectangle {
    flex-direction: column;
  }

  &.SimpleContentInColumn {
    flex-direction: column;
  }

  @media ${device.laptop} {
    flex-direction: column;
    gap: unset;
  }
`;

export const WrapTitle = styled.div`
  width: 100%;
  max-width: 20rem;

  &.MobAndDeskWazeCard {
    display: none;
  }
  &.SimpleContentInColumn {
    max-width: unset;
  }

  @media ${device.laptop} {
    max-width: unset;
  }
`;

export const Title = styled.h4`
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: normal;
  color: #231f20;
  margin: 0;
  white-space: pre-line;

  &.SimpleContentAccordion {
    display: flex;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;

    @media ${device.laptop} {
      font-size: 1.625rem;
      text-align: center;
    }
  }

  &.TitleDesk120 {
    width: 120%;

    @media ${device.laptop} {
      width: 100%;
    }
  }

  @media ${device.laptop} {
    font-size: 1.125rem;
    margin-bottom: 1.25rem;
  }
`;

export const WrapContainer = styled.div`
  width: 100%;
  max-width: 35.625rem;
  &.MobAndDeskWazeCard {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media ${device.mobile} {
      display: flex;
      flex-direction: column;
      padding-bottom: 2rem;
    }
    gap: 1.875rem;
    padding-bottom: 8.375rem;
  }
  &.SimpleContentWithGrayRectangle {
    display: flex;
    justify-content: space-between;
    gap: 1.25rem;
    max-width: unset;

    @media ${device.laptop} {
      flex-wrap: wrap;
    }
  }

  &.SimpleContentAccordion {
    border-bottom: 1px solid #c6c8cc;
  }

  &.SimpleContentInColumn {
    max-width: unset;
  }

  &.SimpleContentDownloadCards {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.875rem;
    column-gap: 1.25rem;
  }

  @media ${device.laptop} {
    max-width: unset;
  }

  &.SimpleContentDownloadCards {
    @media ${device.mobile} {
      flex-direction: column;
    }
  }

  &.CardList {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1.875rem;

    @media ${device.laptop} {
      flex-direction: column;
    }
  }
`;
