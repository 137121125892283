import React, { useEffect, useState, useRef } from 'react';

import { CustomFragment } from 'components/CmsComponents/CustomFragment';
import { formatClassName } from 'utils/formatClassName';

import {
  Container,
  Content,
  Box,
  WrapVideo,
  VideoEmbed,
  WrapImage,
  Image,
  ImageBg,
  WrapContainer,
  WrapTitle,
  Title,
  CustomTitle,
  WrapDescription,
  Description,
  WrapFragments,
  WrapSliderFragments,
} from './style';
import { Link } from 'gatsby';

function HighlightContent({
  title,
  customTitle,
  description,
  images,
  type,
  backgroundColorInitial = { hex: '#1D52A3' },
  backgroundColorFinal = { hex: '#45A7DF' },
  colorText = { hex: '#FFFF' },
  fragments,
}) {
  const [isMobile, setIsMobile] = useState(false);
  const hasMultipleImages = images?.length > 1 ?? false;
  const [activeSlider, setActiveSlider] = useState(false);
  const contentRef = useRef(null);

  const hasBgImage = formatClassName(type).includes('bgImage');
  const hasSliderFragments = formatClassName(type).includes('SliderFragments');
  const hasVideoOnLeft = formatClassName(type).includes(
    'HighlightWithVideoOnLeft'
  );

  const videoIndex = fragments?.findIndex(fragment =>
    fragment.type.includes('videoEmbed')
  );
  const filteredFragments = hasVideoOnLeft
    ? fragments.filter((_, index) => index !== videoIndex)
    : fragments;

  const contentSlider = contentRef;
  let isMouseDown = false;
  let listenerPositionX;
  let elementScrollLeft;

  const updateWindowSize = () => {
    setIsMobile(window.innerWidth < 641);
  };

  const LinkRender = ({ href, children }) => {
    const isInternalLink = href.startsWith('/');
    if (isInternalLink) {
      return <Link to={href}>{children}</Link>;
    }
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  };

  useEffect(() => {
    const hasSlider = formatClassName(type).includes('SliderFragments');

    if (hasSlider) {
      contentSlider.current.addEventListener('mousedown', listener => {
        isMouseDown = true;
        listenerPositionX = listener.pageX - contentSlider.current.offsetLeft;
        elementScrollLeft = contentSlider.current.scrollLeft;
      });
      contentSlider.current.addEventListener('mouseleave', () => {
        isMouseDown = false;
        setActiveSlider(false);
      });
      contentSlider.current.addEventListener('mouseup', () => {
        isMouseDown = false;
        setActiveSlider(false);
      });
      contentSlider.current.addEventListener('mousemove', listener => {
        if (!isMouseDown) return;
        listener.preventDefault();
        setActiveSlider(true);
        const positionX = listener.pageX - contentSlider.current.offsetLeft;
        const distance = (positionX - listenerPositionX) * 2; //change multiply value to change scroll-speed
        contentSlider.current.scrollLeft = elementScrollLeft - distance;
      });
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowSize);
      return () => window.removeEventListener('resize', updateWindowSize);
    }
  }, []);

  return (
    <Container
      bgColorInitial={backgroundColorInitial?.hex ?? '#fff'}
      bgColorFinal={backgroundColorFinal?.hex ?? '#fff'}
      className={formatClassName(type)}
    >
      <Content className={formatClassName(type)}>
        <Box>
          {hasVideoOnLeft ? (
            <WrapVideo className={formatClassName(type)}>
              <VideoEmbed escapeHtml={false} className={formatClassName(type)}>
                {fragments[videoIndex]?.markdown ?? ''}
              </VideoEmbed>
            </WrapVideo>
          ) : (
            <WrapImage className={formatClassName(type)}>
              {hasMultipleImages ? (
                hasBgImage ? (
                  <ImageBg
                    bgUrl={isMobile ? images?.[1]?.url : images?.[0]?.url}
                  />
                ) : (
                  <Image
                    src={isMobile ? images?.[1]?.url : images?.[0]?.url}
                    alt="Destaque Marketing"
                  />
                )
              ) : hasBgImage ? (
                <ImageBg bgUrl={images?.[0]?.url ?? ''} />
              ) : (
                <Image src={images?.[0]?.url} alt="Destaque Marketing" />
              )}
            </WrapImage>
          )}
          <WrapContainer>
            {(title || customTitle) && (
              <WrapTitle>
                {title ? (
                  <Title className={formatClassName(type)}>{title}</Title>
                ) : customTitle ? (
                  <CustomTitle
                    colorTxt={colorText?.hex ?? '#fff'}
                    escapeHtml={false}
                    className={formatClassName(type)}
                    renderers={{
                      link: LinkRender,
                    }}
                  >
                    {customTitle}
                  </CustomTitle>
                ) : (
                  <></>
                )}
              </WrapTitle>
            )}
            {description && (
              <WrapDescription>
                <Description
                  renderers={{
                    link: LinkRender,
                  }}
                  className={formatClassName(type)}
                  children={description}
                />
              </WrapDescription>
            )}
            {filteredFragments?.length > 0 &&
              (hasSliderFragments ? (
                <WrapSliderFragments
                  ref={contentRef}
                  activeSlider={activeSlider}
                >
                  {filteredFragments?.map(fragment => (
                    <CustomFragment
                      key={fragment.id}
                      className={formatClassName(fragment.type)}
                      children={fragment.markdown}
                      debugName={fragment.name}
                    />
                  ))}
                </WrapSliderFragments>
              ) : (
                <WrapFragments className={formatClassName(type)}>
                  {filteredFragments?.map(fragment => (
                    <CustomFragment
                      key={fragment.id}
                      className={formatClassName(fragment.type)}
                      children={fragment.markdown}
                      debugName={fragment.name}
                    />
                  ))}
                </WrapFragments>
              ))}
          </WrapContainer>
        </Box>
      </Content>
    </Container>
  );
}

export { HighlightContent };
