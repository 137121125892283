import axios from 'axios';
import { format, parseISO } from 'date-fns';

// User
export const userSend = async user => {
  const getToken = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_GATSBY_URL_GET_TOKEN}`,
    dataType: 'json',
    cache: false,
  });
  const send_lead = await axios({
    url: `${process.env.REACT_APP_GATSBY_URL_LEADS}`,
    method: 'POST',
    dataType: 'json',
    cache: false,
    headers: {
      Authorization: `Bearer ${getToken.data.access_token}`,
    },
    data: {
      FirstName: user.name,
      LastName: user.lastname,
      Email: user.email,
      MobilePhone: user.phone,
      recordTypeID: process.env.REACT_APP_RECORD_TYPE_ID,
      Pagina_do_Site__c: window?.location?.pathname,
    },
  });

  return send_lead;
};

// Premio Violeta
export const userSendVioleta = async user => {
  const date = user.appointmentDate.split('/');
  const dateFormatted = new Date(`${date[1]}/${date[0]}/${date[2]}`);
  const send_lead = await axios({
    url: 'https://x2bqb5s2nh.execute-api.us-east-1.amazonaws.com/prd/lead',
    method: 'POST',
    dataType: 'json',
    cache: false,
    headers: {
      'x-api-key': `6rz16U53zuaygk9uWRx9d39SEfM3csQH7LPGoSZq`,
    },
    data: {
      FirstName: user.name.split(' ')[0],
      LastName: user.name.split(' ')[1],
      Email: user.email,
      MobilePhone: user.phone,
      Data_Atendimento__c: format(dateFormatted, 'yyyy-MM-dd'),
      // 'Data_Atendimento__c': '2022-02-02T14:14:14.14',
      Nome_Completo_Paciente__c: user.pacientName,
      Nome_Completo_Profissional__c: user.doctorName,
      Area_Profissional__c: user.pacientArea,
      Experiencia_Profissional_Cuidado__c: user.pacientStory,
      recordTypeID: process.env.REACT_APP_RECORD_TYPE_ID,
      Tipo_Contato__c: user.formState,
      Campanhas_Site__c: 'Prêmio Violeta',
    },
  });

  return send_lead;
};

// Formulário Genérico - Salesforce
export const apiLead = axios.create({
  baseURL: process.env.GATSBY_SALESFORCE_API_URL,
  headers: {
    'x-api-key': process.env.GATSBY_SALESFORCE_API_KEY,
  },
});
// Formulário Ouvidoria - Salesforce
export const apiLeadOuvidoria = axios.create({
  baseURL: process.env.GATSBY_SALESFORCE_API_OUVIDORIA_URL,
  headers: {
    'x-api-key': `${process.env.GATSBY_SALESFORCE_API_OUVIDORIA_KEY}`,
  },
});
