import styled from 'styled-components';
import { device } from 'components/device';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100vw;
  background: ${({ backgroundColor }) => backgroundColor?.hex};
  padding: 65px 0 0;

  @media ${device.mobile} {
    padding: 65px 1.25rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 65rem;
  max-height: 68rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @media ${device.mobile} {
    max-height: initial;
  }
`;

export const Title = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #231f20;
  width: 100%;
  text-align: center;

  @media ${device.mobile} {
    font-size: 1.125rem;
  }
`;

export const Description = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #231f20;
  margin: 17px 0 40px;
  width: 100%;
  text-align: center;

  @media ${device.mobile} {
    font-size: 0.875rem;
  }
`;

export const ImageContainer = styled.div`
  text-align: center;
`;

export const ImageContent = styled.div`
  width: 210px;
  margin-bottom 40px;
`;

export const Image = styled.img`
  margin-bottom: 30px;
`;

export const TitleImage = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #231f20;
  margin: 0 0 5px;
  text-align: center;
`;

export const DescriptionImage = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  color: #231f20;
  margin: 0;
  text-align: center;
`;
