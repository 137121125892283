import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
  background: ${({ bgColorInitial, bgColorFinal }) =>
    `linear-gradient(135deg, ${bgColorInitial} 0%, ${bgColorFinal} 100%)`};

  @media ${device.laptop} {
    margin-top: 1.25rem;
  }

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.mb70 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 3.125rem;
    }
  }

  &.mb70x20 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 4.375rem 0;

  @media ${device.laptop} {
    padding: 1.25rem 0;
  }

  &.colorCartilhaLanding {
    p:nth-child(1) {
      color: #fd528f;
    }

    p:nth-child(2) {
      margin-top: 71px;
      font-weight: 500;
    }
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1.875rem;

  @media ${device.laptop} {
    align-items: center;
    flex-direction: column;
    gap: 1.25rem;
    padding: 0 1.25rem;
  }
`;

export const WrapImage = styled.div`
  width: 100%;
  max-width: 29.375rem;
  height: auto;
  min-height: 100%;

  @media ${device.laptop} {
    margin-top: -2.5rem;
  }
`;

export const ImageBg = styled.div`
  width: 100%;
  min-height: 100%;
  background-image: url(${({ bgUrl }) => bgUrl});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  @media ${device.laptop} {
    height: 18rem;
  }

  @media ${device.mobile} {
    height: 12.5rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  @media ${device.laptop} {
    object-position: center 80%;
    height: 18rem;
  }

  @media ${device.mobile} {
    height: 11.625rem;
  }
`;

export const WrapTitle = styled.div`
  width: 100%;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }
`;

export const Title = styled.h4`
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: #ffffff;
  margin: 0;

  @media ${device.laptop} {
    font-size: 1.125rem;
  }
`;

export const CustomTitle = styled(ReactMarkdown)`
  width: 100%;
  color: ${({ backgroundColorText }) => backgroundColorText?.hex};
  margin: 0;

  h1,
  h2,
  h3,
  h4 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: normal;
    margin: 0;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: normal;
  }

  strong {
    font-weight: 600;
  }

  @media ${device.laptop} {
    h1,
    h2,
    h3,
    h4 {
      font-size: 1.125rem;
    }

    p {
      font-size: 1.125rem;
    }
  }
`;

export const WrapDescription = styled.div`
  width: 100%;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }
`;

export const Description = styled(ReactMarkdown)`
  width: 100%;
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: normal;
  color: ${({ backgroundColorText }) => backgroundColorText?.hex};
  margin: 0;

  & + p {
    margin-top: 1.875rem;
  }

  @media ${device.laptop} {
    font-size: 0.875rem;
  }
`;

export const WrapContainer = styled.div`
  width: 100%;
  max-width: 35.625rem;

  @media ${device.laptop} {
    max-width: unset;
  }
`;

export const WrapForm = styled.div`
  width: 100%;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }
`;

export const WrapFootnote = styled.div`
  width: 100%;
`;

export const Footnote = styled(ReactMarkdown)`
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: normal;
  color: ${({ backgroundColorText }) => backgroundColorText?.hex};
  margin: 0;
`;
