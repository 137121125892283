import styled from 'styled-components';
import { Link } from 'gatsby';
import { device } from '../device';

export const ContainerAlert = styled.div`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  background-color: ${props => props.backgroundColor && props.backgroundColor};
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  width: 100%;
  height: auto;

  @media ${device.laptop} {
    position: fixed;
    top: 0;
  }
`;

export const MessageAlert = styled(Link)`
  font-size: 0.875rem;
  border-bottom: 1px solid #fff;
  display: flex;
  color: ${props => props.textColor && props.textColor};
`;
