import styled, { css } from 'styled-components';
import dropdownIcon from 'images/icons/arrow-down-lightblue.svg';

export const Container = styled.span`
  width: 100%;
`;

export const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  color: ${props => props.customTheme?.color && props.customTheme?.color};
  font-weight: ${props =>
    props.customTheme?.fontWeight && props.customTheme?.fontWeight};
  margin-bottom: 0.625rem;
`;

export const Select = styled.div`
  width: 100%;
  border: none;
  border-bottom: ${props =>
    props.isError ? '0.063rem solid red' : '0.063rem solid #c6c8cc'};
  margin: 0;
  padding-bottom: 0.5rem;
  position: relative;
  cursor: ${props => (!props.customTheme?.disabledArrow ? 'pointer' : 'text')};

  &::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    right: 0;
    ${props =>
      !props.customTheme.disabledArrow &&
      css`
        background-image: url(${dropdownIcon});
        background-position: right center;
        background-repeat: no-repeat;
        background-size: contain;
      `}

    transform: ${props =>
      props.rotateImg ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 0.6s;
  }
`;

export const SpanValue = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding-right: 1.5rem;

  p {
    font-size: 0.875rem;
    line-height: 1.375rem;
    ${props =>
      props.hasValue
        ? css`
            color: ${props =>
              props.customTheme?.color && props.customTheme?.color};
          `
        : css`
            color: ${props =>
              props.customTheme?.placeholderColor &&
              props.customTheme?.placeholderColor};
          `}
  }
`;

export const InputSearch = styled.input`
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${props => props.customTheme?.color && props.customTheme?.color};
  outline: none;
  cursor: ${props => (props.defaultCursor ? 'text' : 'pointer')};
  border: 0;
  padding-right: 1.5rem;

  &:focus {
    cursor: text;
  }

  &::placeholder {
    color: ${props =>
      props.customTheme?.placeholderColor &&
      props.customTheme?.placeholderColor};
    opacity: 1;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
