import styled from 'styled-components';
import { device } from 'components/device';
import ReactMarkdown from 'react-markdown';

export const DropdownContent = styled.div`
  padding: 0;
  display: flex;
  align-items: center;

  @media ${device.laptop} {
    margin-bottom: 0rem;
  }
`;

export const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  border-top: 1px solid #c6c8cc;
`;

export const DropdownBoxTitle = styled.div`
  margin-bottom: ${({ isOpen, isCertificate }) =>
    isCertificate ? '1.25rem' : isOpen && '0.25rem'};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: ${props => props.isOpen && 'center'};
  cursor: pointer;
  padding: 1.875rem 0rem;

  img {
    cursor: pointer;
  }

  @media ${device.laptop} {
    padding: 1.25rem 0;
    margin-bottom: 0;
  }
`;

export const DropdownTitle = styled.h2`
  font-weight: normal;
  font-size: 1.5rem;
  color: #231f20;
  text-align: left;
  margin: 0;

  @media ${device.laptop} {
    font-size: 1.125rem;
  }
`;

export const Title = styled.h2`
  margin-top: ${props => (props.hasTitle ? '4.375rem' : '0')};
  margin-bottom: ${props => (props.hasTitle ? '3.125rem' : '2.125rem')};
  font-weight: 400;
  font-size: 1.5rem;
  display: ${props => (props.hasTitle !== undefined ? 'block' : 'none')};

  @media ${device.laptop} {
    margin-top: ${props => (props.hasTitle ? '1.25rem' : '2.25rem')};

    font-size: 1.125rem;
  }
`;

export const Content = styled.div`
  display: ${({ statusCollapse }) => (statusCollapse ? 'flex' : 'none')};
  gap: 1.875rem;

  div {
    width: 50%;

    @media ${device.laptop} {
      width: 100%;
    }
  }
`;

export const ContentText = styled(ReactMarkdown)`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;

  ul li {
    margin-bottom: 0px;
  }

  ul > li:nth-child(4) {
    margin-left: -1.45rem;
    list-style: none;
  }
`;

export const CertificatesContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 0rem;
  }
`;
