import styled from 'styled-components';
import { device } from 'components/device';
import ReactMarkdown from 'react-markdown';

export const DropdownContent = styled.div`
  padding: 0;
  display: flex;
  align-items: center;

  @media ${device.mobile} {
    margin-bottom: 1.25rem;
  }
`;

export const DropdownContainer = styled.div`
  padding: 1.1875rem 1.3125rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  background: #f4f5f7;
  cursor: pointer;
`;

export const DropdownBoxTitle = styled.div`
  margin-bottom: ${props => props.isOpen && '1.875rem'};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: ${props => props.isOpen && 'center'};

  img {
    cursor: pointer;
  }
`;

export const DropdownTitle = styled.h2`
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
  color: #45a7df;
`;

export const Title = styled.h2`
  margin-top: ${props => (props.hasTitle ? '4.375rem' : '0')};
  margin-bottom: ${props => (props.hasTitle ? '3.125rem' : '2.125rem')};
  font-weight: 400;
  font-size: 1.5rem;

  @media ${device.laptop} {
    margin-top: ${props => (props.hasTitle ? '1.25rem' : '2.25rem')}; ;
    margin-bottom: 1.25rem;
    font-size: 1.125rem;
  }
`;

export const ContentText = styled(ReactMarkdown)`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;

  ul li {
    margin-bottom: 0px;
  }

  ul > li:nth-child(4) {
    margin-left: -1.45rem;
    list-style: none;
  }

  &.textItalicNormal {
    font-style: Normal;
  }
  &.textItalicGray {
    em {
      color:  #8F9194;
    }
  }
`;
