import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { device } from 'components/device';

export const Container = styled.div`
  border: 1px solid #c6c8cc;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => (props.fullMobileDesk ? '0' : '2rem')};
`;

export const Title = styled(ReactMarkdown)`
  margin-left: 1.25rem;
  margin-top: 1.25rem;

  em {
    font-style: normal;
  }
`;

export const Text = styled(ReactMarkdown)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #8f9194;
  width: ${props => (props.fullMobileDesk ? '13rem' : '14rem')};
  margin-left: 1.25rem;
  margin-top: 1.25rem;

  em {
    font-style: normal;
  }
`;

export const WazeIcon = styled.img`
   Display:none;
   
   @media ${device.mobile} {
    border: 1px solid #c6c8cc;
    border-radius: 100%;
    padding: 0.625rem;
    width: 2.75rem;
    margin-top: 1.25rem;
    margin-right: 1.25rem;
    display:inline;
}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ArrowRight = styled.img`
  width: 1.125rem;
  margin: 1.063rem 1.438rem 1.438rem 1.438rem;
`;
