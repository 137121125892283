import React, { useEffect, useState, useRef } from 'react';

import { CustomFragment } from 'components/CmsComponents/CustomFragment';
import { formatClassName } from 'utils/formatClassName';

import ArrowRight from 'images/icons/arrow-right.svg';

import {
  Container,
  Content,
  Box,
  WrapDescription,
  Description,
  WrapTitle,
  Title,
  WrapHeaderLink,
  HeaderLink,
  HeaderLinkExternal,
  WrapFragments,
} from './style';

function VideoGalleryContent({
  title,
  headerLinkTitle,
  headerLink,
  description,
  type,
  fragments,
}) {
  const [activeSlider, setActiveSlider] = useState(false);
  const contentRef = useRef(null);
  const isInternalLink = headerLink?.startsWith('/');

  const contentSlider = contentRef;
  let isMouseDown = false;
  let listenerPositionX;
  let elementScrollLeft;

  useEffect(() => {
    contentSlider.current.addEventListener('mousedown', listener => {
      isMouseDown = true;
      listenerPositionX = listener.pageX - contentSlider.current.offsetLeft;
      elementScrollLeft = contentSlider.current.scrollLeft;
    });
    contentSlider.current.addEventListener('mouseleave', () => {
      isMouseDown = false;
      setActiveSlider(false);
    });
    contentSlider.current.addEventListener('mouseup', () => {
      isMouseDown = false;
      setActiveSlider(false);
    });
    contentSlider.current.addEventListener('mousemove', listener => {
      if (!isMouseDown) return;
      listener.preventDefault();
      setActiveSlider(true);
      const positionX = listener.pageX - contentSlider.current.offsetLeft;
      const distance = (positionX - listenerPositionX) * 2; //change multiply value to change scroll-speed
      contentSlider.current.scrollLeft = elementScrollLeft - distance;
    });
  }, []);

  return (
    <Container className={formatClassName(type)}>
      <Content
        className={formatClassName(type)}
        hasHeaderLink={headerLinkTitle && headerLink}
      >
        <Box>
          <WrapTitle>
            <Title>{title}</Title>
            {headerLinkTitle && headerLink && isInternalLink ? (
              <WrapHeaderLink>
                <HeaderLink to={headerLink}>
                  {headerLinkTitle}{' '}
                  {fragments.lenght > 4 && (
                    <img src={ArrowRight} alt={headerLinkTitle} />
                  )}
                </HeaderLink>
              </WrapHeaderLink>
            ) : (
              <WrapHeaderLink>
                <HeaderLinkExternal
                  href={headerLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {headerLinkTitle}{' '}
                  {fragments.lenght > 4 && (
                    <img src={ArrowRight} alt={headerLinkTitle} />
                  )}
                </HeaderLinkExternal>
              </WrapHeaderLink>
            )}
          </WrapTitle>
          <WrapDescription>
            <Description children={description} />
          </WrapDescription>
          <WrapFragments ref={contentRef} activeSlider={activeSlider}>
            {fragments?.map(fragment => (
              <CustomFragment
                key={fragment.id}
                className={formatClassName(fragment.type)}
                children={fragment.markdown}
                debugName={fragment.name}
              />
            ))}
          </WrapFragments>
        </Box>
      </Content>
    </Container>
  );
}

export { VideoGalleryContent };
