import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { device } from 'components/device';

export const ContainerInternationalPatient = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2rem;
  background-color: #f4f5f7;
  z-index: 99;

  @media ${device.laptop} {
    position: fixed;
    top: 0;

    ${({ isVisible }) =>
      !isVisible &&
      css`
        display: none;
      `}
  }
`;

export const HeaderAlertContainer = styled.div`
  width: 100%;
  padding: 0.5rem 0;
`;

export const WrapHeaderAlert = styled.div`
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;

export const HeaderAlertLeftContent = styled.div`
  @media ${device.laptop} {
    width: 100%;
    padding: 0 1rem;
  }
`;

export const HeaderAlertContent = styled.p`
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: normal;
  color: #8f9194;

  a {
    color: #45a7df;
  }

  @media ${device.laptop} {
    text-align: center;
  }
`;

export const HeaderAlertRightContent = styled.div`
  display: flex;
`;

export const TopBarLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 1.875rem;
  color: #8f9194;
  font-size: 0.875rem;
  line-height: 1.5rem;

  @media ${device.laptop} {
    display: none;
  }
`;

export const TopBarLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  img {
    margin-bottom: 0.25rem;
  }
`;

export const HeaderGridArea = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  grid-area: HD;
  width: 100vw;
  display: ${props => (props.HideHeader ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  border-bottom: ${({ bgColor }) =>
    bgColor ? 'none' : '0.063rem solid #c6c8cc'};
  background: ${({ bgColor }) => bgColor || '#fff'};
  z-index: 99;

  @media ${device.laptop} {
    width: 100%;
  }
`;

export const Container = styled.header`
  margin: 0 auto;
  width: 100%;
  max-width: 1170px;
  height: 7.125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ bgColor }) => bgColor || '#fff'};

  @media ${device.laptop} {
    position: ${props => props.isMobOpen && 'fixed'};
    top: ${props => props.isMobOpen && '0'};
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: ${({ bgColor }) =>
      bgColor ? 'none' : '0.063rem solid #c6c8cc'};
    z-index: 4;
  }

  @media ${device.mobile} {
    padding: 1rem;
    height: 6rem;
    background: #fff;
  }
`;

export const ContainerMarketingLogo = styled.div`
  width: 100%;
  max-width: 10.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;

  @media ${device.laptop} {
    width: 100%;
    max-width: unset;
    padding-left: 2.75rem;
    margin-top: 0;
    display: ${props => !props.hasDefaultHeader && 'none'};
  }
`;

export const LogoHsl = styled.img`
  width: 10.625rem;
  height: 2.563rem;

  @media ${device.laptop} {
    display: ${props => (props.isOpen ? 'none' : 'flex')};
  }

  @media ${device.mobile} {
    width: 9.063rem;
    height: 2.125rem;
  }
`;

export const ButtonDropMobile = styled.button`
  background: transparent;
  border: none;
  outline: none;

  @media ${device.laptop} {
    position: absolute;
    display: ${props => !props.hasDefaultHeader && 'none'};
    left: 1.5rem;
  }

  @media ${device.tablet} {
    left: 1rem;
  }
`;

export const DropMobile = styled.img`
  display: none;

  @media ${device.laptop} {
    width: 1.5rem;
    display: flex;
    outline: none;
  }
`;

export const Overlay = styled.div`
  display: none;

  @media ${device.laptop} {
    position: fixed;
    top: 6.55rem;
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #fff;
    animation-duration: 0.6s;
    animation-name: shadeIn;
    z-index: 5;
  }

  @media ${device.mobile} {
    top: 6rem;
  }
`;

export const ContainerMenuResponsive = styled.div`
  @media ${device.laptop} {
    padding: 1.875rem 1.25rem 6.438rem;
    min-height: 100vh;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overscroll-behavior: contain;
    z-index: 4;
  }
`;

export const MenuList = styled.ul`
  width: 100%;
  margin: 0;
  list-style: none;
  font-size: 1rem;

  display: flex;
  flex-direction: column;

  > li {
    width: 100%;
    margin-bottom: 1.875rem;
    color: #45a7df;
    display: flex;
    flex-direction: column;
  }

  &.dropItems {
    display: flex;
    width: 100%;
    margin-bottom: 1.875rem;
    justify-content: space-between;
    cursor: pointer;
    color: #000;
  }

  @media ${device.laptop} {
    padding-bottom: 1rem;
  }

  @media ${device.mobile} {
    min-height: max-content;
  }
`;

export const ItemsList = styled.li`
  width: auto;
  margin-bottom: 1.875rem;
  color: #45a7df;
  display: flex;
  flex-direction: column;

  > p {
    display: flex;
    justify-content: space-between;
  }

  &.dropItems {
    width: 100%;
    margin-bottom: ${props => (props.dropSelectedItem ? '1rem' : '0')};
    cursor: pointer;
    color: #000;
  }
`;

export const MobileHighlightLinkExternal = styled.a`
  width: 100%;
  margin-bottom: 1.875rem;
  display: flex;
  flex-direction: column;

  > p {
    display: flex;
    justify-content: space-between;
  }

  &.dropItems {
    width: 100%;
    margin-bottom: 1.875rem;
    cursor: pointer;
    color: #000;
  }
`;

export const MobileHighlightLinkInternal = styled(Link)`
  width: 100%;
  margin-bottom: 1.875rem;
  display: flex;
  flex-direction: column;

  > p {
    display: flex;
    justify-content: space-between;
  }

  &.dropItems {
    width: 100%;
    margin-bottom: 1.875rem;
    cursor: pointer;
    color: #000;
  }
`;

export const ContainerNav = styled.nav`
  margin-left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media ${device.desktop} {
    width: ${props => (props.hasDefaultHeader ? '100%' : '100%')};
  }

  @media ${device.laptop} {
    display: none;
  }
`;

export const WrapperNav = styled.ul`
  width: ${({ width }) => width ?? 'unset'};
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #98a4b1;

  @media ${device.laptop} {
    display: none;
  }
`;

export const NavItem = styled.li`
  padding: 0;
  margin-bottom: 0;
  height: 100%;
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
  color: ${props => (props.selectedItem ? '#2C6EF2' : '#231F20')};
  white-space: nowrap;
  border: none;
  list-style-type: none;
  cursor: pointer;
  background: transparent;

  & + li {
    margin-left: 0.625rem;
  }

  :hover {
    color: #2c6ef2;
  }

  > img {
    margin-top: 0.125rem;
    margin-right: 0.0625rem;
  }
`;

export const Img = styled.img`
  padding-right: ${props => props.paddingRight};
  margin-left: ${props => props.search && '1.3125rem'};
  margin-right: ${props => props.search && '1.625rem'};

  width: 1.5rem;

  @media ${device.laptop} {
    margin-right: 0;
    display: ${props => props.search && !props.hasDefaultHeader && 'none'};
  }

  @media ${device.mobile} {
    margin-right: ${props => !props.search && '1.3rem'};
    min-width: ${props => !props.search && '1.5rem'};
    min-height: ${props => !props.search && '1.5rem'};

    ${props =>
      props.callCenterMob &&
      `
      margin-right: 0;  
    `};
  }
`;

export const ButtonContainer = styled.li`
  list-style-type: none;
  margin: 0;

  & + li {
    margin-left: 2rem;
  }

  & + & {
    margin-left: 0.625rem;
  }

  button {
    min-width: 130px !important;
  }

  a {
    width: 10.125rem;
    font-size: 0.875rem;
    text-align: center;
    white-space: nowrap;
    color: #fff !important;
    background-color: #45a7df;
    transition: 0.6s background;
    list-style-type: none;
    padding: 0.875rem 1.25rem;
    border: none;
    border-radius: 1.438rem;
    outline: none;

    :hover {
      background: #1d52a3;
    }
  }

  @media ${device.laptop} {
    display: none;
  }
`;

export const ContainerMenuDropdown = styled.div`
  position: absolute;
  left: 0;
  top: 9.188rem;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  box-shadow: 0px 2px 5px #0000000d;
  z-index: 5;
  animation: ${props =>
      props.animation === false ? 'none' : props.menu ? 'ShadeIn' : 'ShadeOut'}
    0.5s;
  animation-direction: alternate;

  @keyframes ShadeIn {
    to {
      opacity: 1;
    }
    from {
      opacity: 0;
    }
  }

  @keyframes ShadeOut {
    to {
      opacity: 0;
    }
    from {
      opacity: 1;
    }
  }
`;

export const WrapperMenuDropdown = styled.div`
  margin: 0 auto;
  padding: ${props => (props.menu ? '2.813rem 0' : '0')};
  width: 100%;
  max-width: 1170px;
  display: flex;

  @media ${device.laptop} {
    display: none;
  }
`;

export const WrapTextDropdown = styled.div`
  width: 100%;
  max-width: 14.25rem;
  margin-right: 1.25rem;

  @media ${device.laptop} {
    max-width: unset;
    margin-right: unset;
  }
`;

export const TextDropdown = styled.p`
  margin-top: ${props => props.isTitle && '-0.25rem'};
  margin-bottom: ${props => !props.isTitle && '1.875rem'};
  width: ${props => (props.isTitle ? '14.25rem' : 'fit-content')};
  color: ${props =>
    props.theme === 'red-link'
      ? '#EF5163  !important'
      : props.isTitle
      ? '#231F20'
      : '#8F9194'};
  white-space: ${props => (props.isTitle ? 'normal' : 'nowrap')};
`;

export const ContentMenuDropdown = styled.div`
  width: unset;
  display: flex;

  div {
    div {
      :last-child {
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  @media ${device.laptop} {
    width: 100%;
    flex-direction: column;
  }
`;

export const WrapperMenuResponsiveDropdown = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.data <= 3
      ? 'repeat(1,306px)'
      : props.data <= 5
      ? 'repeat(2,306px)'
      : 'repeat(3,306px)'};

  @media ${device.laptop} {
    display: block;
    margin-bottom: 1rem;
  }
`;

export const ItemMenuDropdown = styled.div`
  margin-right: 6rem;

  @media ${device.laptop} {
    margin-bottom: 20px;
    margin-right: 0;
  }
`;

export const DropdownContent = styled.div`
  display: none;
  z-index: 5;

  @media ${device.laptop} {
    display: flex;
    margin-left: 1.875rem;
    margin-top: 0.625rem;
    animation: ${props =>
        props.menu === undefined && props.animation !== false
          ? 'none'
          : 'ShadeOut'}
      0.5s;
  }

  @keyframes ShadeIn {
    to {
      opacity: 1;
    }
    from {
      opacity: 0;
    }
  }

  @keyframes ShadeOut {
    to {
      opacity: 0;
    }
    from {
      opacity: 1;
    }
  }
`;

export const ContainerCallCenterMob = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      font-size: 1rem;
      color: #231f20;
    }
  }
`;

export const DonationHeaderMobileLink = styled(Link)`
  display: none;

  button {
    min-width: auto;
  }
  img {
    margin-right: 0.625rem;
  }

  @media ${device.laptop} {
    display: ${({ isOpen }) => (isOpen ? 'none' : 'flex')};
  }
`;

export const SearchIconContainer = styled.div`
  margin-right: 4px;

  @media ${device.mobile} {
    margin-bottom: 46px;
    margin-left: -13px;
  }

  @media ${device.laptop} {
    margin-bottom: 46px;
    margin-left: -13px;
  }
`;
