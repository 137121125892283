import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
`;

export const Header = styled.div`
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
  }
`;

export const HeaderTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: normal;
  color: #231f20;
  margin: 0;

  @media ${device.laptop} {
    width: 65%;
    font-size: 1.125rem;
  }
`;

export const HeaderLinkContainer = styled.div`
  ${props =>
    props.headerLinkZIndex &&
    css`
      z-index: ${props.headerLinkZIndex};
    `}
`;

export const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #45a7df;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;

  @media ${device.laptop} {
    flex-direction: column;
  }
`;

export const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 23.125rem;
`;

export const PostContainerLink = styled(Link)`
  margin-bottom: 0.625rem;

  @media ${device.laptop} {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
`;

export const PostImageContainer = styled.div`
  width: 23.125rem;
  margin-bottom: 1.875rem;

  .graphcms-image-wrapper {
    height: 15.375rem;
  }

  @media ${device.laptop} {
    min-width: 7.5rem;
    max-width: 7.5rem;
    margin-bottom: 0;
    margin-right: 1.25rem;

    img,
    .graphcms-image-wrapper {
      width: 100%;
      height: 4.95rem;

      object-fit: cover;
      object-position: center;
    }
  }
`;

export const PostWithoutImageContainer = styled.div`
  width: 23.125rem;
  height: 15.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #45a7df;
  padding: 1.875rem;
  margin-bottom: 1.875rem;

  > p {
    font-size: 1.25rem;
    line-height: 1.625rem;
    color: #ffffff;
  }

  @media ${device.laptop} {
    display: none;
  }
`;

export const PostTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.625rem;
  color: #231f20;
  margin: 0;

  @media ${device.laptop} {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`;

export const PostAuthor = styled.span`
  ${props =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight};
    `}
  font-size: 0.875rem;
  color: ${props => !props.isDesignSystem && '#8f9194'};
  display: flex;

  p {
    margin-left: 0.25rem;
    display: inline;
    color: #231f20;

    span {
      display: ${props => (!props.isDesignSystem ? 'inline' : 'block')};

      a {
        color: ${props => !props.isDesignSystem && '#45a7df'};
        transition: ${props => !props.isDesignSystem && 'text-decoration 0.2s'};
      }

      a:hover {
        text-decoration: ${props => !props.isDesignSystem && 'underline'};
      }
    }

    span::after {
      content: ${props => !props.isDesignSystem && ' · '};
    }

    span:last-of-type::after {
      content: '';
    }
  }

  @media ${device.laptop} {
    display: none;
  }
`;
