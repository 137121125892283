import React, { useState } from 'react';

import * as S from './style';

import VirtualAssistent from 'images/buttons/icon-virtual-assistent.svg';

const VirtualAssistant = props => {
  const [active, setActive] = useState(false);

  const VirtualAssistantContentShow = () => {
    setActive(!active);
  };

  const LinkRender = ({ href, children }) => {

    return (
      <S.WhatsappButton href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </S.WhatsappButton>
    );
  };

  return (
    <>
      <S.Button onClick={VirtualAssistantContentShow}>
        <S.Img src={VirtualAssistent} alt="" />
      </S.Button>
      <S.DarkScreen active={active} onClick={VirtualAssistantContentShow} />
      <S.BoxAssistant active={active}>
        <S.Title>
          <S.Img src={VirtualAssistent} alt="" />
          <h2>{props.title}</h2>
        </S.Title>
        <S.Text
          children={props.children || ''}
          renderers={{
            link: LinkRender,
          }}
        />
      </S.BoxAssistant>
    </>
  );
};

export default VirtualAssistant;
