import React, { useEffect, useState } from 'react';

import { CustomFragment } from 'components/CmsComponents/CustomFragment';
import { formatClassName } from 'utils/formatClassName';

import {
  Container,
  Content,
  Box,
  WrapTitleAndImage,
  WrapTitle,
  Title,
  WrapImage,
  Image,
  WrapContainer,
  WrapSubtitle,
  Subtitle,
  WrapSpecialtyTagCloud,
  TagCloudLink,
  TagCloudLinkExternal,
} from './style';

function DoctorAndSpecialtyContent({
  type,
  title,
  subtitle,
  images,
  specialties,
  specialtiesCardios,
  fragments,
  estado,
}) {
  const [isMobile, setIsMobile] = useState(false);
  const hasMultipleImages = images?.length > 1 ?? false;

  const updateWindowSize = () => {
    setIsMobile(window.innerWidth < 641);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      updateWindowSize();
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateWindowSize);
      return () => window.removeEventListener('resize', updateWindowSize);
    }
  }, []);

  return (
    <Container className={formatClassName(type)}>
      <Content className={formatClassName(type)}>
        <Box>
          <WrapTitleAndImage>
            <WrapTitle className={formatClassName(type)}>
              <Title>{title}</Title>
            </WrapTitle>
            <WrapImage>
              {hasMultipleImages ? (
                <Image
                  src={isMobile ? images?.[1]?.url : images?.[0]?.url}
                  alt="Destaque Marketing"
                />
              ) : (
                <Image src={images?.[0]?.url} alt="Destaque Marketing" />
              )}
            </WrapImage>
          </WrapTitleAndImage>
          <WrapContainer>
            <WrapSubtitle>
              <Subtitle>{subtitle}</Subtitle>
            </WrapSubtitle>
            <WrapSpecialtyTagCloud>
              {specialtiesCardios?.map(specialty => (
                <TagCloudLink key={specialty.id} to={`/${specialty.slug}`}>
                  {specialty.name}
                </TagCloudLink>
              ))}
              {specialties?.map(specialty => {
                const hasCustomUrl = !!specialty.customUrl;

                const isInternalLink = specialty.customUrl?.startsWith('/');

                return estado ? (
                  <TagCloudLink
                    key={specialty.id}
                    to={`/especialidades-medicas/brasilia/${specialty.slug}`}
                  >
                    {specialty.name}
                  </TagCloudLink>
                ) : hasCustomUrl ? (
                  isInternalLink ? (
                    <TagCloudLink key={specialty.id} to={specialty.customUrl}>
                      {specialty.name}
                    </TagCloudLink>
                  ) : (
                    <TagCloudLinkExternal
                      key={specialty.id}
                      href={specialty.customUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {specialty.name}
                    </TagCloudLinkExternal>
                  )
                ) : (
                  <TagCloudLink
                    key={specialty.id}
                    to={`/especialidades-medicas/sao-paulo/${specialty.slug}`}
                  >
                    {specialty.name}
                  </TagCloudLink>
                );
              })}
            </WrapSpecialtyTagCloud>
            <div>
              {fragments?.map(fragment => (
                <CustomFragment
                  key={fragment.id}
                  className={formatClassName(fragment.type)}
                  children={fragment.markdown}
                  debugName={fragment.name}
                />
              ))}
            </div>
          </WrapContainer>
        </Box>
      </Content>
    </Container>
  );
}

export { DoctorAndSpecialtyContent };
