import styled from 'styled-components';
import { device } from '../../device';

export const ContainerFooter = styled.footer`
  margin-top: -2px;
  grid-area: FT;
  width: 100%;
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: center;
  background: #fff;

  @media ${device.laptop} {
    width: auto;
  }

  @media ${device.mobile} {
    min-height: max-content;
    height: max-content;
  }

  .cmp-revoke-consent {
    display: inherit;
    position: relative;
    padding-bottom: 4px;
    padding-left: inherit;
    padding-right: inherit;
    bottom: inherit;
    top: inherit;
    left: inherit;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${device.laptop} {
    padding: 0 1.25rem;
  }
`;

export const LogoHSL = styled.img`
  width: 10.75rem;
  height: 2.5rem;
  object-fit: contain;

  @media ${device.mobile} {
    width: 9.5rem;
    height: 2.1875rem;
  }
`;
