import styled from 'styled-components';
import { injectMotion } from '@jota-ds/motion-tokens/helpers.js';

export const StepsGroup = styled.div`
  display: flex;
  @media (max-width: 648px) {
    margin: 0 20px;
  }
`;

export const Step = styled.div`
  ${injectMotion('switch-slow')}
  margin-right: 0.5rem;

  ${props =>
    props.type === 'current'
      ? 'width: 1.5rem; height: 0.375rem; background-color: #2C6EF2; border-radius: 31.25rem;'
      : 'width: 0.375rem; height: 0.375rem; background: #C5D7FB; border: 1px solid #2C6EF2; border-radius: 50%;'}

  :last-of-type {
    margin: 0;
  }
`;
