import React from 'react';

import {
  Container,
  ContainerContent,
  Content,
  ImgCertificates,
  ContainerImg,
} from './style';

export function Certificates({ markdown, asset }) {
  return (
    <Container>
      <ContainerContent>
        {asset && (
          <ContainerImg>
            <ImgCertificates
              image={asset}
              className={`img-handle-${asset.handle}`}
              alt={`img-handle-${asset.handle}`}
              fadeIn={true}
              withWebp={true}
            />
          </ContainerImg>
        )}

        <Content children={markdown} escapeHtml={false} />
      </ContainerContent>
    </Container>
  );
}
