import styled from 'styled-components';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};

  @media ${device.laptop} {
    padding: 0 1.25rem;
  }

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.mb70 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 3.125rem;
    }
  }

  &.mb70x20 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 3.125rem 0;

  &.borderTop {
    border-top: 1px solid #c6c8cc;
  }

  &.borderBottom {
    border-bottom: 1px solid #c6c8cc;
  }

  @media ${device.laptop} {
    padding: 1.25rem 0;
  }
`;
