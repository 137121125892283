import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  Fragment,
  useRef,
} from 'react';
import { graphql, StaticQuery, navigate } from 'gatsby';

import { createDoctorData } from 'utils/createDoctorData';
import SelectGroup from 'components/SearchDoctor/SelectGroup';
import OurTeam from 'components/SearchDoctor/OurTeam';

import { DoctorContext } from 'context/doctor';
import { InternacionalizationContext } from 'context/internacionalization';

import DoctorImg from 'images/photos/unity/doctor.png';

import {
  Container,
  WrapHeader,
  HeaderTitle,
  HeaderImage,
  WrapContent,
  Content,
  SelectContainer,
  SearchButton,
} from './style';

import { filterDoctors, normalizeSelects } from './filters.js';
import { ComboGroup } from '../tempoComponents/ComboGroup';

function SearchDoctor({
  data,
  location,
  externalPage = false,
  textLabels = [],
  noSearch = false,
  itensPerPage = 15,
  ...props
}) {
  const {
    renderList,
    setFilters,
    setSpecialty,
    setCrm,
    setNameSearch,
    setName,
    setRenderList,
    setPracticeArea,
  } = useContext(DoctorContext);
  const { language } = useContext(InternacionalizationContext);
  const pageHref =
    typeof window !== 'undefined' &&
    window.location.href.indexOf('international-patient');

  const [filteredSpecialties, setFilteredSpecialties] = useState([]);
  const [filteredPracticeArea, setFilteredPracticeArea] = useState([]);
  const [selectedPracticeArea, setSelectedPracticeArea] = useState([]);
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [filteredDoctorsCrm, setFilteredDoctorsCrm] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [selectedDoctorCrm, setSelectedDoctorCrm] = useState('');
  const [doctorValue, setDoctorValue] = useState('');
  const [crmValue, setCrmValue] = useState('');
  const [sentByEnter, setSentByEnter] = useState(false);
  const containerRef = useRef(null);
  const hasTextLabels = textLabels?.length > 0;
  const removeSpecialCharacters = phrase => {
    return phrase
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '')
      ?.replace(/([^\w]+|\s+)/g, '')
      ?.toLowerCase();
  };
  const labels =
    pageHref > 0
      ? textLabels
      : language === 'PT'
      ? textLabels?.[0]?.singletexts
      : language === 'ES'
      ? textLabels?.[1]?.singletexts
      : [];

  const doctorsDataApi = data?.doctorsApi?.doctors ?? [];
  const specialtiesDataApi = data?.doctorsSpecialtyApi?.doctorsSpecialty ?? [];
  const practicesDataApi = data?.doctorsPracticeApi?.doctorsPractice ?? [];
  const doctorsCMS = data?.gcms?.doctors ?? [];

  const specialties = specialtiesDataApi
    ?.map(specialty => ({
      id: specialty.idSpecialty,
      name: specialty.descSpecialty,
      infos: { ...specialty },
    }))
    .sort((a, b) => (Object.values(a)[1] > Object.values(b)[1] && 1) || -1);

  const practices = practicesDataApi
    ?.map(practices => ({
      id: practices.idPracticeArea,
      name: practices.descPracticeArea,
      infos: { ...practices },
    }))
    .sort((a, b) => (Object.values(a)[1] > Object.values(b)[1] && 1) || -1);

  const formattedDoctorsCMS = doctorsCMS
    ?.map(doctor =>
      createDoctorData({
        doctorCMS: doctor,
        doctorsAPI: doctorsDataApi,
      })
    )
    ?.filter(e => e);

  const doctors = doctorsDataApi
    ?.map(doctor => {
      let formattedDoctor = {};

      const foundDoctorCMS = formattedDoctorsCMS?.find(
        doctorCMS => doctorCMS.id_api === doctor.id
      );

      if (foundDoctorCMS) {
        formattedDoctor = { ...foundDoctorCMS, ...doctor };
      }

      const specialtiesId = doctor.specialty
        ?.split(',')
        ?.map(
          item =>
            specialties?.find(
              specialty => specialty.name.trim() === item.trim()
            )?.id ?? ''
        );
      const practicesId = doctor.practiceArea
        ?.split(',')
        ?.map(
          item =>
            practices?.find(practices => practices.name.trim() === item.trim())
              ?.id ?? ''
        );

      return {
        id: doctor.id,
        name: doctor.name,
        infos: { ...doctor, ...formattedDoctor, specialtiesId, practicesId },
      };
    })
    .sort((a, b) => (Object.values(a)[1] < Object.values(b)[1] && 1) || -1);

  const doctorsCrm = doctorsDataApi
    ?.map(doctor => {
      const specialtiesId = doctor.specialty
        ?.split(',')
        ?.map(
          item =>
            specialties?.find(
              specialty => specialty.name.trim() === item.trim()
            )?.id ?? ''
        );

      return {
        id: doctor.crm,
        name: `${doctor.crm} - ${doctor.name}`,
        infos: { ...doctor, specialtiesId },
      };
    })
    .sort((a, b) => (Object.values(a)[1] < Object.values(b)[1] && 1) || -1);

  let filters = {
    specialtiesId: selectedSpecialty ? [selectedSpecialty].flat() : [],
    practicesId: selectedPracticeArea ? [selectedPracticeArea].flat() : [],
  };
  function handleFilters() {
    let newArray = filterDoctors(doctors, filters);
    setFilteredDoctors(newArray);
    let normalizePracticesId = normalizeSelects(newArray).practicesId;
    let normalizePractices = practices.filter(item =>
      normalizePracticesId.includes(item.id)
    );
    let normalizeSpecialtiesId = normalizeSelects(newArray).specialtiesId;
    let normalizeSpecialties = specialties.filter(item =>
      normalizeSpecialtiesId.includes(item.id)
    );
    let normalizeSelectSpecialty = selectedSpecialty?.length
      ? normalizeSpecialties.filter(item =>
          selectedSpecialty?.includes(item.id)
        )
      : normalizeSpecialties;
    let normalizeSelectPracticeArea = selectedPracticeArea?.length
      ? normalizePractices.filter(item =>
          selectedPracticeArea?.includes(item.id)
        )
      : normalizePractices;

    setFilteredSpecialties(normalizeSelectSpecialty);
    setFilteredPracticeArea(normalizeSelectPracticeArea);
  }

  const clearSelects = () => {
    setFilteredSpecialties(specialties);
    setFilteredDoctors(
      doctors.filter(filteredDoctor => {
        return !filteredDoctor.infos?.crm?.includes('-');
      })
    );
    setFilteredDoctorsCrm(
      doctorsCrm.filter(filteredDoctor => {
        return !filteredDoctor.infos?.crm?.includes('-');
      })
    );
    setFilteredPracticeArea(practices);
    setSelectedSpecialty('');
    setSelectedPracticeArea('');
    setSelectedDoctor('');
    setSelectedDoctorCrm('');
    setDoctorValue('');
    setCrmValue('');
    !externalPage && setRenderList('noRender');
  };

  const handleSpecialty = option => {
    if (Array.isArray(option)) {
      return;
    }

    const foundSpecialty = specialties.find(
      specialty => specialty.id === option
    );

    if (!foundSpecialty) {
      clearSelects();
      return;
    }

    clearSelects();
    const check = [...selectedSpecialty];
    const index = check.indexOf(foundSpecialty.id);
    if (index > -1) {
      check.splice(index, 1);
    } else {
      check.push(foundSpecialty.id);
    }
    setSelectedSpecialty(check);
  };
  const handlePracticeArea = useCallback(
    option => {
      if (Array.isArray(option)) {
        return;
      }

      const foundPractice = practices.find(practice => practice.id === option);

      if (!foundPractice) {
        clearSelects();
        return;
      }

      const check = [...selectedPracticeArea];
      const index = check.indexOf(foundPractice.id);
      if (index > -1) {
        check.splice(index, 1);
      } else {
        check.push(foundPractice.id);
      }
      setSelectedPracticeArea(check);
    },
    [doctors, doctorsCrm, practices]
  );

  const handleDoctor = useCallback(
    (value, type, sendByEnter = false) => {
      let foundDoctor = undefined;

      if (type === 'doctor') {
        foundDoctor = doctors.find(doctor => doctor.id === value);
      } else if (type === 'crm') {
        foundDoctor = doctors.find(doctor => doctor.infos.crm === value);
      }

      if (!foundDoctor || sendByEnter) {
        sendByEnter && setSentByEnter(true);
        handleSubmit(value, type);

        return;
      }

      setSentByEnter(false);

      setSelectedDoctor(foundDoctor.infos?.id ?? '');
      setSelectedDoctorCrm(foundDoctor.infos?.crm ?? '');
    },
    [specialties, doctors]
  );

  const handleSubmit = (value, type) => {
    const handledSpecialty = selectedSpecialty;
    const handledPracticeArea = selectedPracticeArea;

    const handledDoctor = type === 'doctor' ? value : doctorValue;
    const handledCrm = type === 'crm' ? value : crmValue || selectedDoctorCrm;

    if (externalPage) {
      navigate('/encontre-seu-medico/', {
        state: {
          specialty: handledSpecialty,
          practiceArea: handledPracticeArea,
          doctor: handledDoctor,
          crm: handledCrm,
        },
      });
    }

    setRenderList('noRender');

    setSelectedDoctor(handledDoctor);
    setSelectedDoctorCrm(handledCrm);

    let newDoctors = filterDoctors(doctors, filters);

    let submitFilteredDoctors =
      handledSpecialty || handledDoctor || handledCrm ? newDoctors : [];

    if (handledDoctor) {
      submitFilteredDoctors = submitFilteredDoctors?.filter(
        doctor =>
          doctor.id === handledDoctor ||
          removeSpecialCharacters(doctor.name)?.includes(
            removeSpecialCharacters(handledDoctor)
          )
      );
    }

    if (handledCrm) {
      submitFilteredDoctors = submitFilteredDoctors?.filter(doctor =>
        doctor.infos?.crm?.includes(handledCrm)
      );
    }

    const formattedSubmitFilteredDoctors = submitFilteredDoctors
      ?.map(filteredDoctor => ({
        ...filteredDoctor.infos,
      }))
      .sort(
        (doctorA, doctorB) => (doctorA.employee < doctorB.employee && 1) || -1
      );

    const searchSpecialty = specialties
      ?.filter(specialty =>
        [handledSpecialty].flat(Infinity)?.find(item => item === specialty?.id)
      )
      .map(specialty => specialty.name)
      .join(', ');

    const searchPractice = practices
      ?.filter(practice =>
        [handledPracticeArea]
          .flat(Infinity)
          ?.find(item => item === practice?.id)
      )
      .map(practice => practice.name)
      .join(', ');

    const searchDoctor =
      doctors?.find(doctor => doctor?.id === handledDoctor)?.name ??
      handledDoctor ??
      '';

    setFilters(formattedSubmitFilteredDoctors);

    setSpecialty(searchSpecialty);
    setPracticeArea(searchPractice);
    setNameSearch(searchDoctor);
    setName(searchDoctor);
    setCrm(handledCrm);

    !externalPage && setRenderList('search');
  };

  const handleOurTeamClosedButton = useCallback(() => {
    setRenderList('noRender');
    clearSelects();

    window.scrollTo(0, 0);
  }, [renderList]);

  useEffect(() => {
    setFilteredSpecialties(specialties);
    setFilteredDoctors(
      doctors.filter(filteredDoctor => {
        return !filteredDoctor.infos?.crm?.includes('-');
      })
    );
    setFilteredDoctorsCrm(
      doctorsCrm.filter(filteredDoctor => {
        return !filteredDoctor.infos?.crm?.includes('-');
      })
    );
    setFilteredPracticeArea(practices);
  }, []);

  useEffect(() => {
    if (!externalPage) {
      setSelectedSpecialty(location?.state?.specialty ?? []);
      setSelectedDoctor(location?.state?.doctor);
      setSelectedDoctorCrm(location?.state?.crm);
      setDoctorValue(location?.state?.doctor);
      setCrmValue(location?.state?.crm);

      if (
        location?.state?.specialty ||
        location?.state?.doctor ||
        location?.state?.crm
      ) {
        setRenderList('search');
      }
    }
  }, [location, externalPage]);
  return (
    <Container ref={containerRef}>
      {!noSearch && (
        <WrapContent>
          <WrapHeader externalPage={externalPage}>
            <HeaderTitle>
              {hasTextLabels ? labels?.[0] ?? '' : 'Encontre seu Médico'}
            </HeaderTitle>
            <HeaderImage src={DoctorImg} alt="Doctor" />
          </WrapHeader>

          <Content>
            <SelectContainer>
              <ComboGroup
                label={hasTextLabels ? labels?.[1] : 'Especialidade'}
                listItems={filteredSpecialties}
                selectedChecked={
                  selectedSpecialty ? [selectedSpecialty].flat() : []
                }
                onSelectOption={handleSpecialty}
                handleFilters={handleFilters}
              />
            </SelectContainer>
            <SelectContainer>
              <ComboGroup
                label={hasTextLabels ? labels?.[8] : 'Área de atuação'}
                listItems={filteredPracticeArea}
                selectedChecked={
                  selectedPracticeArea ? [selectedPracticeArea].flat() : []
                }
                onSelectOption={handlePracticeArea}
                handleFilters={handleFilters}
              />
            </SelectContainer>
            <SelectContainer>
              <SelectGroup
                label={hasTextLabels ? labels?.[3] : 'Nome do médico'}
                placeholder={
                  hasTextLabels ? labels?.[4] : 'Digite o nome do médico'
                }
                data={filteredDoctors}
                enableInputSearch
                enableSearchWithEnter
                inputValue={e => setDoctorValue(e)}
                onSelectOption={e =>
                  handleDoctor(e.value ?? e, 'doctor', e.sendByEnter ?? false)
                }
                defaultValue={selectedDoctor ?? ''}
                theme={{
                  select: {
                    disabledArrow: true,
                  },
                  label: {
                    fontWeight: 700,
                  },
                  dropdown: {
                    marginTop: 0,
                    borderTop: 0,
                    borderRadius: '0 0 0.5rem 0.5rem',
                    containerPadding: '0.5rem 0',
                    itemPadding: '0.5rem 1rem 0.5rem 0.5rem',
                  },
                }}
              />
            </SelectContainer>
            <SelectContainer>
              <SelectGroup
                label={hasTextLabels ? labels?.[5] : 'Número do registro'}
                placeholder={
                  hasTextLabels ? labels?.[6] : 'Digite o número do CRM'
                }
                data={filteredDoctorsCrm}
                enableInputSearch
                enableSearchWithEnter
                preventOpenDropdownEmptySearch
                inputType="number"
                inputValue={e => setCrmValue(e)}
                onSelectOption={e =>
                  handleDoctor(e.value ?? e, 'crm', e.sendByEnter ?? false)
                }
                defaultValue={selectedDoctorCrm ?? ''}
                disableAutoSelectOption={sentByEnter}
                theme={{
                  select: {
                    disabledArrow: true,
                  },
                  label: {
                    fontWeight: 700,
                  },
                  dropdown: {
                    marginTop: 0,
                    borderTop: 0,
                    borderRadius: '0 0 0.5rem 0.5rem',
                    containerPadding: '0.5rem 0',
                    itemPadding: '0.5rem 1rem 0.5rem 0.5rem',
                  },
                }}
              />
            </SelectContainer>

            <SearchButton
              onClick={() => {
                handleFilters();
                handleSubmit();
              }}
            >
              {hasTextLabels ? labels?.[7] : 'Buscar médicos'}
            </SearchButton>
          </Content>
        </WrapContent>
      )}
      {renderList === 'noRender' && window.innerWidth > 1024 && <Fragment />}
      {!externalPage && renderList === 'search' && (
        <OurTeam
          doctors={data.gcms.doctors}
          itensPerPage={15}
          location={location}
          closedButton={handleOurTeamClosedButton}
          modalHeader={true}
        />
      )}
      {noSearch && (
        <OurTeam
          doctors={data.gcms.doctors}
          itensPerPage={itensPerPage}
          location={location}
          closedButton={handleOurTeamClosedButton}
          modalHeader={true}
          noSearch={noSearch}
          {...props}
        />
      )}
    </Container>
  );
}

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          doctorsApi {
            doctors {
              id
              name
              gender
              crm
              email
              phone
              phonecommercial
              specialty
              employee
              estab
              state
              practiceArea
            }
          }
          doctorsSpecialtyApi {
            doctorsSpecialty {
              descSpecialty
              idSpecialty
            }
          }
          doctorsPracticeApi {
            doctorsPractice {
              descPracticeArea
              idPracticeArea
            }
          }
          gcms {
            doctors(locales: [pt_BR, es, en], first: 1000) {
              id
              id_api
              active
              assignment
              name
              crm
              photo {
                handle
                url
                height
                width
              }
              phonecommercial
              state
              employee
              specialties {
                id
                name
              }
            }
          }
        }
      `}
      render={data => <SearchDoctor data={data} {...props} />}
    />
  );
};
