import marked from 'marked';

export function faqFormatter(markdown) {
  const markdowns = markdown.split('##');
  let faqAux = [];
  markdowns.forEach(el => {
    const titleArr = el
      .split(/\?([\n|\n\r]|\s*[\n|\n\r])/gi)
      .filter(e => e.trim() !== '');
    if (titleArr.length > 1) {
      faqAux.push({ question: titleArr[0] + '?', answer: marked(titleArr[1]) });
    }
  });
  let faqSchema = {};
  const FAQ = faqAux.map(faq => {
    return {
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer,
      },
    };
  });
  faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [FAQ],
  };

  return faqSchema;
}
