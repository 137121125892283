import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
  white-space: pre-line;

  @media ${device.laptop} {
    padding: 0 1.25rem;
    margin-top: 3.375rem;
  }

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.mb70 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 3.125rem;
    }
  }

  &.mb70x20 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 7rem 0 8.43rem 0;

  @media ${device.mobile} {
    padding: 3rem 0 5.125rem 0;
  }

  &.borderBottom {
    border-bottom: 1px solid #c6c8cc;
  }

  @media ${device.laptop} {
    margin-top: 1.875rem;
    padding: 1.25rem 0;
    // display: flex;
    // flex-direction: column;
  }
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
  max-height: 33rem;

  @media ${device.laptop} {
    height: auto;
    flex-direction: column;
    max-height: none;
  }
`;

export const ContainerText = styled.div`
  margin-right: 126px;

  @media ${device.laptop} {
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Title = styled.h1`
  width: 448px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #231f20;
  margin-bottom: 37px;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.mobile} {
    font-size: 1.125rem;
  }
`;

export const Description = styled(ReactMarkdown)`
  width: 417px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 38px;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.mobile} {
    font-size: 0.875rem;
  }
`;

export const ImgContainer = styled.div`
  @media ${device.laptop} {
    margin: 24px 0 46.5px;
    display: flex;
    flex-direction: column;
  }
`;

export const ImgLeft = styled.img`
  margin-right: 27px;

  @media ${device.laptop} {
    margin: 0 0 24px 0;
  }
`;

export const ImgRight = styled.img``;

export const ImgCenter = styled.img`
  margin-right: 47px;

  @media ${device.laptop} {
    margin: 0;
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;

  button {
    min-width: 290px;
  }

  @media ${device.laptop} {
    margin-top 47px;
  }
`;
