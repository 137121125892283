import styled from 'styled-components';
import { device } from 'components/device';

export const WrapperBreakingNews = styled.div`
  width: 100%;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerBreakingNews = styled.div`
  width: 100%;
  max-width: 73.125rem;
  margin-bottom: 2rem;
  display: ${props => (props.displayBreakingNews ? 'none' : '')};

  @media ${device.mobile} {
    margin-top: 5.5rem;
    padding: 0px 1.25rem;
    margin-bottom: 0rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 425px;

  @media ${device.mobile} {
    height: 716px;
  }
`;

export const StepsContainer = styled.div`
  justify-content: center;
  position: absolute;
  bottom: 32px;
  z-index: 1;
  width: 100%;
  max-width: 1005px;
  display: ${props => (props.displayBanner ? 'none' : 'flex')};
  @media (max-width: 648px) {
    bottom: 32px;
  }
`;

export const Container = styled.div`
  display: flex;
  touch-action: none;
  transform: ${({ currentBanner, bannerWidth }) =>
    `translateX(-${currentBanner * bannerWidth}px)`};
  width: 100%;
  max-width: calc(100vw * ${props => props.bannersLength});
  transition: transform 600ms cubic-bezier(0.25, 1, 0.35, 1);

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 648px) {
    touch-action: auto;
  }
`;

export const BannerItem = styled.div`
  flex-shrink: 0;
  width: 100vw;
  height: 425px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};

  @media ${device.mobile} {
    height: 716px;
  }
`;

export const BannerContent = styled.div`
  width: 100%;
  max-width: ${props => (props.fullImage ? '100%' : '1121px')};
  height: 100%;
  display: ${props => (props.fullImage ? '' : 'flex')};
  align-items: center;
  justify-content: space-between;

  @media ${device.mobile} {
    height: ${props => (props.fullImage ? '96%' : '100%')};
    flex-direction: column;
    padding: ${props => (props.fullImage ? '' : '1rem 1.25rem')};
    justify-content: center;
  }
`;

export const BannerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 537px;

  a {
    margin: 2.5rem 0 0;
  }

  @media ${device.mobile} {
    a {
      margin: 1rem 0 0;
    }
  }
`;

export const BannerTitle = styled.h2`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 3.375rem;
  line-height: 111%;
  color: #231f20;
  margin: 0;

  @media ${device.mobile} {
    font-size: 1.5rem;
    margin: 1rem 0 0;
  }
`;

export const BannerSubtitle = styled.h3`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 138%;
  color: #000000;
  margin: 2.5rem 0 0;

  @media ${device.mobile} {
    margin: 1rem 0 0;
  }
`;

export const BannerButton = styled.button`
  width: max-content;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 100px;
  background-color: ${props =>
    props.buttonColor ? props.buttonColor : '#2C6EF2'};
  color: #fff;
  padding: 1rem 1.25rem;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.48, 0, 0.48, 1);

  > img {
    margin-left: 0.625rem;
  }

  @media ${device.mobile} {
    width: 100%;
    justify-content: center;
  }
`;

export const BannerImage = styled.img`
  width: ${props => (props.fullImage ? '100%' : '')};
  height: ${props => (props.fullImage ? '100%' : '517px')};
  object-fit: cover;

  @media ${device.mobile} {
    height: ${props => (props.fullImage ? '' : '303px')};
    order: -1;
  }
`;

export const ArrowsContainer = styled.div`
  justify-content: space-between;
  position: absolute;
  width: 100%;
  max-width: 1368px;
  z-index: 1;
  top: 50%;
  display: ${props => (props.displayBreakingNews ? 'none' : 'flex')};

  @media ${device.mobile} {
    display: none;
  }
`;

export const Arrow = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: #f4f5f7;
  border-radius: 1000px;
  border: none;
`;

export const ArrowImage = styled.img`
  width: 1rem;
  height: 1rem;
`;
