import styled from 'styled-components';
import { Link } from 'gatsby';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;

  @media ${device.laptop} {
    padding: 0 1.25rem;
  }

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.mb70 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 3.125rem;
    }
  }

  &.mb70x20 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 4.375rem 0;

  &.borderTop {
    border-top: 1px solid #c6c8cc;
  }

  &.borderBottom {
    border-bottom: 1px solid #c6c8cc;
  }

  @media ${device.laptop} {
    padding: 1.25rem 0;
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1.875rem;

  @media ${device.laptop} {
    flex-direction: column;
  }
`;

export const WrapTitleAndImage = styled.div``;

export const WrapTitle = styled.div`
  width: 100%;
  max-width: 90%;
  margin-bottom: 4.75rem;

  @media ${device.laptop} {
    max-width: unset;
    margin-bottom: unset;
  }

  &.WrapTitleWidthFull {
    max-width: 100%;
  }
`;

export const Title = styled.h4`
  width: 100%;
  font-size: 2.25rem;
  line-height: 2.875rem;
  font-weight: 600;
  color: #231f20;
  margin: 0;

  @media ${device.laptop} {
    font-size: 1.625rem;
    line-height: 2.25rem;
  }
`;

export const WrapImage = styled.div`
  width: 100%;
  max-width: 16.875rem;
  height: auto;

  @media ${device.laptop} {
    display: none;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const WrapContainer = styled.div`
  width: 100%;
  max-width: 35.625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.laptop} {
    max-width: unset;
  }
`;

export const WrapSubtitle = styled.div`
  width: 100%;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
  }
`;

export const Subtitle = styled.h6`
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: normal;
  color: #8f9194;
  margin: 0;

  @media ${device.laptop} {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;

export const WrapSpecialtyTagCloud = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.25rem;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    gap: 0.625rem;
    margin-bottom: 1.25rem;
  }
`;

export const TagCloudLink = styled(Link)`
  display: inline-block;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #ffffff;
  padding: 0.938rem 1.25rem;
  background: #45a7df;
  border-radius: 6.25rem;

  @media ${device.laptop} {
    font-size: 0.875rem;
  }
`;

export const TagCloudLinkExternal = styled.a`
  display: inline-block;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #ffffff;
  padding: 0.938rem 1.25rem;
  background: #45a7df;
  border-radius: 6.25rem;

  @media ${device.laptop} {
    font-size: 0.875rem;
  }
`;
