import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';

import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;

  &.mb20 {
    margin-bottom: 1.25rem;
  }

  &.mb30 {
    margin-bottom: 1.875rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.mb50 {
    margin-bottom: 3.125rem;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }
  }

  &.mb70 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 3.125rem;
    }
  }

  &.mb70x20 {
    margin-bottom: 4.375rem;

    @media ${device.laptop} {
      margin-bottom: 1.25rem;
    }
  }

  &.LandingTextoVideo {
    h4 {
      font-weight: 700;
      font-size: 2rem;
      width: 566px;
      line-height: 39.68px;
      color: #fd528f;
      margin-bottom: -2rem;
    }

    p {
      width: 566px;

      :nth-child(1) {
        width: 566px;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 32px;
      }

      :nth-child(3) {
        margin-top: 16px;
      }
    }

    iframe {
      width: 614px;
      height: 348px;
      margin-left: 37rem;
      margin-top: -24.5rem;
      position: absolute;
      max-width: 48%;
    }

    @media ${device.laptop} {
      h4 {
        width: 50%;
        margin-left: 0rem;
        margin-bottom: 6px;
      }

      p {
        width: 50%;
        margin-left: 0rem;
      }

      iframe {
        width: 45%;
        height: 75%;
        margin-left: 32rem;
        margin-top: -17rem;
        position: absolute;
        max-width: 100%;
      }
    }

    @media ${device.tablet} {
      h4 {
        width: 100%;
        margin-left: 0rem;
        margin-bottom: 6px;
      }

      p {
        width: 100%;
        margin-left: 0rem;

        :nth-child(1) {
          width: 100%;
          font-size: 1.2rem;
        }
      }

      iframe {
        width: 321px;
        height: 200px;
        margin-left: 0rem;
        margin-top: 0rem;
        position: inherit;
      }
    }

    @media ${device.mobile} {
      h4 {
        width: 100%;
      }

      p {
        width: 100%;
        font-size: 0.875rem;
      }

      iframe {
        width: 100%;
        height: 100%;
        margin-left: 0rem;
        margin-top: 0rem;
        position: relative;
      }
    }
  }

  &.LandingVideoTexto {
    margin-bottom: 33px;

    h4 {
      font-weight: 700;
      font-size: 2rem;
      width: 530px;
      margin-left: 39rem;
      color: #fd528f;
      margin-bottom: -2rem;
    }

    p {
      width: 530px;
      margin-left: 39rem;

      :nth-child(1) {
        width: 530px;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 32px;
      }

      :nth-child(3) {
        margin-top: 20px;
      }
    }

    iframe {
      width: 614px;
      height: 348px;
      margin-top: -22rem;
      position: absolute;
      max-width: 48%;
    }

    @media ${device.laptop} {
      h4 {
        width: 50%;
        margin-left: 31rem;
        margin-bottom: 6px;
      }

      p {
        width: 50%;
        margin-left: 31rem;

        :nth-child(1) {
          width: 50%;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 32px;
        }
      }

      iframe {
        width: 45%;
        height: 75%;
        margin-left: 0rem;
        margin-top: -20rem;
        position: absolute;
        max-width: 100%;
      }
    }

    @media ${device.tablet} {
      h4 {
        width: 100%;
        margin-left: 0rem;
        margin-bottom: 6px;
      }

      p {
        width: 100%;
        margin-left: 0rem;
      }

      iframe {
        width: 321px;
        height: 200px;
        margin-left: 0rem;
        margin-top: 0rem;
        position: inherit;
      }
    }

    @media ${device.mobile} {
      margin-bottom: 30px;
      h4 {
        width: 100%;
      }

      p {
        width: 100%;
        font-size: 0.875rem;

        :nth-child(1) {
          width: 100%;
          font-size: 1.2rem;
        }
      }

      iframe {
        width: 100%;
        height: 100%;
        margin-left: 0rem;
        margin-top: 0rem;
        position: relative;
      }
    }
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 73.125rem;
  margin: 0 auto;
  padding: 4.375rem 0;

  &.borderBottom {
    border-bottom: 1px solid #c6c8cc;
  }

  &.borderTop {
    border-top: 1px solid #c6c8cc;
  }

  @media ${device.laptop} {
    padding: 1.25rem;
  }

  @media ${device.mobile} {
    ${props =>
      props.hasHeaderLink &&
      css`
        position: relative;
        padding-bottom: 3.5rem;
      `}
  }
`;

export const Box = styled.div`
  width: 100%;
`;

export const WrapTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }
`;

export const Title = styled.h4`
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: normal;
  margin-bottom: 0;

  @media ${device.laptop} {
    font-size: 1.125rem;
    line-height: 1.625rem;
    width: 72%;
  }
`;

export const WrapHeaderLink = styled.div`
  @media ${device.mobile} {
    width: 7rem;
    display: flex;
    justify-content: flex-end;
  }

  img:not([alt]) {
    filter: none;
  }
`;

export const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #45a7df;
`;

export const HeaderLinkExternal = styled.a`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #45a7df;
`;

export const WrapDescription = styled.div`
  width: 100%;
`;

export const Description = styled(ReactMarkdown)`
  width: 100%;
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: normal;
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }
`;

export const WrapFragments = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;

  @media ${device.laptop} {
    width: 100vw;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin: 0 -20px;
    padding: 0 20px;
    gap: 1.25rem;

    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    ${props =>
      props.activeSlider &&
      css`
        cursor: grabbing !important;
        user-select: none;

        a,
        iframe,
        video {
          pointer-events: none;
        }
      `}
  }
`;
