// Libs
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import { device } from 'components/device';

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  &:first-of-type {
    border-top: ${props => (props.noBorderTop ? 'unset' : '1px solid #c6c8cc')};
    border-bottom: 1px solid #c6c8cc;
  }
  border-bottom: ${props =>
    props.noBorderBottomLastElement ? 'inherit' : '1px solid #c6c8cc'};
`;

export const Header = styled.button`
  background: transparent;
  cursor: pointer;
  padding: 1.875rem 0;
  display: flex;
  align-items: center;
  border: 0;

  @media ${device.tablet} {
    padding: 1.25rem 0;
  }

  img {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: auto;
  }
`;

export const ContainerChildren = styled.div`
  padding: ${props =>
    props.isQualityAndHealth ? '0rem 0 1.875rem 0' : ' 1.25rem 0 1.875rem 0'};

  @media ${device.tablet} {
    padding: 0.625rem 0 0;
  }
`;

export const HeaderTitle = styled(ReactMarkdown)`
  h2 {
    font-weight: normal;
    font-size: 1.5rem;
    color: #231f20;
    text-align: left;
    margin: 0;

    @media ${device.tablet} {
      font-size: 1.125rem;
    }

    @media ${device.mobile320} {
      font-size: ${props =>
        props.theme.fontSize320 ? props.theme.fontSize320 : '0.875rem'};
    }
  }
`;

export const Content = styled.div`
  max-height: ${props => props.maxHeight};
  background-color: white;
  overflow: hidden;
  transition: max-height 0.5s ease;
`;

export const ContentText = styled(ReactMarkdown)`
  font-size: 1rem;
  line-height: 1.625rem;
  color: ${props =>
    props.colorText
      ? ''
      : props.theme.textColor
      ? props.theme.textColor
      : props.isFormatted
      ? '#231F20'
      : '#8f9194'};
  padding: ${props =>
    props.isPaddingAccordion ? '1.25rem 0 1.875rem' : '1.25rem 0 3.125rem'};
  margin-bottom: ${props =>
    props.contentTextMarginBottom
      ? props.contentTextMarginBottom
      : props.DEFAULT};

  @media ${device.mobile} {
    margin-bottom: 0;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: normal;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  span:first-of-type {
    display: ${props => props.isEnsinoEPesquisa && 'flex'};
    justify-content: ${props => props.isEnsinoEPesquisa && 'center'};
    align-items: ${props => props.isEnsinoEPesquisa && 'center'};
    margin-top: ${props => props.isEnsinoEPesquisa && '1.875rem'};
  }
  p:nth-child(6) {
    margin-top: ${props => props.isEnsinoEPesquisaAndNossaEquipe && '1.875rem'};
  }

  p + p {
    margin-top: 1rem;

    :nth-child(7) {
      display: ${props =>
        props.isDisplay
          ? props.isDisplay
          : props.isEnsinoEPesquisaAndNossaEquipe && 'flex'};
      margin-top: ${props =>
        props.isEnsinoEPesquisaAndNossaEquipe && '1.875rem'};

      @media ${device.mobile} {
        margin-top: ${props =>
          props.isEnsinoEPesquisaAndNossaEquipe && '1.25rem'};
      }

      img {
        margin-right: ${props =>
          props.isEnsinoEPesquisaAndNossaEquipe && '0.625rem'};
        transform: ${props =>
          props.isEnsinoEPesquisaAndNossaEquipe && 'translate(0,0.625rem)'};
        transform: ${props => props.infoNumbers && 'translate(0,1.3rem)'};
      }

      a {
        transform: ${props =>
          props.isEnsinoEPesquisaAndNossaEquipe &&
          'translate(-6.125rem, 1.25rem)'};

        margin-left: ${props => props.infoNumbers && '35px'};

        @media ${device.mobile} {
          transform: ${props =>
            props.isEnsinoEPesquisaAndNossaEquipe &&
            'translate(-5.3125rem, 1.25rem)'};
        }
      }
      a + a {
        margin-left: 0;
      }
    }

    :nth-child(8) {
      padding-top: ${props =>
        props.isEnsinoEPesquisaAndNossaEquipe && '1.25rem'};
    }

    :nth-child(14) {
      display: ${props => props.isEnsinoEPesquisa && 'flex'};

      img {
        margin-right: ${props => props.isEnsinoEPesquisa && '0.625rem'};
      }
    }

    :nth-child(15) {
      display: ${props => props.isEnsinoEPesquisa && 'flex'};

      img {
        margin-right: ${props => props.isEnsinoEPesquisa && '0.625rem'};
      }

      a {
        position: ${props => props.isEnsinoEPesquisa && 'relative'};
        top: ${props => props.isEnsinoEPesquisa && '1.25rem'};
        right: ${props => props.isEnsinoEPesquisa && '6.25rem'};
        margin-bottom: ${props => props.isEnsinoEPesquisa && '1.25rem'};

        @media ${device.mobile} {
          right: ${props => props.isEnsinoEPesquisa && '5.3125rem'};
        }
        @media ${device.tablet} {
          right: ${props => props.isEnsinoEPesquisa && '5.25rem'};
        }
      }
    }

    :nth-child(16) {
      width: ${props => props.isEnsinoEPesquisa && '100%'};
      max-width: ${props => props.isEnsinoEPesquisa && '20.9375rem'};
    }
  }

  ul {
    margin-top: ${({ marginTopUl }) => marginTopUl};
    li {
      margin-bottom: 1rem;

      &::marker {
        color: ${props => props.bulletColor && props.bulletColor};
      }
    }
  }

  a {
    color: #45a7df;
  }

  span {
    display: ${({ spanDisplay }) => (spanDisplay ? spanDisplay : 'flex')};
    justify-content: center;

    :nth-of-type(1) {
      margin-top: ${props => props.isAdjustAccordionMargin && '1.875rem'};

      @media ${device.mobile} {
        margin-top: ${props => props.isAdjustAccordionMargin && '1.25rem'};
      }
    }
    :nth-of-type(2) {
      margin-top: ${props => props.isAdjustAccordionMargin && '1.875rem'};

      @media ${device.mobile} {
        margin-top: ${props => props.isAdjustAccordionMargin && '1.25rem'};
      }
    }
  }

  @media ${device.tablet} {
    font-size: 0.875rem;
    line-height: ${props => (props.isEnsinoEPesquisa ? '1.625rem' : '1.5rem')};
    padding: 0.625rem 0 1.875rem;

    h3 {
      font-size: 1.125rem;
      margin-top: 1.375rem;
      margin-bottom: 1.375rem;
    }
  }

  iframe {
    width: 35.625rem;
    margin-right: 1.875rem;
    margin-bottom: 4.125rem;

    @media ${device.mobile} {
      width: 20.938rem;
      height: 11.563rem;
      margin-bottom: 1.875rem;
    }
  }
`;

export const WrapCustomContainer = styled.div`
  .contact-card {
    padding-top: 0;
    margin-top: 1.25rem;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    strong {
      width: 100%;
      display: inline-block;
      margin-left: 2.125rem;
    }

    em {
      width: 100%;
      display: inline-block;
      padding-left: 0;
      margin-left: 2.125rem;
    }

    @media ${device.mobile} {
      margin-top: 1.375rem;
    }
  }

  .mb-30 {
    margin-bottom: 1.875rem;
  }

  .equipe-container {
    margin-top: 3.75rem;
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media ${device.mobile} {
      flex-direction: column;
      margin-top: 1.25rem;
    }

    > h2:first-of-type {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 1.875rem;

      @media ${device.laptop} {
        font-size: 1.125rem;
      }
    }

    > p:first-of-type {
      display: none;
    }
  }

  .equipe-content {
    background-color: #f4f5f7;
    border-radius: 8px;
    width: 35.625rem;

    > p:nth-of-type(6) {
      margin-bottom: 1.25rem;
      margin-top: 1.25rem;
    }

    > p:nth-of-type(8) {
      margin-top: 0;
    }

    @media ${device.tablet} {
      width: 25rem;
    }

    @media ${device.mobile} {
      width: 100%;
    }

    padding: 1.25rem 1.25rem 1.25rem 1.25rem;

    p > em {
      display: block;
      color: #8f9194;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.625rem;

      @media ${device.mobile} {
        font-size: 0.875rem;
      }
    }

    > p:first-of-type {
      display: none;
    }
  }

  .gestao-saude-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 1.875rem;

    div {
      width: 23.125rem;
      margin-top: 1rem;
    }

    & > p {
      display: none;
    }

    em {
      font-weight: bold;
    }

    ul {
      margin-bottom: -0.5rem;
    }

    ul li {
      margin-bottom: 0rem;
    }
  }

  .qualidade-seguranca-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 1.875rem;

    & > p {
      display: none;
    }

    div {
      width: 23.125rem;
      margin-top: 1rem;
    }

    .gerenciamento-risco-content {
      width: 16.875rem;
    }

    em {
      font-weight: bold;
    }

    ul {
      margin-bottom: -0.5rem;
    }

    ul li {
      margin-bottom: 0rem;
    }

    em {
      font-weight: bold;
    }
  }

  .telessaude-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 1.875rem;

    div {
      width: 23.125rem;
      margin-top: 1rem;
    }

    & > p {
      display: none;
    }

    em {
      font-weight: bold;
    }

    ul {
      margin-bottom: -0.5rem;
    }

    ul li {
      margin-bottom: 0rem;
    }

    em {
      font-weight: bold;
    }
  }
`;
