import React from 'react';

import * as S from './style';

const GrayRectangle = ({ ...rest }) => {

  return (
    <S.Container>
      <S.Content children={rest.children} escapeHtml={false} />
    </S.Container>
  );
};

export default GrayRectangle;
