import styled from 'styled-components';
import { device } from '../../../device';

export const ContainerCardCostumized = styled.div`
  display: flex;
  width: 100%;
`;

export const ContainerCard = styled.div`
  flex-direction: row;
  display: flex;
`;

export const CardArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: #ffffff;
  border: 1px solid #c6c8cc;

  box-sizing: border-box;

  border-radius: 8px;
  margin-right: 10px;
  width: 270px;
  height: 260px;

  :last-child {
    margin-right: 0px;
  }

  @media ${device.mobile} {
    height: auto;
  }

  > h2 {
    padding: 20px 0 0 20px;
    max-width: 210px;
    height: 58px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    @media ${device.mobile} {
      height: 44px;
      margin-bottom: 20px;
    }

    > a {
      color: #231f20;
    }
  }

  p:nth-child(2) {
    padding-left: 20px;

    height: 88px;
    width: 100%;
    max-width: 230px;
    margin-bottom: 27px;

    @media ${device.mobile} {
      margin-top: 20px;

      height: 66px;
    }

    a {
      font-family: 'Inter';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #8f9194;
    }
  }

  p:nth-child(3) {
    padding-left: 23px;
    padding-bottom: 20px;

    @media ${device.mobile} {
      margin-top: 17px;
    }
  }
`;
