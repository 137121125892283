import styled, { css } from 'styled-components';
import { device } from 'components/device';

const transition = css`
  transition: transform 0.45s;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100vw;
  padding: 65px 0 40px;

  @media ${device.mobile} {
    padding: 65px 1.25rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 72.125rem;
  max-height: 46.875rem;
  text-align: -webkit-center;
`;

export const Title = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #231f20;
  margin-bottom: 76px;

  @media ${device.mobile} {
    font-size: 1.125rem;
  }
`;

export const TabContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 950px;
  height: ${props => (props.mobile ? '500px' : '450px')};

  @media ${device.mobile} {
    display: flex;
    align-items: flex-start;
    width: 900px;
  }
`;

export const TabTitle = styled.h2``;

export const ImageContainer = styled.div`
  width: 1330px;
  position: absolute;
  display: flex;
  transform: translateX(
    ${props => (props.active === 0 ? 0 : `-${props.active * 950}px`)}
  );
  ${transition}
  margin-top: 2rem;
`;

export const ImageContent = styled.img`
  object-fit: cover;
  margin-right: ${props => (props.mobile ? '575px' : '0')};
  margin-top: ${props => (props.mobile ? '30px' : '0')};

  @media ${device.mobile395} {
    width: 350px;
    margin-right: ${props => (props.mobile ? '600px' : '0')};
  }
`;
