// Libs
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import {
  CardArea,
  ContainerCardCostumized,
  ContainerCard,
} from './serviceStyleCustomized';

import Carousel, { consts } from 'react-elastic-carousel';

// Components
import { device } from '../../../device';

// Images
import arrowRight from 'images/icons/arrow-right.svg';
import arrowLeft from 'images/icons/arrowLeft.svg';

// Styles
const Container = styled.section`
  grid-area: SVC;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${props => props.isOncologia && '4.375rem 0'};

  @media ${device.desktop4K} {
    width: ${props => (props.isCDIUnits ? '100%' : '93%')};
  }

  @media ${device.laptop} {
    padding: ${props => props.isOncologia && '0'};
  }

  @media ${device.tablet} {
    width: 100%;
    align-items: flex-start;
    margin-left: unset;
  }

  a {
    width: 100%;
  }
`;

const ContainerTitle = styled.div`
  padding-left: ${props => props.isCDIUnits && '1rem'};
  width: ${props => (props.isHome ? '97%' : props.isCDIUnits ? '100%' : '95%')};
  margin-bottom: 3.125rem;
  text-align: left;
  font-size: 1.5rem;
  margin-top: ${props => props.isportalPaciente && '70px'};

  @media ${device.laptop} {
    margin-top: ${props => props.isportalPaciente && '0'};
  }
  ${props =>
    props.isCDIUnits &&
    `
      display: flex;
      justify-content: space-between;
    `}
  @media ${device.desktop4K} {
    width: ${props => (props.isHome ? '97%' : !props.isCDIUnits && '93%')};
  }

  @media ${device.tablet} {
    margin: 1.875rem 0;
    padding-left: ${props => props.isCDIUnits && 0};
    font-size: 1.125rem;
  }
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 0;
  margin-left: -1rem;
  color: #231f20;

  @media ${device.laptop} {
    margin-top: 2rem;
  }

  @media ${device.tablet} {
    margin-left: 0;
    margin-top: 0;
    font-size: 1.125rem;
  }
`;

const ServicesContainer = styled.div`
  width: ${props =>
    props.widthTextHiperlink
      ? '100%'
      : props.isHome
      ? '97%'
      : props.isProntoAtendimento
      ? '100%'
      : props.isCDIUnits
      ? '-webkit-fill-available'
      : '95%'};
  display: ${props => (props.isHome || props.isCDIUnits ? 'flex' : 'grid')};
  grid-template-columns: ${props =>
    !props.isHome || (!props.isCDIUnits && 'repeat(2, 1fr)')};

  margin-left: ${props =>
    props.marginLeftPortalPaciente
      ? props.marginLeftPortalPaciente
      : props.isCDIUnits
      ? '-1rem'
      : props.isProntoAtendimento
      ? '0'
      : '-2rem'};

  @media ${device.laptop} {
    width: ${props =>
      props.isActiveCard
        ? '100%'
        : props.scrolled
        ? '100vw'
        : 'calc(100% + 3rem)'};
    grid-auto-flow: column;
    grid-template-columns: ${props =>
      !props.isProntoAtendimento && 'repeat(2, 1fr)'};
    align-items: center;
    overflow-x: ${props => (props.isActiveCard ? 'unset' : 'scroll')};
    margin-left: 0;

    ::-webkit-scrollbar {
      display: ${({ scrollbar }) => scrollbar};
      height: 4px;
      width: 4px;
    }
    ::-webkit-scrollbar-track {
      background: #fff;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }

  @media ${device.laptop} {
    overflow-x: scroll;
    padding-bottom: ${props => props.paddingBottom && props.paddingBottom};
  }

  @media ${device.tablet} {
    // margin-left: -20px;
  }

  @media ${device.mobile375} {
    width: ${props => props.isProntoAtendimento && '100%'};
    margin-left: ${props => props.isProntoAtendimento && 'initial'};
  }
`;

const ServicesContent = styled.div`
  width: ${props =>
    props.widthTextHiperlink ? '100%' : props.isCDIUnits && '97%'};

  @media ${device.laptop} {
    display: ${props => props.isCDIUnits && 'flex'};
    margin-left: ${({ servicesContentMarginLeft, isCDIUnits }) =>
      servicesContentMarginLeft
        ? servicesContentMarginLeft
        : isCDIUnits && '0'};
    width: ${props => props.isCDIUnits && 'auto'};
  }

  ul {
    display: ${props =>
      props.isHome ? 'flex' : props.isCDIUnits ? 'flex' : 'grid'};
    grid-template-columns: ${props => !props.isHome && 'repeat(2, 1fr)'};
    margin: 0;

    @media ${device.laptop} {
      margin-left: ${props => (props.isMarginLeft ? '0' : '1.4rem')};
      margin-right: ${props => (props.cardHowCanIHelp ? '1.4rem' : '0.6rem')};
      width: ${props => props.isProntoAtendimento && 'auto'};
      display: ${props => props.isProntoAtendimento && 'block'};
      grid-auto-flow: column;
      grid-template-columns: ${props =>
        !props.isProntoAtendimento && 'repeat(2, 1fr)'};
    }

    @media ${device.mobile375} {
      margin: ${props => props.isProntoAtendimento && '0'};
    }
  }

  li,
  .rec-item-wrapper {
    padding: ${props =>
      props.isProntoAtendimento
        ? '1.25rem !important'
        : props.paddingCardDesk
        ? `${props.paddingCardDesk} !important`
        : '1.4rem !important'};
    padding-top: ${props =>
      props.cardHowCanIHelp ? '1.875rem !important' : '1.25rem'};
    margin-right: ${props => props.marginRightCard || '1.875rem'};
    margin-bottom: ${props =>
      props.isProntoAtendimento
        ? '12rem'
        : props.notMarginBottomHome
        ? '0'
        : '1.875rem'};
    width: ${props =>
      props.widthCard
        ? props.widthCard
        : props.isProntoAtendimento
        ? '35.625rem'
        : props.isDiagnostico
        ? 'auto'
        : props.cardHowCanIHelp || props.isWidthCards
        ? '16.875rem !important'
        : '23.438rem !important'};
    height: ${props =>
      props.isportalPaciente
        ? props.isportalPaciente
        : props.isProntoAtendimento
        ? '12.125rem'
        : props.isDiagnostico || props.cardHeightAuto
        ? 'auto'
        : '18.9375rem'};
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    border: ${props =>
      props.isProntoAtendimento
        ? '0.063rem solid #c6c8cc'
        : props.isDiagnostico || props.hasNoBorder
        ? 'none'
        : '0.063rem solid #c6c8cc'};
    cursor: initial;
    border-top: ${props => props.borderTopCard && '1px solid #231F20'};
    border-radius: ${props =>
      props.cardHowCanIHelp ? '0.5rem' : props.isBorderRadius ? '0.5rem' : '0'};
    justify-content: ${props =>
      props.noJustifyContent
        ? 'start'
        : props.isProntoAtendimento && 'space-between'};

    ${props =>
      !props.hasNoHover &&
      `
      :hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
      }
    `}

    li {
      border: ${props =>
        props.isDiagnostico ? '0.063rem solid #c6c8cc' : 'none'};
    }

    :nth-child(2) {
      @media ${device.laptop} {
        padding: ${props =>
          props.pageBelaVista
            ? '1.4rem 1.188rem 1.4rem 1.4rem'
            : props.pageItaim
            ? '1.4rem 1.188rem 1.4rem 1.4rem'
            : props.pageBrasilia && '1.4rem 1.063rem 1.4rem 1.4rem'};
      }

      @media ${device.tablet} {
        padding: ${props =>
          props.pageBelaVista
            ? '1.4rem'
            : props.pageItaim
            ? '1.4rem'
            : props.pageBrasilia && '1.4rem'};
      }
    }

    :nth-child(3) {
      @media ${device.laptop} {
        padding: ${props =>
          props.pageBrasilia && '1.4rem 1.188rem 1.4rem 1.4rem'};
      }

      @media ${device.tablet} {
        padding: ${props => props.pageBrasilia && '1.4rem'};
      }
    }

    @media ${device.laptop} {
      position: ${props => props.isProntoAtendimento && 'relative'};
      width: ${props =>
        props.isProntoAtendimento
          ? 'auto'
          : props.isQuemSomos
          ? 'auto'
          : '16.875rem'};
      height: ${props =>
        props.isProntoAtendimento
          ? '10.25rem'
          : props.cardHeightLap
          ? props.cardHeightLap
          : '16.25rem'};
      margin-right: ${props =>
        props.cardHowCanIHelp
          ? '0.625rem'
          : props.noMarginCardStyle
          ? '0 !important'
          : '0'};
      margin-bottom: 1.875rem;
    }

    @media ${device.mobile} {
      margin-bottom: ${props => props.notMarginBottomHome && '0'};
    }

    @media (min-width: 320px) {
      width: ${props => (props.isWidthCards ? '230px !important' : 'auto')};
    }
    @media (min-width: 360px) {
      width: ${props => (props.isWidthCards ? '270px !important' : 'auto')};
    }
    @media (min-width: 375px) {
      width: ${props => (props.isWidthCards ? '285px !important' : 'auto')};
    }
    @media (min-width: 411px) {
      width: ${props => (props.isWidthCards ? '311px !important' : 'auto')};
    }
    @media (min-width: 414px) {
      width: ${props => (props.isWidthCards ? '315px !important' : 'auto')};
    }
    @media (min-width: 768px) {
      width: ${props =>
        props.isWidthCards
          ? '680px !important'
          : props.isSlideItaim || props.isSlideBelaVista
          ? '18.5625rem !important'
          : props.isSlideBrasilia
          ? '19.562rem !important'
          : 'auto'};

      margin-right: ${props => props.isSlideBelaVista && '0.625rem'};
    }
    @media (min-width: 1024px) {
      width: ${props => (props.isWidthCards ? '270px !important' : 'auto')};
    }

    @media ${device.mobile375} {
      margin-right: ${props => props.isProntoAtendimento && '0'};
      height: ${props =>
        props.isProntoAtendimento
          ? 'auto'
          : props.cardHeightMob
          ? props.cardHeightMob
          : '16.25rem'};
    }

    :nth-child(5n + 0) {
      h2 {
        width: 85%;

        @media ${device.laptop} {
          width: ${props => (props.titleDeskWith ? '100%' : '85%')};
        }
        @media ${device.tablet} {
          width: ${props => (props.pageBelaVista ? '45%' : '90%')};
        }
      }
    }

    ${props =>
      props.pageBrasilia &&
      `
      :nth-child(2n+0) {
        h2 {
          width: 85%;

          @media ${device.laptop} {
            width: 55%;
          }
        }
      }
    `}
  }

  h2,
  h3 {
    max-width: ${props =>
      props.titleDeskWith
        ? '100%'
        : props.titleDeskMaxWithPortalPaciente
        ? props.titleDeskMaxWithPortalPaciente
        : '93%'};
    width: ${props =>
      props.short
        ? '83%'
        : props.titleDeskWithPortalPaciente
        ? props.titleDeskWithPortalPaciente
        : props.titleDeskWith
        ? props.titleDeskWith
        : '87%'};
    height: ${props =>
      props.isAdjustmentInCssLayout
        ? '5.4375rem !important'
        : props.heightTitleCardPortalPaciente};
    margin-bottom: ${props =>
      props.marginBottomCardPortalPaciente
        ? props.marginBottomCardPortalPaciente
        : props.isProntoAtendimento
        ? '0'
        : props.isAdjustmentInCssLayout
        ? '0 '
        : props.cardHowCanIHelp
        ? '1.25rem'
        : '0.625rem;'};
    font-size: ${({ fontSizeTitle }) =>
      fontSizeTitle ? fontSizeTitle : '1.5rem'};
    font-weight: 600;
    color: #231f20;
    padding-right: ${props => (props.isDiagnostico ? '7rem' : '0')};

    > a {
      color: #231f20;

      :last-child {
        display: ${props => (props.isDiagnostico ? 'none' : 'flex')};
      }
    }

    @media ${device.laptop} {
      width: 92%;
      max-width: 100%;

      > a {
        :last-child {
          position: ${props => props.isDiagnostico && 'absolute'};
          top: ${props => props.isDiagnostico && '1.25rem'};
          right: ${props => props.isDiagnostico && '1.25rem'};
          display: ${props => props.isDiagnostico && 'flex'};
          width: ${props => props.isDiagnostico && 'auto'};
          padding: ${props => props.isDiagnostico && '0.625rem'};
          border: ${props => props.isDiagnostico && '1px solid #C6C8CC'};
          border-radius: ${props => props.isDiagnostico && '50%'};
        }
      }
    }

    @media ${device.tablet} {
      width: ${props => (props.short ? '60%' : '66%')};
      font-size: 1rem;
      line-height: 1.375rem;
      height: ${props => props.isAdjustmentInCssLayoutMobile && '3.4375rem'};
      margin-bottom: ${props => props.isAdjustmentInCssLayoutMobile && '0'};
    }

    @media ${device.mobile} {
      width: ${props =>
        props.short ? '62%' : props.isOncologia ? '65%' : '75%'};
    }
  }

  ${'' /* Card */}
  p {
    width: ${props => (props.cardHowCanIHelp ? '13.125rem' : '99%')};
    height: ${({ heightText, cardHowCanIHelp }) =>
      heightText ? heightText : cardHowCanIHelp ? '5.5rem' : 'auto'};

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: ${({ justifyContentText }) =>
      justifyContentText ? justifyContentText : ''};

    margin: ${props => props.isProntoAtendimento && '0'};

    font-size: ${({ fontSizeText, cardHowCanIHelp }) =>
      fontSizeText ? fontSizeText : cardHowCanIHelp ? '0.875rem' : '1rem'};
    line-height: ${props =>
      props.isProntoAtendimento
        ? '0.8rem'
        : props.cardHowCanIHelp
        ? '1.375rem'
        : '1.5rem'};
    color: ${({ colorText }) => (colorText ? colorText : '#8f9194')};

    @media ${device.laptop} {
      width: 100%;
      max-width: ${props => (props.isWidthCards ? '94%' : '100%')};
    }

    @media (min-width: 360px) {
      max-width: ${props =>
        props.textMaxWidth
          ? props.textMaxWidth
          : props.isWidthCards
          ? '78%'
          : '100%'};
    }
    @media (min-width: 768px) {
      max-width: ${({ isWidthCards, textMaxWidth }) =>
        textMaxWidth ? textMaxWidth : isWidthCards ? '94%' : '100%'};
    }

    @media ${device.tablet} {
      font-size: ${({ fontSizeTextMob }) => `${fontSizeTextMob} !important`};
    }

    > a:first-child {
      color: ${({ colorText }) => colorText};
    }

    p > a:visited:first-child {
      color: ${({ colorText }) => colorText};
    }

    > a {
      color: ${({ colorLink }) => (colorLink ? colorLink : '#8f9194')};
      width: ${props =>
        props.widthTextHiperlink ? props.widthTextHiperlink : '104%'};
      height: ${props => props.widthTextHiperlink && '88px'};
      :last-child {
        height: ${props => props.widthTextHiperlink && '0'};
      }

      @media ${device.laptop} {
        width: 100%;
      }
    }

    > img {
      margin-top: 1.25rem;
      margin-bottom: 0.5rem;
      border: none;
      background: transparent;
    }
  }
  p:nth-child(2) {
    margin-top: ${props => (props.widthTextHiperlink ? '0' : '1.25rem')};
    margin-bottom: 1.6875rem;

    ${({ alignLink }) =>
      alignLink &&
      css`
        margin-bottom: 0;
        a:last-child {
          display: flex;
          align-items: ${alignLink};
        }
      `}
  }
  p:nth-child(3) {
    height: ${props => props.widthTextHiperlink && 'auto'};
    margin-bottom: ${props =>
      props.isAdjustmentInCssLayoutMobile && '-3.75rem'};
    margin-bottom: ${props => props.widthTextHiperlink && '2.0625rem'};
    @media ${device.laptop} {
      margin-bottom: ${props =>
        props.isAdjustmentInCssLayoutMobile && '-4.5625rem'};
    }
  }

  .rec-swipable {
    // margin-left: 1rem;
    margin-left: ${({ marginLeftCards }) =>
      marginLeftCards ? marginLeftCards : '0'};

    @media ${device.tablet} {
      margin-left: ${({ marginLeftCardsMob }) => marginLeftCardsMob};
    }

    div:last-child {
      .rec-item-wrapper {
        :last-child {
          margin-right: ${props => props.widthTextHiperlink && '0'};
        }
      }
    }
  }

  .rec-slider-container {
    width: 100vw;
    margin: ${props => props.marginSliderContainer ? props.marginSliderContainer :
      props.isCentroDiagnostico ?  '0 -2.5rem 0 -37px' : '0 -1rem 0 0'};

    @media ${device.laptop} {
      width: ${props =>
        props.isSlideBelaVista
          ? '89.5vw'
          : props.isSlideItaim
          ? '87.5vw'
          : props.isSlideBrasilia
          ? '90.9vw'
          : '100vw'};
    }
    @media (max-width: 414px) {
      width: ${props =>
        props.isSlideItaim
          ? '22.4rem'
          : props.isSlideBelaVista
          ? '22.9rem'
          : props.isSlideBrasilia
          ? '85.7vw'
          : '100vw'};
    }

    @media (max-width: 376px) {
      width: ${props =>
        props.isSlideItaim
          ? '23rem'
          : props.isSlideBelaVista
          ? '23.2rem'
          : props.isSlideBrasilia
          ? '22.7rem'
          : '100vw'};
    }
  }

  .rec-slider {
    @media ${device.laptop} {
      margin: 0;
    }

    ${({ justifyContentCards }) =>
      justifyContentCards &&
      css`
        position: sticky;
        justify-content: ${justifyContentCards};

        @media ${device.mobile} {
          position: inherit;
          justify-content: flex-start;
        }
      `}
  }

  .rec-pagination {
    display: none;
    width: 100%;
    @media ${device.laptop} {
      display: ${props => (props.isFitCardButtonPages ? 'none' : 'block')};
      margin: 0 0 15px;
      button {
        margin: 0 8px 0 0;
        width: 8px;
        height: 8px;
        border-radius: 1.875rem;
        border: 1px solid #c6c8cc;
        background-color: #fff;
        cursor: pointer;
        outline: none;
        box-shadow: none;
      }
      .rec-dot_active {
        box-shadow: none;
        background-color: #c6c8cc;
        border: 1px solid #c6c8cc;
      }
    }
  }

  .rec-carousel-item-visible {
    @media ${device.laptop} {
      padding-right: ${props =>
        props.cardPaddingRight
          ? props.cardPaddingRight
          : props.cardHowCanIHelp
          ? '0'
          : '30px'};
      padding-left: ${props =>
        props.cardPaddingLeft ? props.cardPaddingLeft : ''};
    }
  }
  .rec-arrow {
    display: none;
  }

  .rec-item-wrapper {
    margin-bottom: 0;
  }

  .rec-carousel {
    ${props =>
      props.isOncologia &&
      `
      position: relative;
    `};
    position: ${props => props.isCentroCirurgico && 'relative'};
  }
`;

const ButtonServicesUnity = styled.div`
  margin-top: 0;
  margin-bottom: 3.313rem;
  padding: 0;
  width: 100%;
  display: ${props => (props.isDiagnostico ? 'none' : 'flex')};
  justify-content: left;
  align-items: center;
  font-size: 1rem;
  color: #45a7df;

  p:hover {
    text-decoration: underline;
  }

  @media ${device.laptop} {
    margin-bottom: 1.875rem;
    padding: 1.35rem;
    justify-content: center;
    font-size: 0.875rem;
    border-radius: 6.25rem;
    color: #fff;
    background: #45a7df;
    transition: 0.6s background;

    > img {
      display: none;
    }
    :hover {
      background: #1d52a3;
    }
    p:hover {
      text-decoration: none;
    }
  }
`;

const CarouselButton = styled.button`
  position: ${props => (props.isCentroDiagnostico ? 'relative' : 'absolute')};
  top: ${props =>
    props.isCentroDiagnostico
      ? '0'
      : props.isOncologia
      ? '-5rem'
      : props.isFitCardButtonPages
      ? '50.75rem'
      : '48.313rem'};
  top: ${props => props.prevNextButton && '-5.5rem'};
  left: ${props => props.prevNextButton && '-0.6rem'};
  width: 2.125rem;
  width: ${props => (props.isCentroDiagnostico ? '2.600rem' : '2.125rem')};
  height: 2.125rem;
  display: ${props => (props.haveNoButtons ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  transform: translate(1148px, 0);
  background: #f4f5f7;
  border-radius: 50%;
  outline: none;
  border: none;

  :first-child {
    transform: translate(1106px, 0);
    left: ${props => props.isCentroDiagnostico && '-0.5rem'};
    top: ${props => props.isCentroDiagnostico && '-5rem'};
  }

  :last-child {
    left: ${props => props.isCentroDiagnostico && '-72rem'};
    top: ${props => props.isCentroDiagnostico && '-5rem'};
  }

  :disabled {
    cursor: default;
    opacity: 0.5;
  }

  @media ${device.laptop} {
    display: ${props => (props.isportalPaciente ? 'none' : 'flex')};
  }
`;

function Services({
  markdown,
  cardHowCanIHelp,
  isportalPaciente,
  isSlideItaim,
  isMarginLeft,
  isWidthCards,
  isSlideBelaVista,
  noMarginCardStyle,
  isBorderRadius,
  noJustifyContent,
  pageBrasilia,
  isSlideBrasilia,
  isHome,
  isCDIUnits,
  pageBelaVista,
  pageItaim,
  titleDeskWith,
  titleDeskMaxWithPortalPaciente,
  marginBottomCardPortalPaciente,
  titleDeskWithPortalPaciente,
  heightTitleCardPortalPaciente,
  widthTextHiperlink,
  marginLeftPortalPaciente,
  haveNotTitle,
  isDiagnostico,
  isProntoAtendimento,
  isOncologia,
  borderTopCard,
  hasNoBorder,
  hasNoBorderRadius,
  cardHeightAuto,
  paddingCardDesk,
  haveNoButtons,
  hasNoHover,
  cardHeightLap,
  cardHeightMob,
  marginRightCard,
  isQuemSomos,
  isAdjustmentInCssLayout,
  isAdjustmentInCssLayoutMobile,
  isFitCardButtonPages,
  isActiveCard,
  isPortalPaciente,
  fragments,
  paddingBottom,
  prevNextButton,
  isCentroCirurgico,
  marginLeftCards,
  marginLeftCardsMob,
  isCentroDiagnostico,
  fontSizeTitle,
  fontSizeText,
  fontSizeTextMob,
  servicesContentMarginLeft,
  heightText,
  colorText,
  colorLink,
  justifyContentText,
  textMaxWidth,
  justifyContentCards,
  alignLink,
  scrollbar,
  cardPaddingRight,
  cardPaddingLeft,
  widthCard,
  notMarginBottomHome,
  marginSliderContainer
}) {
  function LinkRenderer({ href, children }) {
    const handleClick = () => {
      if (href === '/faq/paciente') {
        localStorage.setItem('page', 'portal-do-paciente');
      }
    };

    if (href.includes('http')) {
      return (
        <a
          href={href}
          onClick={handleClick}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    }

    return (
      <a
        href={href}
        onClick={handleClick}
        target={props => (props.isFaq ? '_self' : '_blank')}
      >
        {children}
      </a>
    );
  }

  const [scroll, setScroll] = useState(false);

  const data = [markdown];

  let title = '';
  if (fragments?.length > 0) {
    title = fragments[0]?.singletexts[0];
  }

  const cutData = data[0].split('(end)');

  const [isDesktop, setDesktop] = useState(undefined);
  const [isTablet, setTablet] = useState(1);
  const [ipadProAndTablet, setIpadProAndTablet] = useState(undefined);

  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <img src={arrowLeft} alt="voltar" />
      ) : (
        <img src={arrowRight} alt="próximo" />
      );
    return (
      <CarouselButton
        isFitCardButtonPages={isFitCardButtonPages}
        onClick={onClick}
        disabled={isEdge}
        isOncologia={isOncologia}
        haveNoButtons={haveNoButtons}
        isportalPaciente={isportalPaciente}
        prevNextButton={prevNextButton}
        isCentroDiagnostico={isCentroDiagnostico}
      >
        {pointer}
      </CarouselButton>
    );
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  });
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIpadProAndTablet(window.screen.availWidth <= 1024);
    }
  });

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.innerWidth > 500 &&
      window.innerWidth < 1000
    ) {
      setTablet(2);
    }
  }, [isTablet]);

  return (
    <Container isCDIUnits={isCDIUnits} isOncologia={isOncologia}>
      {!haveNotTitle && (
        <ContainerTitle
          isportalPaciente={isportalPaciente}
          isHome={isHome}
          isCDIUnits={isCDIUnits}
          isOncologia={isOncologia}
          isPortalPaciente={isPortalPaciente}
        >
          <Title>
            {!isOncologia
              ? isPortalPaciente
                ? title
                : isHome
                ? 'Como podemos te ajudar hoje?'
                : isCDIUnits
                ? 'Principais exames realizados'
                : 'Nessa unidade você encontra'
              : 'Nossas especialidades'}
          </Title>
        </ContainerTitle>
      )}
      <ServicesContainer
        isHome={isHome}
        isCDIUnits={isCDIUnits}
        marginLeftPortalPaciente={marginLeftPortalPaciente}
        onScroll={() => !scroll && setScroll(true)}
        scrolled={scroll}
        isActiveCard={isActiveCard}
        isProntoAtendimento={isProntoAtendimento}
        isOncologia={isOncologia}
        paddingBottom={paddingBottom}
        fontSizeTitle={fontSizeTitle}
        scrollbar={scrollbar}
      >
        {isCDIUnits ? (
          isDesktop ? (
            <ServicesContent //Card's Pagina Bela-vista, Itaim e Quem-somos
              marginLeftCards={marginLeftCards}
              marginLeftCardsMob={marginLeftCardsMob}
              isAdjustmentInCssLayout={isAdjustmentInCssLayout}
              cardHowCanIHelp={cardHowCanIHelp}
              isportalPaciente={isportalPaciente}
              titleDeskWithPortalPaciente={titleDeskWithPortalPaciente}
              titleDeskMaxWithPortalPaciente={titleDeskMaxWithPortalPaciente}
              marginBottomCardPortalPaciente={marginBottomCardPortalPaciente}
              widthTextHiperlink={widthTextHiperlink}
              marginLeftPortalPaciente={marginLeftPortalPaciente}
              heightTitleCardPortalPaciente={heightTitleCardPortalPaciente}
              noJustifyContent={noJustifyContent}
              isWidthCards={isWidthCards}
              isCDIUnits={isCDIUnits}
              borderTopCard={borderTopCard}
              hasNoBorder={hasNoBorder}
              hasNoBorderRadius={hasNoBorderRadius}
              cardHeightAuto={cardHeightAuto}
              paddingCardDesk={paddingCardDesk}
              hasNoHover={hasNoHover}
              cardHeightLap={cardHeightLap}
              cardHeightMob={cardHeightMob}
              marginRightCard={
                marginRightCard && cutData.length > 4
                  ? typeof marginRightCard === 'string'
                    ? marginRightCard
                    : '1.4rem'
                  : typeof marginRightCard === 'string'
                  ? marginRightCard
                  : '1.5rem'
              }
              isCentroCirurgico={isCentroCirurgico}
              isCentroDiagnostico={isCentroDiagnostico}
              fontSizeTitle={fontSizeTitle}
              fontSizeText={fontSizeText}
              fontSizeTextMob={fontSizeTextMob}
              heightText={heightText}
              colorText={colorText}
              colorLink={colorLink}
              justifyContentText={justifyContentText}
              textMaxWidth={textMaxWidth}
              justifyContentCards={justifyContentCards}
              alignLink={alignLink}
              cardPaddingRight={cardPaddingRight}
              cardPaddingLeft={cardPaddingLeft}
              widthCard={widthCard}
              marginSliderContainer={marginSliderContainer}
            >
              <Carousel itemsToShow={4} renderArrow={myArrow} width="27px">
                {cutData.map(
                  item =>
                    item !== '' && (
                      <ReactMarkdown
                        renderers={{ link: LinkRenderer }}
                        key={item}
                        children={item}
                      />
                    )
                )}
              </Carousel>
            </ServicesContent>
          ) : isActiveCard && ipadProAndTablet ? (
            <ContainerCardCostumized>
              <ContainerCard>
                {cutData.map(
                  item =>
                    item !== '' && (
                      <CardArea>
                        <ReactMarkdown
                          renderers={{ link: LinkRenderer }}
                          key={item}
                          children={item}
                        />
                      </CardArea>
                    )
                )}
              </ContainerCard>
            </ContainerCardCostumized>
          ) : (
            <ServicesContent //Card's Pagina Bela-vista e Itaim Mobile
              isAdjustmentInCssLayoutMobile={isAdjustmentInCssLayoutMobile}
              isFitCardButtonPages={isFitCardButtonPages}
              cardHowCanIHelp={cardHowCanIHelp}
              isportalPaciente={isportalPaciente}
              titleDeskWithPortalPaciente={titleDeskWithPortalPaciente}
              isSlideBrasilia={isSlideBrasilia}
              isSlideItaim={isSlideItaim}
              isWidthCards={isWidthCards}
              isSlideBelaVista={isSlideBelaVista}
              noMarginCardStyle={noMarginCardStyle}
              isCDIUnits={isCDIUnits}
              titleDeskWith={titleDeskWith}
              titleDeskMaxWithPortalPaciente={titleDeskMaxWithPortalPaciente}
              marginBottomCardPortalPaciente={marginBottomCardPortalPaciente}
              widthTextHiperlink={widthTextHiperlink}
              marginLeftPortalPaciente={marginLeftPortalPaciente}
              heightTitleCardPortalPaciente={heightTitleCardPortalPaciente}
              borderTopCard={borderTopCard}
              hasNoBorder={hasNoBorder}
              hasNoBorderRadius={hasNoBorderRadius}
              cardHeightAuto={cardHeightAuto}
              paddingCardDesk={paddingCardDesk}
              hasNoHover={hasNoHover}
              cardHeightLap={cardHeightLap}
              cardHeightMob={cardHeightMob}
              marginRightCard={
                marginRightCard && cutData.length > 4 ? '1.4rem' : '1.5rem'
              }
              servicesContentMarginLeft={servicesContentMarginLeft}
              fontSizeTextMob={fontSizeTextMob}
              heightText={heightText}
              colorText={colorText}
              colorLink={colorLink}
              justifyContentText={justifyContentText}
              textMaxWidth={textMaxWidth}
              justifyContentCards={justifyContentCards}
              alignLink={alignLink}
              cardPaddingRight={cardPaddingRight}
              cardPaddingLeft={cardPaddingLeft}
              widthCard={widthCard}
              marginLeftCards={marginLeftCards}
              marginLeftCardsMob={marginLeftCardsMob}
              marginSliderContainer={marginSliderContainer}
            >
              <Carousel
                itemsToShow={isTablet}
                itemPosition={consts.START}
                outerSpacing={50}
                itemPadding={[0, 20]}
              >
                {cutData.map(
                  item =>
                    item !== '' && <ReactMarkdown key={item} children={item} />
                )}
              </Carousel>
            </ServicesContent>
          )
        ) : (
          <ServicesContent //Card's Pagina Home e pronto-Atentimento
            isAdjustmentInCssLayout={isAdjustmentInCssLayout}
            isBorderRadius={isBorderRadius}
            isMarginLeft={isMarginLeft}
            notMarginBottomHome={notMarginBottomHome}
            cardHowCanIHelp={cardHowCanIHelp}
            isportalPaciente={isportalPaciente}
            titleDeskMaxWithPortalPaciente={titleDeskMaxWithPortalPaciente}
            marginBottomCardPortalPaciente={marginBottomCardPortalPaciente}
            widthTextHiperlink={widthTextHiperlink}
            marginLeftPortalPaciente={marginLeftPortalPaciente}
            heightTitleCardPortalPaciente={heightTitleCardPortalPaciente}
            pageBrasilia={pageBrasilia}
            isHome={isHome}
            pageBelaVista={pageBelaVista}
            pageItaim={pageItaim}
            isCDIUnits={isCDIUnits}
            isDiagnostico={isDiagnostico}
            isProntoAtendimento={isProntoAtendimento}
            borderTopCard={borderTopCard}
            hasNoBorder={hasNoBorder}
            hasNoBorderRadius={hasNoBorderRadius}
            cardHeightAuto={cardHeightAuto}
            hasNoHover={hasNoHover}
            cardHeightLap={cardHeightLap}
            marginRightCard={
              marginRightCard && cutData.length > 4 ? '1.4rem' : '1.5rem'
            }
            fontSizeTextMob={fontSizeTextMob}
            justifyContentCards={justifyContentCards}
            alignLink={alignLink}
            cardPaddingRight={cardPaddingRight}
            cardPaddingLeft={cardPaddingLeft}
            marginLeftCards={marginLeftCards}
            marginLeftCardsMob={marginLeftCardsMob}
                    marginSliderContainer={marginSliderContainer}
          >
            <ReactMarkdown children={markdown || '-'} />
          </ServicesContent>
        )}
      </ServicesContainer>
      <Link to={'detalhes'}>
        {!isHome && !isCDIUnits && !haveNotTitle && (
          <ButtonServicesUnity>
            <p>Veja todos os serviços dessa unidade</p>
            <img src={arrowRight} alt="Veja todos os serviços dessa unidade" />
          </ButtonServicesUnity>
        )}
      </Link>
    </Container>
  );
}

export default Services;
