// Libs
import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../device';
import { CircularProgress } from '@material-ui/core';

const BoxButton = styled.div`
  height: auto;
  width: 100%;
  display: ${props => (props.centerButton ? 'flex' : 'block')};
  justify-content: ${props => (props.centerButton ? 'center' : 'inherit')};
`;
const Button = styled.button`
  width: ${props => props.width};
  min-width: 12.5rem;
  height: ${props => props.heightDesktop && props.heightDesktop};
  background: ${props => props.background};
  font-size: 0.875rem;
  opacity: ${props => (!props.isButtonActive ? '50%' : '100%')};
  border: ${props => (props.borderColor ? `1px solid ${props.borderColor}` : 0)};
  border-radius: 6.25rem;
  color: ${props => props.color};
  margin: 0;
  margin-top: ${props => props.marginTop};
  padding: ${props => props.paddingDesktop};
  outline: none;
  transition: '0.3s background';
  cursor: pointer;
  ${props =>
    props.hasHover &&
    css`
      :hover {
        background: ${props => (!props.isButtonActive ? '#c6c8cc' : '#1d52a3')};
      }
    `}

  @media ${device.laptop} {
    width: 100%;
    height: ${props => props.heightMobile && props.heightMobile};
    font-size: 0.875rem;
    padding: ${props => props.paddingMobile && props.paddingMobile};
  }
`;

const SimpleButton = ({
  label = '',
  onClick = () => {},
  loading = false,
  isButtonActive = false,
  marginTop = '30px',
  paddingDesktop = 0,
  paddingMobile = 0,
  width = '60%',
  background = '#45a7df',
  color = '#FFFFFF',
  hasHover = true,
  loaderSize = 24,
  heightDesktop = '2.813rem',
  heightMobile = '3.5rem',
  centerButton = false,
  borderColor = ""
}) => {
  return (
    <BoxButton centerButton={centerButton}>
      <Button
        width={width}
        heightDesktop={heightDesktop}
        heightMobile={heightMobile}
        color={color}
        background={background}
        marginTop={marginTop}
        paddingDesktop={paddingDesktop}
        paddingMobile={paddingMobile}
        onClick={() => (isButtonActive ? onClick() : null)}
        isButtonActive={isButtonActive}
        hasHover={hasHover}
        borderColor={borderColor}
      >
        {!loading ? (
          label
        ) : (
          <CircularProgress
            size={loaderSize}
            color="inherit"
            isButtonActive={isButtonActive}
          />
        )}
      </Button>
    </BoxButton>
  );
};

export default SimpleButton;
