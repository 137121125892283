module.exports.formatPhone = phone => {
  if (!phone) {
    return null;
  }

  if (phone.charAt(0) === '0') {
    phone = phone.replace('0', '');
  }

  let formattedPhone = phone.replace(/\D/g, '');

  if (formattedPhone.length === 13 || formattedPhone.length === 12) {
    formattedPhone = formattedPhone.replace(
      /^(\d{2})(\d{2})(\d)/g,
      '+$1 ($2) $3'
    );
  } else if (formattedPhone.length === 11 || formattedPhone.length === 10) {
    formattedPhone = formattedPhone.replace(/^(\d{2})(\d)/g, '($1) $2');
  }

  formattedPhone = formattedPhone.replace(/(\d)(\d{4})$/, '$1-$2');

  //Força que todos os DDI seja +55 caso um seja diferente
  //   if (formattedPhone.charAt(0) === '+') {
  //     let formattedPhoneDDI = formattedPhone.substr(0, 3);
  //     formattedPhone = formattedPhone.replace(formattedPhoneDDI, '+55');
  //   }

  return formattedPhone;
};
