// Libs
import React, { useState, useContext, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { formatPhone } from 'utils/formatPhone';

// Components
import PaginationGeneral from 'components/paginationGeneral';
import Alert from 'components/alerts/alert';
import Loader from 'components/loader';
import SimpleButton from 'components/SimpleButton';

// DS
import { TmpButton } from '@tempo/tempo-design-system-core/dist';

// Images
import mandalaSirio from 'images/icons/mandala_hsl@2x.png';
import arrowCircle from 'images/icons/arrow.svg';
import closeIcon from 'images/icons/arrow_to_back.png';

// Context
import { DoctorContext } from 'context/doctor';
import { PaginationContext } from 'context/pagination';
import { HeaderContext } from 'context/header';
import { InternacionalizationContext } from 'context/internacionalization';

// Styled
import {
  ContainerGrid,
  GridContent,
  ContainerCards,
  Cards,
  CardsContent,
  Avatar,
  Photo,
  MandalaIcon,
  LinkProfile,
  CardsDescription,
  BoxTags,
  HeaderModal,
  Title,
  Tags,
  BoxError,
  Subtitle,
  BoxErrorFilter,
  TextErrorFilter,
  ButtonErrorFilter,
  BackButton,
} from './style';

const OurTeam = props => {
  const isWindow = typeof window !== 'undefined';
  const mobile = isWindow && window.innerWidth <= 414;
  const [modalResults, setModalResults] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [scroll, setScroll] = useState(false);
  const [renderFilter, setRenderFilter] = useState(false);
  const [listItems, setListItems] = useState(undefined);
  const [remountComponent, setRemountComponent] = useState(0);
  const [selectedTagFilters, setSelectedTagFilters] = useState([]);
  const [tagFilters, setTagFilters] = useState([
    { display: 'São Paulo', value: 'sp', disabled: true },
    { display: 'Brasília', value: 'bsb', disabled: true },
    {
      display: 'Profissional Sírio-Libanês',
      value: 'hsl_employee',
      disabled: true,
    },
  ]);
  const { language } = useContext(InternacionalizationContext);

  const {
    specialty,
    crm,
    name,
    filters,
    renderList,
    nameSearch,
    setRenderList,
    doctorsList,
    specialtyList,
    practiceArea,
    doctorsOncology,
  } = useContext(DoctorContext);

  const { pagination } = useContext(PaginationContext);

  const { setHideHeader } = useContext(HeaderContext);

  const [paginationListFiltered, setPaginationListFiltered] = useState(
    undefined
  );

  const textLabelErrorMessage =
    props.fragments[0].localizations?.[
      props.fragments[0].localizations?.findIndex(item =>
        item.locale.toLowerCase().includes(language.toLowerCase())
      ) ?? 0
    ]?.singletexts;

  const fetchingFilterDoctors = ({ filterByEmployee, filterByState } = {}) => {
    let filterDoctorByTags = props.noSearch ? doctorsOncology : filters ?? [];

    if (!!filterByEmployee) {
      filterDoctorByTags = filterDoctorByTags.filter(
        item => item.employee === true
      );
    }

    if (!!filterByState) {
      filterDoctorByTags = filterDoctorByTags.filter(doctor =>
        selectedTagFilters?.find(state => doctor?.state?.includes(state))
      );
    }

    setListItems(filterDoctorByTags);
    setRenderFilter(true);
    if (props.noSearch) {
      setRenderList('oncology');
    } else {
      setRenderList('search');
    }
    if (
      props.noSearch &&
      props.displayTags === 'none' &&
      selectedTagFilters.length === 0
    ) {
      let hasBsbState = location.pathname.includes('brasilia');
      if (hasBsbState) {
        return getTags('bsb');
      } else {
        return getTags('sp');
      }
    }
  };

  const getTags = value => {
    const activeFilters = selectedTagFilters;
    const activeFilterIndex = activeFilters?.findIndex(
      filter => filter === value
    );

    if (activeFilterIndex < 0) {
      setSelectedTagFilters(oldValue => [...oldValue, value]);
      return;
    }

    setSelectedTagFilters(
      activeFilters.filter((_, index) => index !== activeFilterIndex)
    );
  };

  const getTagsSpecialty = value => {
    let teste = selectedFilters.some(selected => selected === value);
    let selectedValues;
    if (teste) {
      selectedValues = selectedFilters.filter(polo => polo !== value);
    } else {
      selectedValues = selectedFilters.concat(value);
    }
    setSelectedFilters(selectedValues);
    setRenderList('filtered');
  };

  const setListFiltered = () => {
    const filtered = selectedFilters.map(i => {
      return (
        doctorsList &&
        doctorsList.filter(
          item => item.specialty !== null && item.specialty.includes(i)
        )
      );
    });

    const list =
      selectedFilters.length > 0
        ? filtered.flat()
        : props.cdo
        ? setRenderList('all')
        : undefined;
    setPaginationListFiltered(list);
  };

  const handleDataPagination = () => {
    switch (renderList) {
      case 'all':
        return doctorsOncology;
      case 'search':
        return renderFilter ? listItems : filters;
      case 'filtered':
        return paginationListFiltered;
      case 'oncology':
        return listItems;
      default:
        return undefined;
    }
  };

  const handleList = () => {
    switch (renderList) {
      case 'all':
        return renderAllDoctors();
      case 'search':
        return renderFilter ? renderFiltered() : renderSearchResult();
      case 'filtered':
        return renderListFilter();
      case 'oncology':
        return renderFiltered();
      default:
        return undefined;
    }
  };

  function phoneFormatting(phone) {
    let newPhone = formatPhone(phone);
    if (!newPhone) {
      return null;
    }

    return <a href={`tel:${phone.replace(/\D/g, '')}`}>{newPhone}</a>;
  }

  const tryAgain = () => {
    setRenderList('noRender');
    props.setIsButtonActive(false);
  };

  const renderErrorAPI = () => {
    return (
      <BoxErrorFilter>
        <TextErrorFilter>
          Ocorreu um erro ao carregar os resultados
        </TextErrorFilter>
        <TextErrorFilter>
          Por favor,
          <ButtonErrorFilter onClick={() => tryAgain()}>
            tente novamente
          </ButtonErrorFilter>
        </TextErrorFilter>
      </BoxErrorFilter>
    );
  };

  const cleanFilter = () => {
    setRenderFilter(false);
    setSelectedTagFilters([]);
  };

  const renderFiltered = () => {
    var validations = pagination.some(value => typeof value == 'object');
    return pagination !== undefined && pagination.length > 0 ? (
      !validations ? (
        <Loader />
      ) : (
        pagination.map(item => (
          <Cards key={item.id}>
            <CardsContent>
              <Avatar>
                <Photo
                  src={
                    item.photo?.url ??
                    `https://corpoclinico.hsl.org.br/ImagemVisualizar.ashx?codigoMedico=${
                      item.id_api ?? item.id
                    }`
                  }
                  alt={!item.id_api ? 'Médico sem foto' : item.name}
                />
                {item && item.employee && (
                  <MandalaIcon
                    src={mandalaSirio}
                    alt="Mandala Sírio Libanês"
                    isMandala={item.hsl}
                  />
                )}
              </Avatar>
              <CardsDescription>
                <p>{item.name}</p>
                {item.crm && <p>CRM {item.crm}</p>}
                {item.specialty?.length > 0 && (
                  <p>
                    {item.specialty instanceof Array &&
                    item.specialty.length > 1
                      ? item.specialty.map(i =>
                          item.specialty instanceof Array &&
                          item.specialty[item.specialty.length - 1] !== i
                            ? `${i}; `
                            : i
                        )
                      : item.specialty}
                  </p>
                )}
                {item.phonecommercial && phoneFormatting(item.phonecommercial)}
              </CardsDescription>
            </CardsContent>
            {props.doctors.find(
              doctor =>
                (doctor.id_api === item.id ||
                  doctor.id_api === item.id_api ||
                  doctor.id_cms === item.id ||
                  doctor.id === item.id) &&
                doctor.active
            ) && (
              <LinkProfile to={`/encontre-seu-medico/${item?.slug}`}>
                <img src={arrowCircle} alt="Seta para direta" />
              </LinkProfile>
            )}
          </Cards>
        ))
      )
    ) : selectedTagFilters.length > 0 ? (
      <BoxErrorFilter>
        <TextErrorFilter>
          Nenhum resultado de acordo com seus filtros.
        </TextErrorFilter>
        <br />
        <TextErrorFilter>
          Por favor, tente
          <ButtonErrorFilter onClick={() => cleanFilter()}>
            limpar os filtros
          </ButtonErrorFilter>
        </TextErrorFilter>
      </BoxErrorFilter>
    ) : (
      <></>
    );
  };

  const renderAllDoctors = () => {
    return (
      pagination !== undefined &&
      pagination.length > 0 &&
      pagination.map(item => (
        <Cards key={item.id}>
          <CardsContent>
            <Avatar>
              <Photo
                src={
                  item.photo?.url ??
                  `https://corpoclinico.hsl.org.br/ImagemVisualizar.ashx?codigoMedico=${
                    item.id_api ?? item.id
                  }`
                }
                alt={!item.id_api ? 'Médico sem foto' : item.name}
              />
              {item && item.employee && (
                <MandalaIcon
                  src={mandalaSirio}
                  alt="Mandala Sírio Libanês"
                  isMandala={item.hsl}
                />
              )}
            </Avatar>
            <CardsDescription>
              <p>{item.name}</p>
              {item.crm && <p>CRM {item.crm}</p>}
              {item.specialty.length > 0 && (
                <p>
                  {item.specialty instanceof Array && item.specialty.length > 1
                    ? item.specialty.map(i =>
                        item.specialty instanceof Array &&
                        item.specialty[item.specialty.length - 1] !== i
                          ? `${i}; `
                          : i
                      )
                    : item.specialty}
                </p>
              )}
              {item.phonecommercial && phoneFormatting(item.phonecommercial)}
            </CardsDescription>
          </CardsContent>
          {props.doctors.find(
            doctor =>
              (doctor.id_api === item.id ||
                doctor.id_api === item.id_api ||
                doctor.id_cms === item.id ||
                doctor.id === item.id) &&
              doctor.active
          ) && (
            <LinkProfile to={`/encontre-seu-medico/${item?.slug}`}>
              <img src={arrowCircle} alt="Seta para direta" />
            </LinkProfile>
          )}
        </Cards>
      ))
    );
  };

  const renderListFilter = () => {
    return (
      pagination !== undefined &&
      pagination.length > 0 &&
      pagination.map(item => (
        <Cards key={item.id}>
          <CardsContent>
            <Avatar>
              <Photo
                src={
                  item.photo?.url ??
                  `https://corpoclinico.hsl.org.br/ImagemVisualizar.ashx?codigoMedico=${
                    item.id_api ?? item.id
                  }`
                }
                alt={!item.id_api ? 'Médico sem foto' : item.name}
              />
              {item && item.employee && (
                <MandalaIcon
                  src={mandalaSirio}
                  alt="Mandala Sírio Libanês"
                  isMandala={item.hsl}
                />
              )}
            </Avatar>
            <CardsDescription>
              <p>{item.name}</p>
              {item.crm && <p>CRM {item.crm}</p>}
              {item.specialty.length > 0 && (
                <p>
                  {item.specialty instanceof Array && item.specialty.length > 1
                    ? item.specialty.map(i =>
                        item.specialty instanceof Array &&
                        item.specialty[item.specialty.length - 1] !== i
                          ? `${i}; `
                          : i
                      )
                    : item.specialty}
                </p>
              )}
              {item.phonecommercial && phoneFormatting(item.phonecommercial)}
            </CardsDescription>
          </CardsContent>
          {props.doctors.find(
            doctor =>
              (doctor.id_api === item.id ||
                doctor.id_api === item.id_api ||
                doctor.id_cms === item.id ||
                doctor.id === item.id) &&
              doctor.active
          ) && (
            <LinkProfile to={`/encontre-seu-medico/${item?.slug}`}>
              <img src={arrowCircle} alt="Seta para direta" />
            </LinkProfile>
          )}
        </Cards>
      ))
    );
  };

  const renderSearchResult = () => {
    return (
      <>
        {pagination !== undefined &&
          pagination.length > 0 &&
          pagination.map(item => (
            <Cards key={item.id}>
              <CardsContent>
                <Avatar>
                  <Photo
                    src={
                      item.photo?.url ??
                      `https://corpoclinico.hsl.org.br/ImagemVisualizar.ashx?codigoMedico=${
                        item.id_api ?? item.id
                      }`
                    }
                    alt={!item.id_api ? 'Médico sem foto' : item.name}
                  />
                  {item && item.employee && (
                    <MandalaIcon
                      src={mandalaSirio}
                      alt="Mandala Sírio Libanês"
                      isMandala={item.hsl}
                    />
                  )}
                </Avatar>
                <CardsDescription>
                  <p>{item.name}</p>
                  {item.crm && <p>CRM {item.crm}</p>}
                  {item.specialty.length > 0 && (
                    <p>
                      {item.specialty instanceof Array &&
                      item.specialty.length > 1
                        ? item.specialty.map(i =>
                            item.specialty instanceof Array &&
                            item.specialty[item.specialty.length - 1] !== i
                              ? `${i}; `
                              : i
                          )
                        : item.specialty}
                    </p>
                  )}
                  {item.phonecommercial &&
                    phoneFormatting(item.phonecommercial)}
                </CardsDescription>
              </CardsContent>
              {props.doctors.find(
                doctor =>
                  (doctor.id_api === item.id ||
                    doctor.id_api === item.id_api ||
                    doctor.id_cms === item.id ||
                    doctor.id === item.id) &&
                  doctor.active
              ) && (
                <LinkProfile to={`/encontre-seu-medico/${item?.slug}`}>
                  <img src={arrowCircle} alt="Seta para direta" />
                </LinkProfile>
              )}
            </Cards>
          ))}
      </>
    );
  };

  const errorMessage = value => {
    let message;

    if (language === 'PT') {
      if (name && crm && specialty) {
        message =
          value[0] +
          ` <b>${name},</b> <br /> <b>` +
          value[1] +
          `${crm}</b> em <b>${specialty}</b>`;
      } else if (name) {
        message = value[0] + `<b>${name}</b>`;
      } else if (name && crm) {
        message =
          value[0] +
          `<b>${name},</b> <br /><b>` +
          value[1] +
          `
        ${crm}</b>`;
      } else if (name && specialty) {
        message =
          value[0] +
          `<b>${name}</b> em <br />
        <b>${specialty}</b>`;
      } else if (crm) {
        message = value[0] + `<b>${crm}</b>`;
      } else if (specialty) {
        message = value[0] + `<b>${specialty}</b>`;
      } else {
        message = value[0];
      }
    } else if (language === 'ES') {
      if (name && crm && specialty) {
        message =
          value[0] +
          `<b>${name},</b> <br /> <b>CRM 
        ${crm}</b> em <b>${specialty}</b>`;
      } else if (name) {
        message = value[0] + `<b>${name}</b>`;
      } else if (name && crm) {
        message =
          value[0] +
          `<b>${name},</b> <br /><b> CRM 
        ${crm}</b>`;
      } else if (name && specialty) {
        message =
          value[0] +
          `<b>${name}</b> em <br />
        <b>${specialty}</b>`;
      } else if (crm) {
        message = value[0] + `<b>${crm}</b>`;
      } else if (specialty) {
        message = value[0] + `<b>${specialty}</b>`;
      } else {
        message = value[0];
      }
    }

    return (
      <BoxError>
        <Title dangerouslySetInnerHTML={{ __html: message }} />
        <Subtitle>{value[3]} </Subtitle>
        <p>
          <b>•</b> {value[4]}
        </p>
        <p>
          <b>•</b> {value[5]}
        </p>
        <p>
          <b>•</b> {value[6]}
        </p>
        <p>
          <b>•</b> {value[7]}
        </p>
        <SimpleButton
          label={value[8]}
          isButtonActive
          marginTop="1.625rem"
          onClick={() => {
            setHideHeader(false);
            setModalResults(false);
            props.closedButton();
          }}
        />
      </BoxError>
    );
  };

  const getScroll = () => {
    const box = document.getElementById('scrollBox');
    const teste = box.getBoundingClientRect();
    if (teste.x !== 1) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    const filterByEmployee = selectedTagFilters.find(
      tagFilter => tagFilter === 'hsl_employee'
    );
    const filterByState = selectedTagFilters.find(
      tagFilter => tagFilter === 'sp' || tagFilter === 'bsb'
    );

    fetchingFilterDoctors({
      filterByEmployee,
      filterByState,
    });
  }, [selectedTagFilters, filters]);

  //Force react-paginate component remount to reset active page
  useEffect(() => {
    setRemountComponent(Math.random());
  }, [filters, selectedTagFilters]);

  useEffect(() => {
    setListFiltered();
  }, [selectedFilters]);
  useEffect(() => {
    let hasHslEmployee = props.noSearch
      ? doctorsOncology.some(item => item.employee === true)
      : filters && filters.some(item => item.employee === true);
    let hasSpState = props.noSearch
      ? doctorsOncology.some(item => item.state?.includes('sp'))
      : filters && filters.some(item => item.state?.includes('sp'));
    let hasBsbState = props.noSearch
      ? doctorsOncology.some(item => item.state?.includes('bsb'))
      : filters && filters.some(item => item.state?.includes('bsb'));

    const hslEmployee = {
      ...tagFilters[tagFilters.findIndex(tag => tag.value === 'hsl_employee')],
      disabled: !hasHslEmployee,
    };

    const spState = {
      ...tagFilters[tagFilters.findIndex(tag => tag.value === 'sp')],
      disabled: !hasSpState,
    };

    const bsbState = {
      ...tagFilters[tagFilters.findIndex(tag => tag.value === 'bsb')],
      disabled: !hasBsbState,
    };

    const enableFilters = props.noSearch
      ? [spState, bsbState].filter(item => item.disabled === false)
      : [spState, bsbState, hslEmployee].filter(
          item => item.disabled === false
        );
    const hasUniqueEnableFilter =
      enableFilters.length === 1 ? enableFilters[0].value : false;

    hasUniqueEnableFilter
      ? setSelectedTagFilters([hasUniqueEnableFilter])
      : setSelectedTagFilters([]);
    if (props.noSearch) {
      setTagFilters([spState, bsbState]);
    } else {
      setTagFilters([spState, bsbState, hslEmployee]);
    }
  }, [filters, doctorsOncology]);

  useEffect(() => {
    if (modalResults && props.modalHeader) {
      if (typeof window !== 'undefined') {
        if (window.innerWidth <= 1024) {
          // Faz com que o menu não
          setHideHeader(false);
        }
      }
    }
  }, [modalResults]);

  const especialidade =
    specialtyList &&
    specialtyList.filter(
      item =>
        item.descSpecialty === 'Onco Hematologia e Hemoterapia' ||
        item.descSpecialty === 'Oncologia Clínica' ||
        item.descSpecialty === 'Oncologia' ||
        item.descSpecialty === 'Oncologia Médica' ||
        item.descSpecialty === 'Radioterapia'
    );

  return (
    <ContainerGrid
      render={renderList}
      modal={props.modal}
      showModal={modalResults}
      backgroundMobile={'#fff'}
      pathname={props.location && props.location.pathname}
      containerGridMargin={props.containerGridMargin}
    >
      <GridContent modal={renderList} list={pagination && pagination.length}>
        {!props.noSearch && (
          <HeaderModal>
            <button>
              <img
                src={closeIcon}
                alt="Fechar"
                onClick={() => {
                  setHideHeader(false);
                  setModalResults(false);
                  props.closedButton();
                }}
              />
            </button>
            <div>Resultados de Busca</div>
          </HeaderModal>
        )}
        {renderList === 'search' && filters && filters.length > 0 ? (
          <Title isSearch>
            {pagination &&
            pagination.length >= 1 &&
            renderFilter === true &&
            listItems !== 'bsb' &&
            listItems !== 'sp'
              ? `Encontramos ${listItems && listItems.length} resultado${
                  listItems.length > 1 ? 's' : ''
                } para `
              : `Encontramos ${filters && filters.length} resultado${
                  filters.length > 1 ? 's' : ''
                } para `}

            {specialty && nameSearch && crm ? (
              <b>
                {nameSearch}, CRM {crm} em {specialty}
              </b>
            ) : specialty && nameSearch ? (
              <b>
                {nameSearch} em {specialty}
              </b>
            ) : specialty && crm ? (
              <b>
                CRM {crm} em {specialty}
              </b>
            ) : nameSearch && crm ? (
              <b>
                {nameSearch}, CRM {crm}
              </b>
            ) : specialty ? (
              <b>{specialty}</b>
            ) : crm ? (
              <b>CRM {crm}</b>
            ) : (
              <b>{nameSearch}</b>
            )}
            {practiceArea && ' na área de atuação '}
            {practiceArea && <b>{practiceArea}</b>}
          </Title>
        ) : renderList === 'search' && filters && filters.length <= 0 ? (
          errorMessage(textLabelErrorMessage)
        ) : (
          <>
            {props.noSearch ? (
              <>
                <Title noSearch>Conheça nossa equipe</Title>
                <BoxTags
                  page={props.location && props.location.pathname}
                  cdo
                  isSearch={renderList}
                  scroll={scroll}
                  onScroll={ev => getScroll()}
                  displayTags={props.displayTags}
                >
                  {tagFilters.map(tag => (
                    <Tags
                      id="scrollBox"
                      key={tag.value}
                      onClick={() => !tag.disabled && getTags(tag.value)}
                      background={selectedTagFilters.find(
                        value => value === tag.value
                      )}
                      disabled={tag.disabled}
                    >
                      {tag.display}
                    </Tags>
                  ))}
                </BoxTags>
              </>
            ) : (
              <Title>Conheça nossa equipe</Title>
            )}
          </>
        )}
        {!(renderList === 'search' && filters && filters.length <= 0) && (
          <BoxTags
            page={props.location && props.location.pathname}
            cdo
            isSearch={renderList}
            scroll={scroll}
            onScroll={ev => getScroll()}
            displayTags={props.displayTags}
          >
            {(props.location &&
              props.location.pathname === '/encontre-seu-medico/') ||
            props.location.pathname === '/encontre-seu-medico'
              ? (pagination && pagination.length > 1) || listItems !== undefined
                ? tagFilters.map(tag => (
                    <Tags
                      id="scrollBox"
                      key={tag.value}
                      onClick={() => !tag.disabled && getTags(tag.value)}
                      background={selectedTagFilters.find(
                        value => value === tag.value
                      )}
                      disabled={tag.disabled}
                    >
                      {tag.display}
                    </Tags>
                  ))
                : null
              : especialidade.map(item => (
                  <Tags
                    id="scrollBox"
                    key={item.descSpecialty}
                    onClick={() => getTagsSpecialty(item.descSpecialty)}
                    background={selectedFilters.some(
                      value => value === item.descSpecialty
                    )}
                  >
                    {item.descSpecialty}
                  </Tags>
                ))}
          </BoxTags>
        )}

        {props.location &&
        props.location.pathname === '/encontre-seu-medico/' &&
        filters.length < 1 ? null : (
          <ContainerCards
            render={renderList}
            marginTopLaptop={props.containerCardsMarginTopLaptop}
          >
            {renderFilter
              ? renderFiltered()
              : props.isCatch
              ? renderErrorAPI()
              : handleList()}

            <PaginationGeneral
              key={remountComponent}
              dataPagination={handleDataPagination()}
              team
              isDesktop={props.isDesktop}
              itensPerPage={props.itensPerPage}
              paddingBottom={30}
            />
          </ContainerCards>
        )}
        {renderList === 'search' && filters && filters.length <= 0 ? null : (
          <Alert
            paddingContent="1.75rem 2.8125rem"
            marginTopContent={pagination.length < 15 && '2rem'}
            marginBottomContent={pagination.length < 15 ? '0' : '3rem'}
            justifyContentCenter
            alignItemsContentCenter
            backgroundColorContent="#fff"
            alertText={
              language === 'PT'
                ? 'O Hospital Sírio-Libanês possui corpo clínico aberto, ou seja, os médicos aqui apresentados utilizam-se das instalações do Hospital para tratamento de seus pacientes. Esta relação de nomes não serve como indicação de profissionais. A escolha do médico é uma decisão exclusiva do paciente.'
                : 'El Hospital Sírio-Libanês tiene un personal clínico abierto, es decir, los médicos aquí presentados utilizan las instalaciones del Hospital para tratamiento de sus pacientes. Esta relación de nombres no sirve como indicación de profesionales. La elección del médico es una decisión exclusiva del paciente.'
            }
          />
        )}

        {mobile ? (
          renderList === 'search' && filters && filters.length <= 0 ? null : (
            <BackButton>
              <a href={`/encontre-seu-medico/`}>
                <TmpButton type="primary" size="sm">
                  Voltar
                </TmpButton>
              </a>
            </BackButton>
          )
        ) : null}
      </GridContent>
    </ContainerGrid>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            fragments(
              where: { id: "cky1sjlfc2r7t0b30bhcofjqe" }
              locales: [en, es, pt_BR]
            ) {
              localizations {
                locale
                singletexts
                markdown
              }
            }
          }
        }
      `}
      render={response => {
        return <OurTeam fragments={response.gcms.fragments} {...props} />;
      }}
    />
  );
};
