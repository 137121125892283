import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { device } from '../device';

import { InternacionalizationContext } from '../../context/internacionalization';

const DropDownContainer = styled('div')`
  margin: -0.25rem 5px -0.25rem 30px;
  cursor: pointer;
  display: ${props => (props.showSelect ? 'flex' : 'none')};
  align-items: center;

  &.selected {
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  }

  @media ${device.laptop} {
    display: ${props =>
      props.isMobile && props.showSelect ? 'block' : 'none'};
    ${props =>
      props.isMobile &&
      css`
        margin-left: auto;
        position: absolute;
        left: 70%;
        top: -22px;
        ul {
          left: 0;
          top: 0;
          z-index: 9999;
        }
      `}
  }
`;

const DropDownHeader = styled.div`
  width: 85px;
  padding-top: 0.2rem;
  font-weight: 500;
  font-size: 0.875rem;
  color: #8f9194;
  background: transparent;
  border: none;
  box-shadow: none;
  display: flex;
  position: relative;

  :active arrow {
    transform: rotate(-136deg);
  }
  :first-child {
    margin-left: 10px;
  }
  img {
    margin-left: 0.625rem;
  }

  .arrow {
    border: solid #012365;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    width: 0px;
    position: absolute;
    left: 59px;
    top: 5px;
    transition: all 0.4s ease;
    z-index: 9999;
  }

  // .arrow:active {
  //   transform: rotate(-136deg);
  // }

  .down {
    transform: rotate(45deg);
    transform: ${props => props.isOpen && 'rotate(224deg)'};
  }
`;

const DropDownListContainer = styled('div')`
  position: relative;
`;

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: #ffffff;
  box-sizing: border-box;
  color: #8f9194;
  font-size: 0.875rem;
  font-weight: 500;
  position: absolute;
  cursor: pointer;
  top: 14px;
  width: 95px;
  border: none;
  border-radius: 0 0 8px 8px;
  left: -95px;

  li {
    border-radius: 0 0 8px 8px;
    :hover {
      background-color: #f4f5f7;
    }
    padding: 8px 0 8px 0.625rem;
    display: flex;
    span {
      img {
        margin-left: 0.625rem;
      }
    }
  }
`;

const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 0;
`;

const CustomerSelect = ({ items = [], isMobile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(items[0]);
  const { setLanguage, showSelect } = useContext(InternacionalizationContext);
  const toggle = () => setIsOpen(!isOpen);

  const handleOnClick = value => () => {
    setSelectedOption(value);
    setIsOpen(false);
    setLanguage(value.value);
  };

  return (
    <DropDownContainer
      className={isOpen === true ? 'selected' : ''}
      isMobile={isMobile}
      showSelect={showSelect}
    >
      <DropDownHeader onClick={toggle} isOpen={isOpen}>
        <p>{selectedOption.value}</p>
        <p>{selectedOption.label}</p>
        <i className="arrow down"></i>
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {items
              .filter(option => option.value !== selectedOption.value)
              .map(option => (
                <ListItem onClick={handleOnClick(option)} key={Math.random()}>
                  <span>{option.value}</span>
                  <span>{option.label}</span>
                </ListItem>
              ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};
export default CustomerSelect;
